import React, { Suspense, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { Button, BouncingLoader } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SEVERITY } from 'utils/toast'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { convertTo12HourFormat, DATE_FORMATS, formatDate } from 'utils/date'
import {
  createNewCalendarEvent,
  removeCalendarEvent
} from 'services/CalendarService'
import { CalendarContext } from 'hooks/CalendarContext'

interface IExtractedDays {
  book_chapter_id: string
  class_date: string
  class_time: string
  class_time_end: string
  class_topic: any
  class_topic_number: any
  end_date: string
  end_date_id: string
  exam_id: any
  id: string
  meeting_url: any
  weekday: string
  present_in_calendar: boolean
  student_calendar_event_id: string
  semester_name: string | null
}
const NextClassessTable = ({ extractedDays, event, handleClose }) => {
  const eventStudentId = event.student_item_id

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    attachNewEventToDate,
    detachEventFromDate,
    addInactiveLiveClass,
    removeInactiveLiveClass
  } = useContext(CalendarContext)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const addEventToDate = extractedDay => () => {
    const endDateId = extractedDay.id

    const payload = {
      event_type: 'custom_live_class',
      data: {
        course_end_date_day_id: endDateId,
        student_book_chapter_id: eventStudentId,
        parent_event_id:
          event.type === 'custom_live_class' ? event.parent_event_id : event.id
      }
    }

    const handleSuccess = response => {
      if ([200, 201].includes(response.status)) {
        attachNewEventToDate(response.data)
        addInactiveLiveClass(
          event.type === 'custom_live_class' ? event.parent_event_id : event.id
        )

        showToast({
          key: 'toast.addExtraLiveClassSuccess',
          severity: SEVERITY.success
        })
        handleClose()
      }
    }

    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    createNewCalendarEvent(payload).then(handleSuccess).catch(handleError)
  }

  const removeEventToDate = extractedDay => () => {
    const eventId = extractedDay.student_calendar_event_id

    const handleSuccess = response => {
      if ([200, 201].includes(response.status)) {
        detachEventFromDate(extractedDay)
        if (event.type === 'custom_live_class') {
          removeInactiveLiveClass(event.parent_event_id)
        } else {
          removeInactiveLiveClass(event.id)
        }

        showToast({
          key: 'toast.removeExtraLiveClassSuccess',
          severity: SEVERITY.success
        })
        handleClose()
      }
    }

    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    removeCalendarEvent({ id: eventId }).then(handleSuccess).catch(handleError)
  }

  const extractedDaysRows = extractedDays.map(
    (extractedDay: IExtractedDays, index) => {
      const isUsed = extractedDay.present_in_calendar
      const formattedDate = `${formatDate(
        extractedDay.class_date,
        DATE_FORMATS.describedMonthDay
      )}, ${convertTo12HourFormat(
        extractedDay.class_time
      )}–${convertTo12HourFormat(extractedDay.class_time_end)}`

      return (
        <Suspense key={index} fallback={<BouncingLoader />}>
          <TableRow>
            <TableCell>{formattedDate}</TableCell>
            <TableCell>
              <ActionButton>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={addEventToDate(extractedDay)}
                  disabled={isUsed}
                >
                  {t('calendar.button.addToCalendar')}
                </Button>

                <Button
                  variant='contained'
                  color='secondary'
                  onClick={removeEventToDate(extractedDay)}
                  disabled={!isUsed}
                >
                  {t('calendar.button.removeFromCalendar')}
                </Button>
              </ActionButton>
            </TableCell>
          </TableRow>
        </Suspense>
      )
    }
  )

  return <tbody>{extractedDaysRows}</tbody>
}

export default NextClassessTable

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  text-align: left;
  &:hover {
    background-color: #f9f9f9;
  }
`

const TableCell = styled.td`
  padding: 10px;
`
const ActionButton = styled.div`
  margin-right: 5px;
  & > *:first-child {
    margin-right: 50px;
  }
`
