import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'

import ApexChart from 'react-apexcharts'
import {
  TimeChart,
  convertNullsToZero,
  sortByWeekOrder
} from 'modules/learningTime/utils/TimeChart'
import useOutsideClick from 'hooks/useOutsideClick'

import { fetchStudyTimeData } from 'services/DashboardService'
import {
  CloseIcon,
  IconButton,
  NavArrowLeftIcon,
  NavArrowRightIcon,
  BouncingLoader
} from 'examkrackers-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useSelector } from 'react-redux'
import { getIsImpersonate } from 'modules/auth/ducks/selectors'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/learningTime/utils/events'

export const LearningTimeChart = ({ isOpened, handleIsClose, isFreeTrial }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(3)
  const [chartSerires, setChartSeries] = useState({
    1: [],
    2: [],
    3: []
  })
  const [ranges, setRanges] = useState({
    1: '',
    2: '',
    3: ''
  })
  const [chartConfig, setChartConfig] = useState({
    1: {},
    2: {},
    3: {}
  })
  const containerRef = useRef(null)
  const isImpersonate = useSelector(getIsImpersonate)
  const chartTitle = t('navigation.chart.chartTitle')

  const setLabelsForWeek = useMemo(
    () => (weekday, chart, chartTotalLeftOffset, topOffset) => {
      // Finds bar for specific week
      const weekdayBars = document.querySelector(
        `#learning-time-chart g[seriesName='${weekday}']`
      )

      // here for each bar a span is created with given week day and positioned absolutely to the chart container
      if (weekdayBars) {
        const barsList = Array.from(weekdayBars.children).filter(el =>
          // @ts-ignore
          R.includes('apexcharts-bar-area', el.classList)
        )
        const firstLetterofWeekday = weekday.slice(0, 1)
        barsList.forEach((bar, index) => {
          const barLabel = document.createElement('div')
          const barLabelText = document.createTextNode(firstLetterofWeekday)
          const barTotalLeftOffset = bar.getBoundingClientRect().left
          const barLeftOffset = barTotalLeftOffset - chartTotalLeftOffset

          // @ts-ignore
          barLabel.style.cssText = `position: absolute; left: ${
            barLeftOffset - 4 + 'px'
          }; top: ${topOffset}px; width: ${'29px'}; font-size: ${'12px'};`
          barLabel.className = 'x-axis-dynamic-custom-labels'

          barLabel.appendChild(barLabelText)
          // @ts-ignore
          chart.appendChild(barLabel)
        })
      }
    },
    [chartConfig]
  )

  const setLabels = useMemo(
    () => () => {
      const chart = document.getElementById('learning-time-chart')
      const chartXAxis = document.querySelector(
        '#learning-time-chart .apexcharts-xaxis'
      )
      // @ts-ignore
      const chartTotalLeftOffset = chart.getBoundingClientRect().left
      const topOffset =
        // @ts-ignore
        chartXAxis.getBoundingClientRect().top -
        chart.getBoundingClientRect().top

      setLabelsForWeek('Sun', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Mon', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Tue', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Wed', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Thu', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Fri', chart, chartTotalLeftOffset, topOffset)
      setLabelsForWeek('Sat', chart, chartTotalLeftOffset, topOffset)
    },
    [setLabelsForWeek]
  )

  useOutsideClick(containerRef, handleIsClose)

  const handleChartDates = result => {
    const dates = result.data.dates.slice(-12)
    const formattedDates = dates.map(date => format(new Date(date), 'MM/dd/yy'))
    setRanges({
      1: `${formattedDates[0]} - ${formattedDates[3]}`,
      2: `${formattedDates[3]} - ${formattedDates[7]}`,
      3: `${formattedDates[7]} - ${formattedDates[11]}`
    })
  }

  const handleChartData = result => {
    const data = result.data.data.map(item => ({
      ...item,
      data: item.data.slice(-12)
    }))

    const organisedData = R.pipe(
      // @ts-ignore
      sortByWeekOrder,
      convertNullsToZero
      // @ts-ignore
    )(data)

    const dataByPage = {
      // @ts-ignore
      1: organisedData.map(item => ({
        ...item,
        data: item.data.slice(0, 4)
      })),
      // @ts-ignore
      2: organisedData.map(item => ({
        ...item,
        data: item.data.slice(3, 7)
      })),
      // @ts-ignore
      3: organisedData.map(item => ({
        ...item,
        data: item.data.slice(-4)
      }))
    }

    // @ts-ignore
    setChartSeries(dataByPage)
  }

  useEffect(() => {
    // @ts-ignore
    setChartConfig({
      1: TimeChart(chartSerires[1], chartTitle),
      2: TimeChart(chartSerires[2], chartTitle),
      3: TimeChart(chartSerires[3], chartTitle)
    })
  }, [chartSerires])

  const handleLoading = isLoading => {
    setIsLoading(isLoading)
    setTimeout(() => setIsLoading(false), 1000)
  }

  useEffect(() => {
    eventEmitter.on(events.learningTimeChartLoading, handleLoading)
    return () => {
      eventEmitter.off(events.learningTimeChartLoading, handleLoading)
    }
  }, [])

  useEffect(() => {
    if (isOpened) {
      fetchStudyTimeData()
        .then(result => {
          handleChartDates(result)
          handleChartData(result)
          setTimeout(setLabels, 1000)
        })
        .catch(console.error)
    }
  }, [isOpened])

  const handleChangePage = page => () => {
    handleLoading(true)
    setCurrentPage(page)
  }

  const GraphPlaceholder = isNotNilOrEmpty(chartSerires[currentPage]) && (
    <ChartContainer id='learning-time-chart' ref={containerRef}>
      <ApexChart
        id='learning-time-chart-svg'
        // @ts-ignore
        options={chartConfig[currentPage]}
        series={R.pathOr([], [currentPage, 'series'], chartConfig)}
        type='bar'
        width='802px'
        height='273px'
      />
      <ButtonsContainer>
        <IconButton
          icon={<NavArrowLeftIcon />}
          variant='filled'
          onClick={handleChangePage(currentPage - 1)}
          disabled={currentPage === 1}
          size='small'
        />

        <DateRange>{ranges[currentPage]}</DateRange>

        <IconButton
          type='button'
          icon={<NavArrowRightIcon />}
          variant='filled'
          onClick={handleChangePage(currentPage + 1)}
          disabled={currentPage === 3}
          size='small'
        />
      </ButtonsContainer>
      <div onClick={handleIsClose} className='learning-time-close'>
        <CloseIcon />
      </div>
      {isLoading && (
        <LoadingOverlay>
          <BouncingLoader />
        </LoadingOverlay>
      )}
    </ChartContainer>
  )

  return isOpened ? (
    <Container isFreeTrial={isFreeTrial} isImpersonate={isImpersonate}>
      <AlertWrapper>{GraphPlaceholder}</AlertWrapper>
    </Container>
  ) : null
}

export default LearningTimeChart

const Container = styled.div`
  position: fixed;
  top: ${({ isFreeTrial, isImpersonate }) =>
    isFreeTrial || isImpersonate ? '96px' : '64px'};
  left: -16px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.mainMenu + 10};

  .learning-time-close {
    position: absolute;
    right: 12.5px;
    top: 12.5px;
    cursor: pointer;
    font-size: 9px;
  }
`

const AlertWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`
const ChartContainer = styled.div`
  // this is needed for positioning labels that are append with JS code on mount
  position: relative !important;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.chartShadow};
  overflow: hidden;

  // hides original X axis labels - but they are needed to keep the X axis area
  .xaxis-labels {
    opacity: 0;
  }
  .x-axis-dynamic-custom-labels {
    display: inline-block;
    color: ${({ theme }) => theme.colors.main.grey600};
    font-size: 12px;
    text-align: center;
  }
  .apexcharts-gridline {
    stroke: ${({ theme }) => theme.colors.dashboard.chartsGridLine};
  }

  .apexcharts-bar-area:hover {
    fill: ${({ theme }) => theme.colors.main.secondary500};
  }

  #learning-time-chart-svg {
    g.apexcharts-datalabels {
      transform: translateX(40px);
    }
  }

  .apexcharts-data-labels {
    transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
    opacity: 0;
  }
`
const ButtonsContainer = styled.div`
  position: absolute;
  top: 40px;
  /* right: 40px; */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  /* button {
    min-width: 170px;
  } */
`

const DateRange = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main.primary700};
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  opacity: 0.7;
`
