import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'
import AnalyticsService from 'services/AnalyticsService'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { ANALYTICS_EVENTS } from 'utils/analytics'

import PATHS from 'utils/paths'
import {
  authStudent,
  sendVerificationCode,
  verifySMSCode,
  checkPlivoStatus,
  sendVerificationCodeViaEmail
} from 'services/AuthService'
import { Heading1 } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import {
  getIsAuthorised,
  isAuthLoaded,
  getStudent,
  getHasCourses
} from 'modules/auth/ducks/selectors'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import jwt_decode from 'jwt-decode'
import ModalVerifySMS from 'modules/auth/components/ModalVerifySMS'
import { mapIndexed } from '../utils/ramda'

export const Auth = (): JSX.Element => {
  const [isVerified, setIsVerified] = useState<any>()
  const [verifyModalOpen, setVerifyModalOpen] = useState<any>(false)
  const [code, setCode] = useState()
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()

  const token = R.propOr('', 'token', params)
  // @ts-ignore
  const decodedToken = jwt_decode(token)

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  // @ts-ignore
  const studentEmail = decodedToken.student_email

  const hideAfter2ndChar = string =>
    R.pipe(
      mapIndexed((char, index) => (index > 1 ? '*' : char)),
      R.join('')
      // @ts-ignore
    )(string)

  const hiddenStudentEmail = R.pipe(
    R.split('@'),
    mapIndexed((string, index) =>
      index === 0 ? hideAfter2ndChar(string) : string
    ),
    R.join('@')
  )(studentEmail)

  const [plivoEnabled, setPlivoEnabled] = useState(false)

  const handleCheckPlivoStatus = () => {
    const handleSuccess = response => {
      setPlivoEnabled(response.data)
    }
    const handleError = response => {
      console.error(response)
    }
    checkPlivoStatus().then(handleSuccess).catch(handleError)
  }

  const handleAuth = async () => {
    try {
      const result = await authStudent({ token })
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
      LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
      fetchStudentDetails()
    } catch (e) {
      console.error(e)
      setIsVerified(false)
      setVerifyModalOpen(true)
    }
  }

  useEffect(() => {
    handleCheckPlivoStatus()
    if (process.env.REACT_APP_SMS_DEBUG === 'true' || !plivoEnabled) {
      handleAuth()
    } else {
      setIsVerified(false)
      setVerifyModalOpen(true)
    }
  }, [])

  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthFetched = useSelector(isAuthLoaded)
  const student = useSelector(getStudent)
  const hasCourses = R.propOr(false, 'has_courses', student)

  useEffect(() => {
    const sendEventsToAmplitude = async () => {
      if (isAuthorised && isAuthFetched) {
        console.log('isAuthorised', {
          student,
          hasCourses
        })
        const redirectPath = hasCourses ? PATHS.selectCourse : PATHS.exams
        await AnalyticsService(student).initUser()
        await AnalyticsService(student).logEvent(ANALYTICS_EVENTS.userLogin, {
          studentInfo: student,
          jwt: token
        })
        history.push(redirectPath)
      }
    }
    sendEventsToAmplitude()
  }, [student])

  useEffect(() => {
    if (isVerified === true) {
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
      handleAuth()
    }
  }, [isVerified])

  const handleSendSMSCode = () => {
    const handleSuccess = response => {
      showToast({
        key: 'toast.SMSSent',
        severity: SEVERITY.success
      })
    }
    const handleError = response => {
      console.log({ response })
      console.error(response)
    }
    sendVerificationCode({ token: token })
      .then(handleSuccess)
      .catch(handleError)
  }
  const handleSendSMSCodeViaEmail = () => {
    const handleSuccess = response => {
      showToast({
        key: 'toast.CodeSentByEmail',
        severity: SEVERITY.success,
        options: { email: hiddenStudentEmail }
      })
    }
    const handleError = response => {
      console.error(response)
    }
    sendVerificationCodeViaEmail({ token: token })
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleVerifySMSCode = () => {
    const handleSuccess = () => {
      setIsVerified(true)
      setVerifyModalOpen(false)
      showToast({
        key: 'toast.SMSVerifySuccess',
        severity: SEVERITY.success
      })
    }
    const handleError = response => {
      showToast({
        key: 'toast.SMSVerifyError',
        severity: SEVERITY.error
      })
      console.error(response)
    }
    verifySMSCode({ token: token, code: code })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.auth.login')}</title>
      </Helmet>
      <AuthContainer id='auth-container'>
        {verifyModalOpen ? (
          <ModalVerifySMS
            open={verifyModalOpen}
            handleSendCode={handleSendSMSCode}
            handleVerifyCode={handleVerifySMSCode}
            code={code}
            setCode={setCode}
            isVerified={isVerified}
            // @ts-ignore
            studentPhone={decodedToken.student_phone}
            handleSendCodeViaEmail={handleSendSMSCodeViaEmail}
          />
        ) : (
          <>
            <div className='bounce-ball' />
            <div className='title'>
              <Heading1>{t('auth.title')}</Heading1>
              <Heading1>{t('auth.subtitle')}</Heading1>
            </div>
          </>
        )}
      </AuthContainer>
    </React.Fragment>
  )
}

export const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }

  .bounce-ball {
    margin-right: 20px;
    color: ${({ theme }) => theme.colors.main.primary200};
    position: relative;
    display: inline-block;
    height: 40px;
    width: 15px;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fbae17;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
    }
  }

  @keyframes bounce {
    0% {
      top: 30px;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }
    35% {
      height: 15px;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
  }
`

export default Auth
