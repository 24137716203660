import LocalStorageService from '../services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from './storage'
import { isNotNilOrEmpty } from './ramda'

export const COURSE_LEARNING_TIME_STATUS = {
  started: 'started',
  paused: 'paused'
}

export const COURSE_TYPES = {
  live: 'live_course',
  onDemand: 'on_demand',
  freeTrial: 'free_trial'
}

export const saveCourseStorageKeys = async course => {
  await LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)

  await LocalStorageService.set(
    LOCAL_STORAGE_KEYS.originalCourseId,
    course.book_course_id
  )

  await LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)

  await LocalStorageService.set(
    LOCAL_STORAGE_KEYS.studentCourseType,
    course.type
  )

  if (isNotNilOrEmpty(course.end_date_id)) {
    await LocalStorageService.set(
      LOCAL_STORAGE_KEYS.studentCourseEndDateId,
      course.end_date_id
    )
  }
}

export const removeCourseStorageKeys = () => {
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseType)
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseEndDateId)
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.originalCourseId)
}
