import React, { useContext, useEffect, memo } from 'react'

import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import { ArrowDownIcon, Tooltip } from 'examkrackers-components'
import { COURSE_TYPES } from 'utils/courses'
import CalendarSingleEvent from 'modules/calendar/CalendarSingleEvent'

import * as R from 'ramda'
import { addDays } from 'date-fns'

import eventEmitter from 'providers/eventEmitter'
import { CalendarContext } from 'hooks/CalendarContext'
import { isDateWithinInterval } from 'utils/date'

const CalendarDayEventsList: React.FC<{
  currentlyChecked: string
  shouldDisplayEndDate: boolean
  isDashboard?: boolean
  isFirstClassBeforeStart: boolean
}> = memo(
  ({
    currentlyChecked,
    shouldDisplayEndDate,
    isDashboard = false,
    isFirstClassBeforeStart = false
  }) => {
    const { calendar, course, lists } = useContext(CalendarContext)
    const [isDayExpanded, setIsDayExpanded] = React.useState(false)
    const [listTooLong, setListTooLong] = React.useState(false)

    const courseMeta = JSON.parse(course?.original_metadata || '{}')
    const startDate = calendar?.start_at || ''

    const shouldGreyOutBeforeStartDay: boolean =
      isFirstClassBeforeStart &&
      new Date(currentlyChecked) < new Date(startDate)

    const getCombinedChildrenHeight = element => {
      let combinedHeight = 0
      for (
        let i = 0;
        i < element?.children.length - (listTooLong ? 1 : 0);
        i++
      ) {
        combinedHeight += element.children[i].offsetHeight
      }

      return combinedHeight
    }

    const addExpandDayButton = () => {
      const thisEventList = document.getElementById(
        `${currentlyChecked}-calendar-day-events-list`
      )
      const combinedHeight = getCombinedChildrenHeight(thisEventList)
      const heightThreshold = isDashboard ? 80 : 76
      return combinedHeight > heightThreshold
        ? setListTooLong(true)
        : setListTooLong(false)
    }

    const handleCloseExpandDay = e => {
      const thisEventList = document.getElementById(
        `${currentlyChecked}-calendar-day-events-list`
      )

      if (thisEventList && !thisEventList.contains(e.target)) {
        return setIsDayExpanded(false)
      }
      if (thisEventList && thisEventList.contains(e.target)) {
        return setIsDayExpanded(prev => !prev)
      }
    }

    const isFreeTrial = R.pipe(
      R.propOr('', 'type'),
      R.equals(COURSE_TYPES.freeTrial)
    )(course)

    const isWithin = isDateWithinInterval(new Date(currentlyChecked), {
      start: new Date(startDate),
      end: addDays(
        new Date(startDate),
        Number((courseMeta as { days_amount: string }).days_amount || '7') - 1
      )
    })

    const shouldDisableFreeTrialDay = isFreeTrial && !isWithin

    const renderSingleEvent = (item, index) => {
      if (isFreeTrial && item.is_locked_in_free_trial) {
        return (
          <Tooltip
            tooltipContent='Not available in free trial'
            id={item.id}
            key={`CalendarDayEventsList-CalendarSingleEvent-${item.id}`}
            data-testid={`CalendarDayEventsList-Tooltip-${item.id}`}
          >
            <CalendarSingleEvent
              data-testid={`CalendarDayEventsList-CalendarSingleEvent-${item.id}`}
              key={item.id}
              item={item}
              index={index}
              disabledInFreeTrial
              shouldDisableFreeTrialDay={shouldDisableFreeTrialDay}
              isDashboard={isDashboard}
            />
          </Tooltip>
        )
      }

      return (
        <CalendarSingleEvent
          data-testid={`CalendarDayEventsList-Tooltip-withoutTooltip-${item.id}`}
          key={`CalendarDayEventsList-CalendarSingleEvent-${item.id}`}
          item={item}
          index={index}
          shouldDisableFreeTrialDay={shouldDisableFreeTrialDay}
          isDashboard={isDashboard}
        />
      )
    }

    useEffect(() => {
      if (!listTooLong) {
        setIsDayExpanded(false)
      }
    }, [listTooLong])

    useEffect(() => {
      addExpandDayButton()
    }, [lists, listTooLong, setListTooLong])

    useEffect(() => {
      eventEmitter.on('closeExpandedDay', handleCloseExpandDay)

      return () => {
        eventEmitter.off('closeExpandedDay', handleCloseExpandDay)
      }
    }, [])

    return (
      <Droppable
        data-testid={`CalendarDayEventsList-Droppable-${currentlyChecked}`}
        droppableId={currentlyChecked}
        isDropDisabled={shouldDisableFreeTrialDay}
      >
        {(provided, snapshot) => {
          return (
            <List
              data-testid={`CalendarDayEventsList-List-${currentlyChecked}`}
              shouldDisableFreeTrialDay={shouldDisableFreeTrialDay}
              shouldDisplayEndDate={shouldDisplayEndDate}
              id={`${currentlyChecked}-calendar-day-events-list`}
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              isDayExpanded={isDayExpanded}
              isDashboard={isDashboard}
              shouldGreyOutBeforeStartDay={shouldGreyOutBeforeStartDay}
            >
              {lists &&
                lists[currentlyChecked]?.length > 0 &&
                lists[currentlyChecked].map((item, index) => {
                  return renderSingleEvent(item, index)
                })}
              {provided.placeholder}
              {listTooLong ? (
                <ButtonContainer
                  data-testid={`CalendarDayEventsList-ButtonContainer-${currentlyChecked}`}
                  isDayExpanded={isDayExpanded}
                  id={`${currentlyChecked}-show-more-button`}
                  onClick={e => eventEmitter.emit('closeExpandedDay', e)}
                >
                  <span data-testid='CalendarDayEventsList-span'>
                    <ArrowDownIcon data-testid='CalendarDayEventsList-ArrowDownIcon' />
                  </span>
                </ButtonContainer>
              ) : null}
            </List>
          )
        }}
      </Droppable>
    )
  }
)

export default CalendarDayEventsList

const List = styled.div<{
  isDraggingOver: boolean
  isDayExpanded: boolean
  shouldDisplayEndDate: boolean
  shouldDisableFreeTrialDay: boolean
  isDashboard: boolean
  shouldGreyOutBeforeStartDay: boolean
}>`
  position: relative;
  background: ${({
    shouldDisableFreeTrialDay,
    isDraggingOver,
    theme,
    shouldDisplayEndDate,
    shouldGreyOutBeforeStartDay
  }) =>
    shouldDisableFreeTrialDay
      ? '#f2f2f2'
      : isDraggingOver
      ? theme.colors.main.primary200
      : shouldDisplayEndDate
      ? 'white'
      : shouldGreyOutBeforeStartDay
      ? '#f2f2f2'
      : 'white'};
  border-radius: 6px;
  width: auto;
  flex: 1;
  padding: 3px;
  padding-bottom: ${({ isDayExpanded }) => (isDayExpanded ? '20px' : '3px')};
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-height: ${({ isDayExpanded, isDashboard }) =>
    isDayExpanded ? 'max-content' : isDashboard ? '88px' : '83px'};
  overflow: ${({ isDayExpanded }) => (isDayExpanded ? 'visible' : 'hidden')};
  z-index: ${({ isDayExpanded }) => (isDayExpanded ? '100' : 'unset')};
  transition: all 0.3s ease-in-out;
  ${({ isDayExpanded }) =>
    isDayExpanded ? ' box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);' : ''}

  div[data-tip] {
    width: 100%;
  }
`

const ButtonContainer = styled.button<{
  isDayExpanded: boolean
}>`
  background: ${({ isDayExpanded }) =>
    isDayExpanded
      ? 'none'
      : 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)'};
  position: absolute;
  bottom: -2px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99;
  transition: all 0.3s;
  border: none;
  overflow: clip;

  svg {
    display: flex;
    color: ${({ theme }) => theme.colors.main.primary500};
    padding: 0;
    font-size: 15px;
    z-index: 10;
    transform: ${({ isDayExpanded }) =>
      isDayExpanded
        ? 'rotate(180deg) scale(2.7) translateY(1px) '
        : 'scale(2.7) '};
    transition: all 0.3s ease-in-out;
  }
`
