import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getVideosList } from 'modules/videos/ducks/selectors'
import VideoListItem from './VideoListItem'
import VideoListInfiniteScroller from './VideoListInfiniteScroller'

export const VideoList = (): JSX.Element => {
  const videosList = useSelector(getVideosList)

  // @ts-ignore
  const renderVideos = videosList.map(video => (
    // @ts-ignore
    <VideoListItem key={`video-list-item-${video.id}`} video={video} />
  ))

  return (
    <Container>
      <VideosListContainer>
        {renderVideos}
        <VideoListInfiniteScroller />
      </VideosListContainer>
    </Container>
  )
}

export default VideoList

const Container = styled.div`
  margin-top: 24px;
  flex-grow: 1;
  max-height: calc(100% - 108px);
`

const VideosListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`
