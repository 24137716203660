import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { fetchCoursesListRoutine, removeCurrentCourseRoutine } from 'modules/courses/ducks/actions'
import { getCoursesList } from 'modules/courses/ducks/selectors'

import { EntitiesList } from 'examkrackers-components'
import { SORT_DIRECTION, DEFAULT_ROWS_PER_PAGE } from 'utils/table'

import { Course } from 'types'
import { pathOr, pipe, propOr } from 'ramda'
import qs from 'qs'

import LabelCourseAccess from 'modules/courses/components/LabelCourseAccess'
import ButtonCourseAccess from 'modules/courses/components/ButtonCourseAccess'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { removeCourseStorageKeys } from '../../../utils/courses'

export const TableCourses = (): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const courses: Course[] = useSelector(getCoursesList)

  const fetchCourses = React.useCallback(
    () => dispatch(fetchCoursesListRoutine()),
    [dispatch]
  )
  const removeCurrentCourse = React.useCallback(
    () => dispatch(removeCurrentCourseRoutine()),
    [dispatch]
  )

  React.useEffect(() => {
    fetchCourses()
    removeCourseStorageKeys()
    removeCurrentCourse()
  }, [])

  const hasAnyExpirationCourse = courses?.some(
    course => course.type === 'live_course'
  )

  const expirationHeader = hasAnyExpirationCourse
    ? [
        {
          columnId: 'semester',
          sortable: false,
          id: 'type',
          children: t('courses.table.headers.semester'),
          align: 'left'
        }
      ]
    : []

  const getExpirationColumn = course =>
    hasAnyExpirationCourse
      ? [
          {
            columnId: 'semester',
            cellProps: { align: 'left' },
            children: course.endDate
              ? course.endDate.semester_name
                ? course.endDate.semester_name
                : `${formatDate(
                    course.endDate.end_date,
                    DATE_FORMATS.described
                  )} MCATs`
              : ''
          }
        ]
      : []

  const headers = [
    {
      columnId: 'title',
      sortable: false,
      id: 'title',
      children: t('courses.table.headers.course'),
      align: 'left'
    },
    {
      columnId: 'type',
      sortable: false,
      id: 'type',
      children: t('courses.table.headers.type'),
      align: 'left'
    },
    ...expirationHeader,
    {
      columnId: 'expiration',
      sortable: false,
      id: 'expiration',
      children: t('courses.table.headers.expiration'),
      align: 'left'
    },
    {
      columnId: 'action',
      sortable: false,
      id: 'action',
      children: t('courses.table.headers.action'),
      align: 'right'
    }
  ]

  const rows = courses.map((course: Course) => ({
    id: `row-course-${course.id}`,
    cells: [
      {
        columnId: 'title',
        cellProps: { align: 'left' },
        children: <>{propOr('-', 'title', course)}</>
      },
      {
        columnId: 'type',
        cellProps: { align: 'left' },
        children: (
          <>{t(`courses.courseTypes.${propOr('unknown', 'type', course)}`)}</>
        )
      },
      ...getExpirationColumn(course),
      {
        columnId: 'expiration',
        cellProps: { align: 'left' },
        children: <LabelCourseAccess course={course} />
      },
      {
        columnId: 'action',
        cellProps: { align: 'right' },
        children: <ButtonCourseAccess course={course} />
      }
    ]
  }))

  const defaultRowsPerPage: number = pipe(
    pathOr(DEFAULT_ROWS_PER_PAGE, ['limit', 'take']),
    Number
  )(parsedQuery)

  return (
    <EntitiesList
      paginationPage={1}
      resultsText=''
      headers={headers}
      rows={rows}
      totalPages={1}
      emptyStateText={t('courses.table.emptyState')}
      defaultPage={1}
      defaultSortColumnId='title'
      defaultSortDirection={SORT_DIRECTION.asc}
      defaultRowsPerPage={defaultRowsPerPage as 10 | 50 | 100}
      onTableStateChange={() => {}}
    />
  )
}

export default TableCourses
