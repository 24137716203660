import React from 'react'
import styled from 'styled-components'

import TitleChapter from 'modules/books/components/TitleChapter'
import BookRightColumn from 'modules/books/components/BookRightColumn'
import ButtonNext from 'modules/books/components/ButtonNext'

import { Scroller } from 'examkrackers-components'
import NavigationBookViewer from 'modules/books/components/NavigationBookViewer'
import SelectChapterExam from 'modules/books/components/SelectChapterExam'
import TooltipGlossary from 'modules/books/components/TooltipGlossary'
import SectionContext from 'modules/books/components/SectionContext'

import SubchaptersList from 'modules/books/components/SubchaptersList'
import HeadingChapter from 'modules/books/components/HeadingChapter'
import TopHandlers from 'modules/books/components/TopHandlers'
export const BookViewer = (): JSX.Element => {
  return (
    <React.Fragment>
      <TopHandlers />
      <BookLayout>
        <TitleWrapper>
          <TitleChapter />
          <NavigationWrapper>
            <NavigationBookViewer />
            <SelectChapterExam />
            <SectionContext />
          </NavigationWrapper>
        </TitleWrapper>
        <Container>
          <LeftContainer>
            <LeftContainerInner id='left-container-scroller'>
              <TooltipGlossary />
              <HeadingChapter />
              <SubchaptersList />
              <ButtonNext />
            </LeftContainerInner>
          </LeftContainer>
          <BookRightColumn />
        </Container>
      </BookLayout>
    </React.Fragment>
  )
}

export default BookViewer

const BookLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ theme }) => theme.dimensions.studentTopNavHeight});
`

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  // BookLayout - Book title height
  height: calc(100% - 28px);
`

const LeftContainer = styled.div`
  min-width: 760px;
  width: 60.16%;
  height: 100%;
  flex: none;
  position: relative;
`

const LeftContainerInner = styled(Scroller)`
  // this is a tweak to show items on the left that were hiding under the overflow
  width: calc(100% + 5px);
  margin-left: -10px;
  margin-right: 5px;
  padding-left: 10px;
  height: 100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 0 8px 0 0;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 660px;
  position: relative;
`
const NavigationWrapper = styled.div`
  display: flex;
  gap: 5px;
`
