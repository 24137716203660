import React, { useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInViewport } from 'react-in-viewport'

import { selectListOfTopicsPagination } from 'modules/courseTopics/ducks/selectors'
import {
  fetchCourseTopicsRoutine,
  resetCourseTopicsListRoutine
} from 'modules/courseTopics/ducks/actions'

export const ListOfTopicsInfiniteScroller = (): JSX.Element => {
  const containerRef = useRef(null)
  const { inViewport } = useInViewport(containerRef, { rootMargin: '100px' })
  const topicsPagination = useSelector(selectListOfTopicsPagination)
  const dispatch = useDispatch()

  const fetchTopics = useCallback(
    () => dispatch(fetchCourseTopicsRoutine()),
    [dispatch]
  )

  const resetCourseTopicsList = useCallback(
    () => dispatch(resetCourseTopicsListRoutine()),
    [dispatch]
  )

  useEffect(() => {
    resetCourseTopicsList()
  }, [])

  useEffect(() => {
    if (inViewport) {
      fetchTopics()
    }
  }, [inViewport, topicsPagination])

  return (
    <>
      <div
        style={{ width: '1px', height: '1px', display: 'block' }}
        ref={containerRef}
      />
    </>
  )
}

export default ListOfTopicsInfiniteScroller
