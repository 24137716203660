import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import * as R from 'ramda'

import PATHS from 'utils/paths'
import { COURSE_TYPES } from 'utils/courses'
import { isNotNilOrEmpty } from 'utils/ramda'
import { previewStudentsEmail } from 'utils/user'

import { getStudent } from 'modules/auth/ducks/selectors'
import {
  areAllFlashcardsOff,
  getBooksNavigation,
  hasOnlyTestBundledBooks
} from 'modules/books/ducks/selectors'
import {
  getCurrentCourse,
  getCoursesList,
  getHasAllCoursesExpired,
  getAiTutorEnabledInCourse
} from 'modules/courses/ducks/selectors'
import { fetchStudentVideoCategories } from 'services/VideosService'

import {
  ExamEditorIcon,
  CalendarIcon,
  BooksContainedIcon,
  LogoutIcon,
  NotesIcon,
  VideoCameraIcon,
  FlashcardsIcon,
  DashboardIcon,
  CoursesIcon,
  TrophyIcon,
  ToDoListIcon,
  TutorsIcon,
  AIEvaluationIcon
} from 'examkrackers-components'
import { TetrisIcon } from 'examkrackers-amino-acid-game'

import { Course, RootReducer } from 'types'
import { VIDEO_CATEGORIES } from 'modules/videos/utils/categories'

export const useNavLinks = (push: (path: string) => void) => {
  const [hasTestBundleBookVideos, setHasTestBundleBookVideos] =
    React.useState<boolean>(false)
  const [isVideoChecked, setIsVideoChecked] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const user = useSelector(getStudent)
  const booksNavigation = useSelector((state: RootReducer) =>
    getBooksNavigation(state, push)
  )
  const hideFlashcardsPage = useSelector(areAllFlashcardsOff)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isPrioriDays = R.propOr(false, 'prioridays', currentCourse)
  const isExamAt = R.propOr(false, 'exam_at', currentCourse)
  const isSubscriptionCourse = currentCourse.type === 'on_demand'
  const isOnDemandCourse =
    currentCourse.type === 'on_demand' ||
    currentCourse.type === 'free_trial' ||
    currentCourse.type === 'live_course'
  const hasAllCoursesExpired = useSelector(getHasAllCoursesExpired)
  const courses: Course[] = useSelector(getCoursesList)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)
  const isCalendarAvailable =
    currentCourse.original?.is_calendar_enabled || false
  const isLiveCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.live)
  )(currentCourse)
  const onlyTestBundleBooks = useSelector(hasOnlyTestBundledBooks)
  const isSelectCoursePage = path === PATHS.selectCourse
  const isLoadingDashboardPage = path === PATHS.dashboardLoading
  const hideNavElements = isSelectCoursePage || isLoadingDashboardPage
  const isPreviewStudentsEmail = user.email === previewStudentsEmail
  const hasCourse = R.pipe(R.propOr('', 'id'), isNotNilOrEmpty)(currentCourse)

  const aiTutorEnabledInCourse = useSelector((state: RootReducer) =>
    getAiTutorEnabledInCourse(state)
  )

  const dashboardSettings = currentCourse.original?.dashboard_settings || '{}'
  const dashboardSettingsObject = JSON.parse(dashboardSettings)
  const isBookProgressWidgetEnabled =
    dashboardSettingsObject?.['books_progress_enabled'] || false
  const isTutorsWidgetEnabled =
    dashboardSettingsObject?.['tutoring_enabled'] || false
  const isStepsForSuccessWidgetEnabled =
    dashboardSettingsObject?.['steps_for_success_enabled'] || false

  const dashboardEnabled =
    isBookProgressWidgetEnabled ||
    isTutorsWidgetEnabled ||
    isStepsForSuccessWidgetEnabled ||
    isCalendarAvailable ||
    aiTutorEnabledInCourse

  const gamesNavigation = [
    {
      label: t('navigation.saltyBlox'),
      onClick: () => {
        push(PATHS.gameSaltyBlox)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameSaltyBlox
    },
    {
      label: t('navigation.saltyBloxRespiration'),
      onClick: () => {
        push(PATHS.gameRespiration)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameRespiration
    },
    {
      label: t('navigation.hangman'),
      onClick: () => {
        push(PATHS.gameHangman)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameHangman
    }
  ]

  const logoutLinks = [
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const flashcardLinks = hideFlashcardsPage
    ? []
    : [
        {
          label: t('navigation.flashcards'),
          icon: <FlashcardsIcon width='20px' height='20px' />,
          url: PATHS.flashcardsBoxes
        }
      ]

  const tutorsLinks = isTutorsWidgetEnabled
    ? [
        {
          label: t('navigation.tutoringSchedule'),
          icon: <TutorsIcon width='20px' height='20px' />,
          url: PATHS.tutoringSchedule
        }
      ]
    : []

  const dashboardLink = dashboardEnabled
    ? [
        {
          label: t('navigation.dashboard'),
          icon: <DashboardIcon width='20px' height='20px' />,
          url: PATHS.dashboard
        }
      ]
    : []

  const examStudentLinks = [
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const aiEvaluationLink = aiTutorEnabledInCourse
    ? [
        {
          label: t('navigation.aiEvaluation'),
          icon: <AIEvaluationIcon width='20px' height='20px' />,
          url: PATHS.aiEvaluation
        }
      ]
    : []

  const expiredCoursesLinks = [
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('courses.changeCTA'),
      icon: <CoursesIcon width='20px' height='20px' />,
      url: PATHS.selectCourse
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const courseStudentLinks = [
    ...dashboardLink,
    {
      label: t('navigation.books'),
      icon: <BooksContainedIcon width='20px' height='20px' />,
      nextLevel: booksNavigation,
      url: '#'
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    ...flashcardLinks,
    {
      label: t('navigation.notes'),
      icon: <NotesIcon width='20px' height='20px' />,
      url: PATHS.notes
    },
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    isFreeTrial
      ? isExamAt
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetupFreeTrial
          }
      : isLiveCourse
      ? isExamAt
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetupExpiration
          }
      : isSubscriptionCourse
      ? isPrioriDays
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetup
          }
      : {
          label: t('navigation.calendar'),
          icon: <CalendarIcon width='20px' height='20px' />,
          url: PATHS.calendar
        },
    {
      label: 'Games',
      icon: <TetrisIcon width='20px' height='20px' />,
      nextLevel: gamesNavigation
    },
    {
      label: t('navigation.leaderboard'),
      icon: <TrophyIcon width='20px' height='20px' />,
      url: PATHS.leaderboard
    },
    {
      label: t('navigation.topics'),
      icon: <ToDoListIcon width='20px' height='20px' />,
      url: PATHS.courseTopics
    },
    ...tutorsLinks,
    ...aiEvaluationLink,
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const courseStudentLinksWithoutCalendar = [
    ...dashboardLink,
    {
      label: t('navigation.books'),
      icon: <BooksContainedIcon width='20px' height='20px' />,
      nextLevel: booksNavigation,
      url: '#'
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    ...flashcardLinks,
    {
      label: t('navigation.notes'),
      icon: <NotesIcon width='20px' height='20px' />,
      url: PATHS.notes
    },
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: 'Games',
      icon: <TetrisIcon width='20px' height='20px' />,
      nextLevel: gamesNavigation
    },
    {
      label: t('navigation.leaderboard'),
      icon: <TrophyIcon width='20px' height='20px' />,
      url: PATHS.leaderboard
    },
    {
      label: t('navigation.topics'),
      icon: <ToDoListIcon width='20px' height='20px' />,
      url: PATHS.courseTopics
    },
    // {
    //   label: t('navigation.onboarding'),
    //   icon: <OnboardingIcon width='20px' height='20px' />,
    //   url: PATHS.onboarding
    // },
    ...aiEvaluationLink,
    ...tutorsLinks,
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const TestBundleWithVidsLinks = [
    ...dashboardLink,
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const getCourseLinks = () => {
    switch (true) {
      case hideNavElements || isPreviewStudentsEmail:
        return []
      case isSelectCoursePage && hasAllCoursesExpired:
        return examStudentLinks
      case !isCalendarAvailable:
        return courseStudentLinksWithoutCalendar
      case onlyTestBundleBooks:
        return examStudentLinks
      case isOnDemandCourse:
        return courseStudentLinks
      default:
        return courseStudentLinks
    }
  }

  const getExamLinks = () => {
    switch (true) {
      case onlyTestBundleBooks && hasTestBundleBookVideos:
        return TestBundleWithVidsLinks
      case isSelectCoursePage && !hasAllCoursesExpired:
        return logoutLinks
      case isPreviewStudentsEmail:
        return []
      case !isSelectCoursePage && hasAllCoursesExpired:
        return expiredCoursesLinks
      default:
        return examStudentLinks
    }
  }

  const fetchVideoCategoriesCount = () => {
    fetchStudentVideoCategories().then(response => {
      const allCategory = response.data.find(
        (category: { category: string; count: number }) =>
          category.category === VIDEO_CATEGORIES.all
      )

      // @ts-ignore
      const hasVideos = R.propOr(0, 'count', allCategory) > 0
      setHasTestBundleBookVideos(hasVideos)
      setIsVideoChecked(true)
    })
  }

  useEffect(() => {
    if (!isSelectCoursePage && hasCourse && !isVideoChecked) {
      fetchVideoCategoriesCount()
    }
  }, [hasCourse, isSelectCoursePage, isVideoChecked])

  return {
    getCourseLinks,
    getExamLinks,
    hasCourse,
    onlyTestBundleBooks,
    hideNavElements,
    isSelectCoursePage,
    isLoadingDashboardPage,
    courses,
    isFreeTrial,
    booksNavigation
  }
}

export default useNavLinks
