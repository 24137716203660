import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { isNotNilOrEmpty } from 'utils/ramda'
import PATHS from 'utils/paths'
import { useHistory } from 'react-router-dom'
import {
  shuffleFlashcards,
  getFlashcardsPLevelStats
} from 'services/FlashcardsService'
import { Tooltip, EyeIcon } from 'examkrackers-components'
import FlashcardsBoxTooltipContents from './FlashcardsBoxTooltipContents'
import { findLowestLevelWithFlashcards } from '../../../utils/flashcards'
import events from 'modules/flashcards/utils/events'
import eventEmitter from 'providers/eventEmitter'

interface IFlashcardsFilterBoxProps {
  name: string | JSX.Element
  count: string | JSX.Element | number
  color?: string
  query?: string
  smallTitle?: boolean
  chapterId?: string
  filter?: string | unknown
  filterId?: string | unknown
}

export const FlashcardsFilterBox = ({
  name,
  count = '',
  color = '',
  query = '',
  smallTitle,
  filter,
  filterId
}: IFlashcardsFilterBoxProps): JSX.Element => {
  const {
    push,
    location: { pathname }
  } = useHistory()
  const parsedQuery = qs.parse(query, { ignoreQueryPrefix: true })
  const containerRef = useRef(null)
  const isCustom = name === 'Custom'

  const newQuery = {
    limit: { take: 24, page: 1 },
    filter: parsedQuery
  }

  const redirectToList = () =>
    push(`${PATHS.flashcards}?${qs.stringify(newQuery)}`)

  const redirectToStudyView = startPlvl => {
    const query = qs.stringify(
      {
        filter: {
          ...parsedQuery,
          'f.proficiency_level': startPlvl
        },
        limit: {
          take: 1,
          page: 1
        }
      },
      {
        encode: false,
        addQueryPrefix: true
      }
    )

    const handleSuccess = () => {
      push(`${PATHS.flashcardsStudy}${query}`, {
        from: pathname
      })
      eventEmitter.emit(events.flashcardRedirectLoading, { isLoading: false })
    }
    const handleError = e => {
      eventEmitter.emit(events.flashcardRedirectLoading, { isLoading: false })
    }

    shuffleFlashcards().then(handleSuccess).catch(handleError)
  }

  const getPlvlsAndRedirectToStudyView = () => {
    eventEmitter.emit(events.flashcardRedirectLoading, { isLoading: true })

    const handleSuccess = response => {
      const { data } = response
      redirectToStudyView(findLowestLevelWithFlashcards(data))
    }
    const handleError = e => {
      console.error(e)
      eventEmitter.emit(events.flashcardRedirectLoading, { isLoading: false })
    }

    // @ts-ignore
    getFlashcardsPLevelStats({ filter: filter, filter_id: filterId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const pages = count =>
    [
      <Page1 key={`${name}-page-1`} />,
      <Page2 key={`${name}-page-2`} />,
      <Page3 key={`${name}-page-3`} />
    ].slice(0, count)

  const dummyData = [
    { proficiency_level: 1, count: 0 },
    { proficiency_level: 2, count: 0 },
    { proficiency_level: 3, count: 0 },
    { proficiency_level: 4, count: 0 },
    { proficiency_level: 5, count: 0 },
    { proficiency_level: 6, count: 0 },
    { proficiency_level: 7, count: 0 }
  ]

  const [pLevelStats, setPLevelStats] = useState<any>(dummyData)
  const [isLoading, setIsLoading] = useState(false)

  const fetchAllPLevelStats = () => {
    setIsLoading(true)
    const handleSuccess = response => {
      setPLevelStats(response.data)
      setIsLoading(false)
    }

    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }
    // @ts-ignore
    getFlashcardsPLevelStats({ filter: filter, filter_id: filterId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const handlePLevelTooltip = () => {
    fetchAllPLevelStats()
  }

  const wrapper = (
    <Wrapper
      isCustom={isCustom}
      onClick={isCustom ? () => {} : getPlvlsAndRedirectToStudyView}
    >
      <Back color={color} />
      <SideL color={color} />
      <SideR color={color} />
      <Front color={color} />
      {pages(count)}
    </Wrapper>
  )

  return (
    <Container color={color}>
      <Title smallTitle={smallTitle}>{name}</Title>
      {isCustom ? (
        wrapper
      ) : (
        <Tooltip
          tooltipContent={
            <FlashcardsBoxTooltipContents
              isLoading={isLoading}
              pLevelStats={pLevelStats}
            />
          }
          id={`plvl-${filter}-${filterId}`}
          place='bottom'
          afterShow={handlePLevelTooltip}
        >
          {wrapper}
        </Tooltip>
      )}
      <Footer>
        <Count>({count})</Count>
        {!isCustom && (
          <Actions ref={containerRef} disabled={Number(count) === 0}>
            <PreviewTrigger
              onClick={Number(count) === 0 ? () => {} : redirectToList}
            >
              <EyeIcon />
            </PreviewTrigger>
          </Actions>
        )}
      </Footer>
    </Container>
  )
}

export default FlashcardsFilterBox

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid ${props => props.theme.colors.main.grey300};
  background: ${props => props.theme.colors.backgrounds.main};
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  padding: 8px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: ${({ smallTitle }) => (smallTitle ? '11px' : '14px')};
  line-height: 16px;
`

export const Count = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 11px;
  line-height: 16px;
`

export const Actions = styled.div`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`

const Wrapper = styled.div`
  min-width: 60px;
  min-height: 44px;
  position: relative;
  cursor: ${({ isCustom }) => (isCustom ? 'arrow' : 'pointer')};
`

const Back = styled.div`
  position: absolute;
  width: 48px;
  height: 28px;
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  border-radius: 4px;
  z-index: 0;
  top: 0;
  right: 0;
`

const SideL = styled.div`
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  position: absolute;
  width: 21px;
  height: 11px;
  left: -3px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 0;
`

const SideR = styled.div`
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  position: absolute;
  width: 21px;
  height: 13px;
  left: 43px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 5;
`

const Front = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 28px;
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  border-radius: 4px;
  z-index: 4;
  bottom: 0;
  left: 0;
  font-weight: bold;
`

const PreviewTrigger = styled.div``

const Page1 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 3;
  left: 3px;
  bottom: 4px;
  border-radius: 4px;
`

const Page2 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 2;
  left: 6px;
  bottom: 8px;
  border-radius: 4px;
`

const Page3 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 1;
  left: 9px;
  bottom: 12px;
  border-radius: 4px;
`
