import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'

import ReorderSalty from 'assets/images/Calendar_Reorder_Saltys.svg'

import { getMcatDates } from 'services/CalendarService'

import {
  SingleSelect,
  CalendarIcon,
  Button,
  Tooltip
} from 'examkrackers-components'

import { getOptionByValue } from 'utils/form'
import {
  columnsOrderForExpiration,
  DAYS_BEFORE_FIRST_CLASS_FOR_PRE_READING_END_DATE,
  daysOfWeek,
  prevStateOfCalendarColumnsForExpiration
} from 'utils/calendar'
import { DATE_FORMATS, noTimezoneDate, removeUTCFromDate } from 'utils/date'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import DateInput from 'components/DateInput/DateInput'

import ReorderCalendarConfirmationModal from './ReorderCalendarConfirmationModal'
import DayListItem from 'modules/calendar/setup/DayListItem'
import LeftColumnQuestions from 'modules/calendar/setup/LeftColumnQuestions'
import useCalendarSettingsValidationForExpiration from 'hooks/useCalendarSettingsValidationForExpiration'
import { isAfter, format, subDays, addDays } from 'date-fns'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'
import * as R from 'ramda'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0],
  pre_reading_end_date: ''
}

const ReorderCalendarForExpiration: React.FC = () => {
  const [values, setValues] = useState(initialValues)

  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])

  const { t } = useTranslation()
  const history = useHistory()

  const currentCourse = useSelector(getCurrentCourse)
  const calendarStartAt = values.calendar_start_at

  const firstClassDate: string = R.pipe(
    R.pathOr('', ['endDateFirstDay', 'class_date']),
    removeUTCFromDate
  )(currentCourse)

  const accessibleTo = R.pipe(
    R.propOr('', 'accessible_to'),
    removeUTCFromDate
  )(currentCourse)

  const minPreReadingEndDate = addDays(calendarStartAt, 1)

  const maxPreReadingEndDate = subDays(
    noTimezoneDate(firstClassDate),
    DAYS_BEFORE_FIRST_CLASS_FOR_PRE_READING_END_DATE
  )

  const isLiveCourse = propOr('', 'type', currentCourse) === 'live_course'

  const currentChoiceOfUsersPrioriDays = isNotNilOrEmpty(
    currentCourse.prioridays
  )
    ? prevStateOfCalendarColumnsForExpiration(currentCourse.prioridays)
    : initialValues.prioriDays

  const hasStudentPreReading = currentCourse.is_pre_reading

  const [columnsData, setColumnsData] = useState(currentChoiceOfUsersPrioriDays)

  const courseId: string = propOr('', 'book_course_id', currentCourse)

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date =>
        isAfter(noTimezoneDate(removeUTCFromDate(date.mcat_date)), new Date())
      )
      .sort(
        (a, b) =>
          noTimezoneDate(removeUTCFromDate(a.mcat_date)).getTime() -
          noTimezoneDate(removeUTCFromDate(b.mcat_date)).getTime()
      )
      .map(date => ({
        label: format(
          noTimezoneDate(removeUTCFromDate(date.mcat_date)),
          DATE_FORMATS.slash
        ),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const { dndErrors, errors, touchedFields, setTouchedFields } =
    useCalendarSettingsValidationForExpiration({
      course: currentCourse,
      values,
      mcatDateOptions,
      columnsData,
      isReorder: true
    })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result
    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'prioritySix') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'prioritySeven') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
    if (name === 'pre_reading_end_date') {
      setValues(prev => ({ ...prev, exam_at: accessibleTo }))
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      mcat_at: currentCourse.mcatDate.id,
      exam_at: currentCourse.exam_at,
      calendar_start_at: currentCourse.calendar_start_at,
      prioriDays: currentCourse.prioridays,
      pre_reading_end_date: currentCourse.pre_reading_end_date
    }))
  }, [currentCourse])

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  return (
    <Container data-testid='ReorderCalendarForExpiration-Container'>
      <TitleWrapper data-testid='ReorderCalendarForExpiration-TitleWrapper-calendar.reorder.title'>
        {t('calendar.reorder.title')}
      </TitleWrapper>
      <TextContainer data-testid='ReorderCalendarForExpiration-TextContainer-calendar.reorder.description'>
        <SubtitleWrapper data-testid='ReorderCalendarForExpiration-SubtitleWrapper-calendar.reorder.description'>
          {t('calendar.reorder.description')}
        </SubtitleWrapper>
      </TextContainer>
      <ColumnsWrapper data-testid='ReorderCalendarForExpiration-ColumnsWrapper'>
        <LeftColumn data-testid='ReorderCalendarForExpiration-LeftColumn'>
          <QuestionContainer data-testid='ReorderCalendarForExpiration-QuestionContainer'>
            <DescWrapper data-testid='ReorderCalendarForExpiration-DescWrapper-calendar.setup.mcat.question'>
              <QuestionTitle data-testid='ReorderCalendarForExpiration-QuestionTitle-calendar.setup.mcat.question'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ReorderCalendarForExpiration-QuestionDescription-calendar.setup.mcat.tooltip'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper data-testid='ReorderCalendarForExpiration-SelectWrapper'>
              <StyledSelect
                data-testid='ReorderCalendarForExpiration-StyledSelect-mcatDateOptions'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && isNotNilOrEmpty(values.mcat_at) && (
            <Error data-testid='ReorderCalendarForExpiration-Error-mcatDate'>
              {errors.mcatDate}
            </Error>
          )}
          <QuestionContainer
            disabled={isNilOrEmpty(values.mcat_at)}
            data-testid='ReorderCalendarForExpiration-QuestionContainer'
          >
            <DescWrapper data-testid='ReorderCalendarForExpiration-DescWrapper-calendar.setup.start.question'>
              <QuestionTitle data-testid='ReorderCalendarForExpiration-QuestionTitle-calendar.setup.start.question'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ReorderCalendarForExpiration-QuestionTitle-calendar.setup.start.tooltip'>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ReorderCalendarForExpiration-PickerWrapper'>
              <DateInput
                data-testid='ReorderCalendarForExpiration-DateInput-calendar_start_at'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={firstClassDate}
                disabled
              />
            </PickerWrapper>
            <IconContainer data-testid='ReorderCalendarForExpiration-IconContainer'>
              <CalendarIcon
                data-testid='ReorderCalendarForExpiration-CalendarIcon'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && isNotNilOrEmpty(values.calendar_start_at) && (
            <Error data-testid='ReorderCalendarForExpiration-Error-startDate'>
              {errors.startDate}
            </Error>
          )}
          <>
            {hasStudentPreReading ? (
              <QuestionContainer
                data-testid='ReorderCalendarForExpiration-QuestionContainer'
                disabled={isNilOrEmpty(values.calendar_start_at)}
              >
                <DescWrapper data-testid='ReorderCalendarForExpiration-DescWrapper'>
                  {isLiveCourse ? (
                    <QuestionTitle data-testid='ReorderCalendarForExpiration-QuestionTitle-calendar.setup.endPreReading.question'>
                      {t('calendar.setup.endPreReading.question')}
                    </QuestionTitle>
                  ) : (
                    <QuestionTitle data-testid='ReorderCalendarForExpiration-QuestionTitle-calendar.setup.end.question'>
                      {t('calendar.setup.end.question')}
                    </QuestionTitle>
                  )}
                  <QuestionDescription data-testid='ReorderCalendarForExpiration-QuestionDescription-calendar.setup.end.tooltip'>
                    {t('calendar.setupForExpiration.end.tooltip')}
                  </QuestionDescription>
                </DescWrapper>

                <PickerWrapper data-testid='ReorderCalendarForExpiration-PickerWrapper'>
                  <DateInput
                    data-testid='ReorderCalendarForExpiration-DateInput-pre_reading_end_date'
                    name='pre_reading_end_date'
                    id='pre_reading_end_date_input'
                    value={values.pre_reading_end_date}
                    validate={() => true}
                    size='small'
                    onChange={handleValueChange}
                    minDate={minPreReadingEndDate}
                    maxDate={maxPreReadingEndDate}
                    withExtendModal
                  />
                </PickerWrapper>
                <IconContainer>
                  <CalendarIcon
                    data-testid='ReorderCalendarForExpiration-CalendarIcon'
                    onClick={handleCalendarManualOpen(
                      'prereading_end_date_input'
                    )}
                  />
                </IconContainer>
              </QuestionContainer>
            ) : null}
            {errors.endDate &&
              hasStudentPreReading &&
              isNotNilOrEmpty(values.pre_reading_end_date) && (
                <Error>{errors.endDate}</Error>
              )}
          </>
          {hasStudentPreReading && (
            <LeftColumnQuestions
              disabled={isNilOrEmpty(values.exam_at)}
              isLiveCourse
            />
          )}
        </LeftColumn>
        {hasStudentPreReading ? (
          <RightColumn data-testid='ReorderCalendarForExpiration-RightColumn'>
            <ReorganizeSaltyImage
              data-testid='ReorderCalendarForExpiration-ReorganizeSaltyImage'
              id='reorganize-salty'
              src={ReorderSalty}
              alt='Salty reorganize'
            />
            <DragDropContext
              onDragEnd={handleDragEnd}
              data-testid='ReorderCalendarForExpiration-DagDropContext'
            >
              <WrapperDnd
                disabled={isNilOrEmpty(values.exam_at)}
                data-testid='ReorderCalendarForExpiration-WrapperDnd'
              >
                <WeekDaysColumn data-testid='ReorderCalendarForExpiration-WeekDaysColumn'>
                  <>
                    <ColumnTitle data-testid='ReorderCalendarForExpiration-ColumnTitle-mandatoryDaysOff'>
                      {t('calendar.setup.mandatoryDaysOff')}
                    </ColumnTitle>
                    {columnsOrderForExpiration.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index <= 6) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ReorderCalendarForExpiration-DropZoneWrapper-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ReorderCalendarForExpiration-Droppable-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZone
                                    data-testid={`ReorderCalendarForExpiration-SingleDropZone-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <DropContainer
                                      data-testid={`ReorderCalendarForExpiration-DropContainer-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>

                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ReorderCalendarForExpiration-DayListItem-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZone>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                </WeekDaysColumn>

                <PrioriColumn data-testid='ReorderCalendarForExpiration-PrioriColumn-mandatoryDaysOff'>
                  <>
                    <ColumnTitle data-testid='ReorderCalendarForExpiration-ColumnTitle-studyDays'>
                      {t('calendar.setup.studyDays')}
                    </ColumnTitle>
                    {columnsOrderForExpiration.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index > 6) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ReorderCalendarForExpiration-DropZoneWrapper-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ReorderCalendarForExpiration-Droppable-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`ReorderCalendarForExpiration-SingleDropZoneActive-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`ReorderCalendarForExpiration-DropContainer-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ReorderCalendarForExpiration-DayListItem-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                </PrioriColumn>
              </WrapperDnd>
            </DragDropContext>
            {isNotNilOrEmpty(dndErrors) &&
              touchedFields.includes('priori_days') && (
                <DndErrors data-testid='ReorderCalendarForExpiration-DndErrors'>
                  {dndErrors.map(error => (
                    <Error data-testid='ReorderCalendarForExpiration-Error'>
                      {error}
                    </Error>
                  ))}
                </DndErrors>
              )}
          </RightColumn>
        ) : null}
      </ColumnsWrapper>
      <ButtonsWrapper data-testid='ReorderCalendarForExpiration-ButtonsWrapper'>
        <ButtonContainer data-testid='ReorderCalendarForExpiration-ButtonContainer-cancel'>
          <ButtonWrapper
            color='tertiary'
            onClick={handleCancel}
            data-testid='ReorderCalendarForExpiration-ButtonWrapper-cancel'
          >
            {t('calendar.button.cancel')}
          </ButtonWrapper>
        </ButtonContainer>
        <ButtonContainer data-testid='ReorderCalendarForExpiration-ButtonContainer'>
          {isNotNilOrEmpty(errors) ? (
            <Tooltip
              id='inactive-button'
              tooltipContent='This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
            >
              <ButtonWrapper
                data-testid='ReorderCalendarForExpiration-ButtonWrapper-reorder-button'
                variant='contained'
                color='secondary'
                onClick={() => {}}
                disabled={!hasStudentPreReading}
              >
                {t('calendar.reorder.button')}
              </ButtonWrapper>
            </Tooltip>
          ) : (
            <ReorderCalendarConfirmationModal
              data-testid='ReorderCalendarForExpiration-ReorderCalendarConfirmationModal'
              values={values}
              disabled={isNotNilOrEmpty(errors)}
              isLiveCourse={isLiveCourse}
              hasStudentPreReading={hasStudentPreReading}
            />
          )}
        </ButtonContainer>
      </ButtonsWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(ReorderCalendarForExpiration)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 10px;
  width: 616px;
  line-height: 14px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)<{
  isDraggingOver?: boolean;
}>`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  font-weight: bold;
`
const WeekDaysColumn = styled.div``
// const McatDaysColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: space-between;
// `
// const McatContainer = styled.div`
//   &:first-child {
//     margin-bottom: 33px;
//   }
// `
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;
  div {
    overflow-x: hidden;
  }
`

const ReorganizeSaltyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 133px;
  width: 135px;
  margin-left: 190px;
  margin-top: 51px;
  margin-bottom: 41px;
`

const Error = styled.div`
  color: red;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  justify-content: center;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`

// const WarningBox = styled.div`
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
// `

// const WarningBoxContent = styled.div`
//   text-align: center;
//   color: red;
// `
