import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useNavLinks from 'hooks/useNavLinks'
import { getHasCourses } from 'modules/auth/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { findLinkByLabel, TNavLink } from 'utils/links'
import { useTranslation } from 'react-i18next'
import BookItem from 'modules/books/components/BookItem.mobile'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { BackButton, Heading1 } from 'examkrackers-components'
import TopBarMobile from 'components/TopBar.mobile'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

const MenuBooks = (): JSX.Element => {
  const { t } = useTranslation()

  const { push } = useHistory()
  const hasCourses = useSelector(getHasCourses)
  const [activeBook, setActiveBook] = useState<TNavLink | undefined>(undefined)

  const { getCourseLinks, getExamLinks } = useNavLinks(push)

  const links = hasCourses ? getCourseLinks() : (getExamLinks() as TNavLink[])

  // @ts-ignore
  const BooksLinks: TNavLink | undefined = findLinkByLabel(
    links as TNavLink[],
    t('navigation.books')
  )

  const handleActiveBook = (book: TNavLink) => () => setActiveBook(book)
  const handleRemoveActiveBook = () => setActiveBook(undefined)

  useEffect(() => {
    eventEmitter.on(events.mobileBooksTabClick, handleRemoveActiveBook)
    return () => {
      eventEmitter.off(events.mobileBooksTabClick, handleRemoveActiveBook)
    }
  }, [])

  // @ts-ignore
  const renderBooks = propOr([], 'nextLevel', BooksLinks).map(book => (
    <BookItem
      key={book.label}
      bookLink={book}
      onClick={handleActiveBook(book)}
    />
  ))

  const handleChapterClick = (chapter: TNavLink) => () => push(chapter.url)

  // @ts-ignore
  const renderChapterItems = propOr([], 'nextLevel', activeBook).map(
    chapter => (
      <ChapterItem
        isInactive={chapter.isInactive}
        key={chapter.label}
        onClick={chapter.isInactive ? () => {} : handleChapterClick(chapter)}
      >
        {chapter.label}
      </ChapterItem>
    )
  )

  const renderChapters = isNotNilOrEmpty(activeBook) ? (
    <ChaptersMenu>
      <TopBarMobile />
      <BackButtonWrapper>
        <BackButton onClick={handleRemoveActiveBook}>
          {t('navigation.books')}
        </BackButton>
      </BackButtonWrapper>
      <Heading1 bold>{propOr('', 'label', activeBook)}</Heading1>
      <ChaptersContainer>{renderChapterItems}</ChaptersContainer>
    </ChaptersMenu>
  ) : null

  return (
    <>
      <br />
      <Heading1 bold>{t('navigation.books')}</Heading1>
      <Container>
        {renderBooks}
        {renderChapters}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: inline-flex;
  margin: 10px auto 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`

const BackButtonWrapper = styled.div`
  button {
    min-width: 107px;
  }
`

const ChaptersMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: calc(
    100% - (${({ theme }) => theme.dimensions.mobileBottomNavHeight})
  );
  width: 100%;
  overflow-x: hidden;
  background: ${props => props.theme.colors.backgrounds.main};
  z-index: ${({ theme }) => theme.zIndex.menu};
  padding: 0 17.5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ChaptersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`

const ChapterItem = styled.div<{ isInactive: boolean }>`
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1px;
  padding: 14px 0;
  border-bottom: 1px solid ${props => props.theme.colors.main.grey350};
  opacity: ${props => (props.isInactive ? 0.2 : 1)};
  &:first-of-type {
    border-top: 1px solid ${props => props.theme.colors.main.grey350};
  }
`

export default MenuBooks
