import React from 'react'
import styled from 'styled-components'
import AIEvaluationDashedLines from './AIEvaluationDashedLines'

const AIEvaluationGroupBox: React.FC = ({ children }) => {
  return (
    <Container id='ai-evaluation-group-box'>
      <AIEvaluationDashedLines />
      {children}
    </Container>
  )
}

export default AIEvaluationGroupBox

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.backgrounds.main};
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  box-shadow: ${props => props.theme.shadows.darkShadow};
  height: 140px;
  padding: 0 45px;
  position: relative;
  overflow: hidden;
`
