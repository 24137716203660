import { propOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { getSubchapterById } from '../ducks/selectors'
import { RootReducer } from 'types/reducers'
import ContentQuestionsPreview from './ContentQuestionsPreview'
import qs from 'qs'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

export const ContentQuestionWrapper = (): JSX.Element => {
  const { location } = useHistory()
  const { search } = location
  const params = useParams()
  const [isScrollable, setIsScrollable] = useState(true)

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const subchapterId: string = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const chapterOrder: string = propOr('1', 'chapterOrder', params)

  const subchapter = useSelector((state: RootReducer) =>
    getSubchapterById(state, subchapterId)
  )

  const subchapterNumber = `${chapterOrder}.${propOr('', 'order', subchapter)}`

  const onVideoOpened = () => setIsScrollable(false)
  const onVideoClosed = () => setIsScrollable(true)

  useEffect(() => {
    eventEmitter.on(events.videoOpened, onVideoOpened)
    eventEmitter.on(events.videoClosed, onVideoClosed)
    return () => {
      eventEmitter.off(events.videoOpened, onVideoOpened)
      eventEmitter.off(events.videoClosed, onVideoClosed)
    }
  }, [])

  return (
    <RightBottomScroll
      isScrollable={isScrollable}
      id='question-container'
      data-testid='ContentQuestionsPreview-container'
    >
      <ContentQuestionsPreview subchapterNumber={subchapterNumber} />
    </RightBottomScroll>
  )
}

export default ContentQuestionWrapper

const RightBottomScroll = styled.div<{ isScrollable: boolean }>`
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : 'hidden')};
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`
