import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import * as R from 'ramda'

const ExamLoadingPage: React.FC = () => {
  const [requestUrls, setRequestUrls] = useState<string[]>([])

  const handleRequestIntercepted = url => {
    if (url && url.includes('student-exam')) {
      setRequestUrls(prevUrls => R.uniq([...prevUrls, url]))
    }
  }

  const handleRequestCompleted = url => {
    if (url && url.includes('student-exam')) {
      setRequestUrls(prevUrls => prevUrls.filter(prevUrl => prevUrl !== url))
    }
  }

  useEffect(() => {
    eventEmitter.on(events.requestIntercepted, handleRequestIntercepted)
    eventEmitter.on(events.requestCompleted, handleRequestCompleted)

    return () => {
      eventEmitter.off(events.requestIntercepted, handleRequestIntercepted)
      eventEmitter.off(events.requestCompleted, handleRequestCompleted)
    }
  }, [])

  useEffect(() => {
    eventEmitter.emit(events.examPageLoading, {
      isLoading: requestUrls.length > 0
    })
  }, [requestUrls])

  return (
    // TODO: Remove this once we will specify what is the loading logic
    <LoadingImageContainer isLoading={false}>
      <img
        src='https://benchprep-public.s3.amazonaws.com/aamc/spinner_image.png'
        alt='Exam Loading'
      />
    </LoadingImageContainer>
  )
}

export default ExamLoadingPage

const LoadingImageContainer = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.main.white};
`
