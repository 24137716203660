import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'
import { Trans, useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

export const contentTypes = {
  saltyBucks: 'saltyBucks',
  exam: 'exam',
  flashcards: 'flashcards',
  contentQuestions: 'contentQuestions',
  bookProgress: 'bookProgress',
  speedometer: 'speedometer'
}

export const TooltipChart = (): JSX.Element => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [type, setContentType] = useState('default')

  const contents = {
    default: '',
    [contentTypes.saltyBucks]: (
      <Trans i18nKey='dashboard.charts.infoBox.saltyBucksDesc'>
        0<Title>{t('dashboard.charts.infoBox.saltyBucks')}</Title>2
      </Trans>
    ),
    [contentTypes.exam]: (
      <Trans i18nKey='dashboard.charts.infoBox.mcatDesc'>
        0<Title>{t('dashboard.charts.infoBox.mcat')}</Title>2
      </Trans>
    ),
    [contentTypes.flashcards]: (
      <Trans i18nKey='dashboard.charts.infoBox.flashcardsDesc'>
        0<Title>{t('dashboard.charts.infoBox.flashcards')}</Title>2
      </Trans>
    ),
    [contentTypes.contentQuestions]: (
      <Trans i18nKey='dashboard.charts.infoBox.contentQuestionsDesc'>
        0<Title>{t('dashboard.charts.infoBox.contentQuestions')}</Title>2
      </Trans>
    ),
    [contentTypes.bookProgress]: (
      <Trans i18nKey='dashboard.charts.infoBox.bookProgressDesc'>
        0<Title>{t('dashboard.charts.infoBox.bookProgress')}</Title>2
      </Trans>
    ),
    [contentTypes.speedometer]: (
      <>
        <ExplanationContainer>
          <ExplanationTitle>
            {t('dashboard.speedometer.speedometer')}
          </ExplanationTitle>
          <span> - </span>
          <ExplanationText>
            {t('dashboard.speedometer.speedometerDesc')}
          </ExplanationText>
        </ExplanationContainer>
        <ExplanationContainer>
          <ExplanationTitle>
            {t('dashboard.speedometer.acceleration')}
          </ExplanationTitle>
          <span> - </span>
          <ExplanationText>
            {t('dashboard.speedometer.accelerationDesc')}
          </ExplanationText>
        </ExplanationContainer>
        <ExplanationContainer>
          <ExplanationTitle>{t('dashboard.speedometer.oil')}</ExplanationTitle>
          <span> - </span>
          <ExplanationText>
            {t('dashboard.speedometer.oilDesc')}
          </ExplanationText>
        </ExplanationContainer>
        <ExplanationContainer>
          <ExplanationTitle>
            {t('dashboard.speedometer.temperature')}
          </ExplanationTitle>
          <span> - </span>
          <ExplanationText>
            {t('dashboard.speedometer.tempDesc')}
          </ExplanationText>
        </ExplanationContainer>
      </>
    )
  }

  const handleShowTooltip = data => {
    const event: MouseEvent = R.propOr(
      { pageX: 0, pageY: 0, type: '' },
      'e',
      data
    )
    const type: string = R.propOr('default', 'type', data)
    setIsVisible(true)
    setContentType(type)
    setPosition({ top: event.pageY, left: event.pageX })
  }

  const handleHideTooltip = () => {
    setIsVisible(false)
    setContentType('default')
    setPosition({ top: 0, left: 0 })
  }

  const debouncedMouseEnter = debounce(handleShowTooltip)
  const debouncedMouseLeave = debounce(handleHideTooltip)

  useEffect(() => {
    eventEmitter.on(events.chartTooltipEnter, debouncedMouseEnter)
    eventEmitter.on(events.chartTooltipLeave, debouncedMouseLeave)

    return () => {
      eventEmitter.off(events.chartTooltipEnter, debouncedMouseEnter)
      eventEmitter.off(events.chartTooltipLeave, debouncedMouseLeave)
    }
  }, [])

  return isVisible ? (
    <Container
      style={{ top: `${position.top}px`, left: `${position.left + 15}px` }}
      position={position}
    >
      {contents[type]}
    </Container>
  ) : (
    <div />
  )
}

export default TooltipChart

const Container = styled.div`
  display: inline-block;
  position: fixed;
  opacity: 0.9;
  z-index: ${({ theme }) => theme.zIndex.navigation + 1} !important;
  color: ${props => props.theme.colors.tooltip.font} !important;
  font-family: ${props => props.theme.typography.fontFamily} !important;
  z-index: ${({ theme }) => theme.zIndex.navigation + 1} !important;
  max-width: 347px;
  border-radius: 8px;
  font-size: 11px;
  padding: 18px;
  background: ${({ theme }) =>
    theme.colors.dashboard.speedometer.infoBoxBackground} !important;
  border: 1px solid
    ${({ theme }) => theme.colors.dashboard.speedometer.infoButtonBorder};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`

const Title = styled.span`
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: 700;
`

const ExplanationContainer = styled.div`
  line-height: 16px;
  margin-top: 8px;
  &:first-of-type {
    margin-top: 0;
  }
  &:before {
    white-space: pre;
  }
`
const ExplanationTitle = styled.span`
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: 700;
`
const ExplanationText = styled.span``
