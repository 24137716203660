import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import styled from 'styled-components'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'
import { BouncingLoader } from 'examkrackers-components'
import ResetCalendarModalNew from 'modules/calendar/ResetCalendarModalNew'

export const ResetSubscribtionCalendar = (): JSX.Element => {
  const { t } = useTranslation()
  const currentCourse: Course = useSelector(getCurrentCourse)

  const hasCourseFetched = R.pipe(
    R.propOr('', 'id'),
    isNotNilOrEmpty
  )(currentCourse)

  if (!hasCourseFetched) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.calendar')}</title>
      </Helmet>
      <ResetCalendarModalNew />
    </React.Fragment>
  )
}

export default ResetSubscribtionCalendar

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
