import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { COURSE_TYPES } from 'utils/courses'
import { pipe, propOr, equals } from 'ramda'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import NotificationFreeTrial from 'modules/courses/components/NotificationFreeTrial'

const TopBarMobile = (): JSX.Element => {
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = pipe(
    propOr('', 'type'),
    equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  return (
    <>
      {isFreeTrial && (
        <FreeTrialContainer>
          <NotificationFreeTrial />
        </FreeTrialContainer>
      )}
      <Container>
        <Logo src='/assets/logo/KrackUniversityLogoLightBg.svg' />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${({ theme }) => theme.dimensions.mobileTopNavHeight};
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
`

const Logo = styled.img`
  height: 34px;
`

const FreeTrialContainer = styled.div`
  position: sticky;
  top: 0;
  left: -20px;
  right: 0;
  z-index: 1000;
  margin: 0 -20px 5px;
  width: calc(100% + 40px);

  .free-trial-notification {
    height: 30px;
    background-color: ${({ theme }) => theme.colors.topNav.freeTrial};
  }
`

export default TopBarMobile
