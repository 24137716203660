import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialBackground = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialBackground)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialHighlight)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialZoom)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialZoom
    })
  }, [])

  return (
    <>
      <ExamContent>
        <TutorialBackgroundContainer>
          <div class='heavy-bordered content-container'>
            <h2>Use the Color Contrast Background Feature</h2>
            <h3>Description</h3>
            <p>
              There are 3 options for color contrast backgrounds for the exam:
              Default Color Scheme, Black on Salmon, and White on Black.
            </p>
            <h3>Instructions</h3>
            <p>
              Locate <strong>Default Color Scheme</strong> at the top right of
              the screen.
            </p>
            <ul>
              <li>
                To change background color, click{' '}
                <strong>Default Color Scheme</strong> and select the background
                color of your choice with the left mouse button.
              </li>
              <li>
                To use the keyboard only to change background selection, press
                Tab until the focus box appears on{' '}
                <strong>Default Color Scheme</strong>. Press the space bar, then
                press the down arrow to highlight the background color of your
                choice and then press enter or the spacebar.
              </li>
              <li>To move the focus box backwards press Shift+Tab.</li>
            </ul>
            <h3>Practice</h3>
            <p>
              Change the color contrast backgrounds to see how each background
              color choice displays. Note: You may need to adjust your highlight
              color based on your chosen background color.
            </p>
            <p>
              <strong>Important:</strong> Content was developed to render as
              displayed in the Default Color Scheme. If, when using a different
              background color, you find you need further clarification for a
              given image such as tight borders, view the image in the Default
              Color Scheme.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </TutorialBackgroundContainer>
      </ExamContent>
    </>
  )
}

export default TutorialBackground

const TutorialBackgroundContainer = styled.div`
  p {
    margin: 16px 0;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 0 !important;
    line-height: 22.4px !important;
  }

  .important {
    font-weight: bold;
  }
`
