import React, { useEffect, useCallback, useState } from 'react'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

import PATHS from 'utils/paths'

import { studentLogoutRoutine } from 'modules/auth/ducks/actions'

import { refreshTokenStudent } from 'services/AuthService'
import { getStudent } from 'modules/auth/ducks/selectors'
import { StudentProps } from 'types'

export const minutesToMilliseconds = minutes => minutes * 60000
const intervalTimePeriod = minutesToMilliseconds(10)

export const RefreshTokenProvider = (): JSX.Element => {
  const [pageLoaded, setPageLoaded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    location: { pathname }
  } = history
  const user: StudentProps = useSelector(getStudent)

  const logoutStudent = useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  const excludedPaths = [
    PATHS.unauthorized,
    PATHS.logout,
    PATHS.authImpersonation,
    PATHS.auth
  ]

  const handleRefreshToken = async () => {
    const currentToken = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)

    if (isNotNilOrEmpty(currentToken) && !excludedPaths.includes(pathname)) {
      try {
        const result = await refreshTokenStudent()
        LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
      } catch (e) {
        await AnalyticsService(user).logEvent(ANALYTICS_EVENTS.logoutOnError, {
          error: e,
          api_endpoint: '/students/refresh-token'
        })
        history.push(PATHS.unauthorized)
        logoutStudent()
        console.error(e)
      }
    }
  }

  useEffect(() => {
    const refreshToken = setInterval(handleRefreshToken, intervalTimePeriod)

    return () => {
      clearInterval(refreshToken)
    }
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(user.id) && !pageLoaded) {
      setPageLoaded(true)
      setTimeout(handleRefreshToken, 20000)
    }
  }, [user])

  return <div />
}

export default RefreshTokenProvider
