import { Button } from 'examkrackers-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  tasksImage,
  upcomingTasksImage,
  TasksType
} from './utils/tasksImageArray'

import styled from 'styled-components'

interface EmptyStateCardProps {
  isCurrentTask: boolean
  index?: number
  visibleTasks: any
  onSeeCalendarClick: () => void
}

const EmptyStateCard = ({
  isCurrentTask,
  index,
  visibleTasks,
  onSeeCalendarClick
}: EmptyStateCardProps): React.ReactElement => {
  const { t } = useTranslation()
  const isIndexOne = index && index === 1

  const isLastItem = index === visibleTasks?.length - 1

  const emptyTasksCount =
    visibleTasks?.filter(task => task.id === -1).length || 0

  const taskImage =
    tasksImage[TasksType.NO_TASK_REMAIN][TasksType.NO_TASK_REMAIN]

  const upcomingTaskImage =
    upcomingTasksImage[TasksType.NO_TASK_REMAIN][TasksType.NO_TASK_REMAIN]

  return (
    <>
      <React.Fragment>
        <TaskContainer isCurrentTask={isCurrentTask}>
          <TaskImage
            alt='task image'
            isCurrentTask={isCurrentTask}
            src={isCurrentTask ? taskImage : upcomingTaskImage}
          />
          <TaskContent>
            <TaskTitle>
              {isCurrentTask
                ? t('dashboard.nextTasks.emptyCurrentState')
                : t('dashboard.nextTasks.emptyUpcomingState')}
            </TaskTitle>
          </TaskContent>
          {isCurrentTask ? (
            <Button
              variant='contained'
              onClick={onSeeCalendarClick}
              size='small'
              color='primary'
            >
              {t('dashboard.nextTasks.calendar')}
            </Button>
          ) : null}
          {(isIndexOne && emptyTasksCount === 2) ||
          (index === 0 && emptyTasksCount === 3) ? (
            <Button
              variant='contained'
              onClick={onSeeCalendarClick}
              size='small'
              color='secondary'
            >
              {t('dashboard.nextTasks.calendar')}
            </Button>
          ) : null}
          {isLastItem || (emptyTasksCount === 3 && isIndexOne) ? (
            <StyledButton onClick={onSeeCalendarClick}>
              {t('dashboard.nextTasks.calendar')}
            </StyledButton>
          ) : null}
        </TaskContainer>
      </React.Fragment>
    </>
  )
}

export default EmptyStateCard

const TaskContainer = styled.div<{
  isCurrentTask: boolean
  className?: string
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: ${({ isCurrentTask }) => (isCurrentTask ? '152px' : '75px')};
  padding: 15px;
  margin-bottom: 15px;
`
const TaskImage = styled.img<{ isCurrentTask: boolean }>`
  width: ${({ isCurrentTask }) => (isCurrentTask ? '161px' : '40px')};
  height: ${({ isCurrentTask }) => (isCurrentTask ? '138px' : '40px')};
  object-fit: cover;
  border-radius: ${({ isCurrentTask }) => (isCurrentTask ? '4px' : '0')};
`

const TaskContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

const TaskTitle = styled.div`
  font-family: 'Arial Black', Arial, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
`
const StyledButton = styled.div`
  font-weight: bold;
  text-decoration: underline;
  color: #333;
  cursor: pointer;
  font-size: 14px;
`
