import { createSelector, OutputSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

export const selectCalendar = (state: any) => state.calendar

export const getCalendarAamcEventsColors: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectCalendar, propOr({}, 'aamcEventsColors'))

export const selectManualEvents = createSelector(
  selectCalendar,
  calendarState => {
    const activeTab: string = propOr(
      'readings',
      'manualCalendarActiveTab',
      calendarState
    )

    return pathOr([], ['manualEvents', activeTab], calendarState)
  }
)

// @ts-ignore
export const selectManualCalendarActiveTab: OutputSelector<any, string, (res: any) => string> = createSelector(
  selectCalendar,
  propOr('', 'manualCalendarActiveTab')
)

export const getVisibleDates = createSelector(
  selectCalendar,
  propOr([], 'visibleDates')
)
