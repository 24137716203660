import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ExamContent,
  ExamCollapse,
  ExamQuestionsReviewList,
  EndSectionModal,
  NoFlaggedQuestionsModal,
  NoIncompleteQuestionsModal,
  ExamTabs,
  ExamButton
} from 'examkrackers-components'
import { useParams } from 'react-router-dom'
import {
  last,
  propOr,
  split,
  pipe,
  filter,
  propEq,
  not,
  length,
  ifElse,
  isNil,
  always,
  identity,
  all,
  groupBy,
  prop
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  getQuestionStepStates,
  getExamDetails,
  getSectionFullTitleById,
  getExamQuestionsMap
} from 'modules/exam/ducks/selectors'

import {
  toggleQuestionFlag,
  saveSectionStatus,
  endSection
} from 'services/ExamsService'
import {
  getQuestionNavItems,
  getSectionExamStepsById,
  QUESTION_STATUSES,
  REVIEW_FILTER_TYPES,
  SECTION_STATUSES,
  hasIncomplete,
  hasFlagged
} from 'modules/exam/utils/exam'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { RootReducer } from 'types'
import { findFirstPageId } from '../../utils/examPagesConfig'
import { getIsPreviewAdmin } from '../../../auth/ducks/selectors'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/exam/utils/events'

interface QuestionItemProps {
  displayName: string
  order: number
  onClickHandler: () => void
  onFlagClickHandler: () => void
  flagged: boolean
  status: string
}

export const SectionReview = ({
  setCurrentPage,
  currentPage,
  currentPageConfig,
  setQuestionFilterType,
  setNavigationHandlers
}): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  const [endSectionModalOpen, setEndSectionModalVisibility] = useState(false)
  const [isNoFlaggedModalOpen, setNoFlaggedModalVisibility] =
    React.useState(false)
  const [isNoIncompleteModalOpen, setNoIncompleteModalVisibility] =
    React.useState(false)
  const [currentTab, setCurrentTab] = useState(REVIEW_FILTER_TYPES.all)

  const openEndSectionModal = () => setEndSectionModalVisibility(true)
  const closeEndSectionModal = () => setEndSectionModalVisibility(false)

  const handleCloseNoFlaggedModal = () => setNoFlaggedModalVisibility(false)
  const handleCloseNoIncompleteModal = () =>
    setNoIncompleteModalVisibility(false)

  const id: string = propOr('', 'id')(params)

  const allQuestionStates = useSelector(getQuestionStepStates)
  const sectionId: string = pipe(
    split('section-review-'),
    last,
    ifElse(isNil, always(''), identity)
  )(currentPage)
  const examDetails = useSelector(getExamDetails)
  const sectionFullTitle = useSelector((state: RootReducer) =>
    getSectionFullTitleById(state, sectionId)
  )

  const examPagesSummary = useSelector(getExamQuestionsMap)

  const hasFlaggedQuestions = hasFlagged(sectionId, examPagesSummary)
  const hasIncompleteQuestions = hasIncomplete(sectionId, examPagesSummary)

  const setQuestionFilter = (filterType: any) => () => {
    const newPageId = findFirstPageId(examPagesSummary)(sectionId, filterType)
    setCurrentPage(newPageId)
    setQuestionFilterType(filterType)
  }

  const handleReviewFlagged = () => {
    if (hasFlaggedQuestions) {
      setQuestionFilter(REVIEW_FILTER_TYPES.flagged)()
    } else {
      setNoFlaggedModalVisibility(true)
    }
  }

  const handleReviewIncomplete = () => {
    if (hasIncompleteQuestions) {
      setQuestionFilter(REVIEW_FILTER_TYPES.incomplete)()
    } else {
      setNoIncompleteModalVisibility(true)
    }
  }

  const nextPageHandler = id => () => {
    setCurrentPage(id)
    setQuestionFilterType(REVIEW_FILTER_TYPES.all)
  }
  const stepsList = getSectionExamStepsById(examDetails, sectionId)

  const fetchExamDetails = useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  const [isEndSectionLoading, setEndSectionLoading] = useState(false)

  const setNextPageOnEndSection = () => {
    setEndSectionLoading(true)
    const payload = {
      id,
      section_id: sectionId
    }

    const handleError = e => {
      console.error(e)
      setEndSectionLoading(false)
    }
    const handleSuccess = () => {
      eventEmitter.emit(events.finishSection)
      // Do not remove this state refresh! Without that we will loose timers tracking
      fetchExamDetails({ id })
      setCurrentPage(currentPageConfig.nextPageId)
      setEndSectionLoading(false)
    }

    if (!isPreviewAdmin) {
      endSection(payload).then(handleSuccess).catch(handleError)
    } else {
      setCurrentPage(currentPageConfig.nextPageId)
    }
  }

  const handleFlagClick = questionId => async () => {
    const handleSuccess = () => fetchExamDetails({ id })
    const handleError = e => console.error(e)
    toggleQuestionFlag({ id: questionId })
      .then(handleSuccess)
      .catch(handleError)
  }

  // @ts-ignore
  const questionItems: QuestionItemProps[] = getQuestionNavItems(
    nextPageHandler,
    allQuestionStates,
    stepsList,
    handleFlagClick
  ).map(item => ({
    ...item,
    displayName: 'Question'
  }))

  // @ts-ignore
  const toCompleteCount: number = pipe(
    filter(pipe(propEq('status', QUESTION_STATUSES.complete), not)),
    // @ts-ignore
    length
    // @ts-ignore
  )(questionItems)

  const reviewHeader = (
    <div className='exam-review__questions-header'>
      <div>{sectionFullTitle}</div>
      <div>{toCompleteCount} (Unseen/Incomplete)</div>
    </div>
  )

  useEffect(() => {
    saveSectionStatus({ id: sectionId, status: SECTION_STATUSES.review })
  }, [])

  useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      endSectionButtonOnClick: openEndSectionModal,
      reviewAllButtonOnClick: setQuestionFilter(REVIEW_FILTER_TYPES.all),
      reviewFlaggedButtonOnClick: handleReviewFlagged,
      reviewIncompleteButtonOnClick: handleReviewIncomplete
    }))
  }, [allQuestionStates, examPagesSummary])

  // @ts-ignore
  const groupedQuestionItems = groupBy(prop('status'))(questionItems)

  const tooltip = {
    [REVIEW_FILTER_TYPES.flagged]:
      'Review questions that are flagged for review',
    [REVIEW_FILTER_TYPES.incomplete]: 'Review questions that are incomplete',
    [REVIEW_FILTER_TYPES.unseen]: 'Review questions that were not seen'
  }

  const flaggedItems = questionItems.filter(item => item.flagged === true)

  const flaggedCategory =
    flaggedItems.length > 0
      ? [
          {
            name: `Flagged (${flaggedItems.length})`,
            key: REVIEW_FILTER_TYPES.flagged,
            items: flaggedItems
          }
        ]
      : []

  const questionGroupNames = [
    {
      name: `All (${questionItems.length})`,
      key: REVIEW_FILTER_TYPES.all,
      items: questionItems
    },
    ...Object.entries(groupedQuestionItems)
      .filter(([status]) => status !== QUESTION_STATUSES.complete)
      .map(([status, items]) => ({
        name: `${status.charAt(0).toUpperCase() + status.slice(1)} (${
          items.length
        })`,
        key: status,
        items: items
      })),
    ...flaggedCategory
  ]

  const tabs = questionGroupNames.map(({ key, name }) => ({
    label: name,
    value: key,
    ...(key === REVIEW_FILTER_TYPES.all ? {} : { tooltip: tooltip[key] })
  }))

  const tabContents = questionGroupNames.map(({ key, items }) => ({
    value: key,
    content: <ExamQuestionsReviewList items={items} />
  }))

  const reviewButtonAction = {
    [REVIEW_FILTER_TYPES.all]: setQuestionFilter(REVIEW_FILTER_TYPES.all),
    [REVIEW_FILTER_TYPES.flagged]: handleReviewFlagged,
    [REVIEW_FILTER_TYPES.incomplete]: handleReviewIncomplete,
    [REVIEW_FILTER_TYPES.unseen]: setQuestionFilter(REVIEW_FILTER_TYPES.unseen)
  }

  const ReviewButtonLabel = {
    [REVIEW_FILTER_TYPES.all]: (
      <span>
        Review <span className='underline'>A</span>ll
      </span>
    ),
    [REVIEW_FILTER_TYPES.flagged]: (
      <span>
        Review <span className='underline'>F</span>lagged
      </span>
    ),
    [REVIEW_FILTER_TYPES.incomplete]: (
      <span>
        Review <span className='underline'>I</span>ncomplete
      </span>
    ),
    [REVIEW_FILTER_TYPES.unseen]: (
      <span>
        Review <span className='underline'>U</span>nseen
      </span>
    )
  }

  const handleTabChange = tab => {
    setCurrentTab(tab)
    setQuestionFilterType(tab)
  }

  // @ts-ignore
  return (
    <SectionReviewContainer>
      <HeaderContainer>
        <div className='section-review__header'>
          <h1 className='centered'>Section Review</h1>
        </div>
        <ExamButton onClick={reviewButtonAction[currentTab]}>
          {ReviewButtonLabel[currentTab]}
        </ExamButton>
      </HeaderContainer>
      <div></div>
      <div className='exam-review__questions'>
        <ExamTabs
          onChange={handleTabChange}
          activeTab={currentTab}
          tabs={tabs}
          tabContents={tabContents}
        />
      </div>
      <EndSectionModal
        incomplete={toCompleteCount}
        handleConfirm={setNextPageOnEndSection}
        handleClose={closeEndSectionModal}
        open={endSectionModalOpen}
        isLoading={isEndSectionLoading}
      />
      <NoIncompleteQuestionsModal
        open={isNoIncompleteModalOpen}
        handleClose={handleCloseNoIncompleteModal}
      />
      <NoFlaggedQuestionsModal
        open={isNoFlaggedModalOpen}
        handleClose={handleCloseNoFlaggedModal}
      />
    </SectionReviewContainer>
  )
}

export default SectionReview

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding 32px;
`

const SectionReviewContainer = styled(ExamContent)`
  background-color: ${props => props.theme.exam.original.review.mainBackground};
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;

  h2 {
    margin: 0 !important;
  }

  .exam-review__questions {
    padding: 32px;
    flex-grow: 1;
    max-height: 100%;

    .collapse_header h2 {
      flex-grow: 1;
    }
  }

  .exam-review__questions-header {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24.27px;
  }

  .section-review__header h1 {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin: 0px 0px 1px 28px;
  }

  .exam-review__instructions {
    flex-grow: 1;

    p {
      padding: 5px 2px;
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 16px;
    }

    p:last-child {
      margin-bottom: 5px;
    }

    ol {
      margin-left: 40px;
      margin-bottom: 16px;
    }

    li {
      list-style: decimal outside;
      line-height: 20.7104px;
      margin-top: 5.1776px;
    }

    li:first-child {
      margin-top: 0;
    }
  }
`
