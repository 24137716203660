import { CalendarIcon, Tooltip } from 'examkrackers-components'
import { CalendarContext } from 'hooks/CalendarContext'
import CalendarDayEventsList from 'modules/calendar/CalendarDayEventsList'
import React, { FC, useContext, useCallback, memo } from 'react'
import {
  apiMove,
  apiMoveToArchive,
  apiReorder,
  ARCHIVE_TAB_TYPES,
  uiMove,
  uiMoveToArchive,
  uiReorder
} from 'utils/calendar'
import { useDispatch, useSelector } from 'react-redux'
import {
  addManualCalendarTaskRoutine,
  setManualCalendarActiveTabRoutine,
  updateManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { equals } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import McatDaySalty from 'assets/images/Calendar_Salty_MCATDay.svg'
import {
  formatMinutesToHoursAndMinutes,
  isDateWithinInterval,
  noTimezoneDate,
  removeUTCFromDate
} from 'utils/date'
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Title,
  NoEventsContainer,
  NoEventsMessage,
  IconContainer,
  DayHeader,
  DaysContainer,
  DayColumn,
  DayNumber,
  TitleContainer,
  CalendarWidgetContainer,
  SeeCalendarButton,
  WidgetTitle,
  SetupCalendarButton,
  PartySaltyImage,
  StyledClockIcon
} from './CalendarWidget.styles'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import { useTranslation } from 'react-i18next'
import { addDays, format } from 'date-fns'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { CourseMeta } from 'modules/calendar/CalendarRow'
import { isHolidayInDate } from '../../../calendar/utils/calendarCell'
import * as R from 'ramda'

const CalendarWidget: FC = memo(() => {
  const { lists, setLists, archiveEvents, calendar } =
    useContext(CalendarContext)
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const course = useSelector(getCurrentCourse)

  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  // const startDate =
  // new Date(calendar?.start_at as string) ||
  // new Date(course?.accessible_from as string)

  const startAt = R.pipe(R.propOr('', 'start_at'), removeUTCFromDate)(calendar)
  const examAt = R.pipe(R.propOr('', 'exam_at'), removeUTCFromDate)(calendar)
  const mcatAt = R.pipe(R.propOr('', 'mcat_at'), removeUTCFromDate)(calendar)

  const accessibleFrom = R.pipe(R.propOr('', 'accessible_from'))(course)

  // @ts-ignore
  const startDate = noTimezoneDate(startAt || accessibleFrom)

  const dates = [yesterday, today, tomorrow].map(date => {
    const dateStr = format(date, 'yyyy-MM-dd')
    const holiday = isHolidayInDate(dateStr)
    return {
      date: dateStr,
      dayNumber: date.getDate(),
      isToday: dateStr === format(today, 'yyyy-MM-dd'),
      isStartDate: startDate && dateStr === startAt,
      isFinalDate: dateStr === examAt,
      isMcatDate: dateStr === mcatAt,
      holiday
    }
  })

  const isCalendarEmpty = isNilOrEmpty(calendar)

  const isFreeTrial = course.type === 'free_trial'

  // const courseEndDate = new Date(course.accessible_to as string)
  const courseEndDate = noTimezoneDate(removeUTCFromDate(course.accessible_to))

  const courseMeta: CourseMeta = JSON.parse(
    course?.metadata || '{}'
  ) as CourseMeta

  const isWeekCourse = Number(courseMeta?.days_amount) === 7

  // Memoize the getTotalDuration function
  const getTotalDuration = useCallback(
    (date: string) => {
      if (!lists[date] || !Array.isArray(lists[date])) {
        return 0
      }
      return lists[date].reduce(
        (acc: number, curr) =>
          acc + ((curr as { duration?: number }).duration || 0),
        0
      )
    },
    [lists]
  )

  const daysRange = `${format(yesterday, 'EEEE MMM d')} - ${format(
    tomorrow,
    'EEEE MMM d'
  )}`

  // Memoize the onDragEnd function
  const onDragEnd = useCallback(
    async result => {
      const { source, destination, draggableId } = result

      const normalizedDraggableId = draggableId.replace('-custom', '')

      const sourceId = source.droppableId
      const destinationId = destination.droppableId
      const isFromArchive = sourceId.includes('events-archive')
      const isToArchive = destinationId.includes('events-archive')

      // no destination
      if (!destination) {
        return
      }

      // Archive reorder
      if (isFromArchive && isToArchive) {
        if (source.droppableId === destination.droppableId) {
          const uiReorderItems = uiReorder(
            archiveEvents,
            source.index,
            destination.index
          )

          dispatch(
            updateManualCalendarTasksRoutine({
              list: uiReorderItems
            })
          )
        }

        return
      }

      // Move from archive to calendar
      if (isFromArchive && !isToArchive) {
        const uiMoveResult = uiMove(
          archiveEvents,
          lists[destinationId],
          source,
          destination,
          true,
          destinationId
        )

        dispatch(
          updateManualCalendarTasksRoutine({
            list: uiMoveResult.source
          })
        )

        setLists(prev => ({
          ...prev,
          [destinationId]: uiMoveResult.destination
        }))

        const apiMoveResult = await apiMove(
          archiveEvents,
          lists[destinationId],
          source,
          destination,
          normalizedDraggableId,
          true,
          false
        )

        if (
          !uiMoveResult.source.every((_, index) => {
            return (
              uiMoveResult.source[index].event_date ===
              apiMoveResult.source[index].event_date
            )
          }) ||
          !uiMoveResult.destination.every((_, index) => {
            return (
              uiMoveResult.destination[index].event_date ===
              apiMoveResult.destination[index].event_date
            )
          })
        ) {
          setLists(prev => ({
            ...prev,
            [destinationId]: apiMoveResult.destination
          }))

          dispatch(
            updateManualCalendarTasksRoutine({
              list: apiMoveResult.source
            })
          )
        }

        return
      }
      // From calendar to archive
      if (!isFromArchive && isToArchive) {
        if (draggableId.includes('custom')) {
          dispatch(
            showToastRoutine({
              key: 'It is not possible to archive a custom event',
              severity: SEVERITY.warning
            })
          )

          return
        } else {
          const uiMoveResult = uiMoveToArchive(lists[sourceId], source)

          setLists(prev => ({
            ...prev,
            [sourceId]: uiMoveResult.source
          }))

          const apiMoveResult = await apiMoveToArchive(
            lists[sourceId],
            source,
            normalizedDraggableId
          )

          if (isNotNilOrEmpty(apiMoveResult.event)) {
            dispatch(
              addManualCalendarTaskRoutine({
                event: apiMoveResult.event
              })
            )

            const tab = ARCHIVE_TAB_TYPES[apiMoveResult.event.type]

            dispatch(setManualCalendarActiveTabRoutine({ activeTab: tab }))
          }

          if (!equals(uiMoveResult.source, apiMoveResult.source)) {
            setLists(prev => ({
              ...prev,
              [sourceId]: apiMoveResult.source
            }))
          }

          return
        }
      }

      // Calendar Moves
      if (!isFromArchive && !isToArchive) {
        // Same cell
        if (source.droppableId === destination.droppableId) {
          const uiReorderItems = uiReorder(
            lists[sourceId],
            source.index,
            destination.index
          )

          setLists(prev => ({
            ...prev,
            [sourceId]: uiReorderItems
          }))

          const apiReorderItems = await apiReorder(
            lists[sourceId],
            source.index,
            destination.index
          )

          if (!equals(uiReorderItems, apiReorderItems)) {
            setLists(prev => ({
              ...prev,
              [sourceId]: apiReorderItems
            }))
          }
        } else {
          // Different cell
          const uiMoveResult = uiMove(
            lists[sourceId],
            lists[destinationId],
            source,
            destination
          )

          setLists(prev => ({
            ...prev,
            [sourceId]: uiMoveResult.source,
            [destinationId]: uiMoveResult.destination
          }))

          const apiMoveResult = await apiMove(
            lists[sourceId],
            lists[destinationId],
            source,
            destination,
            normalizedDraggableId
          )

          if (
            !uiMoveResult.source.every((_, index) => {
              return (
                uiMoveResult.source[index].event_date ===
                apiMoveResult.source[index].event_date
              )
            }) ||
            !uiMoveResult.destination.every((_, index) => {
              return (
                uiMoveResult.destination[index].event_date ===
                apiMoveResult.destination[index].event_date
              )
            })
          ) {
            setLists(prev => ({
              ...prev,
              [sourceId]: apiMoveResult.source,
              [destinationId]: apiMoveResult.destination
            }))
          }
        }
      }
    },
    [lists, setLists, archiveEvents, dispatch]
  )

  const handleSeeCalendarClick = () => {
    if (!isCalendarEmpty) {
      push(PATHS.calendar)
    } else if (isFreeTrial) {
      push(PATHS.calendarSetupFreeTrial)
    } else {
      push(PATHS.calendarSetup)
    }
  }

  return (
    <CalendarWidgetContainer>
      <WidgetTitle>
        <h3>{t('dashboard.calendarWidget.title')}</h3>
        <SeeCalendarButton onClick={handleSeeCalendarClick}>
          {t('dashboard.calendarWidget.seeCalendar')}
        </SeeCalendarButton>
      </WidgetTitle>
      <Card>
        <CardContent>
          <CardHeader>
            <TitleContainer>
              <Icon>
                <IconContainer>
                  <CalendarIcon />
                </IconContainer>
              </Icon>
              <Title>{daysRange}</Title>
            </TitleContainer>
          </CardHeader>
          {isCalendarEmpty ? (
            <NoEventsContainer
              $isDraggingOver={false}
              onClick={handleSeeCalendarClick}
              style={{
                cursor: 'pointer',
                backgroundColor: '#F5F5F5'
              }}
            >
              <SetupCalendarButton>
                {t('dashboard.calendarWidget.setupCalendar')}
              </SetupCalendarButton>
            </NoEventsContainer>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <DaysContainer>
                {dates.map(
                  ({
                    date,
                    dayNumber,
                    isToday,
                    isStartDate,
                    isFinalDate,
                    isMcatDate,
                    holiday
                  }) => {
                    const endDateByDaysAmount = addDays(
                      startDate,
                      Number(courseMeta?.days_amount || '7') - 1
                    )

                    const endDateByCourseType =
                      isWeekCourse || isFreeTrial
                        ? endDateByDaysAmount
                        : courseEndDate

                    const isWithin: boolean = isDateWithinInterval(
                      noTimezoneDate(date),
                      {
                        start: startDate,
                        end: endDateByCourseType
                      }
                    )

                    return (
                      <DayColumn
                        key={date}
                        $isToday={isToday}
                        $isStartDate={isStartDate}
                        $isFinalDate={isFinalDate}
                        $isMcatDate={isMcatDate}
                      >
                        {isWithin ? (
                          <DayHeader
                            $isMcatDate={isMcatDate && !lists[date]?.length}
                          >
                            {holiday ? (
                              <Tooltip tooltipContent={holiday.name} id={date}>
                                <DayNumber
                                  $today={isToday}
                                  $isStartDate={isStartDate}
                                  $isMcatDate={isMcatDate}
                                  $holiday={!!holiday}
                                >
                                  {dayNumber}
                                </DayNumber>
                              </Tooltip>
                            ) : isStartDate ? (
                              <Tooltip
                                tooltipContent={t(
                                  'dashboard.calendarWidget.startDate'
                                )}
                                id={date}
                              >
                                <DayNumber
                                  $today={isToday}
                                  $isStartDate={isStartDate}
                                  $isMcatDate={isMcatDate}
                                >
                                  {dayNumber}
                                </DayNumber>
                              </Tooltip>
                            ) : isFinalDate ? (
                              <Tooltip
                                tooltipContent={t(
                                  'dashboard.calendarWidget.endDate'
                                )}
                                id={date}
                              >
                                <DayNumber
                                  $today={isToday}
                                  $isStartDate={isStartDate}
                                  $isMcatDate={isMcatDate}
                                >
                                  {dayNumber}
                                </DayNumber>
                              </Tooltip>
                            ) : (
                              <DayNumber
                                $today={isToday}
                                $isStartDate={isStartDate}
                                $isMcatDate={isMcatDate}
                                $holiday={!!holiday}
                              >
                                {dayNumber}
                              </DayNumber>
                            )}
                            {(!isMcatDate || lists[date]?.length > 0) && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px'
                                }}
                              >
                                <StyledClockIcon />
                                <span>
                                  {formatMinutesToHoursAndMinutes(
                                    getTotalDuration(date)
                                  )}
                                </span>
                              </div>
                            )}
                            {isMcatDate && !lists[date]?.length && (
                              <PartySaltyImage
                                src={McatDaySalty}
                                alt='Salty celebration'
                              />
                            )}
                          </DayHeader>
                        ) : (
                          <DayHeader style={{ backgroundColor: '#f5f5f5' }}>
                            <DayNumber $today={isToday}>{dayNumber}</DayNumber>
                          </DayHeader>
                        )}
                        {lists[date] && lists[date].length > 0 && isWithin ? (
                          <CalendarDayEventsList
                            currentlyChecked={date}
                            shouldDisplayEndDate={false}
                            isDashboard
                          />
                        ) : (
                          <Droppable
                            droppableId={date}
                            isDropDisabled={!isWithin || isMcatDate}
                          >
                            {(provided, snapshot) => (
                              <NoEventsContainer
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                $isDraggingOver={snapshot.isDraggingOver}
                                $isMcatDate={isMcatDate && !lists[date]?.length}
                                style={{
                                  backgroundColor: `${
                                    !isWithin ? '#F5F5F5' : 'transparent'
                                  }`
                                }}
                              >
                                <NoEventsMessage>
                                  {isMcatDate && !lists[date]?.length
                                    ? t('calendar.mcatDay')
                                    : isWithin
                                    ? t('dashboard.calendarWidget.noEvents')
                                    : ''}
                                </NoEventsMessage>
                                {provided.placeholder}
                              </NoEventsContainer>
                            )}
                          </Droppable>
                        )}
                      </DayColumn>
                    )
                  }
                )}
              </DaysContainer>
            </DragDropContext>
          )}
        </CardContent>
      </Card>
    </CalendarWidgetContainer>
  )
})

export default CalendarWidget
