import { parseISO, isBefore } from 'date-fns'
import { EmptyType, StudentTask, Task } from 'types/dashboard'
import { calendarEventType, checkEventTiming } from 'utils/calendar'
import { TasksType } from './utils/tasksImageArray'
import { createUTCFromEST } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
export const TaskStatus = {
  INCOMPLETE: 'incomplete',
  SKIPPED: 'skipped',
  COMPLETE: 'complete',
  EMPTY: 'empty'
} as const

export type TaskStatusType = (typeof TaskStatus)[keyof typeof TaskStatus]

class TasksWidgetService {
  emptyTask: EmptyType = {
    id: -1,
    status: TaskStatus.EMPTY
  }

  isReviewEvent(type: string): boolean {
    return (
      type === calendarEventType.fullLengthExamReview ||
      type === calendarEventType.customFullLengthExamReview ||
      type === calendarEventType.sectionExamReview ||
      type === calendarEventType.customSectionExamReview ||
      type === calendarEventType.chapterExamReview ||
      type === calendarEventType.otherExamReview
    )
  }

  isLiveClass(type: string): boolean {
    return (
      type === calendarEventType.liveClass ||
      type === calendarEventType.customLiveClass ||
      type === calendarEventType.customEndDateEvent
    )
  }

  isExam(type: string): boolean {
    return (
      type === calendarEventType.chapterExam ||
      type === calendarEventType.sectionExam ||
      type === calendarEventType.customSectionExam ||
      type === calendarEventType.fullLengthExam ||
      type === calendarEventType.customFullLengthExam ||
      type === calendarEventType.otherExam
    )
  }

  getTaskType = (title: string, taskType?: string): string => {
    const taskTypeByTitle = title.slice(0, 3).toLowerCase()

    if (taskType === TasksType.CUSTOM) {
      return TasksType.CUSTOM
    } else if (
      isNotNilOrEmpty(title) &&
      Object.values(TasksType).includes(taskTypeByTitle)
    ) {
      return taskTypeByTitle
    } else {
      return TasksType.CUSTOM
    }
  }

  checkEventTiming(event: Event): boolean {
    return checkEventTiming(event)
  }

  isStatusIncomplete(task: Task): boolean {
    return task.status === TaskStatus.INCOMPLETE
  }

  shouldDisplayCurrentTask(task: Task | StudentTask): boolean {
    return task !== undefined
  }

  isStatusCompleteOrSkipped(task: Task): boolean {
    return (
      task.status === TaskStatus.COMPLETE || task.status === TaskStatus.SKIPPED
    )
  }

  addEmptyTasks(tasks: (Task | StudentTask | EmptyType)[]) {
    if (tasks.length <= 3) {
      const emptyTasksNeeded = 3 - tasks.length
      const emptyTasks = Array(emptyTasksNeeded).fill(this.emptyTask)

      return [...tasks, ...emptyTasks]
    }

    return tasks
  }

  isBeginnerTaskComplete(task: StudentTask): boolean {
    return task.is_completed
  }

  ifTaskNotFromPast(task: Task): boolean {
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)

    const inputDate = createUTCFromEST(task.event_date, '00:00:00')

    return inputDate >= today
  }

  flatListOfTasks(lists: Record<string, Task[]>): Task[] {
    return Object.values(lists).flat()
  }

  getIncompleteTasks(lists: Record<string, Task[]>): Task[] {
    const flattenedList = this.flatListOfTasks(lists)

    return flattenedList.filter(
      task => this.isStatusIncomplete(task) && this.ifTaskNotFromPast(task)
    )
  }

  filterBeginnerTasks(
    tasks: StudentTask[],
    hasStudentCalendar: boolean
  ): StudentTask[] {
    const tasksToFilter = tasks.filter(
      task => task.task.order < 3 && !this.isBeginnerTaskComplete(task)
    )

    return hasStudentCalendar
      ? tasksToFilter.filter(task => task.task.order === 1)
      : tasksToFilter
  }
}

export default new TasksWidgetService()
