import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import { pathOr } from 'ramda'
import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { useTranslation } from 'react-i18next'
import PATHS from 'utils/paths'

import { Button, NavArrowLeftIcon, Tag } from 'examkrackers-components'
import { fetchVideoDetails } from '../services/VideosService'
import { isNotNilOrEmpty } from '../utils/ramda'
import VideoDetailsBookFilters from '../modules/videos/components/VideoDetailsBookFilters'
import VideoDetailsList from '../modules/videos/components/VideoDetailsList'
import { useSelector } from 'react-redux'
import { selectAllBooks } from '../modules/books/ducks/selectors'
import VideoDetailsMainVideo from '../modules/videos/components/VideoDetailsMainVideo'
import VideoWatchingTime from '../modules/learningTime/components/VideoWatchingTime'
import { VIDEO_CATEGORIES } from 'modules/videos/utils/categories'
import { VideoSubchapterNote } from 'modules/videos/components/VideoSubchapterNote'
import AnalyticsService from 'services/AnalyticsService'
import { getStudent } from 'modules/auth/ducks/selectors'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import { Course } from 'types/courses'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { MixedVideo } from 'types/videos'

export const VideoDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const {
    push,
    replace,
    location: { pathname, search }
  } = useHistory()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const user = useSelector(getStudent)

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const [seeVideoDetailsPage, setSeeVideoDetailsPage] = useState(true)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeVideoDetailsPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeVideosPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeVideoDetailsPage(false)
    }
  }, [currentCourse, seeVideoDetailsPage])

  const selectedCategory = pathOr(
    'all',
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )

  const id = R.propOr('', 'id', params)
  const [video, setVideo] = useState<MixedVideo | null>(null)

  const books = useSelector(selectAllBooks)
  const tags = R.propOr([], 'tags', video)
  const bookTag = R.pathOr({}, [0, 'tag'], tags)
  const bookId = R.pathOr('', [0, 'book_id'], tags)
  const bookTagColor = R.pathOr({}, [0, 'tag_colour'], tags)

  const studentBookId = R.pipe(
    R.find(R.propEq('book_id', bookId)),
    R.propOr('', 'id')
  )(books)

  const saveVideoDetails = () => {
    const handleSuccess = response => {
      setVideo(R.propOr({}, 'data', response))
    }
    const handleError = e => console.error(e)

    fetchVideoDetails({ id }).then(handleSuccess).catch(handleError)
  }

  const setBookIdFilterIfNeeded = () => {
    if (isNotNilOrEmpty(studentBookId)) {
      const filterQuery = R.propOr({}, 'filter', parsedQuery)

      const newQuery = {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          'b.id': studentBookId
        }
      }

      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  useEffect(() => {
    saveVideoDetails()
  }, [id])

  useEffect(() => {
    setBookIdFilterIfNeeded()
  }, [video])

  const goBackToVideos = () => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    const currentQueryWithoutBookFilter = {
      ...parsedQuery,
      filter: {
        ...R.omit(['b.id', 'bcc.id'], filterQuery)
      }
    }

    push(
      `${PATHS.videos}${qs.stringify(currentQueryWithoutBookFilter, {
        addQueryPrefix: true
      })}`
    )
  }

  const hasCategorySelected = selectedCategory !== VIDEO_CATEGORIES.all
  // @ts-ignore
  const hasBooksCategorySelected = selectedCategory === VIDEO_CATEGORIES.books

  return (
    <Container>
      <VideoWatchingTime />
      <VideoDetailsLayout>
        <VideosLeftColumn>
          <BackButtonContainer>
            <Button
              size='small'
              color='transparent'
              variant='contained'
              startIcon={<NavArrowLeftIcon />}
              onClick={goBackToVideos}
            >
              {t('videos.backToVideos')}
            </Button>
          </BackButtonContainer>
          {isNotNilOrEmpty(video) && <VideoDetailsMainVideo video={video} />}
          {isNotNilOrEmpty(video) && hasBooksCategorySelected && (
            <VideoSubchapterNote video={video as MixedVideo} />
          )}
        </VideosLeftColumn>
        <VideosRightColumn>
          <OtherVideosHeadingContainer>
            {hasCategorySelected && isNotNilOrEmpty(video) && (
              <HeadingTitleContainer>
                <HeadingTitle>{t('videos.otherVideosFrom')}</HeadingTitle>
                <VideoCategory>
                  {t(`videos.videoCategories.${selectedCategory}`)}
                </VideoCategory>
                {hasBooksCategorySelected && (
                  <VideoTags id='video-tags'>
                    {/* @ts-ignore */}
                    <Tag text={bookTag} color={bookTagColor} isStatic />
                  </VideoTags>
                )}
              </HeadingTitleContainer>
            )}
          </OtherVideosHeadingContainer>
          {isNotNilOrEmpty(video) && hasBooksCategorySelected && (
            <VideoBookFiltersContainer>
              <VideoDetailsBookFilters />
            </VideoBookFiltersContainer>
          )}
          <OtherVideosList>
            {isNotNilOrEmpty(video) && <VideoDetailsList />}
          </OtherVideosList>
        </VideosRightColumn>
      </VideoDetailsLayout>
    </Container>
  )
}

export default VideoDetails

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  margin-top: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`

const VideoDetailsLayout = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  flex-grow: 1;
`

const VideosLeftColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const VideosRightColumn = styled.div`
  height: 100%;
  width: 400px;
  flex: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const VideoCategory = styled.div`
  min-width: 72px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  background: ${({ theme }) => theme.colors.main.grey200};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.main.text};
  text-transform: capitalize;
`

const BackButtonContainer = styled.div``

const OtherVideosHeadingContainer = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
`

const HeadingTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const HeadingTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const VideoTags = styled.div``

const VideoBookFiltersContainer = styled.div``

const OtherVideosList = styled.div`
  flex-grow: 1;
  max-height: calc(100% - 90px);
`
