// Logical assumptions:
// Time is counted only in the currently active window
// If the user leaves the browser page for a while, the clock stops adding seconds
// When leaving the site, we update the clock in the database
// When unmounting page (eg.refresh), we update the clock in the database
// When we return to the site, the clock is updated with the value that has been saved in the database
// If the user uses "pause", then the clock does not count the seconds and the page is blocked by a popup

import React, { useState, useRef } from 'react'
import * as R from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { useRouteMatch, useHistory } from 'react-router-dom'

import { COURSE_LEARNING_TIME_STATUS, COURSE_TYPES } from 'utils/courses'
import PATHS from 'utils/paths'

import { getLearningTimeDetails } from 'modules/learningTime/ducks/selectors'
import { handleUpdateCourseTimeStateRoutine } from 'modules/learningTime/ducks/actions'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'

import {
  ClockIcon,
  PauseIcon,
  PlayIcon,
  ArrowDownIcon,
  HideIcon,
  ChartIcon,
  ShowIcon
} from 'examkrackers-components'
import OverlayPause from 'modules/learningTime/components/OverlayPause'

import { LearningTimeChart } from 'modules/learningTime/components/LearningTimeChart'

import useOutsideClick from 'hooks/useOutsideClick'

import { LearningTimeAmount } from './LearningTimeAmount'

import { isPathToNotCountStudyTime } from '../utils/CountTime'

export const LearningTimer = (): JSX.Element => {
  const [isHidden, setIsHidden] = useState(false)
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const [isLearningTimeChartShown, setIsLearningTimeChartShown] =
    useState(false)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const { push, location } = useHistory()
  const { search, pathname } = location
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const handleChangeOfHighlightState = () => {
    const newQuery = qs.stringify({
      ...parsedQuery,
      highlightMode: 'none'
    })

    push(`${pathname}?${newQuery}`)
  }

  const { path } = useRouteMatch()

  const toggleClockLabel = () => {
    setIsDropdownOpened(false)
    setIsHidden(!isHidden)
  }

  const toggleLearningTimeChart = () => {
    setIsLearningTimeChartShown(true)
    setIsDropdownOpened(false)
    handleChangeOfHighlightState()
  }

  const { t } = useTranslation()

  const clockContainerRef = useRef(null)
  const pauseContainerRef = useRef(null)

  const handleIsOpened = () => setIsDropdownOpened(true)
  const handleIsClosed = () => {
    setIsLearningTimeChartShown(false)
    setIsDropdownOpened(false)
  }
  useOutsideClick(clockContainerRef, handleIsClosed)

  const dispatch = useDispatch()

  // @ts-ignore
  const learningTimeDetails = useSelector(getLearningTimeDetails)
  // @ts-ignore

  const learningTimeState: string = R.propOr(
    COURSE_LEARNING_TIME_STATUS.started,
    'state',
    learningTimeDetails
  )

  const handleUpdateCourseTimeState = React.useCallback(
    (payload = {}) => dispatch(handleUpdateCourseTimeStateRoutine(payload)),
    [dispatch]
  )

  const handlePause = () => {
    handleUpdateCourseTimeState({
      status: COURSE_LEARNING_TIME_STATUS.paused
    })
  }

  const isPaused = learningTimeState === COURSE_LEARNING_TIME_STATUS.paused

  return (
    <Container isDropdownOpened={isDropdownOpened}>
      {isHidden ? (
        <HiddenLearningTimerContainer isDropdownOpened={isDropdownOpened}>
          <ClockIconContainer isHidden={isHidden}>
            <ClockIcon />
          </ClockIconContainer>
          <ArrowIconContainer
            onClick={() => handleIsOpened()}
            isDropdownOpened={isDropdownOpened}
          >
            <ArrowDownIcon />
          </ArrowIconContainer>
        </HiddenLearningTimerContainer>
      ) : (
        <>
          <LearningTimerContainer
            paused={isPaused}
            isDropdownOpened={isDropdownOpened}
          >
            <ClockIconContainer
              isPathToNotCountStudyTime={
                isPathToNotCountStudyTime(path) &&
                path !== PATHS.dashboard &&
                isAuthFetched &&
                isOrdinaryStudent
              }
            >
              <ClockIcon />
            </ClockIconContainer>
            {isPaused && <OverlayPause pauseContainerRef={pauseContainerRef} />}
            <LearningTimeAmount isPaused={isPaused} />
            <PauseContainer
              paused={isPaused}
              onClick={() => handlePause()}
              className='elements-wrapper'
            >
              <IconWrapper>
                {learningTimeState === COURSE_LEARNING_TIME_STATUS.started ? (
                  <ActionButton
                    paused={isPaused}
                    isPathToNotCountStudyTime={
                      isPathToNotCountStudyTime(path) &&
                      isAuthFetched &&
                      isOrdinaryStudent
                    }
                  >
                    <PauseIcon width='9px' height='9px' />
                  </ActionButton>
                ) : (
                  <ActionButton paused={isPaused}>
                    <PlayIcon width='9px' height='9px' />
                  </ActionButton>
                )}
              </IconWrapper>
            </PauseContainer>
            <ArrowIconContainer
              onClick={() => handleIsOpened()}
              isDropdownOpened={isDropdownOpened}
            >
              <ArrowDownIcon />
            </ArrowIconContainer>
          </LearningTimerContainer>
        </>
      )}
      {isDropdownOpened ? (
        <>
          <ClockMenuContainer
            ref={clockContainerRef}
            isDropdownOpened={isDropdownOpened}
          >
            <ClockMenuItem onClick={toggleClockLabel}>
              {isHidden ? (
                <TextIconWrapper>
                  {t('navigation.clockMenu.showClock')}
                  <ShowIcon />
                </TextIconWrapper>
              ) : (
                <TextIconWrapper>
                  {t('navigation.clockMenu.hideClock')}
                  <HideIcon />
                </TextIconWrapper>
              )}
            </ClockMenuItem>
            <ClockMenuItem onClick={toggleLearningTimeChart}>
              <TextIconWrapper>
                {t('navigation.clockMenu.showTimeStatistic')}
                <ChartIcon />{' '}
              </TextIconWrapper>
            </ClockMenuItem>
          </ClockMenuContainer>
        </>
      ) : null}
      {isLearningTimeChartShown && (
        <LearningTimeChart
          isFreeTrial={isFreeTrial}
          isOpened={isLearningTimeChartShown}
          handleIsClose={handleIsClosed}
        />
      )}
    </Container>
  )
}

export default LearningTimer
const Container = styled.div`
  display: flex;
  position: relative;
`

const LearningTimerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  min-width: 105px;
  background-color: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.background};
  border-radius: 40px;
  margin: 0;
  padding: 3px 6px 3px 3px;
  box-shadow: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.boxShadow};
`

const HiddenLearningTimerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  background-color: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.background};
  border-radius: 40px;
  margin: 0;
  padding: 3px 6px 3px 3px;
  box-shadow: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.boxShadow};
`

const ClockIconContainer = styled.div`
  display: flex;
  background: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.clockIconBackground};
  color: ${({ theme }) => theme.colors.topNav.totalSaltyBucksAndTimer.iconFont};
  font-size: ${({ theme }) => theme.typography.fontSizeBig};
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: ${({ isHidden }) => (isHidden ? '0px' : '6px')};
  opacity: ${({ isPathToNotCountStudyTime }) =>
    isPathToNotCountStudyTime ? 0.3 : 1};
`

const ArrowIconContainer = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main.heading};
  padding-left: 5px;
  transform: ${({ isDropdownOpened }) =>
    isDropdownOpened && `translateY(-10%) rotateX(180deg)`};
`

const PauseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  &.elements-wrapper {
    align-items: center;
    cursor: pointer;

    &:hover * {
      color: ${({ theme }) => theme.colors.main.primary500} !important;
    }
  }
`

const ActionButton = styled.div`
  display: ${({ isPathToNotCountStudyTime }) =>
    isPathToNotCountStudyTime ? 'none' : 'flex'};
  justify-content: center;
  width: 24px;
  height: 24px;
  align-items: center;
  cursor: pointer;
  p {
    padding-left: 3px;
    padding-top: 1px;
    z-index: ${({ theme, paused }) =>
      paused ? theme.zIndex.menu + 20 : 'auto'};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraHiperUltraSmall};
    font-weight: bold;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500};
  }

  svg {
    transform: translateX(0.5px);
  }
`

const IconWrapper = styled.div`
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) =>
      theme.colors.topNav.totalSaltyBucksAndTimer.pauseIconBackground};
  background: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.pauseIconBackground};
  color: ${({ theme }) => theme.colors.topNav.totalSaltyBucksAndTimer.iconFont};
`
const ClockMenuContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 45px;
  left: 0px;
  width: 155px;
  height: 70px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.main.heading};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex: 1;
  margin-right: 6px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`
const ClockMenuItem = styled.div`
  padding: 6px 16px;
  font-size: 12px;
  font-family: 'Arial', 'Helvetica', 'sans-serif';
  &:hover {
    background: ${({ theme }) => theme.colors.selects.option.backgroundActive};
  }
`
const TextIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
