import Holidays from 'date-holidays'
import { isSameDay } from 'date-fns'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { noTimezoneDate } from 'utils/date'

const usHolidaysService = new Holidays('US')
const US_HOLIDAYS = usHolidaysService.getHolidays()

export const checkUSHoliday = date => {
  const holiday = US_HOLIDAYS.find(
    h => isSameDay(h.start, date) && ['public', 'bank'].includes(h.type)
  )

  return holiday ? { ...holiday } : null
}

export const calendarDay = date => {
  return date
}

export const isHolidayInDate = (date: string): boolean =>
  isNotNilOrEmpty(
    checkUSHoliday(typeof date === 'string' ? noTimezoneDate(date) : date)
  )
export const getHolidayName = (date: string): string => {
  const holidayInfor = checkUSHoliday(
    typeof date === 'string' ? noTimezoneDate(date) : date
  )
  return propOr('', 'name', holidayInfor)
}
