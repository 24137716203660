import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialZoom = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialZoom)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialBackground)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialScroll)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialZoom
    })
  }, [])

  return (
    <>
      <ExamContent>
        <TutorialZoomContainer>
          <div className='heavy-bordered content-container'>
            <h2>Use the Zoom Feature</h2>
            <h3>Description</h3>
            <p>
              You can zoom in and zoom out on your screen so that the screen
              content appears larger or smaller than initially presented. You
              can zoom in or out multiple times to make the text much larger or
              much smaller.
            </p>
            <h3>Instructions</h3>
            <p>Locate the Ctrl button and the +, -, and 0 buttons.</p>
            <ul>
              <li>
                Ctrl and + buttons will zoom in, or enlarge, the size of the
                text and graphics on the screen.
              </li>
              <li>
                Ctrl and - buttons will zoom out, or reduce, the size of the
                text and graphics on the screen.
              </li>
              <li>
                Ctrl and 0 buttons will bring the text and graphics back to the
                originally intended size.
              </li>
              <li>
                You must use the + and - key on the keyboard not the keypad.
              </li>
              <li>
                Zooming in or out will persist from item to item and passage to
                passage unless you set the screen back with Ctrl 0.
              </li>
            </ul>
            <h3>Practice</h3>
            <ul className='numeric'>
              <li>
                Click anywhere on the screen. Press the Ctrl and + buttons 2
                times to enlarge the content on the screen.
              </li>
              <li>
                Press the Ctrl and 0 buttons to return the content to its
                original size.
              </li>
              <li>
                Press the Ctrl and – buttons 2 times to reduce the content on
                the screen.
              </li>
              <li>
                Press the Ctrl and 0 buttons to return content to its original
                size.
              </li>
            </ul>
            <p>
              <strong>Important:</strong> Content is intended to render as
              initially displayed. By zooming in, the rendering of the graphs,
              tables and images may be distorted.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </TutorialZoomContainer>
      </ExamContent>
    </>
  )
}

export default TutorialZoom

const TutorialZoomContainer = styled.div`
  p {
    margin: 16px 0;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 0 !important;
    line-height: 22.4px !important;
  }

  .important {
    font-weight: bold;
  }
`
