import React from 'react'
import styled from 'styled-components'
import BookPlaceholder from 'assets/images/MCAT_placeholder.jpeg'
import { propOr } from 'ramda'
import { isNilOrEmpty } from 'utils/ramda'

const BookItem = ({ bookLink, onClick }): JSX.Element => {
  const coverImage = propOr('', 'coverImage', bookLink)

  return (
    <Container
      isInactive={bookLink.isInactive}
      onClick={bookLink.isInactive ? () => {} : onClick}
    >
      <BookImage
        // @ts-ignore
        src={isNilOrEmpty(coverImage) ? BookPlaceholder : coverImage}
      />
      <BookLabel>{bookLink.label}</BookLabel>
    </Container>
  )
}

const Container = styled.div<{ isInactive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: ${props => (props.isInactive ? 0.2 : 1)};
`

const BookImage = styled.img`
  width: 93px;
  height: 120px;
  background-color: ${props => props.theme.colors.grey350};
  border-radius: 10px;
  border-width: 1px;
  border-color: ${props => props.theme.colors.text};
  border-style: solid;
  object-fit: cover;
`

const BookLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  max-width: 93px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`

export default BookItem
