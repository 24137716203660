import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  Modal,
  Tooltip,
  VideoPlayer
} from 'examkrackers-components'
import styled from 'styled-components'
import { formatMinutesToHoursAndMinutesWithFullName } from 'utils/date'
import { calendarEventTypeLabel } from 'utils/calendar'
import { removeUnderscoreAndAddSpace } from 'utils/string'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import TasksWidgetService from './TasksWidgetService'
import { getStudent } from 'modules/auth/ducks/selectors'
import ExamButton from 'modules/exams/components/ExamButton'

import { tasksImage, upcomingTasksImage } from './utils/tasksImageArray'
import { EXAM_STATUS } from 'utils/exam'
import { fetchExamsDetails } from 'services/ExamsService'
import * as R from 'ramda'

interface StudentTaskContent {
  videoUrl: string
  description: string
}

interface TaskCardProps {
  isCurrentTask?: boolean
  handleUpdateEvent: () => void
  title: string
  id: string
  duration?: number
  type?: string
  status: boolean
  content?: StudentTaskContent
  handleRedirectFromActionUri?: () => void
  isFirstUpcomingTask?: boolean
  isCommonTask?: boolean
  onSeeCalendarClick?: () => void
  isReviewEvent: boolean
  student_exam_status?: string | null
  action_uri?: string
  isLiveClassAvailable?: boolean
  studentExamId?: string | null
  studentExamIds?: string | null
  studentItemId?: string | null
}

const TaskCard = ({
  isCurrentTask = false,
  handleUpdateEvent,
  title,
  id,
  duration,
  type: taskType,
  status,
  content,
  isFirstUpcomingTask = false,
  isCommonTask,
  handleRedirectFromActionUri,
  onSeeCalendarClick,
  isReviewEvent,
  student_exam_status,
  action_uri,
  isLiveClassAvailable: isLiveClassAvailableProp,
  studentItemId
}: TaskCardProps): React.ReactElement => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(status)
  const [exam, setExam] = useState(null)
  const studentDetails = useSelector(getStudent)
  const studentVideoTask = !isCommonTask ? content?.videoUrl : null
  const studentVideoTaskDescription = !isCommonTask
    ? content?.description
    : null

  const isTaskTypeCustom =
    taskType === 'custom' || taskType === 'custom_event_type'
  const taskImage =
    tasksImage[TasksWidgetService.getTaskType(title, taskType)][taskType]
  const upcomingTaskImage =
    upcomingTasksImage[TasksWidgetService.getTaskType(title, taskType)][
      taskType
    ]

  const hasStudentExamStatus = student_exam_status !== null

  const hasVideoReview = isReviewEvent && isNotNilOrEmpty(action_uri)
  const isExamCompleted =
    hasStudentExamStatus && isReviewEvent && student_exam_status === 'completed'
  const isReviewAndShouldBeAvailable = hasVideoReview && isExamCompleted

  const isLiveClass = taskType
    ? TasksWidgetService.isLiveClass(taskType)
    : false

  const isLiveClassAvailable = isLiveClass && isLiveClassAvailableProp

  const isStudentBeginTask = taskType === '1' || taskType === '2'

  const isStudentBeginCalendarTask = taskType === '2'

  const studentExamId = studentItemId
  const isExam = taskType ? TasksWidgetService.isExam(taskType) : false

  const fetchExamDetails = async (examId: string) => {
    const response = await fetchExamsDetails({ id: examId })
    setExam(response.data.exam)
  }

  useEffect(() => {
    if (isExam && studentExamId && isNilOrEmpty(exam)) {
      fetchExamDetails(studentExamId)
    }
  }, [studentExamId, exam])

  const studentBeginningTaskDuration = (isCurrentTask: boolean) => {
    if (taskType === '1') {
      return '5 min'
    } else if (taskType === '2') {
      return (
        <HelperText isCurrentTask={isCurrentTask}>
          2 min |{' '}
          <HelperLink
            isCurrentTask={isCurrentTask}
            onClick={
              isTaskTypeCustom
                ? onSeeCalendarClick
                : !isCommonTask
                ? handleOpenModal
                : handleRedirectFromActionUri
            }
          >
            Need Help? Video Walkthrough
          </HelperLink>
        </HelperText>
      )
    }
    return ''
  }

  const examTask = isExam ? exam : null

  useEffect(() => {
    setIsChecked(status)
  }, [status])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCheckboxChange = () => {
    const updatedStatus = !isChecked
    setIsChecked(updatedStatus)
    // setTimeout is used to show user that the checkbox has been changed
    setTimeout(() => {
      handleUpdateEvent()
    }, 700)
  }

  const handleTimestampSave = progress => {
    const timeOfVideoInMinutes = 320
    const timeOfPlayedVideoInMinutes = progress.playedSeconds
    const percentageOfVideoWatched =
      (timeOfPlayedVideoInMinutes / timeOfVideoInMinutes) * 100
    const hasUserWatchedAlmostWholeVideo = percentageOfVideoWatched >= 98

    hasUserWatchedAlmostWholeVideo && handleUpdateEvent()
  }

  const displayRightButton = () => {
    if ((isFirstUpcomingTask || isCurrentTask) && isLiveClass) {
      return (
        <>
          {isLiveClassAvailable ? (
            <Button
              variant='contained'
              onClick={handleRedirectFromActionUri}
              size='small'
              color={isCurrentTask ? 'primary' : 'secondary'}
              disabled={false}
            >
              {isTaskTypeCustom
                ? t('dashboard.nextTasks.calendar')
                : t('dashboard.nextTasks.start')}
            </Button>
          ) : (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip'
              tooltipContent={t('calendar.liveClassTooltip')}
              id='liveClassTooltip'
            >
              <Button
                variant='contained'
                onClick={
                  isTaskTypeCustom
                    ? onSeeCalendarClick
                    : !isCommonTask
                    ? handleOpenModal
                    : handleRedirectFromActionUri
                }
                size='small'
                color={isCurrentTask ? 'primary' : 'secondary'}
                disabled
              >
                {isTaskTypeCustom
                  ? t('dashboard.nextTasks.calendar')
                  : t('dashboard.nextTasks.start')}
              </Button>
            </Tooltip>
          )}
        </>
      )
    }

    if (isCurrentTask && isExam) {
      return (
        <>
          <ExamButton
            data-testid='DisplayEventModalProps-StyledExamButton'
            status={R.propOr(EXAM_STATUS.scheduled, 'status', examTask)}
            id={R.propOr('', 'id', examTask)}
            date={R.propOr('', 'accessible_to', examTask)}
            exam={examTask}
            isImpersonated={R.propOr(false, 'is_impersonated', studentDetails)}
            isDashboardPage
            isDashboardCurrentTask
          />
        </>
      )
    }
    if (isFirstUpcomingTask && isExam) {
      return (
        <>
          <ExamButton
            data-testid='DisplayEventModalProps-StyledExamButton'
            status={R.propOr(EXAM_STATUS.scheduled, 'status', examTask)}
            id={R.propOr('', 'id', examTask)}
            date={R.propOr('', 'accessible_to', examTask)}
            exam={examTask}
            isImpersonated={R.propOr(false, 'is_impersonated', studentDetails)}
            isDashboardPage
            isDashboardCurrentTask
            isFirstUpcomingTask
          />
        </>
      )
    }
    if ((isFirstUpcomingTask || isCurrentTask) && isReviewEvent) {
      return (
        <>
          {isReviewAndShouldBeAvailable ? (
            <Button
              variant='contained'
              onClick={handleRedirectFromActionUri}
              size='small'
              color={isCurrentTask ? 'primary' : 'secondary'}
              disabled={false}
            >
              {isTaskTypeCustom
                ? t('dashboard.nextTasks.calendar')
                : t('dashboard.nextTasks.start')}
            </Button>
          ) : (
            <Tooltip
              data-testid='exam-review-Tooltip'
              tooltipContent={t('dashboard.nextTasks.availableReviewTooltip')}
              id='examReview'
            >
              <Button
                variant='contained'
                onClick={() => {}}
                size='small'
                color={isCurrentTask ? 'primary' : 'secondary'}
                disabled
              >
                {isTaskTypeCustom
                  ? t('dashboard.nextTasks.calendar')
                  : t('dashboard.nextTasks.start')}
              </Button>
            </Tooltip>
          )}
        </>
      )
    }

    if ((isFirstUpcomingTask || isCurrentTask) && isStudentBeginCalendarTask) {
      return (
        <Button
          variant='contained'
          onClick={onSeeCalendarClick}
          size='small'
          color={isCurrentTask ? 'primary' : 'secondary'}
          disabled={false}
        >
          {isTaskTypeCustom
            ? t('dashboard.nextTasks.calendar')
            : t('dashboard.nextTasks.start')}
        </Button>
      )
    }

    if (isFirstUpcomingTask || isCurrentTask) {
      return (
        <Button
          variant='contained'
          onClick={
            isTaskTypeCustom
              ? onSeeCalendarClick
              : !isCommonTask
              ? handleOpenModal
              : handleRedirectFromActionUri
          }
          size='small'
          color={isCurrentTask ? 'primary' : 'secondary'}
          disabled={
            isReviewEvent
              ? !isReviewAndShouldBeAvailable
              : isLiveClass
              ? !isLiveClassAvailable
              : false
          }
        >
          {isTaskTypeCustom
            ? t('dashboard.nextTasks.calendar')
            : t('dashboard.nextTasks.start')}
        </Button>
      )
    }
    return null
  }

  const displayRightButtonForLastTask = () => {
    if (!isFirstUpcomingTask && !isCurrentTask && isLiveClass) {
      return (
        <>
          {isLiveClassAvailable ? (
            <StyledButton
              variant='contained'
              onClick={handleRedirectFromActionUri}
              size='small'
              color={isCurrentTask ? 'primary' : 'secondary'}
              disabled={false}
            >
              {isTaskTypeCustom
                ? t('dashboard.nextTasks.calendar')
                : t('dashboard.nextTasks.start')}
            </StyledButton>
          ) : (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip'
              tooltipContent={t('calendar.liveClassTooltip')}
              id='liveClassTooltip'
            >
              <StyledButton
                variant='contained'
                onClick={() => {}}
                size='small'
                color={isCurrentTask ? 'primary' : 'secondary'}
                className='disabled'
              >
                {isTaskTypeCustom
                  ? t('dashboard.nextTasks.calendar')
                  : t('dashboard.nextTasks.start')}
              </StyledButton>
            </Tooltip>
          )}
        </>
      )
    }

    if (!isCurrentTask && !isFirstUpcomingTask && isExam) {
      return (
        <>
          <ExamButton
            data-testid='DisplayEventModalProps-StyledExamButton'
            status={R.propOr(EXAM_STATUS.scheduled, 'status', examTask)}
            id={R.propOr('', 'id', examTask)}
            date={R.propOr('', 'accessible_to', examTask)}
            exam={examTask}
            isImpersonated={R.propOr(false, 'is_impersonated', studentDetails)}
            isDashboardPage
          />
        </>
      )
    }
    if (!isFirstUpcomingTask && !isCurrentTask && isReviewEvent) {
      return (
        <>
          {isReviewAndShouldBeAvailable ? (
            <StyledButton
              variant='contained'
              onClick={handleRedirectFromActionUri}
              size='small'
              color={isCurrentTask ? 'primary' : 'secondary'}
            >
              {isTaskTypeCustom
                ? t('dashboard.nextTasks.calendar')
                : t('dashboard.nextTasks.start')}
            </StyledButton>
          ) : (
            <Tooltip
              data-testid='exam-review-Tooltip'
              tooltipContent={t('dashboard.nextTasks.availableReviewTooltip')}
              id='examReview'
            >
              <StyledButton
                variant='contained'
                onClick={() => {}}
                size='small'
                color={isCurrentTask ? 'primary' : 'secondary'}
                className='disabled'
              >
                {isTaskTypeCustom
                  ? t('dashboard.nextTasks.calendar')
                  : t('dashboard.nextTasks.start')}
              </StyledButton>
            </Tooltip>
          )}
        </>
      )
    }
    if (!isCurrentTask && !isFirstUpcomingTask && isStudentBeginCalendarTask) {
      return (
        <StyledButton className='' onClick={onSeeCalendarClick}>
          {t('dashboard.nextTasks.calendar')}
        </StyledButton>
      )
    }
    if (!isCurrentTask && !isFirstUpcomingTask) {
      return (
        <>
          {isTaskTypeCustom ? (
            <StyledButton className='' onClick={onSeeCalendarClick}>
              {t('dashboard.nextTasks.calendar')}
            </StyledButton>
          ) : null}
          {isReviewEvent ? (
            <StyledButton
              className={
                !isReviewEvent
                  ? false
                  : !isReviewAndShouldBeAvailable
                  ? 'disabled'
                  : ''
              }
              onClick={
                !isReviewAndShouldBeAvailable
                  ? () => {}
                  : handleRedirectFromActionUri
              }
            >
              {t('dashboard.nextTasks.start')}
            </StyledButton>
          ) : null}
          {!isTaskTypeCustom && !isReviewEvent && !isLiveClass ? (
            <StyledButton className='' onClick={handleRedirectFromActionUri}>
              {t('dashboard.nextTasks.start')}
            </StyledButton>
          ) : null}
        </>
      )
    }
    return null
  }

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={title}
        data-testid='GettingStarted-task-modal'
      >
        <ModalContainer data-testid='GettingStarter-modal-container'>
          <ModalDescription data-testid='GettingStarter-modal-description'>
            {studentVideoTaskDescription}
          </ModalDescription>
          <VideoContainer>
            <VideoPlayer
              url={studentVideoTask || ''}
              width='640px'
              height='360px'
              onProgress={handleTimestampSave}
              progressInterval={5000}
              controls
              // frameBorder='0'
              // allow='autoplay; fullscreen; picture-in-picture'
              // allowFullScreen
              // title={`Video: ${studentVideoTask || ''}`}
            />
          </VideoContainer>
        </ModalContainer>
      </Modal>
      <React.Fragment key={id}>
        <TaskContainer isCurrentTask={isCurrentTask}>
          <TaskImage
            src={isCurrentTask ? taskImage : upcomingTaskImage}
            alt='task image'
            isCurrentTask={isCurrentTask}
          />

          <TaskContent>
            <TaskWrapper>
              <TaskTitleWrapper>
                <TaskTitle>
                  {removeUnderscoreAndAddSpace(title)}{' '}
                  {taskType ? calendarEventTypeLabel(taskType) : ''}
                </TaskTitle>

                {duration ? (
                  <TaskDuration>
                    {formatMinutesToHoursAndMinutesWithFullName(duration)}
                  </TaskDuration>
                ) : null}
              </TaskTitleWrapper>
            </TaskWrapper>
            {isStudentBeginTask ? (
              <TaskDuration>
                {studentBeginningTaskDuration(isCurrentTask)}
              </TaskDuration>
            ) : null}
          </TaskContent>

          <ButtonGroup>
            <ButtonsContainer>
              <StandardButtonsWrapper
                positionCentered={isCurrentTask && isCommonTask}
              >
                {!isCommonTask ? (
                  <StyledButton onClick={handleUpdateEvent}>
                    {t('dashboard.nextTasks.skip')}
                  </StyledButton>
                ) : null}
                {displayRightButton()}
              </StandardButtonsWrapper>

              {isCurrentTask && isCommonTask ? (
                <CheckBoxContainer>
                  <Checkbox
                    name={id}
                    isSelected={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <CheckBoxLabel>
                    {t('dashboard.nextTasks.complete')}
                  </CheckBoxLabel>
                </CheckBoxContainer>
              ) : null}
            </ButtonsContainer>

            {displayRightButtonForLastTask()}
          </ButtonGroup>
        </TaskContainer>
      </React.Fragment>
    </>
  )
}

export default TaskCard

const TaskContainer = styled.div<{
  isCurrentTask: boolean
  className?: string
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.dashboard.shadow};
  overflow: hidden;
  height: ${({ isCurrentTask }) => (isCurrentTask ? '152px' : '75px')};
  padding: 15px;
  margin-bottom: 15px;
`
const TaskImage = styled.img<{ isCurrentTask: boolean }>`
  width: ${({ isCurrentTask }) => (isCurrentTask ? '161px' : '40px')};
  height: ${({ isCurrentTask }) => (isCurrentTask ? '138px' : '40px')};
  object-fit: cover;
  border-radius: ${({ isCurrentTask }) => (isCurrentTask ? '4px' : '0')};
`

const TaskContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

const TaskWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`

const TaskTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TaskTitle = styled.div`
  font-family: 'Arial Black', Arial, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
`

const TaskDuration = styled.div`
  margin: 5px 0;
  font-size: 14px;
  color: #666;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const StyledButton = styled.div`
  font-weight: bold;
  text-decoration: underline;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  &.disabled {
    color: #aaa;
    cursor: not-allowed;
  }
`
const StandardButtonsWrapper = styled.div<{ positionCentered: boolean }>`
  display: flex;
  flex-direction: ${({ positionCentered }) =>
    positionCentered ? 'column' : 'row'};
  align-items: ${({ positionCentered }) =>
    positionCentered ? 'flex-end' : 'center'};
  gap: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const ModalContainer = styled.div`
  text-align: left;
  font-size: 16px;
`

const ModalDescription = styled.p`
  /* Add any additional styles if needed */
`

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0;
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  color: #888888;
`

const CheckBoxLabel = styled.div`
  margin-left: 5px;
`
const HelperText = styled.div<{ isCurrentTask: boolean }>`
  font-size: 14px;
  display: flex;
  flex-direction: ${({ isCurrentTask }) => (isCurrentTask ? 'column' : 'row')};
`

const HelperLink = styled.div<{ isCurrentTask: boolean }>`
  text-decoration: underline;
  cursor: pointer;
  margin-left: ${({ isCurrentTask }) => (isCurrentTask ? '0' : '5px')};
`
