import { handleToggleMobileMenuRoutine } from 'ducks/layout/actions'
import { API_STATES } from 'utils/redux'
import { Action, LayoutReducer } from 'types'

export const initialState: LayoutReducer = {
  state: API_STATES.PRISTINE,
  isMobileMenuOpen: false
}

export default (state = initialState, action: Action): LayoutReducer => {
  switch (action.type) {
    case handleToggleMobileMenuRoutine.SUCCESS:
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen
      }
    default:
        return state
  }
}
