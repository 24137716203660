import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { filter, propEq, propOr, head } from 'ramda'
import { HtmlRenderer } from 'examkrackers-components'

import { selectNotifications } from 'modules/notifications/ducks/selector'

const NotificationContent = ({ notificationId }) => {
  const allNotifications = useSelector(selectNotifications)
  const filterNotificationById = filter(
    propEq('id', notificationId),
    allNotifications
  )
  const chosenNotification = head(filterNotificationById)

  const htmlContent = propOr('', 'description_html', chosenNotification)

  return (
    // @ts-ignore
    <Container spellCheck='false' autoCorrect='false' autoComplete='false'>
      <HtmlRenderer htmlString={htmlContent} />
    </Container>
  )
}

const Container = styled.div<{
  spellCheck: boolean
  autoCorrect: boolean | string
  autoComplete: boolean | string
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${({ theme }) => theme.colors.notifications.active};

  .ql-editor {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
    /* overflow-y: scroll; */
    height: 100%;
  }
  img {
    cursor: initial !important;
    max-width: 100%;
    max-height: 100%;
  }
`

export default NotificationContent
