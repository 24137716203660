import * as R from 'ramda'

export const isEventAnExamAvailableInFreeTrial = (
  event: any,
  examsAvailableInFreeTrial: any[]
) => {
  const studentExamIds = R.pipe(
    R.propOr('', 'student_exam_ids'),
    R.split(',')
  )(event)

  const isExamAvailableInFreeTrial = examsAvailableInFreeTrial.some(examId =>
    studentExamIds.includes(examId)
  )

  return isExamAvailableInFreeTrial
}
