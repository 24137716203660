import React, { useCallback, useEffect } from 'react'
import {
  Redirect,
  Route,
  useHistory
} from 'react-router-dom/cjs/react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RouteProps, StudentProps } from 'types'
import useSocketProvider from 'providers/socket/Socket.Provider'

import PATHS from 'utils/paths'
import { findStudentRoles } from 'utils/user'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import {
  getIsAuthorised,
  isAuthLoaded,
  getIsAuthActive,
  getStudent
} from 'modules/auth/ducks/selectors'
import {
  studentFetchDetailsRoutine,
  studentLogoutRoutine
} from 'modules/auth/ducks/actions'
import FreeTrialNotificationHandler from 'modules/courses/components/FreeTrialNotificationHandler'
import ImpersonateNotificationHandler from 'components/ImpersonateNotificationHandler'

import LayoutAuth from 'components/LayoutAuth'

import {
  ThemeProvider,
  GlobalStyles,
  setLightTheme
} from 'examkrackers-components'
import * as R from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

interface PrivateRouteProps {
  allowedRoles: string[]
}

export const RoutePrivate = (props: RouteProps & PrivateRouteProps) => {
  const { component, allowedRoles, path, location, ...rest } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const redirectToNotFoundPage = () => history.push(PATHS.notFound)
  const redirectToCourseDeletedPage = () => history.push(PATHS.selectCourse)
  const redirectToCoursePausedPage = () => history.push(PATHS.coursePaused)

  const { isLoaded } = useSocketProvider()

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )
  const logoutStudent = useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  const user: StudentProps = useSelector(getStudent)
  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isAuthActive = useSelector(getIsAuthActive)
  const roles = findStudentRoles(user)

  const handleLogout = async error => {
    await AnalyticsService(user).logEvent(ANALYTICS_EVENTS.logoutOnError, error)
    logoutStudent()
  }

  useEffect(() => {
    if (isAuthFetched) {
      AnalyticsService(user).initUser()
    }
  }, [user])

  useEffect(() => {
    if (isAuthorised && !isAuthFetched) {
      fetchStudentDetails()
    }

    eventEmitter.on(events.logoutUser, handleLogout)
    eventEmitter.on(events.courseDeleted, redirectToCourseDeletedPage)
    eventEmitter.on(events.coursePaused, redirectToCoursePausedPage)

    return () => {
      eventEmitter.off(events.logoutUser, handleLogout)
      eventEmitter.off(events.courseDeleted, redirectToCourseDeletedPage)
      eventEmitter.on(events.coursePaused, redirectToCoursePausedPage)
    }
  }, [])

  useEffect(() => {
    eventEmitter.on(events.notFound, redirectToNotFoundPage)

    return () => {
      eventEmitter.off(events.notFound, redirectToNotFoundPage)
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (isAuthorised && isAuthFetched && !isAuthActive) {
      logoutStudent()
    }
  }, [isAuthorised, isAuthFetched, isAuthActive])

  useEffect(() => {
    if (!isAuthorised) {
      history.push(PATHS.unauthorized)
    }
  }, [isAuthorised])

  useEffect(() => {
    setLightTheme()
  }, [])

  const Component = component

  const renderPageOrRedirect = props => {
    const hasDedicatedRole = R.any(r => R.includes(r, allowedRoles), roles)

    switch (true) {
      case !isAuthorised:
        return (
          <Redirect
            to={{
              pathname: PATHS.unauthorized,
              state: { from: location }
            }}
          />
        )
      case isAuthorised &&
        isAuthFetched &&
        isNotNilOrEmpty(roles) &&
        !hasDedicatedRole &&
        isLoaded:
        return (
          <Redirect
            to={{
              pathname: PATHS.dashboard,
              state: { from: location }
            }}
          />
        )
      default:
        return (
          <ThemeProvider>
            <>
              <ImpersonateNotificationHandler />
              <FreeTrialNotificationHandler />
              <GlobalStyles />
              <LayoutAuth>
                <Component {...props} />
              </LayoutAuth>
            </>
          </ThemeProvider>
        )
    }
  }

  return <Route {...rest} render={renderPageOrRedirect} />
}

RoutePrivate.defaultProps = {
  allowedRoles: []
}

export default RoutePrivate
