import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExamFrame } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialScroll = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialScroll)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialZoom)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialNavigation)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialScroll
    })
  }, [])

  const leftContent = (
    <TutorialScrollContainer>
      <div className='heavy-bordered content-container'>
        <h2>Use the Scroll Feature</h2>
        <h3>Description</h3>
        <p>
          A scroll bar appears when a question or passage does not fully fit on
          the screen. Scroll bars may be horizontal or vertical, and you can use
          them to review the text or graphics that are not currently shown on
          the screen.
        </p>
        <h3>Instructions</h3>
        <p>
          Locate the scroll bar and the scroll bar arrows on each end of the
          scroll bar.
        </p>
        <ul>
          <li>
            To scroll one line at a time, click on either scroll bar arrow.
          </li>
          <li>
            To scroll quickly, hold your left mouse button down while pointing
            at the scroll bar and move the scroll bar itself in the desired
            direction, or click on the page and use the scroll wheel in the
            center of your mouse to scroll in the desired direction.
          </li>
        </ul>
        <h3>Practice</h3>
        <p>
          Use the scroll feature to review the full passage on the right side of
          the page.
        </p>
      </div>
      <p>
        Select{' '}
        <strong>
          <span className='underline'>N</span>ext
        </strong>{' '}
        to advance to the next page.
      </p>
    </TutorialScrollContainer>
  )
  const rightContent = (
    <TutorialScrollContainer>
      <h3>Practice:</h3>
      <p className='indent'>
        Amines play important roles in many biochemical processes. Many methods
        of preparing amines have been explored. Alkylation of primary amines
        with haloalkanes is a well-established procedure in synthesizing
        secondary amines. Unfortunately, this method is limited by the risk of
        further alkylation to tertiary amines and subsequently to quaternary
        ammonium compounds. A selective synthesis, shown in Scheme I, in which
        ethanolamine reacts with carbonyl compounds via an imine intermediate
        that equilibrates with an oxazolidine ring (Compound&nbsp;
        <strong>1</strong>), does not experience the same problem.
      </p>
      <div className='center'>
        <img
          alt='Compound 1'
          src='https://s3.amazonaws.com/benchprep-public/aamc/OG1-BS-BLG6-00-1.jpeg'
          style={{ width: '700px', display: 'inline' }}
        />
      </div>
      <div className='center scheme'>
        <strong>Scheme I</strong>
      </div>
      <p className='indent'>
        <span>
          The secondary amine generated upon reduction can react with different
          compounds to produce stable oxazolidine rings, shown in Scheme II,
          with different topicity on the
        </span>
        <i>N,O</i>
        <span>
          -acetal carbon atom and, more important, with a tertiary amine
          functional group.
        </span>
      </p>
      <div className='center'>
        <img
          alt='Compound 2'
          src='https://s3.amazonaws.com/benchprep-public/aamc/OG1-BS-BLG6-00-2.jpeg'
          style={{ width: '460px', display: 'inline' }}
        />
      </div>
      <div className='center scheme'>
        <strong>Scheme II</strong>
      </div>
      <br />
      <div className='footnote'>
        <span>
          Adapted from S. Saba et al., "Synthesis and NMR Spectral Analysis of
          Amine Heterocycles: The Effect of Asymmetry on the
        </span>
        <sup>1</sup>
        <span>H and</span>
        <sup>13</sup>
        <span>C Spectra of</span>
        <i>N,O</i>
        <span>
          -Acetals." ©2007 by the Division of Chemical Education, Inc., American
          Chemical Society, and references cited therein.
        </span>
      </div>
    </TutorialScrollContainer>
  )

  return <ExamFrame left={leftContent} right={rightContent} />
}

export default TutorialScroll

const TutorialScrollContainer = styled.div`
  p {
    margin: 16px 0;
  }

  ul {
    margin-bottom: 25.888px;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .centered {
    text-align: center;

    img {
      margin-top: -17px;
    }
  }

  .scheme {
    line-height: 24.27px;
  }

  .footnote {
    font-size: 10.6667px;
    line-height: 10.6667px;
  }
`
