import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import {
  getBookByOriginalId,
  getChapterByBookOriginalIdAndOrder
} from 'modules/books/ducks/selectors'
import { useTranslation } from 'react-i18next'
import { RootReducer } from 'types'

interface HeadingContainerProps {
  color: string
  image: string
  $isLoading?: boolean
}

export const HeadingChapter = (): JSX.Element => {
  const [isImageLoading, setIsImageLoading] = useState(true)
  const { t } = useTranslation()
  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )
  const isFirstPageOfChapter = partOrder === 1

  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const tag = R.propOr('', 'tag', book)
  const headerAbbreviation = R.propOr('', 'header_abbreviation', book)
  const tagColor = R.propOr('', 'tag_colour', book)
  const image = R.propOr(null, 'chapter_heading_image_url', book)
  const chapterTitle: string = R.propOr('', 'title', chapter)

  useEffect(() => {
    if (!image) return

    const img = new Image()
    img.src = image
    img.onload = () => setIsImageLoading(false)

    return () => {
      img.onload = null
    }
  }, [image])

  return isFirstPageOfChapter && image ? (
    <HeadingContainer
      color={tagColor}
      image={image}
      $isLoading={isImageLoading}
    >
      <HeadingContent>
        <BookTag color={tagColor}>{headerAbbreviation ?? tag}</BookTag>
        <div>
          <ChapterOrder>
            {t('bookViewer.chapter')} {chapterOrder}
          </ChapterOrder>
          <ChapterTitle>{chapterTitle}</ChapterTitle>
        </div>
      </HeadingContent>
    </HeadingContainer>
  ) : (
    <div />
  )
}

export default HeadingChapter

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const HeadingContainer = styled.div<HeadingContainerProps>`
  margin-top: 5px;
  position: relative;
  margin-left: -20px;
  width: calc(100vw + 3px);
  min-height: 89px;
  padding: 20px;
  isolation: isolate;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -2;
    background-image: ${({ image }) => `url(${image})`};
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    animation: ${fadeIn} 0.3s ease-in;
    will-change: transform;
    transform: translateZ(0);
    ${({ $isLoading }) =>
      $isLoading &&
      css`
        filter: blur(10px);
        transform: scale(1.1);
      `}
  }
`

const HeadingContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 89px;
  height: 100%;
  gap: 20px;
`

const BookTag = styled.div<{ color: string }>`
  color: ${({ theme, color }) => theme.colors.tags[color].backgroundActive};
  background-color: ${({ theme, color }) =>
    theme.colors.tags[color].background};
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0px;
  flex: none;
`

const ChapterOrder = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.main.white};
`

const ChapterTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 36.8px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.main.white};
`
