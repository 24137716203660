import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Course } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { propOr, pipe } from 'ramda'
import {
  CalendarEventTypes,
  getCalendar,
  getMcatDates
} from 'services/CalendarService'
import {
  addDaysToDate,
  DATE_FORMATS,
  formatDate,
  getOnlyDate,
  noTimezoneDate,
  removeUTCFromDate
} from 'utils/date'
import {
  createWeeksArray,
  generateCalendarDays,
  generateDateObject,
  sortArchivedEventsByType
} from 'utils/calendar'
import {
  addManualCalendarTaskRoutine,
  getAamcEventsColorsRoutine,
  setManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import { selectManualEvents } from 'modules/calendar/ducks/selectors'
import * as R from 'ramda'

import { CalendarFirstClass } from 'types/courses'
import { isAfter } from 'date-fns'
import { Task } from 'types/dashboard'
import { COURSE_TYPES } from 'utils/courses'

type CalendarState = {
  exam_at: string | Date
  start_at: string | Date
  mcat_at: string | Date
  calendarDays: any[]
  calendarDayEvents: {
    [key: string]: CalendarEventTypes
  }
} | null

export interface CalendarContextTypes {
  course: Partial<Course>
  lists?: any
  courseDaysAmount: number
  attachNewEventToDate: (
    event: CalendarEventTypes | CalendarEventTypes[]
  ) => void
  detachEventFromDate: (event: CalendarEventTypes) => void
  children: ReactNode
  isLoading: boolean
  isConfigMode: boolean
  calendarWeeks: { start: string; end: string; days: any[] }[] | []
  setLists: (payload: any) => void
  setConfigMode: (val: boolean) => void
  updateEvent: ({
    oldEvent,
    newEvent
  }: {
    oldEvent: CalendarEventTypes
    newEvent: CalendarEventTypes
  }) => void
  updateEventStatus: (event: CalendarEventTypes, status: string) => void
  calendar: CalendarState
  mcatDateOptions: { label: string; value: string }[] | []
  archiveEvents: CalendarEventTypes[] | []
  courseEndDate: Date
  firstVisibleSunday: string
  setFirstVisibleSunday: (arg: string) => void
  endDateFirstDay?: CalendarFirstClass
  originalCourseId?: string | null
  firstClassDate?: string | null
  inactiveLiveClasses: string[]
  addInactiveLiveClass: (id: string) => void
  removeInactiveLiveClass: (id: string) => void
  updateTask: (taskId: string, updates: Partial<Task>) => void
}

const defaultValues = {
  course: {},
  lists: undefined,
  courseDaysAmount: 0,
  attachNewEventToDate: (event: CalendarEventTypes | CalendarEventTypes[]) =>
    null,
  detachEventFromDate: (event: CalendarEventTypes) => null,
  children: <div />,
  isLoading: false,
  isConfigMode: false,
  calendarWeeks: [],
  setLists: () => {},
  setConfigMode: () => {},
  updateEvent: () => {},
  updateEventStatus: () => {},
  mcatDateOptions: [],
  archiveEvents: [],
  calendar: null,
  courseEndDate: new Date(),
  firstVisibleSunday: '',
  setFirstVisibleSunday: () => {},
  originalCourseId: '',
  firstClassDate: null,
  inactiveLiveClasses: [],
  addInactiveLiveClass: () => {},
  removeInactiveLiveClass: () => {},
  updateTask: () => {}
}

export const CalendarContext =
  createContext<CalendarContextTypes>(defaultValues)

export const CalendarContextProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [lists, setLists] = useState<any>({})
  const currentCourse: Course = useSelector(getCurrentCourse)
  const archiveEvents = useSelector(selectManualEvents)
  const [isLoading, setIsLoading] = useState(true)
  const [configMode, setConfigMode] = useState(false)
  const [calendar, setCalendar] = useState<CalendarState>(null)
  const [calendarWeeks, setCalendarWeeks] = useState<any[]>([])
  const [mcatDateOptions, setMcatDateOptions] = useState<any[]>([])
  const [firstVisibleSunday, setFirstVisibleSunday] = useState('')
  const [firstClassDate, setFirstClassDate] = useState<string | null>(null)
  const [inactiveLiveClasses, setInactiveLiveClasses] = useState<string[]>([])

  const isFreeTrial = currentCourse.type === COURSE_TYPES.freeTrial

  const originalCourseId = LocalStorageService.get(
    LOCAL_STORAGE_KEYS.originalCourseId
  )
  const bookCourseId = currentCourse?.book_course_id || null

  const dispatch = useDispatch()

  const handleCalendarResponse = (resp: any) => {
    const calendarDates = resp.data.calendar
    const events = resp.data.calendar_events.data

    const firstClassEventDate =
      events.find(e => e.type === 'live_class')?.event_date || null

    if (firstClassEventDate) {
      setFirstClassDate(firstClassEventDate)
    }
    const inactiveLiveClassesIds = resp.data.parent_event_ids

    if (isNotNilOrEmpty(inactiveLiveClassesIds)) {
      setInactiveLiveClasses(inactiveLiveClassesIds)
    }

    const calendarStartAt = removeUTCFromDate(calendarDates.start_at)

    const isClassBeforeStart =
      firstClassEventDate &&
      // @ts-ignore
      noTimezoneDate(removeUTCFromDate(firstClassEventDate)) <
        // @ts-ignore
        noTimezoneDate(calendarStartAt)

    const startDate = isClassBeforeStart ? firstClassEventDate : calendarStartAt
    // const endDate = removeUTCFromDate(calendarDates.exam_at)
    const endDate = removeUTCFromDate(currentCourse.accessible_to)
    const mcatDate = removeUTCFromDate(calendarDates.mcat_at)

    const calendarEndAt =
      // @ts-ignore
      noTimezoneDate(mcatDate) > noTimezoneDate(endDate) ? mcatDate : endDate
    // @ts-ignore
    const weeks = createWeeksArray(startDate, calendarEndAt)

    setCalendarWeeks(weeks)

    const archived = events.filter(e => e.status === 'archived')
    const notArchived = events.filter(e => e.status != 'archived')

    dispatch(
      setManualCalendarTasksRoutine({
        list: sortArchivedEventsByType(archived)
      })
    )

    const lastDayOfCalendarEvents = isFreeTrial
      ? noTimezoneDate(removeUTCFromDate(calendarDates.exam_at))
      : endDate

    const calendarDayEvents = generateCalendarDays(
      startDate,
      // @ts-ignore
      lastDayOfCalendarEvents,
      notArchived.map(event => ({
        ...event,
        id: event.id || `${event.type}-${event.event_date}-${Math.random()}`
      }))
    )
    const calendarDays = generateDateObject(startDate, mcatDate)

    setConfigMode(isNilOrEmpty(calendarDates.exam_at))
    setCalendar({
      ...calendarDates,
      calendarDayEvents,
      calendarDays
    })

    setLists(calendarDayEvents)
  }

  const handleCalendarFetch = () => {
    getCalendar()
      .then(resp => {
        const calendarDates = resp.data.calendar
        const hasCalendarSet = isNotNilOrEmpty(calendarDates.mcat_at)

        if (hasCalendarSet) {
          handleCalendarResponse(resp)
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const daysAmount = currentCourse.metadata
    ? pipe(
        propOr('{}', 'metadata'),
        JSON.parse,
        propOr('0', 'days_amount'),
        Number
      )(currentCourse)
    : 0

  const removeInactiveLiveClass = (id: string) => {
    setInactiveLiveClasses(prev => {
      const index = prev.findIndex(classId => classId === id)
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)]
      }
      return prev
    })
  }

  const addInactiveLiveClass = (id: string) => {
    setInactiveLiveClasses(prev => [...prev, id])
  }

  const attachNewEventToDate = (
    event: CalendarEventTypes | CalendarEventTypes[]
  ) => {
    if (Array.isArray(event)) {
      event.forEach(singleEvent => {
        const eventDate = singleEvent.event_date.slice(0, 10)
        setLists(prev => ({
          ...prev,
          [eventDate]: [
            ...R.uniqBy(e => e.id, [...prev[eventDate], singleEvent])
          ]
        }))
      })
    } else {
      const eventDate = event.event_date.slice(0, 10)
      setLists(prev => ({
        ...prev,
        [eventDate]: [...R.uniqBy(e => e.id, [...prev[eventDate], event])]
      }))
    }
  }

  const detachEventFromDate = (event: CalendarEventTypes) => {
    const getEventDate = event.event_date ? event.event_date : event.class_date
    const id = event.event_date ? event.id : event.student_calendar_event_id

    const dateWithoutTime = getEventDate?.match(getOnlyDate)
    const extractedDate = dateWithoutTime ? dateWithoutTime[0] : ''

    setLists(prev => {
      const day = prev[extractedDate]
      return {
        ...prev,
        [extractedDate]: day.filter(e => e.id !== id)
      }
    })
  }

  const updateEvent = ({ oldEvent, newEvent }) => {
    const existingEventDate = removeUTCFromDate(oldEvent.event_date)
    const newEventDate = removeUTCFromDate(newEvent.event_date)

    setLists(dates => {
      const updatedLists = { ...dates }

      if (updatedLists[existingEventDate as string]) {
        // Remove the event from its old position (if it exists)
        Object.keys(updatedLists).forEach(date => {
          updatedLists[date] = updatedLists[date].filter(
            event => event.id !== newEvent.id
          )
        })

        // Add the event to the new date
        updatedLists[newEventDate as string] = [
          ...(updatedLists[newEventDate as string] || []),
          newEvent
        ]
      }

      if (newEvent.status !== 'archived') {
        updatedLists[newEventDate as string] = R.uniqBy(
          e => e.id,
          [...(updatedLists[newEventDate as string] || []), newEvent]
        )
      }

      return updatedLists
    })

    if (newEvent.status === 'archived') {
      dispatch(
        addManualCalendarTaskRoutine({
          event: {
            ...newEvent,
            event_date: '2030-04-04T00:00:00'
          }
        })
      )
    }
  }

  const updateEventStatus = (event, status) => {
    setLists(prev => {
      const updatedLists = { ...prev }

      if (status === 'archived') {
        for (const date in updatedLists) {
          updatedLists[date] = updatedLists[date].filter(e => e.id !== event.id)
        }
        return updatedLists
      }

      // Iterate over each date in the lists
      for (const date in updatedLists) {
        updatedLists[date] = updatedLists[date].map(e => {
          if (e.id == event.id) {
            return {
              ...e,
              status: status // update the status here
            }
          } else {
            return e
          }
        })
      }

      return updatedLists
    })
  }

  const courseEndDate = useMemo(() => {
    const accessibleTo = R.propOr('', 'accessible_to', currentCourse)

    const calculatedAccessibleTo = addDaysToDate(new Date(), daysAmount)

    return isNotNilOrEmpty(accessibleTo)
      ? noTimezoneDate(accessibleTo as string)
      : calculatedAccessibleTo
  }, [currentCourse])

  useEffect(() => {
    handleCalendarFetch()
  }, [])

  useEffect(() => {
    originalCourseId && dispatch(getAamcEventsColorsRoutine())
    // fetchNextClasses()
  }, [originalCourseId])

  useEffect(() => {
    if (bookCourseId) {
      getMcatDates({ id: bookCourseId })
        .then(resp => {
          const dates = resp.data.data

          const options = dates
            .filter(date => isAfter(noTimezoneDate(date.mcat_date), new Date()))
            .sort(
              (a, b) =>
                noTimezoneDate(a.mcat_date).getTime() -
                noTimezoneDate(b.mcat_date).getTime()
            )
            .map(date => ({
              label: formatDate(date.mcat_date, DATE_FORMATS.slash),
              value: propOr('', 'id', date)
            }))

          setMcatDateOptions(options)
        })
        .catch(err => {
          setMcatDateOptions([])
          console.error(err)
        })
    }
  }, [bookCourseId])

  const updateTask = (taskId: string, updates: Partial<Task>) => {
    setLists(prevLists => {
      const newLists = { ...prevLists }

      // Find and update the task in all lists
      Object.keys(newLists).forEach(key => {
        newLists[key] = newLists[key].map(task =>
          task.id === taskId ? { ...task, ...updates } : task
        )
      })

      return newLists
    })
  }

  return (
    <CalendarContext.Provider
      value={{
        archiveEvents,
        attachNewEventToDate,
        calendar,
        calendarWeeks,
        course: currentCourse,
        courseDaysAmount: daysAmount as number,
        courseEndDate,
        detachEventFromDate,
        isConfigMode: configMode,
        isLoading,
        lists,
        mcatDateOptions,
        setConfigMode,
        setLists,
        updateEvent,
        updateEventStatus,
        firstVisibleSunday,
        setFirstVisibleSunday,
        originalCourseId,
        firstClassDate,
        children,
        inactiveLiveClasses,
        addInactiveLiveClass,
        removeInactiveLiveClass,
        updateTask
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}
