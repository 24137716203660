import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialSectionReview = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialSectionReview)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )
  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialEnd)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialFlagForReview)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialSectionReview
    })
  }, [])

  return (
    <ExamContent>
      <TutorialSectionReviewContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>Use the Section Review Feature</h2>
            <h3>Description</h3>
            <p>
              When you advance past the last question of each section and if
              there is time remaining, you can use the Section Review to review
              questions. The Section Review indicates which questions are
              complete, incomplete, unseen or marked with the flag icon to
              review later. The flag icon next to the question number will be
              filled in blue, if you flagged that question previously.
            </p>
            <h3>Instruction</h3>
            <ul className='numeric'>
              <li>
                To read the directions for Section Review, select the square
                with a pencil next to the word "Instructions" in the upper left
                corner as shown with the yellow arrow on the screen or the Alt+O
                keyboard shortcut. After reading the instructions, you may close
                the instructions by selecting Close or Alt+C.
              </li>
              <li>
                There are four ways to review questions.
                <ul className='alphabetical'>
                  <li>
                    Selecting{' '}
                    <strong>
                      Review <span className='underline'>A</span>ll
                    </strong>{' '}
                    at the top right of the screen or Alt+A from the All tab
                    will allow you to review all questions in the section
                    beginning at question one.
                  </li>
                  <li>
                    Selecting the Incomplete tab allows you to see all items
                    that are incomplete. Select{' '}
                    <strong>
                      Review <span className='underline'>I</span>ncomplete
                    </strong>{' '}
                    or Alt+I to review each incomplete question in the section.
                  </li>
                  <li>
                    Selecting the Unseen tab allows you to review only questions
                    you have not seen. Select{' '}
                    <strong>
                      Review <span className='underline'>U</span>nseen
                    </strong>{' '}
                    or Alt+U to review each unseen question in the section.
                  </li>
                  <li>
                    Selecting the Flagged tab allows you to review only
                    questions that you flagged for review. Select{' '}
                    <strong>
                      <span className='underline'>R</span>eview Flagged
                    </strong>{' '}
                    or Alt+R to review each flagged question.
                  </li>
                  <li>
                    Selecting <strong>Review</strong> to the right of a question
                    allows you to review individual questions. After you’ve
                    reviewed the question, select{' '}
                    <strong>
                      Revie<span className='underline'>w</span> Screen
                    </strong>{' '}
                    or Alt+W to return to the Section Review.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span className='underline'>E</span>nd Section{' '}
                </strong>
                at the bottom of the screen or Alt+E allows you to end the
                section and advance to the next exam section.
              </li>
            </ul>
            <div className='center'>
              <img
                alt='section review screenshot'
                src='https://s3.amazonaws.com/benchprep-public/aamc/tutorial-candidate-name-section-review-columns.png'
                style={{ width: '500px', display: 'inline' }}
              />
            </div>
            <h3>Practice</h3>
            <p>
              Review the <strong>Section Review</strong> table provided.
              Identify the flag and status indicator for each question in the
              table as well as the All, Incomplete, Unseen, and Flagged tabs.
            </p>
            <p>
              The{' '}
              <strong>
                Revie<span className='underline'>w</span> Screen
              </strong>{' '}
              functions similarly to the{' '}
              <strong>
                Na<span className='underline'>v</span>igation{' '}
              </strong>{' '}
              feature, which is no longer available once the Section Review
              screen has been reached.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </div>
      </TutorialSectionReviewContainer>
    </ExamContent>
  )
}

export default TutorialSectionReview

const TutorialSectionReviewContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
    margin-top: 5px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`
