import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TableExams from 'modules/exams/components/TableExams'
import ExamFilters from 'modules/exams/components/ExamFilters'
import { useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'

import { Course, PaginationProps } from 'types'
import {
  getExamsPagination,
  getIsExamsLoaded
} from 'modules/exams/ducks/selectors'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'

import { getStudent } from 'modules/auth/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

function ExamsView(): JSX.Element {
  const { t } = useTranslation()
  const { push } = useHistory()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const hasCourse = R.pipe(R.propOr('', 'id'), isNotNilOrEmpty)(currentCourse)
  const examsPagination: PaginationProps = useSelector(getExamsPagination)
  const totalRecords = propOr(0, 'recordsTotal', examsPagination)
  const examsLoaded = useSelector(getIsExamsLoaded)
  const user = useSelector(getStudent)
  const [seeExamsPage, setSeeExamsPage] = useState(true)

  useEffect(() => {
    if (!hasCourse && examsLoaded && totalRecords === 0) {
      push('/empty-account')
    }
  }, [examsLoaded])

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeExamsPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeExamsPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeExamsPage(false)
    }
  }, [currentCourse, seeExamsPage])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.exam.listing')}</title>
      </Helmet>
      <Header>
        <PageTitle>
          {t('exams.title')} ({totalRecords})
        </PageTitle>
        <SaltyWrapper>
          <img
            src='/assets/illustrations/SaltyBigBrain.svg'
            alt='salty-image'
          />
        </SaltyWrapper>
        <FiltersWrapperOuter>
          <FiltersWrapper justSearch={!hasCourse}>
            <ExamFilters justSearch={!hasCourse} />
          </FiltersWrapper>
        </FiltersWrapperOuter>
      </Header>
      <TableExams />
    </React.Fragment>
  )
}

export default ExamsView

const FiltersWrapperOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
`

const Header = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  min-height: 32px;
`

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: ${({ justSearch }) => (justSearch ? '0 0 0 auto' : '0 auto')};
  max-width: ${({ justSearch }) => (justSearch ? '100%' : '50%')};
  justify-content: ${({ justSearch }) => (justSearch ? 'flex-end' : 'center')};
`

const SaltyWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 150px;
  z-index: ${({ theme }) => theme.zIndex.navigation + 11};
  img {
    max-width: 54px;
  }
`
