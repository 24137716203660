import React, { useEffect, useMemo, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'

import ReorderSalty from 'assets/images/Calendar_Reorder_Saltys.svg'

import { getMcatDates } from 'services/CalendarService'
import { CalendarContext, CalendarContextProvider } from 'hooks/CalendarContext'

import {
  SingleSelect,
  CalendarIcon,
  Button,
  Tooltip
} from 'examkrackers-components'

import { getOptionByValue } from 'utils/form'
import {
  columnsOrder,
  countStudyDaysInRange,
  daysOfWeek,
  prevStateOfCalendarColumns
} from 'utils/calendar'
import {
  DATE_FORMATS,
  formatDate,
  getUniversalDate,
  noTimezoneDate,
  removeUTCFromDate
} from 'utils/date'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import DateInput from 'components/DateInput/DateInput'

import ReorderCalendarConfirmationModal from './ReorderCalendarConfirmationModal'
import DayListItem from 'modules/calendar/setup/DayListItem'
import LeftColumnQuestions from 'modules/calendar/setup/LeftColumnQuestions'
import useCalendarSettingsValidation from 'hooks/useCalendarSettingsValidation'
import { addDays, isAfter } from 'date-fns'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0]
}

const WrappedReorderCalendarModalNew = () => {
  return (
    <CalendarContextProvider>
      <ReorderCalendarModalNew />
    </CalendarContextProvider>
  )
}
const ReorderCalendarModalNew: React.FC = () => {
  const [values, setValues] = useState(initialValues)
  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])

  const { t } = useTranslation()
  const history = useHistory()

  const currentCourse = useSelector(getCurrentCourse)

  const currentChoiceOfUsersPrioriDays =
    isNotNilOrEmpty(currentCourse) &&
    prevStateOfCalendarColumns(currentCourse.prioridays)

  const [columnsData, setColumnsData] = useState(currentChoiceOfUsersPrioriDays)

  const { course } = useContext(CalendarContext)

  const amountOfDays = course.metadata && JSON.parse(course.metadata)
  const parsedAmountOfDays =
    amountOfDays && propOr(0, 'days_amount', amountOfDays)
  const isOneWeekSubscription = parsedAmountOfDays == 7

  const courseId = propOr('', 'book_course_id', currentCourse)

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date =>
        isAfter(
          new Date(date.mcat_date),
          addDays(new Date(course.accessible_from), 7)
        )
      )
      .sort(
        (a, b) =>
          new Date(a.mcat_date).getTime() - new Date(b.mcat_date).getTime()
      )
      .map(date => ({
        label: formatDate(date.mcat_date, DATE_FORMATS.slash),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const {
    dndErrors,
    startDateMax,
    errors,
    touchedFields,
    setTouchedFields
  } = useCalendarSettingsValidation({
    course: currentCourse,
    values,
    mcatDateOptions,
    columnsData,
    isReorder: true
  })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result
    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'mcatDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'reviewDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
  }

  const handleCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      mcat_at: currentCourse.mcatDate.id,
      exam_at: isOneWeekSubscription
        ? getUniversalDate(course.accessible_to)
        : currentCourse.exam_at,
      calendar_start_at: isOneWeekSubscription
        ? getUniversalDate(new Date())
        : currentCourse.calendar_start_at,
      prioriDays: currentCourse.prioridays
    }))
  }, [currentCourse, isOneWeekSubscription])

  const studyDaysCount = countStudyDaysInRange(
    values.calendar_start_at,
    values.exam_at,
    values.prioriDays
  )

  const { calendar_start_at, mcat_at, exam_at, prioriDays } = values
  const hasAllValues = [calendar_start_at, mcat_at, exam_at, prioriDays].every(
    el => isNotNilOrEmpty(el)
  )

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  return (
    <Container data-testid='ReorderCalendarModalNew-Container'>
      <TitleWrapper data-testid='ReorderCalendarModalNew-TitleWrapper-reorder.title'>
        {t('calendar.reorder.title')}
      </TitleWrapper>
      <TextContainer data-testid='ReorderCalendarModalNew-TextContainer-description'>
        <SubtitleWrapper data-testid='ReorderCalendarModalNew-SubtitleWrapper-description'>
          {t('calendar.reorder.description')}
        </SubtitleWrapper>
      </TextContainer>
      <ColumnsWrapper data-testid='ReorderCalendarModalNew-ColumnsWrapper'>
        <LeftColumn data-testid='ReorderCalendarModalNew-LeftColumn'>
          <QuestionContainer data-testid='ReorderCalendarModalNew-QuestionContainer-mcat'>
            <DescWrapper data-testid='ReorderCalendarModalNew-DescWrapper-mcat'>
              <QuestionTitle data-testid='ReorderCalendarModalNew-QuestionTitle-mcat'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ReorderCalendarModalNew-QuestionDescription-mcat'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper data-testid='ReorderCalendarModalNew-SelectWrapper-mcat'>
              <StyledSelect
                data-testid='ReorderCalendarModalNew-StyledSelect-mcat'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && isNotNilOrEmpty(values.mcat_at) && (
            <Error data-testid='ReorderCalendarModalNew-Error-mcatDate'>
              {errors.mcatDate}
            </Error>
          )}
          <QuestionContainer
            data-testid='ReorderCalendarModalNew-QuestionContainer-mcat'
            disabled={isNilOrEmpty(values.mcat_at) || isOneWeekSubscription}
          >
            <DescWrapper data-testid='ReorderCalendarModalNew-DescWrapper-start'>
              <QuestionTitle data-testid='ReorderCalendarModalNew-QuestionTitle-start'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ReorderCalendarModalNew-QuestionDescription-start'>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ReorderCalendarModalNew-PickerWrapper-start'>
              <DateInput
                data-testid='ReorderCalendarModalNew-DateInput-start'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={startDateMax}
                disabled
              />
            </PickerWrapper>
            <IconContainer data-testid='ReorderCalendarModalNew-IconContainer-calendar'>
              <CalendarIcon
                data-testid='ReorderCalendarModalNew-CalendarIcon'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && isNotNilOrEmpty(values.calendar_start_at) && (
            <Error data-testid='ReorderCalendarModalNew-Error-startDate'>
              {errors.startDate}
            </Error>
          )}
          <QuestionContainer
            disabled={
              isNilOrEmpty(values.calendar_start_at) || isOneWeekSubscription
            }
            data-testid='ReorderCalendarModalNew-QuestionContainer-start'
          >
            <DescWrapper data-testid='ReorderCalendarModalNew-DescWrapper-end'>
              <QuestionTitle data-testid='ReorderCalendarModalNew-QuestionTitle-end'>
                {t('calendar.setup.end.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ReorderCalendarModalNew-QuestionDescription-end'>
                {t('calendar.setup.end.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ReorderCalendarModalNew-PickerWrapper-exam_at'>
              <DateInput
                data-testid='ReorderCalendarModalNew-DateInput-exam_at'
                name='exam_at'
                id='exam_at_input'
                value={values.exam_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={noTimezoneDate(
                  removeUTCFromDate(values.calendar_start_at)
                )}
                maxDate={noTimezoneDate(
                  removeUTCFromDate(currentCourse.accessible_to)
                )}
                withExtendModal
              />
            </PickerWrapper>
            <IconContainer data-testid='ReorderCalendarModalNew-IconContainer-calendar'>
              <CalendarIcon
                data-testid='ReorderCalendarModalNew-CalendarIcon'
                onClick={handleCalendarManualOpen('exam_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.endDate && isNotNilOrEmpty(values.exam_at) && (
            <Error data-testid='ReorderCalendarModalNew-Error-end'>
              {errors.endDate}
            </Error>
          )}
          <LeftColumnQuestions
            data-testid='ReorderCalendarModalNew-LeftColumnQuestions'
            disabled={isNilOrEmpty(values.exam_at) || isOneWeekSubscription}
          />
        </LeftColumn>
        <RightColumn data-testid='ReorderCalendarModalNew-RightColumn'>
          <ReorganizeSaltyImage
            data-testid='ReorderCalendarModalNew-ReorganizeSaltyImage'
            id='reorganize-salty'
            src={ReorderSalty}
            alt='Salty reorganize'
          />
          <DragDropContext
            onDragEnd={handleDragEnd}
            data-testid='ReorderCalendarModalNew-DragDropContext'
          >
            <WrapperDnd
              disabled={isNilOrEmpty(values.exam_at) || isOneWeekSubscription}
              data-testid='ReorderCalendarModalNew-WrapperEnd'
            >
              <WeekDaysColumn data-testid='ReorderCalendarModalNew-WeekDaysColumn'>
                <>
                  <ColumnTitle data-testid='ReorderCalendarModalNew-ColumnTitle'>
                    {t('calendar.setup.mandatoryDaysOff')}
                  </ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index <= 6) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ReorderCalendarModalNew-DropZoneWrapper-mandatoryDaysOff-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ReorderCalendarModalNew-Droppable-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZone
                                  data-testid={`ReorderCalendarModalNew-SingleDropZone-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <DropContainer
                                    data-testid={`ReorderCalendarModalNew-DropContainer-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>

                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ReorderCalendarModalNew-DayListItem-${column.day[0].id}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZone>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </WeekDaysColumn>
              <McatDaysColumn>
                <>
                  <McatContainer>
                    <ColumnTitle data-testid='ReorderCalendarModalNew-ColumnTitle-fullLengthMCATDay'>
                      {t('calendar.setup.fullLengthMCATDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 7) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ReorderCalendarModalNew-DropZoneWrapper-fullLengthMCATDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ReorderCalendarModalNew-Droppable-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`ReorderCalendarModalNew-SingleDropZoneActive-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`ReorderCalendarModalNew-DropContainer-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ReorderCalendarModalNew-DayListItem-${column.day[0].id}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                  <McatContainer>
                    <ColumnTitle>
                      {t('calendar.setup.fullLengthMCATReviewDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 8) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ReorderCalendarModalNew-DropZoneWrapper-fullLengthMCATReviewDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ReorderCalendarModalNew-Droppable-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`ReorderCalendarModalNew-SingleDropZoneActive-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`ReorderCalendarModalNew-DropContainer-${column.id}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ReorderCalendarModalNew-DayListItem-${column.day[0].id}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                </>
              </McatDaysColumn>
              <PrioriColumn>
                <>
                  <ColumnTitle>{t('calendar.setup.studyDays')}</ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index > 8) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ReorderCalendarModalNew-DropZoneWrapper-studyDays-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ReorderCalendarModalNew-Droppable-studyDays-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZoneActive
                                  data-testid={`ReorderCalendarModalNew-SingleDropZoneActive-studyDays-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  isDraggingOver={snapshot.isDraggingOver}
                                >
                                  <DropContainer
                                    data-testid={`ReorderCalendarModalNew-DropContainer-studyDays-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>
                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ReorderCalendarModalNew-DayListItem-studyDays-${column.day[0].id}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZoneActive>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </PrioriColumn>
            </WrapperDnd>
          </DragDropContext>
          {isNotNilOrEmpty(dndErrors) &&
            touchedFields.includes('priori_days') && (
              <DndErrors>
                {dndErrors.map(error => (
                  <Error>{error}</Error>
                ))}
              </DndErrors>
            )}
        </RightColumn>
      </ColumnsWrapper>
      {studyDaysCount > 0 &&
        studyDaysCount < 44 &&
        hasAllValues &&
        !isOneWeekSubscription && (
          <WarningBox>
            <WarningBoxContent>
              Attention: Your current selection has only {studyDaysCount} Study
              Days.
              <br />
              We recommend at least 44 Study Days, so that you have at least one
              study day per chapter.
            </WarningBoxContent>
          </WarningBox>
        )}
      {isOneWeekSubscription && (
        <WarningBox>
          <WarningBoxContent>
            {t('calendar.setupForOneWeekSubWarningPartOne')}
            <br />
            {t('calendar.setupForOneWeekSubWarningPartTwo')}
          </WarningBoxContent>
        </WarningBox>
      )}
      <ButtonsWrapper>
        <ButtonContainer>
          <ButtonWrapper color='tertiary' onClick={handleCancel}>
            {t('calendar.button.cancel')}
          </ButtonWrapper>
        </ButtonContainer>
        <ButtonContainer>
          {isNotNilOrEmpty(errors) && !isOneWeekSubscription ? (
            <Tooltip
              id='inactive-button'
              tooltipContent={
                'This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
              }
            >
              <ButtonWrapper
                variant='contained'
                color='secondary'
                onClick={() => {}}
                disabled
              >
                {t('calendar.reorder.button')}
              </ButtonWrapper>
            </Tooltip>
          ) : (
            <ReorderCalendarConfirmationModal
              values={values}
              disabled={isNotNilOrEmpty(errors) && !isOneWeekSubscription}
            />
          )}
        </ButtonContainer>
      </ButtonsWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(WrappedReorderCalendarModalNew)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 10px;
  width: 616px;
  line-height: 14px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  font-weight: bold;
`
const WeekDaysColumn = styled.div``
const McatDaysColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`
const McatContainer = styled.div`
  &:first-child {
    margin-bottom: 33px;
  }
`
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;
  div {
    overflow-x: hidden;
  }
`

const ReorganizeSaltyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 133px;
  width: 135px;
  margin-left: 190px;
  margin-top: 51px;
  margin-bottom: 41px;
`

const Error = styled.div`
  color: red;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  justify-content: center;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`

const WarningBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const WarningBoxContent = styled.div`
  text-align: center;
  color: red;
`
