import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { propOr, includes, pathOr } from 'ramda'
import { useParams, useHistory, Prompt } from 'react-router-dom'
import { RootReducer } from 'types'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import PATHS from 'utils/paths'
import { getSavedTimeOrDefault } from 'modules/exam/utils/examTimeConfig'
import { isNotNilOrEmpty } from 'utils/ramda'

import { saveExamState } from 'services/ExamsService'

import { PauseExam } from 'examkrackers-components'
import {
  getSectionsIdsWithCurrentTime,
  getSectionIdByCurrentPageId,
  getExamDetails,
  getStepHtml,
  getSectionDetailsById
} from 'modules/exam/ducks/selectors'
import PAGE_IDS from 'modules/exam/utils/pageIds'
import { resetExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

import {
  getAccountType,
  getIsPreviewAdmin,
  getStudent
} from 'modules/auth/ducks/selectors'
import * as R from 'ramda'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import { DATE_FORMATS, formatDate } from 'utils/date'

interface PauseHandlerProps {
  secondsLeft: number
  currentPage: string
  currentPageConfig: any
}

export const PauseHandler = ({
  secondsLeft,
  currentPage,
  currentPageConfig
}: PauseHandlerProps): JSX.Element => {
  const { push } = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)
  const [isNotFound, setIsNotFound] = useState(false)
  const user = useSelector(getStudent)

  const accountType: string = useSelector(getAccountType)
  const examDetails = useSelector(getExamDetails)
  const type_title = pathOr('', ['type', 'title'], examDetails)
  const title = pathOr('', ['exam', 'title'], examDetails)
  const questionNumber = R.propOr(0, 'currentPage', currentPageConfig)
  const sectionIdByCurrentPageId: string = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )

  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, currentPage)
  )

  const sectionNumber = R.pipe(
    R.propOr([], 'questions'),
    R.findIndex(R.propEq('id', sectionIdByCurrentPageId)),
    R.add(1)
  )(examDetails)

  const pageName = R.propOr('', 'name', currentPageConfig)

  const passageId = R.propOr('', 'passageId', stepHtml)
  const passages = R.pathOr(
    [],
    ['questions', sectionNumber - 1, 'passages'],
    examDetails
  )

  const passage = R.find(R.propEq('id', passageId), passages)

  const passageNumber = R.propOr('standalone', 'realOrder', passage)

  const renderIfQuestionPage = value =>
    isNotNilOrEmpty(stepHtml) ? value : '-'

  const renderIfSectionPage = value =>
    isNotNilOrEmpty(stepHtml) || isNotNilOrEmpty(sectionDetails) ? value : '-'

  const handleNotFoundExam = () => setIsNotFound(true)

  const handleRedirectOnNotFound = () => {
    push(PATHS.exams)
    showToast({
      key: 'toast.examNotFound',
      severity: SEVERITY.error
    })
  }

  useEffect(() => {
    if (isNotFound) {
      setTimeout(handleRedirectOnNotFound, 200)
    }
  }, [isNotFound])

  const isBeggingExamPage = includes(currentPage, [
    PAGE_IDS.start,
    PAGE_IDS.confirmId,
    PAGE_IDS.certification,
    PAGE_IDS.certificationDecline,
    PAGE_IDS.tutorialStart,
    PAGE_IDS.tutorialNavigation,
    PAGE_IDS.tutorialScroll,
    PAGE_IDS.tutorialZoom,
    PAGE_IDS.tutorialBackground,
    PAGE_IDS.tutorialHighlight,
    PAGE_IDS.tutorialStrikethrough,
    PAGE_IDS.tutorialPeriodicTable,
    PAGE_IDS.tutorialFlagForReview,
    PAGE_IDS.tutorialSectionReview,
    PAGE_IDS.tutorialEnd
  ])

  const [isLeaving, setIsLeaving] = useState(false)
  const [isManualPause, setIsManualPause] = useState(false)

  const id: string = propOr('', 'id')(params)
  const sectionIdsWithCurrentTime = useSelector(getSectionsIdsWithCurrentTime)

  const redirectToExamsList = () => {
    push(PATHS.exams)
    // This is because ExamTimers are based on the exam reducer while unmounting
    setTimeout(resetExamDetails, 1000)
  }
  const onbeforeunloadFn = () => {
    if (!isNotFound) {
      setIsLeaving(true)
    }
  }

  const resetExamDetails = React.useCallback(
    () => dispatch(resetExamDetailsRoutine()),
    [dispatch]
  )

  const saveExam = async (callback = () => {}) => {
    if (isNotNilOrEmpty(sectionIdByCurrentPageId)) {
      const payload = {
        id,
        data: {
          section_id: sectionIdByCurrentPageId,
          exam_seconds_left: getSavedTimeOrDefault(
            sectionIdsWithCurrentTime,
            sectionIdByCurrentPageId,
            secondsLeft
          ),
          current_page: currentPage
        }
      }

      const handleError = e => console.error(e)
      saveExamState(payload).then(callback).catch(handleError)
    } else {
      callback()
    }
  }

  useEffect(() => {
    if (isManualPause && !isPreviewAdmin) {
      saveExam(redirectToExamsList)
    }
    if (isManualPause && isPreviewAdmin) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL}/exams`
    }
  }, [isManualPause, isPreviewAdmin])

  const handlePause = e => {
    e.preventDefault()
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.examPaused, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType,
      'Question number': renderIfQuestionPage(questionNumber),
      'Passage number': renderIfQuestionPage(passageNumber),
      'Section number': renderIfSectionPage(sectionNumber),
      Page: pageName
    })
    setIsManualPause(true)
  }

  const handlePrompt = (location, action) => {
    if (action === 'POP') saveExam()
    return 'Are you sure you want to leave the exam?'
  }

  useEffect(() => {
    if (isLeaving && !isPreviewAdmin) {
      saveExam()
    }
    if (isLeaving && isPreviewAdmin) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL}/exams`
    }
  }, [isLeaving, isPreviewAdmin])

  const isReviewPage = includes('section-review', currentPage)
  const isBreakPage = includes('section-break', currentPage)
  const isGoogleFormPage = includes(PAGE_IDS.googleForm, currentPage)

  const shouldShowPrompt =
    !isPreviewAdmin &&
    !isManualPause &&
    !isBreakPage &&
    !isBeggingExamPage &&
    !isGoogleFormPage

  useEffect(() => {
    if (shouldShowPrompt && !isNotFound) {
      window.onbeforeunload = () => true
    }

    window.addEventListener('beforeunload', onbeforeunloadFn)
    eventEmitter.on(events.notFound, handleNotFoundExam)

    return () => {
      window.onbeforeunload = null
      window.removeEventListener('beforeunload', onbeforeunloadFn)
      eventEmitter.off(events.notFound, handleNotFoundExam)
    }
  }, [])
  return (
    <>
      {shouldShowPrompt && !isNotFound && (
        <Prompt when={shouldShowPrompt} message={handlePrompt} />
      )}
      {!isReviewPage && !isBreakPage && !isGoogleFormPage && (
        <PauseExam handlePause={handlePause} />
      )}
    </>
  )
}

export default PauseHandler
