// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'examkrackers-components'
import Image from 'components/Image'

import { getFileContent } from 'services/FilesService'
import { getFileExtensionFromUrl } from 'utils/file'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const ModalFixedImagePreview = (): JSX.Element => {
  const [currentImage, setCurrentImage] = React.useState(null)

  const handleOpen = image => setCurrentImage(image)
  const handleClose = () => setCurrentImage(null)

  const imageSmallUrl: string = propOr('', 'small_ver', currentImage)
  const imageLargeUrl: string = propOr('', 'image', currentImage)

  const imageLarge = imageLargeUrl || imageSmallUrl

  React.useEffect(() => {
    eventEmitter.on(events.fixedImagePreview, handleOpen)
    return () => {
      eventEmitter.off(events.fixedImagePreview, handleOpen)
    }
  }, [])

  return (
    <Modal open={isNotNilOrEmpty(currentImage)} handleClose={handleClose}>
      <Image url={imageLarge} />
    </Modal>
  )
}

export default ModalFixedImagePreview
