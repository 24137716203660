import React from 'react'

import useIsMobile from 'hooks/useIsMobile'
import Book from 'modules/books/Book'
import BookMobile from 'modules/books/Book.mobile'

import BookActiveTime from 'modules/books/components/BookActiveTime'
import BookHeader from 'modules/books/components/BookHeader'
import BookReadingProgress from 'modules/books/components/BookReadingProgress'
import ChapterReadingTime from 'modules/learningTime/components/ChapterReadingTime'
import ScrollAnchorHandler from 'modules/books/components/ScrollAnchorHandler'
import VideoWatchingTime from 'modules/learningTime/components/VideoWatchingTime'

import RedirectHandler from 'modules/books/components/RedirectHandler'

export const BookViewer = (): JSX.Element => {
  const isMobile = useIsMobile()

  const BookDesktopComponent = <Book />
  const BookMobileComponent = <BookMobile />

  return (
    <React.Fragment>
      <ScrollAnchorHandler />
      <RedirectHandler />
      <BookHeader />
      <BookReadingProgress />
      <ChapterReadingTime />
      <VideoWatchingTime />
      <BookActiveTime />
      {isMobile ? BookMobileComponent : BookDesktopComponent}
    </React.Fragment>
  )
}

export default BookViewer
