import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { studentLogoutRoutine } from 'modules/auth/ducks/actions'
import { StudentProps } from 'types'
import { getStudent } from 'modules/auth/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import useIsMobile from 'hooks/useIsMobile'
import PATHS from 'utils/paths'
import * as R from 'ramda'
import styled from 'styled-components'
import { Heading1 } from 'examkrackers-components'
import { WarningReversed } from 'examkrackers-components'

export const Logout = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const user: StudentProps = useSelector(getStudent)

  const logoutStudent = React.useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  React.useEffect(() => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.logout, {
      studentInfo: user
    })

    logoutStudent()

    setTimeout(() => {
      window.location.replace(
        R.propOr(PATHS.unauthorized, 'REACT_APP_HOME_URL', process.env)
      )
    }, 1000)
  }, [])

  const LogoutDeskto = (
    <React.Fragment>
      <AuthContainer>
        <WarningReversed />
        <div className='title'>
          <Heading1>{t('logout.title')}</Heading1>
          <Heading1>{t('logout.subtitle')}</Heading1>
        </div>
      </AuthContainer>
    </React.Fragment>
  )

  const LogoutMobile = (
    <React.Fragment>
      <MobileContainer>
        <WarningReversed />
        <div>{t('logout.title')}</div>
        <div>{t('logout.subtitle')}</div>
      </MobileContainer>
    </React.Fragment>
  )

  console.log({ isMobile })

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.logout')}</title>
      </Helmet>
      {isMobile ? LogoutMobile : LogoutDeskto}
    </React.Fragment>
  )
}

export default Logout

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  svg {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`
