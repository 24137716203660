import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PATHS from 'utils/paths'

import LocalStorageService from 'services/LocalStorageService'
import { fetchSingleCourse } from 'services/CourseService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

import { BouncingLoader, Heading1, Heading2 } from 'examkrackers-components'

import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { pathOr } from 'ramda'

const ExamLoading = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const checkIfReady = () => {
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

    const handleSuccess = response => {
      const isCourseReady = pathOr(false, ['data', 'is_ready'], response)
      setIsReady(isCourseReady)
    }
    const handleError = e => {
      console.error(e)
      push(PATHS.selectCourse)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    fetchSingleCourse({ id: courseId }).then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    const handleCheckIfReady = setInterval(checkIfReady, 5000)

    return () => {
      clearInterval(handleCheckIfReady)
    }
  }, [])

  useEffect(() => {
    if (isReady) {
      push(PATHS.exams)
    }
  }, [isReady])

  return (
    <>
      <Helmet>
        <title>{t('pages.dashboard')}</title>
      </Helmet>
      <Container>
        <StyledBouncingLoader />
        <div className='title'>
          <Heading1>{t('courses.loadingCourse.heading')}</Heading1>
          <Heading2>
            <Trans
              i18nKey='courses.loadingCourse.description'
              components={{ Bold: <Bold /> }}
            />
          </Heading2>
        </div>
      </Container>
    </>
  )
}

export default ExamLoading

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const StyledBouncingLoader = styled(BouncingLoader)`
  margin-right: 20px;
`

const Bold = styled.strong`
  font-weight: 700;
`
