import APIService from 'services/APIService'
import {
  CourseTimeStatePayload,
  UpdateCourseTimeStatePayload,
  InitialiseCoursePayload,
  FetchSingleCoursePayload,
  SetCourseEndDatePayload,
  SetBooksOrderPayload
} from 'types'
import LocalStorageService from './LocalStorageService'
import { LOCAL_STORAGE_KEYS } from '../utils/storage'
import { format } from 'date-fns'

const api = APIService()

export const fetchCourseTimeState = (payload: CourseTimeStatePayload) => {
  return api.get(`/stopwatches`)
}

export const updateCourseTimeState = (
  payload: UpdateCourseTimeStatePayload
) => {
  const formatedNow = format(new Date(), 'yyyy-MM-dd')

  return api.patch(`/stopwatches`, {
    state: payload.state,
    seconds: payload.seconds,
    date: formatedNow
  })
}

export const fetchAllCourses = () =>
  api.get('/student-courses?limit[page]=1&limit[take]=9999')

export const initialiseCourse = (payload: InitialiseCoursePayload) =>
  api.post(`/student-courses/${payload.id}/prepare`)

export const fetchSingleCourse = (payload: FetchSingleCoursePayload) =>
  api.get(`/student-courses/${payload.id}`)

export const setCourseEndDate = (payload: SetCourseEndDatePayload) =>
  api.patch(`/student-courses/${payload.id}/set-expected-end-date`, {
    expected_end_date: payload.expected_end_date
  })

export const setCourseFlashcardsCount = payload => {
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

  return api.patch(`/student-courses/${courseId}/flashcard-count`, {
    flashcard_count: payload.flashcard_count
  })
}

export const setBooksOrder = (payload: SetBooksOrderPayload) =>
  api.patch(`/student-courses/${payload.courseId}/book-order`, payload.list)
