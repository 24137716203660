import { createRoutine } from 'redux-saga-routines'
import { ActionPayload, PaginationProps } from 'types'
import { put, call, takeLatest, fork, takeEvery } from 'utils/saga'
import * as VideosService from 'services/VideosService'
import { pathOr } from 'ramda'
import { select } from '@redux-saga/core/effects'
import { getVideosPagination, selectVideosState } from './selectors'
import * as R from 'ramda'
import qs from 'qs'
import { API_STATES } from '../../../utils/redux'
import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { VIDEO_CATEGORIES } from '../utils/categories'

export const fetchVideosListRoutine = createRoutine('FETCH_VIDEOS_LIST')
export const resetVideosListRoutine = createRoutine('RESET_VIDEOS_LIST')
export const fetchVideosListWithInfiniteScrollRoutine = createRoutine(
  'FETCH_VIDEOS_LIST_WITH_INFINITE_SCROLL'
)

function* fetchVideosList({ payload }: ActionPayload) {
  yield put(fetchVideosListRoutine.request())
  try {
    const result = yield call(VideosService.fetchVideosList, payload)
    yield put(fetchVideosListRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchVideosListRoutine.failure(e))
    console.error(e)
  }
}

function* resetVideosList() {
  yield put(resetVideosListRoutine.request())
  try {
    yield put(resetVideosListRoutine.success())
  } catch (e) {
    yield put(resetVideosListRoutine.failure(e))
  }
}

function* fetchVideosListWithInfiniteScroll({ payload }: ActionPayload) {
  const pagination: PaginationProps = yield select(getVideosPagination)
  const reducerState = yield select(selectVideosState)
  const queryString = R.propOr('', 'query', payload)

  // @ts-ignore
  const parsedQuery = qs.parse(queryString, { ignoreQueryPrefix: true })

  const selectedCategory = pathOr(
    VIDEO_CATEGORIES.all,
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )

  // @ts-ignore
  const isBooksCategory = selectedCategory === 'books'
  const filterQuery = R.pipe(R.propOr({}, 'filter'))(parsedQuery)

  const isWatchedFilterQuery = R.pipe(
    R.ifElse(
      R.has('__is_watched'),
      filters => ({
        ...filters,
        __is_watched: filters.__is_watched === 'true' ? '1' : '-1'
      }),
      R.always({})
    )
  )(filterQuery)

  const getQueryBySelectedCategory = () => {
    switch (selectedCategory) {
      case VIDEO_CATEGORIES.books:
        return {
          ...parsedQuery,
          // @ts-ignore
          filter: { ...R.dissoc('category', filterQuery) }
        }
      case VIDEO_CATEGORIES.all:
      case VIDEO_CATEGORIES.favourites:
        return {
          ...parsedQuery,
          // @ts-ignore
          filter: {
            // @ts-ignore
            ...R.dissoc('category', filterQuery),
            ...R.dissoc('category', isWatchedFilterQuery)
          }
        }
      default:
        return {
          ...parsedQuery,
          // @ts-ignore
          filter: {
            category: selectedCategory,
            // @ts-ignore
            ...filterQuery,
            ...isWatchedFilterQuery
          }
        }
    }
  }

  const fetchVideosListFunction = () => {
    switch (
      selectedCategory as
        | VIDEO_CATEGORIES.all
        | VIDEO_CATEGORIES.books
        | VIDEO_CATEGORIES.favourites
    ) {
      case VIDEO_CATEGORIES.all:
        return VideosService.fetchAllStudentVideos
      case VIDEO_CATEGORIES.books:
        return VideosService.fetchVideosList
      case VIDEO_CATEGORIES.favourites:
        return VideosService.fetchStudentFavouriteVideos
      default:
        return VideosService.fetchStudentVideosByCategory
    }
  }

  const initialQuery = {
    ...getQueryBySelectedCategory(),
    limit: { take: 10, page: 1 }
  }

  const query = R.propEq('recordsTotal', 0)(pagination)
    ? qs.stringify(initialQuery, { addQueryPrefix: true })
    : qs.stringify(
        {
          ...initialQuery,
          // @ts-ignore
          limit: { take: 10, page: Number(R.propOr(0, 'page', pagination)) + 1 }
        },
        { addQueryPrefix: true }
      )

  const hasMorePages = Number(pagination.page) < Number(pagination.pagesTotal)
  const isInitialFetch =
    Number(pagination.page) === 1 && reducerState === API_STATES.PRISTINE

  if (hasMorePages || isInitialFetch) {
    yield put(fetchVideosListWithInfiniteScrollRoutine.request())
    try {
      const { data } = yield call(fetchVideosListFunction(), {
        query
      })
      yield put(fetchVideosListWithInfiniteScrollRoutine.success(data))
    } catch (e) {
      yield put(fetchVideosListWithInfiniteScrollRoutine.failure(e))
    }
  }
}

export function* fetchVideosListWatcher() {
  yield takeLatest(fetchVideosListRoutine, fetchVideosList)
}

export function* fetchVideosListWithInfiniteScrollWatcher() {
  yield takeEvery(
    fetchVideosListWithInfiniteScrollRoutine,
    fetchVideosListWithInfiniteScroll
  )
}

export function* resetVideosListWatcher() {
  yield takeLatest(resetVideosListRoutine, resetVideosList)
}

export const videoSagas = [
  fork(fetchVideosListWatcher),
  fork(fetchVideosListWithInfiniteScrollWatcher),
  fork(resetVideosListWatcher)
]
