import { createRoutine } from 'redux-saga-routines'
import * as Effects from 'utils/saga'

export const handleToggleMobileMenuRoutine = createRoutine(
  'HANDLE_TOGGLE_MOBILE_MENU'
)

function* handleToggleMobileMenu() {
  yield Effects.put(handleToggleMobileMenuRoutine.request())
  try {
    yield Effects.put(handleToggleMobileMenuRoutine.success())
  } catch (e) {
    yield Effects.put(handleToggleMobileMenuRoutine.failure(e))
  }
}

export function* handleToggleMobileMenuWatcher() {
  yield Effects.takeLatest(
    handleToggleMobileMenuRoutine.TRIGGER,
    handleToggleMobileMenu
  )
}
export const layoutSagas = [Effects.fork(handleToggleMobileMenuWatcher)]
