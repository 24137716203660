import { isEmpty } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Loader, Tooltip } from 'examkrackers-components'
import { fetchSubchaptersByChapterId } from 'services/BooksService'

interface AIEvaluationTooltipProps {
  children: JSX.Element | string
  chapter: {
    student_book_id: string
    tag: string
    tag_colour: string
    order: number
    average_score: number
    title: string
    book_title: string
  }
  chapterId: string
}

const AIEvaluationTooltip: React.FC<AIEvaluationTooltipProps> = ({
  chapter,
  children,
  chapterId
}) => {
  const { t } = useTranslation()
  const [subchapters, setSubchapters] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFetched, setIsFetched] = useState(false)

  const fetchSubchapters = async () => {
    if (isEmpty(subchapters) && !isFetched && !isLoading) {
      setIsLoading(true)
      fetchSubchaptersByChapterId({ chapterId })
        .then(response => {
          setSubchapters(response.data)
          setIsLoading(false)
          setIsFetched(true)
        })
        .catch(() => {
          setIsLoading(false)
          setIsFetched(false)
        })
    }
  }

  const renderSubchapters = () => {
    return (
      <SubchaptersContainer>
        <TooltipTitle>
          {t('aiEvaluation.barTooltip.title', {
            title: chapter.book_title,
            order: chapter.order
          })}
          : {chapter.title}
        </TooltipTitle>
        <SubchapterUl>
          {subchapters.map((subchapter: { id: string; title: string }) => (
            <SubchapterTitle key={subchapter.id}>
              {subchapter.title}
            </SubchapterTitle>
          ))}
        </SubchapterUl>
      </SubchaptersContainer>
    )
  }

  const renderTooltipContent = isLoading ? <Loader /> : renderSubchapters()

  return (
    <Tooltip
      tooltipContent={
        <ContentContainer>{renderTooltipContent}</ContentContainer>
      }
      id={chapterId}
      afterShow={fetchSubchapters}
      place='right'
    >
      {children}
    </Tooltip>
  )
}

export default AIEvaluationTooltip

const ContentContainer = styled.div`
  padding: 5px;
  min-width: 294px;
  text-align: left;
  text-wrap: wrap;
  white-space: wrap;
  break-word: break-word;
`

const SubchaptersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TooltipTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18.4px;
  margin-bottom: 8px;
`

const SubchapterUl = styled.ul`
  display: inline-block;
`

const SubchapterTitle = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 18.4px;
  list-style-type: disc;
  padding: 0;
  margin-left: 16px;
`
