import {
  format,
  isBefore,
  addMinutes,
  formatISO,
  formatDistanceStrict,
  add,
  compareAsc,
  isSameDay,
  addMonths,
  parseISO,
  differenceInDays,
  isWithinInterval
} from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { isNilOrEmpty } from './ramda'

export const DATE_FORMATS = {
  dashed: 'MM-dd-yyyy',
  slash: 'MM/dd/yyyy',
  dashedWithTime: 'MM-dd-yyyy HH:mm:ss',
  dashedDateAndTime: 'MM-dd-yyyy-HH-mm-ss',
  monthDay: 'MMMM do',
  withoutDashed: 'dd MMM yyyy',
  yearFirst: 'yyyy-MM-dd',
  described: 'MMMM yyyy',
  describedDay: 'iiii, LLL d',
  describedMonthDay: 'MMM d'
}

export interface ClassSchedule {
  id: string
  end_date_id: string
  class_time: string
  class_time_end: string
  class_date: string
  class_topic: string
  class_topic_number: string
  meeting_url: string
  book_chapter_id: string
  exam_id: string | null
  custom_title: string | null
  fill_colour_start: string | null
  fill_colour_stop: string | null
  font_colour: string | null
  end_date: string
  present_in_calendar: boolean
  weekday: string
  semester_name: string | null
}

export const noTimezoneDate = (dateString: string | null): Date | null => {
  if (isNilOrEmpty(dateString)) {
    return null
  } else {
    return parseISO(dateString as string)
  }
}

export const removeUTCFromDate = (dateString: string | null | undefined): string | null => {
  if (isNilOrEmpty(dateString)) {
    return null
  } else {
    return dateString?.split('T')[0]
  }
}

export const createUTCFromEST = (dateString: string, timeString: string) => {
  // Extract date part from ISO string
  const datePart = dateString.split('T')[0]

  // Create EST datetime string
  const estDateTimeString = `${datePart} ${timeString}`

  // Convert to UTC considering timezone and DST
  return fromZonedTime(estDateTimeString, 'America/New_York')
}

export const getUtcDate = (date: string | Date): Date => {
  const dateObject = new Date(date)
  return addMinutes(new Date(dateObject), dateObject.getTimezoneOffset())
}

export const formatDate = (date, formatSchema) =>
  format(getUtcDate(date), formatSchema)

export const formatToISO = date => formatISO(new Date(date))

const formatIntoDoubleChars = numb => (numb < 10 ? `0${numb}` : numb)

export const minutesToHoursAndMinutes = mins => {
  const hours = mins / 60
  const roundedHours = Math.floor(hours)
  const minutes = Math.round((hours - roundedHours) * 60)
  return `${formatIntoDoubleChars(roundedHours)}:${formatIntoDoubleChars(
    minutes
  )}:00`
}

export const isDateBeforeToday = date =>
  isBefore(new Date(date), new Date(Date.now()))

export const isDateBeforeGivenDate = (dateA, dateB) =>
  isBefore(new Date(dateA), new Date(dateB))

export const formatSecondsToMinutesAndSeconds = sec => {
  const minutes = sec / 60
  const roundedMinutes = Math.floor(minutes)
  const seconds = Math.ceil((minutes - roundedMinutes) * 60)
  return `${roundedMinutes}m ${formatIntoDoubleChars(seconds)}s`
}

export const formatSecondsToMinutesToHoursAndMinutes = val => {
  const mins = val / 60
  const hours = mins / 60
  const roundedHours = Math.floor(hours)
  const minutes = Math.round((hours - roundedHours) * 60)
  return `${roundedHours}:${formatIntoDoubleChars(minutes)}`
}

export const formatMinutesToHoursAndMinutes = (val: number): string => {
  const hours = Math.floor(val / 60)
  const minutes = val % 60

  if (hours > 0) {
    return `${hours}h ${minutes}m`
  } else {
    return `${minutes}m`
  }
}
export const formatMinutesToHoursAndMinutesWithFullName = (
  val: number
): string => {
  const hours = Math.floor(val / 60)
  const minutes = val % 60

  if (hours > 0) {
    return `${hours}h ${minutes}min`
  } else {
    return `${minutes}min`
  }
}

export const formatSecondsToMinutesToHours = val => {
  const mins = val / 60
  const hours = mins / 60
  const roundedHours = Math.floor(hours)
  return roundedHours
}

export const formatSecondsToHoursMinutesAndSeconds = sec => {
  const minutes = sec / 60
  const roundedMinutes = Math.floor(minutes)
  const seconds = Math.floor((minutes - roundedMinutes) * 60)

  const hours = minutes / 60
  const roundedHours = Math.floor(hours)

  const minutesWithoutHours = Math.round((hours - roundedHours) * 60)

  return `${formatIntoDoubleChars(roundedHours)}:${formatIntoDoubleChars(
    minutesWithoutHours
  )}:${formatIntoDoubleChars(seconds)}`
}

export const daysFromToday = date =>
  formatDistanceStrict(new Date(date), new Date(Date.now()), { unit: 'day' })

export const addDaysToDate = (date, days) => add(new Date(date), { days: days })

export const randomSeconds = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const getTimeVariant = dateString => {
  const currentDate = new Date()
  const targetDate = new Date(dateString)
  const timeDifference = currentDate.getTime() - targetDate.getTime()
  const secondsDifference = Math.floor(timeDifference / 1000)

  if (secondsDifference < 60) {
    return 'just now'
  } else if (secondsDifference < 3600) {
    const minutes = Math.floor(secondsDifference / 60)
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
  } else if (secondsDifference < 86400) {
    const hours = Math.floor(secondsDifference / 3600)
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`
  } else if (secondsDifference < 604800) {
    const days = Math.floor(secondsDifference / 86400)
    return `${days} day${days !== 1 ? 's' : ''} ago`
  } else if (secondsDifference < 2592000) {
    const weeks = Math.floor(secondsDifference / 604800)
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`
  } else if (secondsDifference < 31536000) {
    const months = Math.floor(secondsDifference / 2592000)
    return `${months} month${months !== 1 ? 's' : ''} ago`
  } else {
    const years = Math.floor(secondsDifference / 31536000)
    return `${years} year${years !== 1 ? 's' : ''} ago`
  }
}

export const formatTestDate = (test: string): string => {
  if (!test) return ''
  const year = new Date(test).getUTCFullYear()
  const month = new Date(test).getUTCMonth() + 1
  const day = new Date(test).getUTCDate()
  return `${year}/${month}/${day}`
}

export const cutTimeAndFormat = (dateString: string): Date => {
  const dateWithoutTime = dateString.split('T')[0]
  const formattedDate = formatTestDate(dateWithoutTime)
  return new Date(formattedDate)
}

export const resetTimeZoneInDate = value => {
  const formatTestDate = test => {
    return test.toUTCString()
  }

  const transformedValue = new Date(formatTestDate(value))
  return transformedValue
}

export const findNearestMcatDate = datesArray => {
  const today = new Date()
  const minimumDate = addMonths(today, 3)
  let nearestDate = null
  let nearestDiff = Infinity

  datesArray.forEach(item => {
    const mcatDate = parseISO(item.mcat_date)
    const diff = differenceInDays(mcatDate, minimumDate)

    if (diff >= 0 && diff < nearestDiff) {
      nearestDiff = diff
      nearestDate = item
    }
  })

  return nearestDate
}

export const isBeforeToday = date => {
  const today = new Date()
  const passedDateToUTC = new Date(date).toUTCString()
  const passedDate = new Date(passedDateToUTC)
  const todayUTC = new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
  )

  const offset = today.getTimezoneOffset() * 60000 // offset in milliseconds
  const todayAdjusted = new Date(today.getTime() - offset)
  const passedDateAdjusted = new Date(passedDate.getTime() - offset)
  const comparison = compareAsc(passedDateAdjusted, todayAdjusted)

  return comparison === -1 && !isSameDay(passedDateAdjusted, todayUTC)
}

export const getUniversalDate = date => {
  const dateObj = new Date(date)
  const offset = dateObj?.getTimezoneOffset() || 0

  return new Date(dateObj.getTime() + offset * 60000)
}

export const getUniversalDateString = (date): string => {
  const correctedDate = getUniversalDate(date)

  const day = String(correctedDate.getUTCDate())
  const month = String(correctedDate.getUTCMonth() + 1)
  const year = String(correctedDate.getUTCFullYear())

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export const isDateWithinInterval = (date, interval) => {
  const { start, end } = interval
  if (
    !isNaN(date.getTime()) &&
    !isNaN(start.getTime()) &&
    !isNaN(end.getTime())
  ) {
    return isWithinInterval(date, { start, end })
  }

  return false
}

export const calculateDuration = (
  startTime: string,
  endTime: string
): number => {
  // Convert the times to minutes
  const [startHour, startMinute] = startTime.split(':').map(Number)
  const [endHour, endMinute] = endTime.split(':').map(Number)

  // Convert times to minutes since midnight
  const startTotalMinutes = startHour * 60 + startMinute
  const endTotalMinutes = endHour * 60 + endMinute

  // Calculate the difference
  let durationMinutes = endTotalMinutes - startTotalMinutes

  // If end time is earlier than start time, assume it's the next day
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60 // Add 24 hours in minutes
  }
  return durationMinutes
}

export const formatTime = timeString => {
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  return (hour % 12 || 12) + ':' + minute
}

export const getOnlyDate = /(\d{4}-\d{2}-\d{2})/

export const formatDateForMCATs = dateString => {
  // Split the date string to get the year, month, and day
  const [year, month, day] = dateString.split('T')[0].split('-')

  // Create an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  // Get the month as a string without leading zeros in day
  const monthString = monthNames[Number(month) - 1]

  // Get the last two digits of the year
  const shortYear = year.slice(-2)

  // Construct the final formatted string
  return `${monthString} '${shortYear} MCATs`
}

export const extractDays = data => {
  const allDays: ClassSchedule[] = []

  data.forEach(course => {
    if (Array.isArray(course.days)) {
      const daysWithSemester = course.days.map(day => ({
        ...day,
        semester_name: course.semester_name
      }))
      allDays.push(...daysWithSemester)
    } else {
      allDays.push(course.days)
    }
  })

  return allDays
}
export const getYearsFromDates = (date1, date2) => {
  const year1 = Number(date1.split('-')[0])
  const year2 = Number(date2.split('-')[0])

  const uniqueYearsSet = new Set([year1, year2])

  const uniqueYearsArray = Array.from(uniqueYearsSet)

  return uniqueYearsArray
}

export function convertTo12HourFormat(time) {
  if (isNilOrEmpty(time)) return ''

  let [hours, minutes] = time.split(':').map(Number)
  let period = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12 || 12

  return `${hours}:${minutes.toString().padStart(2, '0')}${period}`
}

export function isESTTimeWithin15Minutes(estDateString, estTimeString) {
  const now = new Date()
  const currentHours = now.getHours()
  const currentMinutes = now.getMinutes()

  const utcDateFromEst = createUTCFromEST(estDateString, estTimeString)

  const targetHours = utcDateFromEst.getHours()
  const targetMinutes = utcDateFromEst.getMinutes()

  const currentTotalMinutes = currentHours * 60 + currentMinutes
  const targetTotalMinutes = targetHours * 60 + targetMinutes

  const timeDifference = targetTotalMinutes - currentTotalMinutes

  return timeDifference >= 0 && timeDifference <= 15
}

export function isCurrentESTTimeBetween(
  estDateString,
  estStartTime,
  estEndTime
) {
  const now = new Date()

  const utcDateFromEstStart = createUTCFromEST(estDateString, estStartTime)
  const utcDateFromEstEnd = createUTCFromEST(estDateString, estEndTime)

  return now >= utcDateFromEstStart && now <= utcDateFromEstEnd
}

export function isESTTimeAsToday(estDateString, estTimeString) {
  const today = new Date()
  const todayYear = today.getFullYear()
  const todayMonth = today.getMonth()
  const todayDay = today.getDate()

  const inputDate = createUTCFromEST(estDateString, estTimeString)
  const inputYear = inputDate.getFullYear()
  const inputMonth = inputDate.getMonth()
  const inputDay = inputDate.getDate()

  return (
    inputYear === todayYear &&
    inputMonth === todayMonth &&
    inputDay === todayDay
  )
}
