import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import LeaderboardLeftColumn from 'modules/leadershipBoard/components/LeaderboardLeftColumn'
import LeaderboardMiddleColumn from 'modules/leadershipBoard/components/LeaderboardMiddleColumn'
import LeaderboardRightColumn from 'modules/leadershipBoard/components/LeaderboardRightColumn'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

const Leaderboard = (): JSX.Element => {
  const { t } = useTranslation()
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const [seeLeaderboardPage, setSeeLeaderboardPage] = useState(true)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeLeaderboardPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeLeaderboardPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeLeaderboardPage(false)
    }
  }, [currentCourse, seeLeaderboardPage])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.leaderboard')}</title>
      </Helmet>
      <Header>
        <PageTitle>{t('leaderboard.title')}</PageTitle>
      </Header>
      <LeaderboardWrapper>
        <LeaderboardLeftColumn />
        <LeaderboardMiddleColumn />
        <LeaderboardRightColumn />
      </LeaderboardWrapper>
    </React.Fragment>
  )
}

export default Leaderboard

const LeaderboardWrapper = styled.div`
  min-width: 1280px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`

const Header = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  min-height: 32px;
`

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`
