import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  getChapterByBookOriginalIdAndOrder,
  getSubchaptersListByChapterIdAndPart
} from 'modules/books/ducks/selectors'

import SubchapterPreview from 'modules/books/components/SubchapterPreview.mobile'

import { RootReducer, Subchapter } from 'types'
import { useParams } from 'react-router'

export const SubchaptersList = (): JSX.Element => {
  const wrapperRef = React.useRef(null)

  // @ts-ignore
  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )

  const chapterId = R.propOr('', 'id', chapter)

  const subchapters: Subchapter[] = useSelector(state =>
    getSubchaptersListByChapterIdAndPart(state, chapterId, partOrder)
  )

  const renderSubchapters = subchapters.map((subchapter: Subchapter) => (
    <SubchapterPreview
      key={`subchapter-${subchapter.id}`}
      subchapter={subchapter}
    />
  ))

  return <div ref={wrapperRef}>{renderSubchapters}</div>
}

export default SubchaptersList
