import React from 'react'
import styled from 'styled-components'

const UnauthorisedBackground = ({ children }) => {
  return (
    <>
      <BackgroundImage image='/assets/illustrations/SaltyToCollege.png' />
      <LogoContainer>
        <img
          src='/assets/logo/ExamsLogoLightBg.svg'
          alt='logo'
          className='layout-logo'
        />
      </LogoContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  )
}

export default UnauthorisedBackground

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: flex-start;
  padding: 20px;
  max-width: calc(100% - 20px);
  position: absolute;
  top: calc(${({ theme }) => theme.dimensions.mobileTopNavHeight} + 10px);
  left: 10px;
  justify-content: flex-start;
  box-shadow: 2px 2px 8px 0px #00000026;
`

const LogoContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  max-height: ${({ theme }) => theme.dimensions.mobileTopNavHeight};
  line-height: ${({ theme }) => theme.dimensions.mobileTopNavHeight};
  padding: 10px;
  background: ${props => props.theme.colors.backgrounds.main};

  img {
    max-width: 150px;
  }
`

const BackgroundImage = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ image }) => image});
  background-size: 120%;
  background-position: bottom;
  background-repeat: no-repeat;
  filter: blur(2px);
  opacity: 0.5;
`
