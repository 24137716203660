import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { handleUpdateCourseTimeStateRoutine } from '../ducks/actions'
import { useDispatch } from 'react-redux'
import { COURSE_LEARNING_TIME_STATUS } from 'utils/courses'
import useIsMobile from 'hooks/useIsMobile'
import ReactDOM from 'react-dom'

export const OverlayPause = ({ pauseContainerRef }): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const handleUpdateCourseTimeState = React.useCallback(
    (payload = {}) => dispatch(handleUpdateCourseTimeStateRoutine(payload)),
    [dispatch]
  )

  const handlePlay = () => {
    handleUpdateCourseTimeState({
      status: COURSE_LEARNING_TIME_STATUS.started,
      unpause: true
    })
  }

  const GraphPlaceholder = (
    <PlaceholderContainer isMobile={isMobile}>
      <div>
        <img src='/assets/illustrations/SaltyTime.svg' />
      </div>
      <div className='text-container'>
        <div className='text-heading'>{t('navigation.pause.title')}</div>
        <div className='text-description'>
          {t('navigation.pause.description')}
        </div>
      </div>
    </PlaceholderContainer>
  )

  useEffect(() => {
    window.addEventListener('keydown', handlePlay)
    window.addEventListener('touchstart', handlePlay)

    return () => {
      window.removeEventListener('keydown', handlePlay)
      window.removeEventListener('touchstart', handlePlay)
    }
  }, [])

  return ReactDOM.createPortal(
    <Container
      isMobile={isMobile}
      id='overlay-pause-container'
      onClick={handlePlay}
    >
      <AlertWrapper ref={pauseContainerRef}>{GraphPlaceholder}</AlertWrapper>
    </Container>,
    document.body
  )
}

export default OverlayPause

const Container = styled.div<{ isMobile?: boolean; theme: any }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.modalBackground};
  backdrop-filter: blur(2px);
  transition: opacity 400ms ${({ theme }) => theme.transitions.easing.easeInOut};
  z-index: ${({ theme, isMobile }) =>
    isMobile ? theme.zIndex.menu + 200 : theme.zIndex.mainMenu + 10};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AlertWrapper = styled.div`
  width: 790px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlaceholderContainer = styled.div<{ isMobile?: boolean; theme: any }>`
  width: 100%;
  margin: ${({ isMobile }) => (isMobile ? '0 20px' : '0')};
  max-width: 811px;
  max-height: 235px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '3rem 0 3rem 1.5rem')};
  box-shadow: ${props => props.theme.shadows.darkShadow};
  background: ${({ theme }) => theme.palette.panelBackground};
  border-radius: ${props => props.theme.shape.borderRadiusBig};

  img {
    margin-right: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 214px;
    text-align: center;
    color: ${({ theme }) => theme.palette.textDark};
  }

  .text-heading {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.00450187px;
    font-weight: bold;
  }

  .text-description {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.1px;
  }
`
