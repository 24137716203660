import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getSubchaptersListByChapterIdAndPart } from '../ducks/selectors'
import { Subchapter } from 'types'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { RootReducer } from 'types'
import { getChapterByBookOriginalIdAndOrder } from '../ducks/selectors'
import styled from 'styled-components'
import { ArrowDownIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import qs from 'qs'
import { getIsMobileMenuOpen } from 'ducks/layout/selectors'
const SCROLLBAR_OFFSET = 80

export const ScrollbarNav = (): JSX.Element => {
  const { t } = useTranslation()
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [showNav, setShowNav] = useState(false)
  const hideNavTimeout = useRef<NodeJS.Timeout | null>(null)
  const isDraggingRef = useRef<boolean>(false)
  const isMenuOpen = useSelector(getIsMobileMenuOpen)

  // @ts-ignore
  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const { location } = useHistory()
  const { search } = location
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )

  const chapterId = R.propOr('', 'id', chapter)

  const currentSubchapterId: string = R.propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const subchapters: Subchapter[] = useSelector(state =>
    getSubchaptersListByChapterIdAndPart(state, chapterId, partOrder)
  )

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const scrollHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const percentage = (scrollTop / scrollHeight) * 100

    setScrollPercentage(percentage)
  }

  const handleTouchMove = e => {
    if (!isDraggingRef.current) return
    e.preventDefault()

    const trackHeight = window.innerHeight - SCROLLBAR_OFFSET
    const touch = e.touches[0]
    const touchPosition = touch.clientY

    const boundedPosition = Math.max(
      SCROLLBAR_OFFSET,
      Math.min(touchPosition, trackHeight)
    )
    const percentage =
      ((boundedPosition - SCROLLBAR_OFFSET) /
        (trackHeight - SCROLLBAR_OFFSET)) *
      100

    const scrollPosition =
      (percentage / 100) *
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight)

    window.scrollTo({
      top: scrollPosition,
      behavior: 'auto'
    })

    setScrollPercentage(percentage)
  }

  const handleTouchStart = () => {
    isDraggingRef.current = true
    setShowNav(true)

    if (hideNavTimeout.current) {
      clearTimeout(hideNavTimeout.current)
    }

    document.addEventListener('touchmove', handleTouchMove, {
      passive: false
    })
    document.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchEnd = () => {
    isDraggingRef.current = false
    hideNavTimeout.current = setTimeout(() => setShowNav(false), 2000)

    document.removeEventListener('touchmove', handleTouchMove)
    document.removeEventListener('touchend', handleTouchEnd)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPercentage])

  const handleSelectSubchapter = subchapterId => () => {
    const element = document.getElementById(`subchapter-anchor-${subchapterId}`)
    if (!element) return

    const scrollPosition = element.offsetTop
    const maxScroll =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const boundedScrollPosition = Math.min(scrollPosition, maxScroll)

    const percentage = (boundedScrollPosition / maxScroll) * 100
    setScrollPercentage(percentage)

    window.scrollTo({
      top: boundedScrollPosition - 20,
      behavior: 'auto'
    })
  }

  useEffect(() => {
    setScrollPercentage(0)
  }, [partOrder, chapterOrder])

  return (
    <>
      <ScrollbarContainer isMenuOpen={isMenuOpen}>
        <ScrollbarThumb
          style={{ top: `${scrollPercentage}%` }}
          onTouchStart={handleTouchStart}
        >
          <NavArrowUpIcon />
          <NavArrowDownIcon />
        </ScrollbarThumb>
      </ScrollbarContainer>
      <Navigation visible={showNav}>
        <NavTitle>
          {t('bookViewer.part')} {partOrder}
        </NavTitle>
        {subchapters.map(subchapter => (
          <NavItem
            onClick={handleSelectSubchapter(subchapter.id)}
            key={subchapter.id}
            active={currentSubchapterId === subchapter.id}
          >
            {chapterOrder}.{subchapter.order} {subchapter.title}
          </NavItem>
        ))}
      </Navigation>
    </>
  )
}

export default ScrollbarNav

const NavArrowUpIcon = styled(ArrowDownIcon)`
  transform: rotate(180deg);
  color: ${({ theme }) => theme.colors.main.secondary600};
`

const NavArrowDownIcon = styled(ArrowDownIcon)`
  color: ${({ theme }) => theme.colors.main.secondary600};
`

const Navigation = styled.div<{ visible: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  width: 60vw;
  height: calc(100% - ${({ theme }) => theme.dimensions.mobileBottomNavHeight});
  padding: 10px 50px 10px 0;
  background: ${({ theme }) =>
    `linear-gradient(to left, ${theme.colors.main.white} 70%, rgba(255,255,255,0))`};
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-around;
  transition: opacity 0.3s ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.menu};
`

const NavTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16.1px;
  letter-spacing: 0px;
  text-align: right;
  color: ${({ theme }) => theme.colors.main.primary500};
`

const NavItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: right;
  max-width: 100%;
  padding: 5px;
  z-index: ${({ theme }) => theme.zIndex.menu};

  background: ${props =>
    props.active ? props.theme.colors.main.black : 'transparent'};
  color: ${props =>
    props.active
      ? props.theme.colors.main.white
      : props.theme.colors.main.black};
`

const ScrollbarContainer = styled.div<{ isMenuOpen: boolean }>`
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 1)};
  position: fixed;
  right: 10px;
  top: ${SCROLLBAR_OFFSET}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.menu + 1};
  height: calc(
    100% - ${({ theme }) => theme.dimensions.mobileBottomNavHeight} -
      ${SCROLLBAR_OFFSET}px
  );
`

const ScrollbarThumb = styled.div`
  width: 44px;
  height: 44px;
  padding-right: 5px;
  background: ${({ theme }) => theme.colors.main.white};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: grab;
  color: white;
  font-size: 18px;
  user-select: none;
  position: absolute;
  transition: transform 0.2s ease;
  z-index: ${({ theme }) => theme.zIndex.menu + 1};
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
`
