import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Subchapter } from 'types'

import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {
  getBookByOriginalId,
  getIsBookContentsLoading
} from 'modules/books/ducks/selectors'
import BookContentsList from './BookContentsList.mobile'

interface SubchapterPreviewProps {
  subchapter: Subchapter
}

export const SubchapterHeader = ({ subchapter }: SubchapterPreviewProps) => {
  const order: string = R.pipe(R.propOr('', 'order'), R.toString)(subchapter)
  const title: string = R.propOr('', 'title')(subchapter)
  const subchapterId: string = R.propOr('', 'id')(subchapter)
  const isLoading = useSelector(getIsBookContentsLoading)

  const params = useParams()

  const bookId: string = R.propOr('', 'bookId')(params)
  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const tagColor = R.propOr('', 'tag_colour', book)

  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  return (
    <SubchapterContainer color={tagColor} isLoading={isLoading}>
      <div id={subchapterId} className='section-title-wrapper'>
        {chapterOrder}.{order} {title}
      </div>
    </SubchapterContainer>
  )
}

export const SubchapterPreview = ({
  subchapter
}: SubchapterPreviewProps): JSX.Element => {
  const subchapterId: string = R.propOr('', 'id')(subchapter)

  return (
    <div id={`subchapter-anchor-${subchapterId}`}>
      <SubchapterHeader subchapter={subchapter} />
      <div>
        <BookContentsList subchapter={subchapter} />
      </div>
      <br />
    </div>
  )
}

const SubchapterContainer = styled.div`
  display: inline-block;
  margin-top: 1rem;
  position: relative;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
  width: 100%;

  .section-title-wrapper {
    display: inline-block;
    transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.main.heading};
    max-width: 535px;
    margin-bottom: 7px;
    padding-bottom: 3px;
    width: 100%;
    border-bottom: 3px solid
      ${({ theme, color }) => theme.colors.tags[color].backgroundActive};
  }
`

export default React.memo(SubchapterPreview)
