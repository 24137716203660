import React from 'react'
import styled from 'styled-components'
import { getIsMobileMenuOpen } from 'ducks/layout/selectors'
import { useSelector } from 'react-redux'
import useNavLinks from 'hooks/useNavLinks'
import { getHasCourses } from 'modules/auth/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { filterLinksByUrls, TNavLink } from 'utils/links'
import PATHS from 'utils/paths'
import { Heading1 } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { hasOnlyTestBundledBooks } from 'modules/books/ducks/selectors'
import TopBarMobile from './TopBar.mobile'
const MenuMobile = (): JSX.Element | null => {
  const { t } = useTranslation()
  const isMenuOpen = useSelector(getIsMobileMenuOpen)

  const { push } = useHistory()
  const onlyTestBundleBooks = useSelector(hasOnlyTestBundledBooks)
  const hasCourses = useSelector(getHasCourses)

  const { getCourseLinks, getExamLinks } = useNavLinks(push)

  const links =
    !hasCourses || onlyTestBundleBooks
      ? (getExamLinks() as TNavLink[])
      : getCourseLinks()

  // For now, we only show the logout link. Additional navigation paths will be added
  // as more mobile views are implemented
  const MenuLinks = filterLinksByUrls(links as TNavLink[], [PATHS.logout])

  const renderedLinks = MenuLinks.map(link => (
    <MenuItem key={link.url} onClick={() => push(link.url)}>
      <span>{link.icon}</span>
      <span>{link.label}</span>
    </MenuItem>
  ))

  return (
    <Container isMenuOpen={isMenuOpen}>
      <TopBarMobile />
      <br />
      <Heading1 bold>{t('navigation.menu')}</Heading1>
      <MenuItemsContainer>{renderedLinks}</MenuItemsContainer>
    </Container>
  )
}

const Container = styled.div<{ isMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '-100%')};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: ${props => props.theme.colors.backgrounds.main};
  z-index: ${({ theme }) => theme.zIndex.menu};
  padding: 0 17.5px;
  overflow-y: ${({ isMenuOpen }) => (isMenuOpen ? 'auto' : 'hidden')};
  transition: right 400ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

const MenuItemsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 36px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary500};
  }
`

export default MenuMobile
