import React from 'react'
import {
  ArrowRightIcon,
  Tooltip,
  InfoCircleIcon
} from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ModalLearnHowToStudy from './ModalLearnHowToStudy'

const StepsForSuccessWidget: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <WidgetTitle>
        <h3>
          {t('dashboard.stepsForSuccess.title')}{' '}
          <Tooltip
            tooltipContent={
              <TooltipContentContainer>
                {t('dashboard.stepsForSuccess.titleTooltip')}
              </TooltipContentContainer>
            }
            id='title-tooltip'
          >
            <InfoCircleIcon />
          </Tooltip>
        </h3>
        <ModalLearnHowToStudy />
      </WidgetTitle>
      <StepsContainer>
        <StepWrapper>
          <Step>{t('dashboard.stepsForSuccess.step1')}</Step>
          <Tooltip
            tooltipContent={
              <TooltipContentContainer>
                {t('dashboard.stepsForSuccess.step1Tooltip')}
              </TooltipContentContainer>
            }
            id='step-1-tooltip'
          >
            <InfoCircleIcon />
          </Tooltip>
        </StepWrapper>
        <ArrowIconWraper>
          <Line />
          <ArrowRightIcon />
        </ArrowIconWraper>
        <StepWrapper>
          <Step>{t('dashboard.stepsForSuccess.step2')}</Step>
          <Tooltip
            tooltipContent={
              <TooltipContentContainer>
                {t('dashboard.stepsForSuccess.step2Tooltip')}
              </TooltipContentContainer>
            }
            id='step-2-tooltip'
          >
            <InfoCircleIcon />
          </Tooltip>
        </StepWrapper>
        <ArrowIconWraper>
          <Line />
          <ArrowRightIcon />
        </ArrowIconWraper>
        <StepWrapper>
          <Step>{t('dashboard.stepsForSuccess.step3')}</Step>
          <Tooltip
            tooltipContent={
              <TooltipContentContainer>
                {t('dashboard.stepsForSuccess.step3Tooltip')}
              </TooltipContentContainer>
            }
            id='step-3-tooltip'
          >
            <InfoCircleIcon />
          </Tooltip>
        </StepWrapper>
        <ArrowIconWraper>
          <Line />
          <ArrowRightIcon />
        </ArrowIconWraper>
        <StepWrapper>
          <Step>{t('dashboard.stepsForSuccess.step4')}</Step>
          <Tooltip
            tooltipContent={
              <TooltipContentContainer>
                {t('dashboard.stepsForSuccess.step4Tooltip')}
              </TooltipContentContainer>
            }
            id='step-4-tooltip'
          >
            <InfoCircleIcon />
          </Tooltip>
        </StepWrapper>
      </StepsContainer>
    </Container>
  )
}

export default StepsForSuccessWidget

const Container = styled.div`
  h3 {
    font-weight: 900;
    font-family: 'Arial Black', Arial, sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: ${({ theme }) => theme.colors.main.black};
    margin-bottom: 8px;
  }
`

const ArrowIconWraper = styled.div`
  display: flex;
  align-items: center;
`

const WidgetTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.main.black};

  h3 {
    font-family: 'Arial Black', Arial, sans-serif;
  }

  svg {
    cursor: pointer;
    font-size: 10px;
  }
`

const Line = styled.div`
  width: 20px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.main.text};
  margin-right: -8px;
`
const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  height: 56px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  padding: 10px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.dashboard.shadow};

  svg {
    cursor: pointer;
    font-size: 10px;
  }
`

const Step = styled.div`
  margin: 0 10px;
`

const StepWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.main.text};
  }
`

const TooltipContentContainer = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  line-height: 12.65px;
  padding: 5px 0;
`
