import React from 'react'
import useDateInput from 'components/DateInput/DateInput.controller'
import DatePicker from 'react-datepicker'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  DateInputProps,
  DatePickerContainer,
  DatePickerLabel,
  DatePickerWrapper
} from 'components/DateInput/DatePicker.model'
import { getUniversalDate, isDateWithinInterval } from 'utils/date'
import { isAfter, isBefore } from 'date-fns'
import { useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import ExtendPortal from 'components/DateInput/ExtendPortal'
import { useHistory } from 'react-router-dom'
import { pathOr } from 'ramda'

const DateInput = (props: DateInputProps) => {
  const {
    name,
    id,
    label,
    disabled,
    value,
    isClearable,
    required,
    course,
    size = 'normal',
    popperPlacement = 'bottom',
    withExtendModal
  } = props
  const reduxCourse = useSelector(getCurrentCourse)
  const history = useHistory()

  const { selectedDate, valid, handleChange, handleFocus, getDateBoundaries } =
    useDateInput(props)

  const getCorrectDate = () => {
    return selectedDate ? getUniversalDate(selectedDate) : null
  }

  const renderDayContents = (day, date) => {
    const historyCourse = pathOr(null, ['location', 'state', 'course'], history)
    const currentCourse = isNotNilOrEmpty(course)
      ? course
      : isNotNilOrEmpty(reduxCourse)
      ? reduxCourse
      : isNotNilOrEmpty(historyCourse)
      ? historyCourse
      : null

    const isDayDisabled = isNotNilOrEmpty(currentCourse)
      ? isAfter(date, date, new Date(currentCourse.accessible_to))
      : false

    return isDayDisabled && withExtendModal ? (
      <ExtendPortal>{day}</ExtendPortal>
    ) : (
      <div>{day}</div>
    )
  }

  return (
    <>
      <DatePickerWrapper size={size} disabled={disabled} error={!valid}>
        <DatePickerContainer>
          <DatePickerLabel
            isDisabled={disabled}
            size={size}
            htmlFor={name}
            error={!valid}
            hasValue={isNotNilOrEmpty(value)}
          >
            {label}
            {isNotNilOrEmpty(label) && required && ' *'}
          </DatePickerLabel>
          <DatePicker
            id={id}
            utcOffset={0}
            isClearable={isClearable}
            onChangeRaw={e => {
              if (e.target.value) {
                const date = e.target.value
                const min = getDateBoundaries('min')
                const max = getDateBoundaries('max')

                if (!isNaN(date.getTime())) {
                  if (min && max) {
                    if (isDateWithinInterval(date, { start: min, end: max })) {
                      handleChange(date)
                    }
                  }
                  if (min && !max) {
                    if (isAfter(date, min)) {
                      handleChange(date)
                    }
                  }
                  if (!min && max) {
                    if (isBefore(date, max)) {
                      handleChange(date)
                    }
                  }
                  if (!min && !max) {
                    handleChange(date)
                  }
                }
              }
            }}
            minDate={getDateBoundaries('min')}
            maxDate={getDateBoundaries('max')}
            fixedHeight
            onFocus={handleFocus}
            disabled={disabled}
            required={required}
            popperPlacement={popperPlacement}
            selected={getCorrectDate(selectedDate)}
            onChange={handleChange}
            renderDayContents={renderDayContents}
          />
        </DatePickerContainer>
      </DatePickerWrapper>
    </>
  )
}

export default DateInput
