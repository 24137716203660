import { createRoutine } from 'redux-saga-routines'
import * as Effects from '@redux-saga/core/effects'
import * as FreeTrialService from 'services/FreeTrialService'
import { select } from '@redux-saga/core/effects'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'

export const fetchBookIsFreeTrialFlagsRoutine = createRoutine(
  'FETCH_BOOK_IS_FREE_TRIAL_FLAGS'
)

function* fetchBookIsFreeTrialFlags() {
  yield Effects.put(fetchBookIsFreeTrialFlagsRoutine.request())

  try {
    const currentCourse = yield select(getCurrentCourse)
    const course_original_id = R.pathOr('', ['original', 'id'])(currentCourse)
    const result = yield Effects.call(
      FreeTrialService.getBookIsFreeTrialFlags,
      course_original_id
    )
    yield Effects.put(fetchBookIsFreeTrialFlagsRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchBookIsFreeTrialFlagsRoutine.failure(e))
  }
}

export const fetchBookIsFreeTrialFlagsWatcher = function* () {
  yield Effects.takeLatest(
    fetchBookIsFreeTrialFlagsRoutine,
    fetchBookIsFreeTrialFlags
  )
}

export const freeTrialSagas = [Effects.fork(fetchBookIsFreeTrialFlagsWatcher)]
