// module/exams/ducks/ducks.ts - Exam Saga Actions

import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import { ActionPayload } from 'types'
import * as ExamsService from 'services/ExamsService'

export const fetchExamsListRoutine = createRoutine('FETCH_EXAMS_LIST')
export const fetchExamLogsListRoutine = createRoutine('FETCH_EXAM_LOGS_LIST')
export const fetchArrayOfExamsAvailableInFreeTrialRoutine = createRoutine(
  'FETCH_ARRAY_OF_EXAMS_AVAILABLE_IN_FREE_TRIAL'
)

function* fetchArrayOfExamsAvailableInFreeTrial() {
  yield Effects.put(fetchArrayOfExamsAvailableInFreeTrialRoutine.request())

  try {
    const result = yield Effects.call(
      ExamsService.getArrayOfExamsAvailableInFreeTrial
    )
    yield Effects.put(
      fetchArrayOfExamsAvailableInFreeTrialRoutine.success(result)
    )
  } catch (e) {
    yield Effects.put(fetchArrayOfExamsAvailableInFreeTrialRoutine.failure(e))
  }
}

function* fetchExamsList({ payload }: ActionPayload) {
  yield Effects.put(fetchExamsListRoutine.request())

  try {
    const result = yield Effects.call(ExamsService.fetchExamsList, payload)
    yield Effects.put(
      fetchExamsListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchExamsListRoutine.failure(e))
    console.error(e)
  }
}

function* fetchExamLogsList({ payload }: ActionPayload) {
  yield Effects.put(fetchExamLogsListRoutine.request())

  try {
    const result = yield Effects.call(ExamsService.fetchExamLogsList, payload)
    yield Effects.put(
      fetchExamLogsListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchExamLogsListRoutine.failure(e))
  }
}

export function* fetchArrayOfExamsAvailableInFreeTrialWatcher() {
  yield Effects.takeLatest(
    fetchArrayOfExamsAvailableInFreeTrialRoutine,
    fetchArrayOfExamsAvailableInFreeTrial
  )
}

export function* fetchExamsListWatcher() {
  yield Effects.takeLatest(fetchExamsListRoutine, fetchExamsList)
}

export function* fetchExamLogsListWatcher() {
  yield Effects.takeLatest(fetchExamLogsListRoutine, fetchExamLogsList)
}

export const examsSagas = [
  Effects.fork(fetchExamsListWatcher),
  Effects.fork(fetchExamLogsListWatcher),
  Effects.fork(fetchArrayOfExamsAvailableInFreeTrialWatcher)
]
