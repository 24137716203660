import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'
import { RootReducer, Action } from 'types'

// plop_root_reducer_reducer_import

import settingsReducer, {
  initialState as settingsInitialState
} from 'modules/settings/ducks/reducer'

import examReducer, {
  initialState as examInitialState
} from 'modules/exam/ducks/reducer'

import authReducer, {
  initialState as authInitialState
} from 'modules/auth/ducks/reducer'

import examsReducer, {
  initialState as examsInitialState
} from 'modules/exams/ducks/reducer'

import videosReducer, {
  initialState as videosInitialState
} from 'modules/videos/ducks/reducer'

import flashcardsReducer, {
  initialState as flashcardsInitialState
} from 'modules/flashcards/ducks/reducer'

import toastReducer, {
  initialState as toastInitialState
} from 'modules/toast/ducks/reducer'

import studentReducer, {
  initialState as studentInitialState
} from 'modules/student/ducks/reducer'

import diagnosticsReducer, {
  initialState as diagnosticsInitialState
} from 'modules/diagnostics/ducks/reducer'

import examTypesReducer, {
  initialState as examTypesInitialState
} from 'modules/examTypes/ducks/reducer'

import examTimeTrackerReducer, {
  initialState as examTimeTrackerInitialState
} from 'modules/examTimeTracker/ducks/reducer'

import booksReducer, {
  initialState as booksInitialState
} from 'modules/books/ducks/reducer'

import glossaryReducer, {
  initialState as glossaryInitialState
} from 'modules/glossary/ducks/reducer'

import learningTimeReducer, {
  initialState as learningTimeInitialState
} from 'modules/learningTime/ducks/reducer'

import coursesReducer, {
  initialState as coursesInitialState
} from 'modules/courses/ducks/reducer'

import notesReducer, {
  initialState as notesInitialState
} from 'modules/notes/ducks/reducer'

import notificationReducer, {
  initialState as notificationInitialState
} from 'modules/notifications/ducks/reducer'

import selectionReducer, {
  initialState as selectionInitialState
} from 'ducks/selection/reducer'

import courseTopicsReducer, {
  initialState as courseTopicsInitialState
} from 'modules/courseTopics/ducks/reducer'

import onboardingReducer, {
  initialState as onboardingInitialState
} from 'modules/onboarding/ducks/reducer'

import calendarReducer, {
  initialState as calendarInitialState
} from 'modules/calendar/ducks/reducer'

import layoutReducer, {
  initialState as layoutInitialState
} from 'ducks/layout/reducer'

import freeTrialReducer, {
  initialState as freeTrialInitialState
} from 'modules/freeTrial/ducks/reducer'

import { settingsSagas } from 'modules/settings/ducks/actions'
import { authSagas, studentLogoutRoutine } from 'modules/auth/ducks/actions'
import { examsSagas } from 'modules/exams/ducks/actions'
import { toastSagas } from 'modules/toast/ducks/actions'
import { examSagas } from 'modules/exam/ducks/actions'
import { videoSagas } from 'modules/videos/ducks/actions'
import { diagnosticSagas } from 'modules/diagnostics/ducks/actions'
import { studentSagas } from 'modules/student/ducks/actions'
import { examTypesSagas } from 'modules/examTypes/ducks/actions'
import { examTimeTrackerSagas } from 'modules/examTimeTracker/ducks/actions'
import { booksSagas } from 'modules/books/ducks/actions'
import { glossarySagas } from 'modules/glossary/ducks/actions'
import { learningTimeSagas } from 'modules/learningTime/ducks/actions'
import { flashcardsSagas } from 'modules/flashcards/ducks/actions'
import {
  courseSagas,
  removeCurrentCourseRoutine
} from 'modules/courses/ducks/actions'
import { notesSagas } from 'modules/notes/ducks/actions'
import { notificationsSagas } from 'modules/notifications/ducks/actions'
import { selectionSagas } from 'ducks/selection/actions'
import { courseTopicsSagas } from 'modules/courseTopics/ducks/actions'
import { onboardingSagas } from 'modules/onboarding/ducks/actions'
import { calendarSagas } from 'modules/calendar/ducks/actions'
import { layoutSagas } from 'ducks/layout/actions'
import { freeTrialSagas } from 'modules/freeTrial/ducks/actions'

// reducer
const appReducer = combineReducers({
  // plop_root_reducer_combine

  settings: settingsReducer,
  toast: toastReducer,
  exam: examReducer,
  exams: examsReducer,
  videos: videosReducer,
  auth: authReducer,
  diagnostic: diagnosticsReducer,
  student: studentReducer,
  examTypes: examTypesReducer,
  examTimeTracker: examTimeTrackerReducer,
  books: booksReducer,
  glossary: glossaryReducer,
  learningTime: learningTimeReducer,
  flashcards: flashcardsReducer,
  courses: coursesReducer,
  notes: notesReducer,
  notifications: notificationReducer,
  selection: selectionReducer,
  courseTopics: courseTopicsReducer,
  onboarding: onboardingReducer,
  calendar: calendarReducer,
  layout: layoutReducer,
  freeTrial: freeTrialReducer
})

const initialReducer = {
  // plop_root_reducer_initial

  settings: settingsInitialState,
  toast: toastInitialState,
  auth: authInitialState,
  exam: examInitialState,
  exams: examsInitialState,
  videos: videosInitialState,
  diagnostic: diagnosticsInitialState,
  student: studentInitialState,
  examTypes: examTypesInitialState,
  examTimeTracker: examTimeTrackerInitialState,
  books: booksInitialState,
  glossary: glossaryInitialState,
  learningTime: learningTimeInitialState,
  flashcards: flashcardsInitialState,
  courses: coursesInitialState,
  notes: notesInitialState,
  notifications: notificationInitialState,
  selection: selectionInitialState,
  courseTopics: courseTopicsInitialState,
  onboarding: onboardingInitialState,
  calendar: calendarInitialState,
  layout: layoutInitialState,
  freeTrial: freeTrialInitialState
}

const rootReducer = (state: RootReducer, action: Action) => {
  if (action.type === studentLogoutRoutine.SUCCESS) {
    state = { ...state, ...initialReducer }
  }

  if (action.type === removeCurrentCourseRoutine.SUCCESS) {
    state = {
      ...initialReducer,
      auth: state.auth,
      courses: state.courses
    }
  }

  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([
    // plop_root_reducer_root_saga

    ...settingsSagas,
    ...toastSagas,
    ...examSagas,
    ...authSagas,
    ...examsSagas,
    ...videoSagas,
    ...diagnosticSagas,
    ...studentSagas,
    ...examTypesSagas,
    ...examTimeTrackerSagas,
    ...booksSagas,
    ...glossarySagas,
    ...learningTimeSagas,
    ...flashcardsSagas,
    ...courseSagas,
    ...notesSagas,
    ...notificationsSagas,
    ...selectionSagas,
    ...courseTopicsSagas,
    ...onboardingSagas,
    ...calendarSagas,
    ...layoutSagas,
    ...freeTrialSagas,
    fork(routinePromiseWatcherSaga)
  ])
}

export default rootReducer
