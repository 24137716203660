import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

import { fetchAllBooksRoutine } from 'modules/books/ducks/actions'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import BookFiltersTag from 'components/BookFiltersTag'
import { SingleSelect } from 'examkrackers-components'

import { isNilOrEmpty } from 'utils/ramda'
import { getOptionByValue } from 'utils/form'

import { Book, Chapter } from 'types'

const VideoBookFilters = () => {
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const books = useSelector(selectAllBooks)
  const selectedBookId = R.pathOr('', ['filter', 'b.id'], parsedQuery)
  const selectedBookChapterId = R.pathOr('', ['filter', 'bcc.id'], parsedQuery)

  // @ts-ignore
  const selectedBook: Book = R.find(R.propEq('id', selectedBookId))(books)

  // @ts-ignore
  const chapters: Chapter[] = R.pipe(
    R.find(R.propEq('id', selectedBookId)),
    R.propOr([], 'chapters')
  )(books)
  const sortByOrder = R.sortBy(R.prop('order'))
  const sortedBookChapters = sortByOrder(chapters)

  const booksOptions = useMemo(() => {
    return books.map(book => ({
      label: R.propOr('', 'title', book),
      value: R.propOr('', 'tag', book),
      chapters: R.propOr('', 'chapters', book),
      tag: R.propOr('', 'tag', book),
      tag_colour: R.propOr('', 'tag_colour', book),
      id: R.propOr('', 'id', book)
    }))
  }, [books])

  const chapterOptions = useMemo(() => {
    return sortedBookChapters.map((chapter, index) => ({
      label: `${index + 1}. ${R.propOr('', 'title', chapter)}`,
      value: R.propOr('', 'id', chapter)
    }))
  }, [chapters, selectedBook])

  const fetchBookTags = useCallback(
    () => dispatch(fetchAllBooksRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchBookTags()
  }, [])

  const filtersBook = booksOptions.map(book => (
    // @ts-ignore
    <BookFiltersTag key={`book-filter-${book.id}`} book={book} />
  ))

  const handleChapterChange = option => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    if (option) {
      const newQuery = {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          'bcc.id': option.value
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        filter: {
          ...R.omit(['bcc.id'], filterQuery)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  return (
    <Container>
      <BookFilterWrapper>{filtersBook}</BookFilterWrapper>
      <SelectWrapper>
        <StyledSelect
          id='select-video-chapter'
          label={t('videos.filters.chapter')}
          placeholder={t('videos.filters.chapter')}
          size='small'
          // @ts-ignore
          options={chapterOptions}
          disabled={isNilOrEmpty(selectedBookId)}
          onChange={handleChapterChange}
          // @ts-ignore
          value={getOptionByValue(selectedBookChapterId)(chapterOptions)}
          removeMargin
        />
      </SelectWrapper>
    </Container>
  )
}

export default VideoBookFilters

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`

const StyledSelect = styled(SingleSelect)`
  min-width: 170px;
  max-width: 170px;
  div {
    overflow-x: hidden;
  }
`

const BookFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 3px;
  }
`

const SelectWrapper = styled.div``
