import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { HeaderDecorative, Button } from 'examkrackers-components'
import PATHS from 'utils/paths'

export const CoursePaused = (): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const goToCourses = () => push(PATHS.selectCourse)

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.coursePaused')}</title>
      </Helmet>
      <HeaderDecorative>{t('coursePaused.title')}</HeaderDecorative>
      <br />
      <DescriptionContainer>
        <span>{t('coursePaused.description')}:</span>
        <ContactContainer>
          <a href='mailto:support@examkrackers.com'>support@examkrackers.com</a>
        </ContactContainer>
      </DescriptionContainer>
      <br />
      <ButtonContainer>
        <Button type='button' id='coursePaused-btn' onClick={goToCourses}>
          {t('coursePaused.CTA')}
        </Button>
      </ButtonContainer>
    </React.Fragment>
  )
}

export default CoursePaused

const ButtonContainer = styled.div`
  margin-top: 10px;
`

const DescriptionContainer = styled.div`
  text-align: center;
  font-size: 16px;
`

const ContactContainer = styled.div`
  a {
    color: ${({ theme }) => theme.colors.main.primary500};
  }
`
