import { propOr } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LabelCourseAccess from './LabelCourseAccess'
import ButtonCourseAccess from './ButtonCourseAccess'
import { formatDate } from 'utils/date'
import { DATE_FORMATS } from 'utils/date'

export const CourseRow = ({ course }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Grid>
      <Label>{t('courses.table.headers.course')}</Label>
      <Value>{propOr('-', 'title', course)}</Value>

      <Label>{t('courses.table.headers.type')}</Label>
      <Value>
        {t(`courses.courseTypes.${propOr('unknown', 'type', course)}`)}
      </Value>

      <Label>{t('courses.table.headers.semester')}</Label>
      <Value>
        {course.endDate
          ? course.endDate.semester_name
            ? course.endDate.semester_name
            : `${formatDate(
                course.endDate.end_date,
                DATE_FORMATS.described
              )} MCATs`
          : '-'}
      </Value>

      <Label>{t('courses.table.headers.expiration')}</Label>
      <Value>
        <LabelCourseAccess course={course} />
      </Value>

      <Label>{t('courses.table.headers.action')}</Label>
      <Value>
        <ButtonCourseAccess lineBreak course={course} />
      </Value>
    </Grid>
  )
}

export default CourseRow

const Grid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 18px;
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey350};
  padding: 10px 0 20px;
`

const Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16.1px;
  letter-spacing: 0px;
`

const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.1px;
  letter-spacing: 0px;
`
