import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, InputField, ReloadIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { validateSMSCodeField, validateSMSCodeValue } from '../ducks/schema'
import { getEmailResendStatus } from 'services/AuthService'
import useIsMobile from 'hooks/useIsMobile'
import UnauthorisedBackground from 'components/UnauthorisedBackground.mobile'

const ModalVerifySMS = ({
  open,
  handleSendCode,
  handleSendCodeViaEmail,
  handleVerifyCode,
  code,
  setCode,
  isVerified,
  studentPhone
}) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [valid, _validateSchema] = React.useState(false)
  const [isResendActive, setIsResendActive] = useState<boolean>(false)

  const handleOnChange = (name, value) => {
    setCode(value)
  }

  React.useEffect(() => {
    if (isVerified === false) {
      handleSendCode()
    }
  }, [])

  React.useEffect(() => {
    validateSMSCodeValue({ '2fa-verification-code': code }, _validateSchema)
  }, [code])

  useEffect(() => {
    getEmailResendStatus().then(resp => {
      const settings = resp.data?.data || []
      const resendSetting = settings.find(
        el => el.name === 'enable_email_resend'
      )
      setIsResendActive(resendSetting?.value === '1')
    })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    handleVerifyCode(code)
  }

  const SMSForm = (
    <Form open={open} className={isMobile ? 'modal-sms-mobile' : ''}>
      <Title>
        <h3>{t('smsVerification.title')} </h3>
        <p>
          {t('smsVerification.description')} {studentPhone}.{' '}
        </p>
      </Title>

      <InputContainer className={isMobile ? 'mobile-input-container' : ''}>
        <InputField
          t={t}
          name='2fa-verification-code'
          id='2fa-sms-code'
          label={t('smsVerification.codeForm.inputLabel')}
          value={code}
          onChange={handleOnChange}
          startIcon={<ReloadIcon />}
          validate={validateSMSCodeField({ '2fa-verification-code': code })}
          required
          autoComplete={'off'}
        />
      </InputContainer>
      <BottomElements>
        <ButtonContainer>
          <Button
            variant='contained'
            color='tertiary'
            onClick={() => handleSendCode()}
            type='button'
          >
            {t('smsVerification.codeForm.resendButton')}
          </Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            disabled={!valid}
          >
            {t('smsVerification.codeForm.verifyButton')}
          </Button>
        </ButtonContainer>
        <BottomTextContainer>
          {isResendActive && (
            <p>
              {t('smsVerification.cantReceiveSMS')}
              <span onClick={handleSendCodeViaEmail}>
                {t('smsVerification.codeViaEmail')}
              </span>
            </p>
          )}
          <p>
            {t('smsVerification.codeLostInfo')}
            <a href='mailto:support@examkrackers.com'>
              {t('smsVerification.customerSupport')}
            </a>
          </p>
        </BottomTextContainer>
      </BottomElements>
    </Form>
  )

  const LayoutDesktop = (
    <>
      <BackgroundImage
        image='/assets/illustrations/SaltyToCollege.png'
        className={isMobile ? 'mobile-bg-container' : ''}
      />
      <LogoContainer className={isMobile ? 'mobile-logo-container' : ''}>
        <img
          src='/assets/logo/ExamsLogoLightBg.svg'
          alt='logo'
          className='layout-logo'
        />
      </LogoContainer>
      {SMSForm}
    </>
  )
  const LayoutMobile = (
    <UnauthorisedBackground>{SMSForm}</UnauthorisedBackground>
  )

  return isMobile ? LayoutMobile : LayoutDesktop
}

export default ModalVerifySMS

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    height: 56px;
    width: 249px;
  }
`
const BottomElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const BottomTextContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  a,
  span {
    color: ${({ theme }) => theme.colors.main.primary700};
    text-decoration: underline;
    cursor: pointer;
  }
`

const InputContainer = styled.div`
  width: 100%;
`

const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 8px;
  padding: 48px;
  max-height: 564px;
  max-width: 610px;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;

  h3 {
    color: ${({ theme }) => theme.colors.main.primary800};
    width: 100%;
    font-size: 42px;
    font-weight: 700;
    line-height: 68px;
  }

  &.modal-sms-mobile {
    max-height: 307px;
    align-self: flex-start;
    padding: 0px;
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    justify-content: center;

    h3 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;
    }

    button {
      height: 41px;
      width: 132px;
    }

    ${ButtonContainer} {
      justify-content: space-between;
    }

    ${BottomElements} {
      gap: 0;
    }

    ${InputContainer} {
      margin-top: 8px;
    }

    .mobile-input-container div {
      margin-top: 0;
    }
  }
`

const LogoContainer = styled.div`
  display: none;

  &.mobile-logo-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    max-height: ${({ theme }) => theme.dimensions.mobileTopNavHeight};
    line-height: ${({ theme }) => theme.dimensions.mobileTopNavHeight};
    padding: 10px;
    background: ${props => props.theme.colors.backgrounds.main};

    img {
      max-width: 150px;
    }
  }
`

const BackgroundImage = styled.div`
  display: none;

  &.mobile-bg-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${({ image }) => image});
    background-size: 120%;
    background-position: bottom;
    background-repeat: no-repeat;
    filter: blur(2px);
    opacity: 0.5;
  }
`
