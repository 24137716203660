import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'examkrackers-components'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'

import { getRetakeResults } from 'services/ExamsService'

import TableRetakeOverall from './TableRetakeOverall'

const RetakeOverallModal = ({ exam }) => {
  const [open, setOpen] = useState(false)
  const [retakeResults, setRetakeResults] = useState([])
  const studentExamId = exam.id
  const examTitle = exam.title

  const { t } = useTranslation()

  const fetchRetakeResults = async () => {
    try {
      const response = await getRetakeResults({ id: studentExamId })

      return response.data
    } catch (error) {
      console.error('Error fetching retake results:', error)
    }
  }

  const handleOpen = async () => {
    setOpen(true)
    const retakesInfo = await fetchRetakeResults()
    setRetakeResults(retakesInfo)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <PastScoresButton onClick={handleOpen}>
        {t('exams.retakeTable.buttons.pastScores')}
      </PastScoresButton>
      <Modal
        data-testid='NextClassesModal-Button-Modal'
        open={open}
        handleClose={handleClose}
        hasBorder
      >
        <>
          <DescWrapper>{examTitle}</DescWrapper>
          <DescWrapper>{t('exams.retakeTable.title')}</DescWrapper>

          <Table>
            <thead>
              <tr>
                <HeaderCell> {}</HeaderCell>
                <HeaderCell> {t('exams.retakeTable.headers.date')}</HeaderCell>
                <HeaderCell> {t('exams.retakeTable.headers.rank')}</HeaderCell>
                <HeaderCell>
                  {' '}
                  {t('exams.retakeTable.headers.rawScore')}
                </HeaderCell>
                <HeaderCell>
                  {' '}
                  {t('exams.retakeTable.headers.scaledScore')}
                </HeaderCell>
              </tr>
            </thead>
            <TableRetakeOverall retakeResults={retakeResults} />
          </Table>
        </>
      </Modal>
    </>
  )
}

export default RetakeOverallModal

const PastScoresButton = styled.div`
  display: flex;
  justify-content: center;

  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
`

const DescWrapper = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 660px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`

const HeaderCell = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`
