import React, { useEffect, useState } from 'react'
import { getBookProgressData } from 'services/BooksService'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  BioReadingIcon,
  LabReadingIcon,
  CheReadingIcon,
  CarReadingIcon,
  PsyReadingIcon,
  PhyReadingIcon,
  OrgReadingIcon
} from 'examkrackers-components'

interface BookData {
  seen_count: string
  total_count: string
  student_book_id: string
  title: string
  id: string
  original_book_id: string
  book_tag: string
  last_chapter: string
  last_part: string
  percent: number
  last_student_book_subchapter_id_seen: string
}

const getBookIcon = (bookTag: string) => {
  const tag = bookTag.toLowerCase()
  if (tag.includes('bio')) return <BioReadingIcon />
  if (tag.includes('che')) return <CheReadingIcon />
  if (tag.includes('phy')) return <PhyReadingIcon />
  if (tag.includes('psy')) return <PsyReadingIcon />
  if (tag.includes('cars')) return <CarReadingIcon />
  if (tag.includes('car')) return <CarReadingIcon />
  if (tag.includes('lab')) return <LabReadingIcon />
  if (tag.includes('org')) return <OrgReadingIcon />
  return <CarReadingIcon />
}

export const BookProgressChartWidget: React.FC = () => {
  const [data, setData] = useState<BookData[]>([])
  const { push } = useHistory()

  const handleProgressClick = (book: BookData) => {
    const url = `/books/${book.original_book_id}/chapter/${
      book.percent !== 0 ? book.last_chapter : 1
    }/part/${book.percent !== 0 ? book.last_part : 1}${
      book.last_student_book_subchapter_id_seen
        ? `?sectionIdContext=${book.last_student_book_subchapter_id_seen}`
        : ''
    }`
    push(url)
  }

  useEffect(() => {
    getBookProgressData()
      .then(result => {
        const sortedData = (result?.data || []).sort((a, b) => {
          return a.title.localeCompare(b.title)
        })

        setData(sortedData)
      })
      .catch(console.error)
  }, [])

  return (
    <ChartWrapper>
      <ChartTitle>Book Progress</ChartTitle>
      <ChartContainer>
        {data.map((book, index) => {
          return (
            <ProgressRow key={`${book.id}-${index}`}>
              <IconLabel onClick={() => handleProgressClick(book)}>
                {getBookIcon(book.book_tag)}
                <span>{book.book_tag}</span>
              </IconLabel>
              <ProgressBarContainer onClick={() => handleProgressClick(book)}>
                <ProgressBar>
                  <Progress width={book.percent} />
                </ProgressBar>
                <ProgressText>{book.percent}%</ProgressText>
              </ProgressBarContainer>
            </ProgressRow>
          )
        })}
      </ChartContainer>
    </ChartWrapper>
  )
}

const ChartWrapper = styled.div`
  padding: 24px 0;
`

const ChartTitle = styled.h2`
  font-family: 'Arial Black';
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.main.black};
`

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const ProgressRow = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const IconLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: max-content;
  color: #444444;
  white-space: nowrap;
  gap: 8px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1.5px;
    color: #444444;
  }
`

const ProgressBarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease;
  height: 20px;
  gap: 12px;
  &:hover {
    opacity: 0.8;
  }
`

const ProgressBar = styled.div`
  height: 16px;
  background-color: ${({ theme }) => theme.colors.main.white};
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 4px;
  overflow: hidden;
`

const Progress = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: #fdc800;
  transition: width 0.3s ease;
`

const ProgressText = styled.span`
  min-width: 45px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
  text-align: right;
`
