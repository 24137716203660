import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import GladiatorGameQR from 'modules/games/components/GladiatorGameQR'

function GamesView(): JSX.Element {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.games.main')}</title>
      </Helmet>
      <Header>
        <PageTitle>{t('games.title')}</PageTitle>
      </Header>
      <Container>
        <GladiatorGameQR />
      </Container>
    </React.Fragment>
  )
}

export default GamesView

const Header = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  min-height: 32px;
`

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`
