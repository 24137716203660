import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Heading1, WarningReversed } from 'examkrackers-components'
import { EK_PURCHASE_PORTAL_PAGE } from 'utils/links'
import useIsMobile from 'hooks/useIsMobile'
import UnauthorisedBackground from 'components/UnauthorisedBackground.mobile'
// Unauthorized route
//
// This route is shown when an error 401 is thrown.
export const Unauthorized = (): JSX.Element => {
  // Get i18 handler
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const LayoutDesktop = (
    <React.Fragment>
      <AuthContainer isMobile={isMobile}>
        <WarningReversed />
        <div className='title'>
          <Heading1>{t('unauthorized.title')}</Heading1>
          <Heading1>
            {t('unauthorized.subtitle')}
            <PurchasePageLink href={EK_PURCHASE_PORTAL_PAGE}>
              {t('unauthorized.link')}
            </PurchasePageLink>
          </Heading1>
        </div>
      </AuthContainer>
    </React.Fragment>
  )
  const LayoutMobile = (
    <UnauthorisedBackground>{LayoutDesktop}</UnauthorisedBackground>
  )

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.unauthorized')}</title>
      </Helmet>
      {isMobile ? LayoutMobile : LayoutDesktop}
    </React.Fragment>
  )
}

export default Unauthorized

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    h1 {
      font-family: Arial;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0%;
      text-align: center;
    }

    svg {
      font-size: 32px;
      color: ${({ theme }) => theme.colors.main.error500};
      margin-bottom: 10px;
    }
  `}
`

const PurchasePageLink = styled.a`
  color: ${({ theme }) => theme.colors.main.primary500};
`
