import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import styled from 'styled-components'

import PATHS from 'utils/paths'

import { getIsImpersonate, getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import { isDateBeforeToday } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'

import { TopNavigation, VolumeControl, Button } from 'examkrackers-components'
import LearningTimer from 'modules/learningTime/components/LearningTimer'
import TotalSaltyBucks from 'modules/saltyBucks/components/TotalSaltyBucks'
import ProfileDropdown from './ProfileDropdown'
import NotificationFreeTrial from 'modules/courses/components/NotificationFreeTrial'
import ModalEditUsername from 'modules/student/components/ModalEditUsername'

import { Course } from 'types'
import ModalExpiredCourse from 'modules/courses/components/ModalExpiredCourse'
import { studentLogoutRoutine } from 'modules/auth/ducks/actions'
import NotificationImpersonate from './NotificationImpersonate'
import NotificationDropdown from '../modules/notifications/components/NotificationDropdown'
import SettingsDropdown from './SettingsDropdown'
import { fetchNotificationsRoutine } from 'modules/notifications/ducks/actions'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import useNavLinks from 'hooks/useNavLinks'
import CustomCourseLogo from './CutomCourseLogo'

export const AuthNav = (): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const studentId = R.propOr('', 'id', user)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isImpersonate = useSelector(getIsImpersonate)
  const accessibleTo = R.propOr('', 'accessible_to', currentCourse)
  const isExpired = isDateBeforeToday(accessibleTo)

  const {
    getCourseLinks,
    getExamLinks,
    hasCourse,
    onlyTestBundleBooks,
    hideNavElements,
    isSelectCoursePage,
    isLoadingDashboardPage,
    courses,
    isFreeTrial
  } = useNavLinks(push)

  const fetchNotifications = React.useCallback(
    payload => dispatch(fetchNotificationsRoutine(payload)),
    [dispatch]
  )

  const handleLogout = async () => {
    await AnalyticsService(user).logEvent(ANALYTICS_EVENTS.logout, {
      studentInfo: user
    })
    dispatch(studentLogoutRoutine())
  }

  const hasCustomLogo = isNotNilOrEmpty(currentCourse.original?.logo_url)
  const customLogoUrl = currentCourse.original?.logo_url

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && isNotNilOrEmpty(studentId)) {
      fetchNotifications({ studentId })
    }
  }, [studentId, currentCourse])

  const [openModalEditUsername, setOpenModalEditUsername] =
    React.useState(false)

  const handleOpenModalEditUsername = () => setOpenModalEditUsername(true)

  const getNotification = () => {
    switch (true) {
      case isImpersonate:
        return <NotificationImpersonate />
      case isFreeTrial:
        return <NotificationFreeTrial />
      default:
        return ''
    }
  }

  const handleClickOnMenuButton = () => {
    if (isNotNilOrEmpty(currentCourse.id)) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.clickOnMenuButton, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
    } else {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.clickOnMenuButton, {
        'Course type': false,
        'Course name': false,
        'Course expiration date': false,
        'App place': 'select-course'
      })
    }
  }

  const showRightElements = () => {
    switch (true) {
      case isLoadingDashboardPage:
        return ''
      case isSelectCoursePage:
        return (
          <Button
            variant='contained'
            onClick={handleLogout}
            size='small'
            color='tertiary'
          >
            Logout
          </Button>
        )
      default:
        return [
          <NotificationDropdown key='notification-dropdown' />,
          <ProfileDropdown
            key='notification-profile'
            handleOpenModalEditUsername={handleOpenModalEditUsername}
          />,
          <SettingsDropdown key='notification-settings' />
        ]
    }
  }

  const courseStudentNavProps = hasCourse
    ? {
        redirectHandler: push,
        clickableLogo: true,
        logoOnClick: () => push(PATHS.dashboard),
        showCrackUniversityLogo: R.propOr(false, 'has_courses', user),
        notification: getNotification(),
        greeting: '',
        menu: t('navigation.menu'),
        links: onlyTestBundleBooks ? getExamLinks() : getCourseLinks(),
        onMenuOpen: () => handleClickOnMenuButton(),
        navRightElements: showRightElements(),
        navLeftElements:
          hideNavElements || onlyTestBundleBooks ? (
            ''
          ) : (
            <NavLeftElements hasCustomLogo={hasCustomLogo}>
              {hasCustomLogo ? (
                <CustomCourseLogo imageUrl={customLogoUrl} />
              ) : null}
              <VolumeControlWrapper>
                <VolumeControl />
              </VolumeControlWrapper>
              <TotalSaltyBucks />
              <LearningTimer />
            </NavLeftElements>
          ),
        multipleCourse: courses.length > 1
      }
    : {
        redirectHandler: push,
        notification: getNotification(),
        showCrackUniversityLogo: R.propOr(false, 'has_courses', user),
        greeting: t('navigation.userGreeting', {
          username: R.propOr('', 'name', user)
        }),
        menu: t('navigation.menu'),
        links: getExamLinks(),
        onMenuOpen: () => handleClickOnMenuButton()
      }

  return (
    <>
      {/* @ts-ignore */}
      <TopNavigation {...courseStudentNavProps} data-testid='TopNavigation' />
      <ModalEditUsername
        open={openModalEditUsername}
        setOpen={setOpenModalEditUsername}
      />
      {isExpired && <ModalExpiredCourse />}
    </>
  )
}

export default AuthNav

const VolumeControlWrapper = styled.div`
  margin-left: 16px;
`

const NavLeftElements = styled.div<{ hasCustomLogo?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ hasCustomLogo }) => (hasCustomLogo ? '10px' : '16px')};
`
