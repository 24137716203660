import { EXAM_SECTION_TIME_CHART_SERIES } from '../modules/diagnostics/utils/ExamSectionTimeChart'
import { EXAM_SECTION_DIFFICULTY_CHART_SERIES } from '../modules/diagnostics/utils/ExamSectionDifficultyChart'
import { COURSE_TYPES } from 'utils/courses'
import { Course } from 'types'

export const ANALYTICS_EVENTS = {
  userLogin: 'User login',
  examStarted: 'Exam started',
  examResumed: 'Exam resumed',
  examEnded: 'Exam ended',
  examPaused: 'Exam paused',
  examCompleted: 'Exam completed',
  warningPopupClosed: 'Warning pop-up closed',
  passageReadingTimeChecked: 'Finished reading the passage checkbox clicked',
  diagnosticsReviewed: 'Diagnostics reviewed',
  answerSheetReviewed: 'Answer sheet reviewed',
  singleAnswerReviewed: 'Single answer reviewed',
  allAnswerReviewed: 'All answers reviewed',
  passageReadingTimeReviewed: 'Passage reading time reviewed',
  markedOnExam: 'Marked on Exam',
  diagnosticChartsReviewed: 'Diagnostic Charts Reviewed',
  startFreeTrialCourse: 'Start Free Trial Course',
  extendFreeTrialCourse: 'Extend Free Trial Course',
  continueFreeTrialCourse: 'Continue Free Trial Course',
  startSubscriptionCourse: 'Start Subscription Course',
  extendSubscriptionCourse: 'Extend Subscription Course',
  continueSubscriptionCourse: 'Continue Subscription Course',
  startExpirationCourse: 'Start Expiration Course',
  extendExpirationCourse: 'Extend Expiration Course',
  continueExpirationCourse: 'Continue Expiration Course',
  navigateToAllCourseslist: 'Navigate to all Courses list',
  clickOnMenuButton: 'Click On Menu Button',
  seeDashboardPage: 'See Dashboard page',
  seeBooksBookmark: 'See Books Bookmark',
  seeBooksPage: 'See Books Page',
  seeVideosPage: 'See Videos page',
  seeFlashcardsPage: 'See Flashcards page',
  seeNotespage: 'See Notes page',
  seeExamsPage: 'See Exams page',
  seeLeaderboardPage: 'See Leaderboard page',
  seeAAMCChecklistPage: 'See AAMC Checklist page',
  seeCalendarPage: 'See Calendar page',
  playAminoAcidGame: 'Play Amino Acid Game',
  playRespirationGame: 'Play Respiration Game',
  playHangmanGame: 'Play Hangman Game',
  goToBooksNextChapter: `Go to Book's next chapter`,
  goToBooksPreviousChapter: `Go to Book's previous chapter`,
  startStudyingFlashcardsFromABook: 'Start studying Flashcards from a Book',
  flashcardFlipped: 'Flashcard flipped',
  flashcardSkipped: 'Flashcard skipped',
  startStudyingAllFlashcards: 'Start studying all Flashcards',
  startStudyingBookFlashcards: 'Start studying book Flashcards',
  flashcardAddedToABox: 'Flashcard added to a box',
  flashcardMarkedAsIncorrect: 'Flashcard marked as incorrect',
  flashcardMarkedAsCorrect: 'Flashcard marked as correct',
  flashcardArchived: 'Flashcard archived',
  viewVideoDetails: 'View Video details',
  addVideoToFavourites: 'Add Video to favourites',
  playAVideo: 'Play a Video',
  pauseAVideo: 'Pause a Video',
  rateAVideo: 'Rate a Video',
  cqAnswered: 'CQ answered',
  cqSkipped: 'CQ skipped',
  cqResetted: 'CQ resetted',
  allCqsResetted: "All CQ's resetted",
  goToNextCq: 'Go to Next CQ',
  goToPreviousCq: 'Go to Previous CQ',
  logout: 'Logout',
  logoutOnError: 'Logout on error'
}

export const ANALYTICS_ACCOUNT_TYPE = {
  exam: 'Exam',
  course: 'Course',
  freeTrial: 'Free Trial'
}

export const seriesNameToChartType = seriesName => {
  switch (seriesName) {
    case EXAM_SECTION_TIME_CHART_SERIES.passagesWorking:
      return 'Time Working Passage'
    case EXAM_SECTION_TIME_CHART_SERIES.passageReading:
      return 'Time Reading Passage'
    case EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion:
      return 'Time Working Question (You)'
    case EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion:
      return 'Time Working Question (Average)'
    case EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty:
      return 'Question Difficulty'
    default:
      return ''
  }
}

export const handleActionAnalytics = (course: Course) => {
  // @sieradz - if the course does not have accessible_from it means we start it otherwise we continue it
  if (course?.accessible_from === null) {
    return 'start'
  } else {
    return 'continue'
  }
}

export const getCourseTypeForAnalyticsEvents = (action, type: string) => {
  switch (type) {
    case COURSE_TYPES.live:
      return `${action}ExpirationCourse`
    case COURSE_TYPES.onDemand:
      return `${action}SubscriptionCourse`
    case COURSE_TYPES.freeTrial:
      return `${action}FreeTrialCourse`
    default:
      return ''
  }
}
