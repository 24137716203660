import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { ExamToolsBar } from 'examkrackers-components'
import { ExamPageConfigProps, RootReducer } from 'types'

import PauseHandler from './PauseHandler'
import { resetTextSelection } from 'utils/exam'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/exam/utils/events'
import Timer from 'modules/exam/components/Timer/Timer'
import {
  toggleQuestionFlag,
  saveResourceHtml,
  saveSectionSecondsLeftForScoreCalculation
} from 'services/ExamsService'
import {
  addQuestionStateRoutine,
  fetchExamDetailsRoutine,
  fetchQuestionHtmlRoutine
} from 'modules/exam/ducks/actions'
import { getSectionsIdsWithCurrentTime } from 'modules/exam/ducks/selectors'
import { getSavedTimeOrDefault } from 'modules/exam/utils/examTimeConfig'

import {
  getQuestion,
  getSectionDetailsById,
  getSectionIdByCurrentPageId,
  getStepHtml
} from 'modules/exam/ducks/selectors'

import { pipe } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { multiply } from 'ramda'
import { generateSaveQuestionHtmlPayloads } from 'modules/exam/utils/step'
import { pathOr } from 'ramda'

interface ExamTopComponentsProps {
  currentPage: string
  setCurrentPage: (id: string) => void
  handleTimeEnd: () => any
  currentPageConfig: ExamPageConfigProps
}

export const ExamTopComponents = ({
  setCurrentPage,
  currentPage,
  currentPageConfig,
  handleTimeEnd
}: ExamTopComponentsProps): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const id: string = R.propOr('', 'id')(params)

  const [secondsLeft, setSecondsLeft] = React.useState(0)

  const sectionIdsWithCurrentTime = useSelector(getSectionsIdsWithCurrentTime)

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, currentPage)
  )
  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )

  const updateQuestionStep = React.useCallback(
    (payload: any) => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const fetchExamDetails = React.useCallback(
    (id: string) => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const fetchQuestionHtml = React.useCallback(
    (id: string) => dispatch(fetchQuestionHtmlRoutine({ id })),
    [dispatch]
  )

  const isTutorialPage = R.includes('tutorial', currentPage)

  const handleFlagClick = async () => {
    updateQuestionStep({
      ...questionState,
      flagged: !questionState.flagged
    })

    if (!isTutorialPage) {
      const handleSuccess = () => fetchExamDetails(id)
      const handleError = (e: Error) => console.error(e)
      toggleQuestionFlag({ id: currentPage })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const handleSaveStepHtml = async () => {
    if (isNotNilOrEmpty(sectionIdByCurrentPageId)) {
      const payloads = generateSaveQuestionHtmlPayloads(stepHtml)
      // @ts-ignore
      await Promise.all(
        // @ts-ignore
        payloads.map(payload => saveResourceHtml({ id, ...payload }))
      )
      if (!currentPageConfig.nextPageId?.includes('review')) {
        fetchQuestionHtml(String(currentPageConfig.nextPageId))
      }
    }

    resetTextSelection()
  }

  const nextSectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, currentPageConfig.nextPageId)
  )
  const nextSectionSeconds = pipe(
    pathOr(0, ['sectionLength', 'sectionMinutes']),
    multiply(60)
  )(nextSectionDetails)

  const handleFinishSection = () => {
    saveSectionSecondsLeftForScoreCalculation({
      id,
      exam_seconds_left: getSavedTimeOrDefault(
        sectionIdsWithCurrentTime,
        currentPageConfig.nextPageId,
        nextSectionSeconds
      )
    })
  }

  useEffect(() => {
    if (isNotNilOrEmpty(sectionIdByCurrentPageId)) {
      eventEmitter.on(events.finishSection, handleFinishSection)
    }

    return () => {
      eventEmitter.off(events.finishSection, handleFinishSection)
    }
  }, [sectionIdByCurrentPageId, secondsLeft])

  const isFinishPage = R.includes('end-exam', currentPage)

  React.useEffect(() => {
    const style = document.createElement('style')
    style.textContent = `.dropdown { z-index: 1; }`
    document.head.appendChild(style)
  }, [])

  return (
    <>
      <Timer
        setSecondsLeft={setSecondsLeft}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        currentPageConfig={currentPageConfig}
        onTimeEnd={handleTimeEnd}
      />
      <ExamToolsBar
        reviewInstructionsButton={currentPageConfig.reviewInstructionsButtonVisibility}
        highlightButton={currentPageConfig.highlightButtonVisibility}
        themeDropdown={currentPageConfig.themeDropdownVisibility}
        strikethroughButton={currentPageConfig.strikethroughButtonVisibility}
        flagForReviewButton={currentPageConfig.flagForReviewButtonVisibility}
        onFlagClick={handleFlagClick}
        isFlagged={questionState.flagged}
        afterHtmlChangeCallback={handleSaveStepHtml}
      />
      {!isFinishPage && (
        <PauseHandler
          currentPageConfig={currentPageConfig}
          secondsLeft={secondsLeft}
          currentPage={currentPage}
        />
      )}
    </>
  )
}

export default ExamTopComponents
