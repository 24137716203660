import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Input, SingleSelect } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import {
  dissoc,
  isNil,
  omit,
  pathOr
} from 'ramda'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { fetchAvailableExamTypesRoutine } from 'modules/examTypes/ducks/actions'
import { getOptionByValue } from 'utils/form'

const durationOptions = [
  { value: 'chapter', label: 'Chapter' },
  { value: 'section', label: 'Section' },
  { value: 'full_length', label: 'Full-length' }
] 

const ExamFilters = ({ justSearch }) => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const dispatch = useDispatch()

  const books = useSelector(selectAllBooks)
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const selectedBook = pathOr('', ['filter', 'book_id'], parsedQuery)
  const selectedDuration = pathOr('', ['filter', 'duration'], parsedQuery)

  const fetchAvailableExamTypes = React.useCallback(
    () => dispatch(fetchAvailableExamTypesRoutine()),
    [dispatch]
  )

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (searchQuery.length === 0) {
      const newQuery = {
        ...parsedQuery,
        // @ts-ignore
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          // @ts-ignore
          ...dissoc('search', parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        // @ts-ignore
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          // @ts-ignore
          ...parsedQuery.filter,
          search: searchQuery
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }, [searchQuery])

  const bookOptions = useMemo(() => {
    return books.map(book => ({
      // @ts-ignore
      label: book.title,
      // @ts-ignore
      value: book.book_id
    }))
  }, [books])

  const handleBookChange = option => {
    if (isNil(option)) {
      const newQuery = {
        ...parsedQuery,
        // @ts-ignore
        limit: { ...parsedQuery.limit, page: 1 },
        filter: omit(['chapter_id', 'book_id'], parsedQuery.filter)
      }
      replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        // @ts-ignore
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          // @ts-ignore
          ...dissoc('chapter_id', parsedQuery.filter),
          book_id: option.value
        }
      }
      replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
    }
  }

  const handleDurationChange = option => {
    if (isNil(option)) {
      const newQuery = {
        ...parsedQuery,
        // @ts-ignore
        limit: { ...parsedQuery.limit, page: 1 },
        filter: omit(['duration'], parsedQuery.filter)
      }
      replace(`${pathname}?${qs.stringify(newQuery)}`)
      return
    }
    const newQuery = {
      ...parsedQuery,
      // @ts-ignore
      limit: { ...parsedQuery.limit, page: 1 },
      filter: {
        // @ts-ignore
        ...parsedQuery.filter,
        duration: option.value
      }
    }
    replace(`${pathname}?${qs.stringify(newQuery)}`)
  }

  const debounceHandler = useCallback(
    debounce((e: any) => setSearchQuery(e.target.value), 500),
    []
  )

  // Now `typesOptionsToUpperCase` will have the renamed labels
  // in the specified order with any additional options at the end.

  React.useEffect(() => {
    fetchAvailableExamTypes()
  }, [])

  return (
    <FiltersWrapper>
      {!justSearch && (
        <>
          <StyledSelect
            isClearable
            removeMargin
            removeTopLabel
            label={t('exams.filters.book')}
            size='small'
            options={bookOptions}
            onChange={handleBookChange}
            value={getOptionByValue(selectedBook)(bookOptions)}
          />
          <StyledSelect
            label={t('exams.filters.duration')}
            size='small'
            isClearable
            options={durationOptions}
            onChange={handleDurationChange}
            value={getOptionByValue(selectedDuration)(durationOptions)}
            removeMargin
            removeTopLabel
          />
        </>
      )}
      <SearchInputContainer>
        <Input
          size='small'
          type='search'
          onChange={debounceHandler}
          placeholder={t('exams.table.searchPlaceholder')}
        />
      </SearchInputContainer>
    </FiltersWrapper>
  )
}

export default ExamFilters

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    width: 120px;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }

  & > div > div {
    margin: 0 !important;
  }
`

const StyledSelect = styled(SingleSelect)`
  min-width: 154px;

  div {
    overflow-x: hidden;
  }
`

const SearchInputContainer = styled.div`
  min-width: 154px;

  & input {
    width: 100%;
  }
`
