import React, { useCallback, useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { RouteProps } from 'types'
import { useSelector, useDispatch } from 'react-redux'
import {
  ThemeProvider,
  ExamGlobalStyles,
  setExamTheme,
  examThemeVariants
} from 'examkrackers-components'
// import * as R from 'ramda'

import PATHS from 'utils/paths'
// import { findStudentRoles } from 'utils/user'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import {
  getIsAuthorised,
  isAuthLoaded,
  getIsAuthActive,
  getStudent
  // getStudent
} from 'modules/auth/ducks/selectors'
import {
  studentFetchDetailsRoutine,
  studentLogoutRoutine
} from 'modules/auth/ducks/actions'
import RoutePrivate from './RoutePrivate'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

interface PrivateRouteProps {
  allowedRoles: string[]
}

export const RouteExam = (props: RouteProps & PrivateRouteProps) => {
  const { component, allowedRoles, path, location, ...rest } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const redirectToCourseDeletedPage = () => history.push(PATHS.selectCourse)
  const redirectToCoursePausedPage = () => history.push(PATHS.coursePaused)
  const student = useSelector(getStudent)

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )
  const logoutStudent = useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  const handleLogout = error => {
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.logoutOnError, error)
    logoutStudent()
  }

  // const user: StudentProps = useSelector(getStudent)
  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isAuthActive = useSelector(getIsAuthActive)
  // const roles = findStudentRoles(user)

  useEffect(() => {
    if (isAuthFetched) {
      AnalyticsService(student).initUser()
    }
  }, [student])

  useEffect(() => {
    if (isAuthorised && !isAuthFetched) {
      fetchStudentDetails()
    }

    eventEmitter.on(events.logoutUser, handleLogout)
    eventEmitter.on(events.courseDeleted, redirectToCourseDeletedPage)
    eventEmitter.on(events.coursePaused, redirectToCoursePausedPage)

    return () => {
      eventEmitter.off(events.logoutUser, handleLogout)
      eventEmitter.off(events.courseDeleted, redirectToCourseDeletedPage)
      eventEmitter.off(events.coursePaused, redirectToCoursePausedPage)
      setExamTheme(examThemeVariants.default)
    }
  }, [])

  useEffect(() => {
    if (isAuthorised && isAuthFetched && !isAuthActive) {
      logoutStudent()
    }
  }, [isAuthorised, isAuthFetched, isAuthActive])

  useEffect(() => {
    if (!isAuthorised) {
      history.push(PATHS.unauthorized)
    }
  }, [isAuthorised])

  const Component = component

  const renderPageOrRedirect = props => {
    // @ts-ignore
    // const hasDedicatedRole = R.any(r => R.includes(r, allowedRoles), roles)

    switch (true) {
      case !isAuthorised:
        return (
          <Redirect
            to={{
              pathname: PATHS.unauthorized,
              state: { from: location }
            }}
          />
        )
      // case isAuthorised && isAuthFetched && !hasDedicatedRole:
      //   return (
      //     <Redirect
      //       to={{
      //         pathname: PATHS.dashboard,
      //         state: { from: location }
      //       }}
      //     />
      //   )
      default:
        return (
          <ThemeProvider isExamTheme>
            <div>
              <ExamGlobalStyles />
              <Component {...props} />
            </div>
          </ThemeProvider>
        )
    }
  }

  return <Route {...rest} render={renderPageOrRedirect} />
}

RoutePrivate.defaultProps = {
  allowedRoles: []
}

export default RouteExam
