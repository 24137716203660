import React, { useMemo } from 'react'
import styled from 'styled-components'
import CalendarCell from './CalendarCell'

export interface CourseMeta {
  days_amount: string
}

const CalendarRow = ({ row, rowIndex, year, month }) => {
  const rowRef = React.useRef(null)
  const rowRange = row[0] + '-' + row[row.length - 1]

  const renderDays = useMemo(() => {
    return row.map((day, index) => {
      return (
        <CalendarCell day={day} index={index} key={`calendar-cell-${index}`} />
      )
    })
  }, [row])

  return (
    <TableContainer
      className='calendar-row'
      key={rowIndex + rowRange}
      ref={rowRef}
      id={rowRange}
      data-id={`${month.toString().padStart(2, '0')}-${year}-row`}
    >
      {renderDays}
    </TableContainer>
  )
}

export default CalendarRow

const TableContainer = styled.tr`
  display: flex;
  background-color: ${({ theme }) => theme.colors.main.grey300};
  gap: 1px;
  min-width: 100%;
`
