import { API_STATES } from 'utils/redux'
import { fetchBookIsFreeTrialFlagsRoutine } from './actions'
import { Action, FreeTrialReducer } from 'types'

export const initialState: FreeTrialReducer = {
  state: API_STATES.PRISTINE,
  bookIsFreeTrialFlags: []
}

export default (state = initialState, action: Action): FreeTrialReducer => {
  switch (action.type) {
    case fetchBookIsFreeTrialFlagsRoutine.REQUEST:
      return { ...state, state: API_STATES.IN_PROGRESS }
    case fetchBookIsFreeTrialFlagsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        bookIsFreeTrialFlags: action.payload
      }
    case fetchBookIsFreeTrialFlagsRoutine.FAILURE:
      return { ...state, state: API_STATES.DONE }
    default:
      return state
  }
}
