import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getVideosList } from 'modules/videos/ducks/selectors'
import qs from 'qs'
import VideoDetailsListItem from './VideoDetailsListItem'
import VideoListInfiniteScroller from './VideoListInfiniteScroller'
import * as R from 'ramda'

export const VideoDetailsList = (): JSX.Element => {
  const {
    replace,
    location: { search, pathname }
  } = useHistory()
  const videosList = useSelector(getVideosList)
  const params = useParams()
  const id = R.propOr('', 'id', params)

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const defaultFilters = qs.stringify(
      {
        ...parsedQuery,
        limit: { take: 10, page: 1 }
      },
      { addQueryPrefix: true }
    )
    replace(`${pathname}${defaultFilters}`)
  }, [search])

  // @ts-ignore
  const renderVideos = videosList
    // @ts-ignore
    .filter(video => video.id !== id)
    .map(video => (
      <VideoDetailsListItem key={`video-list-item-${video.id}`} video={video} />
    ))

  return (
    <Container>
      <VideosListContainer>
        {renderVideos}
        <VideoListInfiniteScroller />
      </VideosListContainer>
    </Container>
  )
}

export default VideoDetailsList

const Container = styled.div`
  height: 100%;
`

const VideosListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`
