import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import { propOr } from 'ramda'
import { getSubchapterById } from '../ducks/selectors'
import { useSelector } from 'react-redux'
import { RootReducer } from 'types'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import useIsMobile from 'hooks/useIsMobile'

export const SectionContext = (): JSX.Element => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { location } = useHistory()
  const { search } = location
  const params = useParams()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const chapterOrder = propOr('', 'chapterOrder', params)

  const subchapterId = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const subchapter = useSelector((state: RootReducer) =>
    getSubchapterById(state, subchapterId)
  )

  const subchapterNumber = `${chapterOrder}.${propOr('1', 'order', subchapter)}`

  return (
    <Container isMobile={isMobile}>
      {t('bookViewer.section.label')}: {subchapterNumber}
    </Container>
  )
}

export default SectionContext

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: 4px 8px;
  gap: 2px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border: 1px solid ${({ theme }) => theme.colors.main.grey300};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  max-height: 22px;
  ${({ isMobile }) =>
    !isMobile &&
    `
    position: absolute;
    right: -95px;
    top: 3px;
  `}
`
