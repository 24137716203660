import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  getBookByOriginalId,
  getChapterByBookOriginalIdAndOrder
} from 'modules/books/ducks/selectors'
import { Course, RootReducer } from 'types'
import { useParams } from 'react-router'

import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import useIsMobile from 'hooks/useIsMobile'

export const TitleChapter = (): JSX.Element => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  // @ts-ignore
  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )

  const bookTitle = R.propOr('', 'title', book)
  const chapterTitle = R.propOr('', 'title', chapter)

  const [seeBookTitle, setSeeBookTitle] = useState('')

  useEffect(() => {
    if (
      isNotNilOrEmpty(currentCourse.id) &&
      bookTitle !== '' &&
      chapterTitle !== '' &&
      seeBookTitle !== bookTitle
    ) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeBooksPage, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        Title: bookTitle || false,
        'Chapter Title': chapterTitle || false,
        'Chapter Order': chapterOrder || false
      })
      setSeeBookTitle(bookTitle)
    }
  }, [currentCourse, bookTitle, chapterTitle])

  return (
    <BookHeading isMobile={isMobile}>
      {t('bookViewer.title', { bookTitle, chapterOrder, chapterTitle })}
    </BookHeading>
  )
}

export default TitleChapter

const BookHeading = styled.div<{ isMobile: boolean }>`
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.main.text};
  padding: 6px 0;
  max-width: 540px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  ${({ isMobile }) =>
    isMobile &&
    `
    max-width: 220px;
    white-space: normal;
  `}
`
