import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getExamDetails } from 'modules/exam/ducks/selectors'

import ExamScoreTabs from 'modules/diagnostics/components/ExamScoreTabs'
import TabExamSectionDiagnostics from 'modules/diagnostics/components/TabExamSectionDiagnostics'
import TooltipQuestionDetails from 'modules/diagnostics/components/TooltipQuestionDetails'
import { BackButton } from 'examkrackers-components'
import PATHS from 'utils/paths'
import { pathOr, propOr } from 'ramda'
import { ExamDetails } from 'types'
import RetakeExamButton from 'modules/exams/components/RetakeExamButton'
// import { SCORE_CALCULATION_METHOD } from '../utils/exam'

import {
  fetchAverageQuestionsGraphDataRoutine,
  fetchPassageReadingTimeGraphRoutine,
  fetchPassageWorkingTimeGraphRoutine,
  fetchQuestionsGraphDataRoutine,
  fetchAveragePassagesGraphDataRoutine
} from 'modules/diagnostics/ducks/actions'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'

export const ExamSectionDiagnostic = (): JSX.Element => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const id: string = R.propOr('', 'id')(params)
  const sectionId: string = R.propOr('', 'sectionId')(params)

  const examDetails = useSelector(getExamDetails) as ExamDetails
  const questions = R.propOr([], 'questions')(examDetails)
  // @ts-ignore
  const sectionDetails = R.find(R.propEq('id', sectionId))(questions)
  const scores = R.pathOr([], ['exam', 'scores', 'sections'])(examDetails)
  const sectionScoreDetails = R.find(R.propEq('id', sectionId))(scores)
  const sectionOrder = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionId)),
    R.propOr(0, 'order')
  )(examDetails)
  const exam_id = R.pathOr('', ['exam', 'exam_id'])(examDetails)

  const sectionTitle = propOr('', 'title', sectionDetails)
  const examName = pathOr('', ['exam', 'title'], examDetails)
  const scaledScore = propOr(0, 'scaled_score', sectionScoreDetails)
  const percentileRank = propOr(0, 'percentile_rank', sectionScoreDetails)
  const totalAnswersAmount = propOr(0, 'total_amount', sectionScoreDetails)
  const correctAnswersAmount = propOr(0, 'amount_correct', sectionScoreDetails)

  const redirectToExamsList = () => history.push(PATHS.exams)
  const examSections = examDetails?.exam?.scores?.sections || []
  const pageTitle =
    examSections.length > 2
      ? `${examName} ${sectionTitle ? `: ${sectionTitle}` : ''}`
      : `${examName}`

  const tilesExamName = examSections.length > 2 ? sectionTitle : examName

  const fetchExamDetails = React.useCallback(
    () => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const fetchPassageReadingTimeGraph = React.useCallback(
    () => dispatch(fetchPassageReadingTimeGraphRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchPassageWorkingTimeGraph = React.useCallback(
    () => dispatch(fetchPassageWorkingTimeGraphRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchQuestionsGraphData = React.useCallback(
    () => dispatch(fetchQuestionsGraphDataRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchAverageQuestionsGraphData = React.useCallback(
    (exam_id: string, section_order: unknown) =>
      dispatch(
        fetchAverageQuestionsGraphDataRoutine({ exam_id, section_order })
      ),
    [dispatch]
  )

  const fetchAveragePassagesGraphData = React.useCallback(
    (exam_id: string, section_order: unknown) =>
      dispatch(
        fetchAveragePassagesGraphDataRoutine({ exam_id, section_order })
      ),
    [dispatch]
  )

  React.useEffect(() => {
    fetchExamDetails()
    fetchPassageReadingTimeGraph()
    fetchPassageWorkingTimeGraph()
    fetchQuestionsGraphData()
  }, [])

  React.useEffect(() => {
    if (isNotNilOrEmpty(exam_id)) {
      fetchAverageQuestionsGraphData(exam_id, sectionOrder)
      fetchAveragePassagesGraphData(exam_id, sectionOrder)
    }
  }, [examDetails])


  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t('pages.exam.sectionDiagnostic', {
            section: pageTitle
          })}
        </title>
      </Helmet>
      <Container>
        <TooltipQuestionDetails />
        <ExamScoreTabs
          tiles={[
            <div style={{ display: 'flex' }}>
              <ExamName>{pageTitle}</ExamName>
              <RetakeExamButton
                key='retake-exam-button'
                maxCompletionsNumber={examDetails.exam.max_completions}
                numberOfCompletions={examDetails.exam.completions_done}
                id={examDetails.exam.id}
              />
            </div>,
            <BackButton key='back-button' onClick={redirectToExamsList}>
              {t('diagnostics.answerSheet.backButton')}
            </BackButton>
          ]}
        >
          <TabExamSectionDiagnostics>
            <ExamNameTile>{tilesExamName}</ExamNameTile>
            <StyledInfoTile key='rank-tile'>
              <span>{t('diagnostics.answerSheet.rank')} </span>
              {percentileRank}%{/* </TileContent> */}
            </StyledInfoTile>

            <StyledInfoTile key='raw-tile'>
              <span>{t('diagnostics.answerSheet.raw')} </span>
              {correctAnswersAmount} of {totalAnswersAmount}
            </StyledInfoTile>

            <StyledInfoTile key='score-tile'>
              <span>{t('diagnostics.answerSheet.scaled')} </span>
              {scaledScore}
            </StyledInfoTile>
          </TabExamSectionDiagnostics>
        </ExamScoreTabs>
      </Container>
    </React.Fragment>
  )
}

export default ExamSectionDiagnostic

const Container = styled.div`
  position: relative;
`

const StyledInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffeedf;
  /* margin: 0 8px; */
  border-radius: 4px;
  padding: 4px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 20.7px;

  span {
    font-weight: normal;
    font-size: 14px;
  }
`

const ExamName = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 16px;
`

const ExamNameTile = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18.4px;
  padding: 0 2px;
`
