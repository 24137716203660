import { getTheme } from 'examkrackers-components'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/learningTime/utils/events'

import * as R from 'ramda'

const swapArrays = (a, b) => b.concat(a)

export const sortByWeekOrder = R.pipe(
  R.splitWhen(
    R.pipe(
      // @ts-ignore
      R.prop('name'),
      R.equals('Sun')
    )
  ),
  R.apply(swapArrays)
)

// this is because firefox issue,
// null bars are not drawn on the chart
// so the labels are not positioned correctly
// because their position is caluclated dynamically on mount
export const convertNullsToZero = R.map(
  R.applySpec({
    name: R.prop('name'),
    data: R.pipe(
      R.prop('data'),
      R.map(value => (value === null ? 0 : value))
    )
  })
)

const formatYAxisTimeLabel = seconds => {
  const mins = seconds / 60
  const hours = mins / 60
  const roundedHours = Math.ceil(hours)

  if (seconds === 0) {
    return seconds
  } else {
    return `${roundedHours} h`
  }
}

const formatBarTimeLabel = seconds => {
  const mins = seconds / 60
  const hours = mins / 60
  const roundedHours = Math.floor(hours)
  const minutes = Math.round((hours - roundedHours) * 60)

  if (seconds === 0) {
    return seconds
  } else if (roundedHours > 0 && minutes > 0) {
    return `${roundedHours}h ${minutes}min`
  } else if (roundedHours <= 0 && minutes > 0) {
    return `${minutes}min`
  } else if (seconds < 60) {
    return `${seconds}s`
  }
}

export const TimeChart = (data, chartTitle) => {
  const series = data
  const theme = getTheme()

  const maxSeconds = R.pipe(
    R.map(R.prop('data')),
    // @ts-ignore
    R.flatten,
    list => R.reduce(R.max, 0, list)
    // @ts-ignore
  )(series)

  const getMaxY = () => {
    // @ts-ignore
    const roundedHours = Math.ceil(maxSeconds / 3600)
    return roundedHours * 3600 + 3600
  }

  const getTickAmount = () => {
    const maxY = getMaxY()
    return maxY / 3600
  }

  return {
    series,
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top'
        },
        horizontal: false,
        borderRadius: 0,
        columnWidth: '100%',
        barWidth: '36px'
      }
    },
    title: {
      text: chartTitle,
      align: 'left',
      style: {
        fontSize: '18',
        fontFamily: 'Arial, sans-serif',
        color: getTheme().colors.main.heading
      },
      margin: 20,
      offsetX: 7
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            // @ts-ignore
            color: theme.colors.dashboard.learningTime.barsTo,
            opacity: 1
          },
          {
            offset: 100,
            // @ts-ignore
            color: theme.colors.dashboard.learningTime.barsFrom,
            opacity: 1
          }
        ]
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -40,
      offsetY: -25,
      style: {
        fontSize: '8px',
        colors: [getTheme().colors.main.heading],
        fontFamily: 'Arial, sans-serif',
        fontWeight: 700
      },
      formatter: val =>
        val === null || val === 0 ? '' : formatBarTimeLabel(val)
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 4
    },
    grid: {
      show: true,
      padding: {
        top: 10,
        right: 10,
        bottom: 0,
        left: 30
      }
    },
    tooltip: {
      enabled: false
    },

    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      events: {
        beforeMount: () =>
          eventEmitter.emit(events.learningTimeChartLoading, true),
        dataPointMouseEnter: (event, chartContext, config) => {
          const { dataPointIndex, seriesIndex } = config
          // @ts-ignore
          const element = document.querySelector(
            `.apexcharts-datalabels[data\\:realIndex="${seriesIndex}"]`
          )
          // @ts-ignore
          const labelElement = element.children[dataPointIndex]
          // @ts-ignore
          labelElement.style.opacity = 1
        },
        dataPointMouseLeave: (event, chartContext, config) => {
          const { dataPointIndex, seriesIndex } = config
          // @ts-ignore
          const element = document.querySelector(
            `.apexcharts-datalabels[data\\:realIndex="${seriesIndex}"]`
          )
          // @ts-ignore
          const labelElement = element.children[dataPointIndex]
          // @ts-ignore
          labelElement.style.opacity = 0
        }
      }
    },

    xaxis: {
      show: true,
      categories: [
        '1',
        '2',
        '3',
        '4'
        // '5',
        // '6',
        // '7',
        // '8',
        // '9',
        // '10',
        // '11',
        // '12',
        // '13'
      ],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        align: 'left',
        style: {
          fontSize: '12px',
          colors: [getTheme().colors.main.text],
          fontFamily: 'Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'xaxis-labels'
        }
      }
    },
    yaxis: {
      min: 0,
      max: getMaxY(),
      tickAmount: getTickAmount(),
      labels: {
        offsetX: 0,
        tickAmount: 30,
        forceNiceScale: true,
        formatter: val => formatYAxisTimeLabel(val),
        style: {
          fontSize: '12px',
          colors: [getTheme().colors.main.text],
          fontFamily: 'Arial, sans-serif',
          fontWeight: 400
        }
      }
    }
  }
}
