import { propOr } from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'
import qs from 'qs'

interface AIEvaluationProgressBarProps {
  color:
    | 'purple'
    | 'red'
    | 'blue'
    | 'green'
    | 'orange'
    | 'brown'
    | 'mathPurple'
    | 'aquamarine'
    | 'turquoise'
    | 'yellow'
    | 'grey'
    | 'terraCotta'
    | 'gold'
    | 'tangerine'
    | 'guacamole'
    | 'ultramarine'
    | 'grape'
    | 'moss'
    | 'slate'
  percent: number
  title: string
  subtitle?: string | null
}

const AIEvaluationProgressBar: React.FC<AIEvaluationProgressBarProps> = ({
  color,
  percent,
  title,
  subtitle
}) => {
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const collapsed = propOr('false', AI_EVALUATION_PARAMS.collapse)(parsedQuery)

  const isCollapsed = collapsed === 'true'

  return (
    <Container>
      <Percent>{percent > 0 ? `${percent}%` : '-'}</Percent>
      <ProgressBarContainer color={color}>
        <ProgressBar percent={percent} color={color} />
      </ProgressBarContainer>
      <TitleContainer>
        <Title id='bar-title'>{title}</Title>
        <Subtitle
          isCollapsed={isCollapsed}
          subtitleLength={subtitle?.length || 0}
        >
          {subtitle}
        </Subtitle>
      </TitleContainer>
    </Container>
  )
}

export default AIEvaluationProgressBar

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const Percent = styled.div`
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 3px;
  position: absolute;
  top: -24px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  min-width: 100px;
`

const Subtitle = styled.div<{
  isCollapsed: boolean
  subtitleLength: number
}>`
  opacity: ${({ isCollapsed }) => (isCollapsed ? '1' : '0')};
  margin-top: 2px;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.5px;
  min-width: ${({ subtitleLength }) => {
    switch (true) {
      case subtitleLength > 40:
        return '110px'
      case subtitleLength === 40:
        return '100px'
      case subtitleLength > 35 && subtitleLength <= 39:
        return '110px'
      default:
        return '90px'
    }
  }};
  max-width: 108px;
  width: auto;
  transition: opacity 0.3s ease-in-out;
`

const ProgressBarContainer = styled.div<{
  color: AIEvaluationProgressBarProps['color']
}>`
  width: 15px;
  height: 39.37px;
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 0.68px solid
    ${({ theme, color }) => theme.colors.tags[color]?.backgroundActive};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px #00000040;
`

const ProgressBar = styled.div<{
  color: AIEvaluationProgressBarProps['color']
  percent: number
}>`
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  background: ${({ theme, color }) =>
    theme.colors.tags[color]?.backgroundActive};
  border-radius: 2px 2px 0px 0px;
  transition: height 0.3s ease-in-out;
  height: ${(props: { percent: number }) => `calc(${props.percent} * 1%)`};
`

// width: 39.37px;
// height: 15px;
// top: 39.7px;
// left: 0.52px;
// gap: 0px;
// border-radius: 4px 0px 0px 0px;
// border: 0.68px 0px 0px 0px;
// opacity: 0px;
// angle: 90 deg;
