import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { ExamPageConfigProps, RootReducer } from 'types'
import { ExamContainer, ExamNavigation, Loader } from 'examkrackers-components'
import { examWizard } from 'modules/exam/utils/examWizard'

import { generateDynamicExamPagesConfigs } from 'modules/exam/utils/examPagesConfig'

import {
  getQuestionNavItems,
  tutorialExamSteps,
  getSectionExamStepsById,
  REVIEW_FILTER_TYPES
} from 'modules/exam/utils/exam'

import { isNotNilOrEmpty } from 'utils/ramda'

import ExamTopComponents from 'modules/exam/components/ExamElements/ExamTopComponents'
import BlankPage from 'modules/exam/components/QuestionPages/BlankPage'

import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import {
  getQuestionStepStates,
  getExamQuestionsMap,
  getSectionIdByCurrentPageId,
  getExamLastSavedPage,
  getExamDetails
} from 'modules/exam/ducks/selectors'

import RedirectAfterAccessPeriodTimeout from '../../../components/RedirectAfterAccessPeriodTimeout'

import usePrevious from 'hooks/usePrevious'
import styled from 'styled-components'
import ExamLoadingPage from './ExamElements/ExamLoadingPage'
import { includes } from 'ramda'

const emptyFunction = () => {}

const initNavigationHandlers = {
  endExamButtonOnClick: emptyFunction,
  endSectionButtonOnClick: emptyFunction,
  endTestDayCertificationOnClick: emptyFunction,
  navigationButtonOnClick: emptyFunction,
  nextButtonOnClick: emptyFunction,
  previousButtonOnClick: emptyFunction,
  reviewAllButtonOnClick: emptyFunction,
  reviewFlaggedButtonOnClick: emptyFunction,
  reviewIncompleteButtonOnClick: emptyFunction,
  reviewScreenButtonOnClick: emptyFunction,
  answerSheetButtonOnClick: emptyFunction,
  beginBreakButtonOnClick: emptyFunction,
  endBreakButtonOnClick: emptyFunction,
  continueExamButtonOnClick: emptyFunction
}

export const Exam = (): JSX.Element => {
  // Get URL parameters
  const params = useParams()

  // Get React-Redux dispatch handler
  const dispatch = useDispatch()

  // Get the last saved page
  const lastSavedPage = useSelector(getExamLastSavedPage)

  const [currentPage, setCurrentPage] = React.useState(lastSavedPage)
  const [questionFilterType, setQuestionFilterType] = React.useState(null)
  const [onTimeEnd, setOnTimeEnd] = React.useState({
    handleTimeEnd: emptyFunction
  })
  const [navigationHandlers, setNavigationHandlers] = React.useState(
    initNavigationHandlers
  )

  const examPagesSummary = useSelector(getExamQuestionsMap)
  const examDetails = useSelector(getExamDetails)
  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )
  const prevSectionId = usePrevious(sectionIdByCurrentPageId)
  const allQuestionStates = useSelector(getQuestionStepStates)

  const nextPageHandler = (id: string) => () => setCurrentPage(id)
  const saveNewEndTimeHandler = (handleTimeEnd: any) =>
    setOnTimeEnd({ handleTimeEnd })

  const getFilterTypeOrDefault = questionFilterType || REVIEW_FILTER_TYPES.all

  const id: string = R.propOr('', 'id')(params)
  const dynamicPagesWizard = generateDynamicExamPagesConfigs(
    getFilterTypeOrDefault
  )(examPagesSummary)
  const stepsList = isNotNilOrEmpty(sectionIdByCurrentPageId)
    ? getSectionExamStepsById(examDetails, sectionIdByCurrentPageId)
    : tutorialExamSteps
  const navItems = getQuestionNavItems(
    nextPageHandler,
    allQuestionStates,
    stepsList
  )
  const examWizardPages = [...examWizard, ...dynamicPagesWizard]
  // @ts-ignore
  const currentPageConfig: ExamPageConfigProps = R.find(
    R.propEq('id', currentPage)
  )(examWizardPages)
  const Component = R.propOr(BlankPage, 'component')(currentPageConfig)

  const isBreakPage = includes('section-break', currentPage)

  React.useEffect(() => {
    if (prevSectionId !== sectionIdByCurrentPageId) {
      setQuestionFilterType(null)
    }
  }, [sectionIdByCurrentPageId])

  const updateNavHandlers = (handlers: any) =>
    setNavigationHandlers(prevState => ({
      ...prevState,
      ...handlers
    }))

  const fetchExamDetails = React.useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    updateNavHandlers({
      reviewScreenButtonOnClick: () =>
        setCurrentPage(`section-review-${sectionIdByCurrentPageId}`)
    })
  }, [sectionIdByCurrentPageId])

  React.useEffect(() => {
    fetchExamDetails({ id })
  }, [])

  React.useEffect(() => {
    setCurrentPage(lastSavedPage)
  }, [lastSavedPage])

  const hasPageConfigFound: boolean = isNotNilOrEmpty(currentPageConfig)
  const hasExamFetched: boolean =
    R.pathOr('', ['exam', 'id'])(examDetails) === id
  const isTutorialPage = R.includes('tutorial', currentPage)
  const accessPeriod = R.pathOr('', ['exam', 'accessible_to'])(examDetails)
  const isPeriodicTableInExamCopyEnabled = R.pathOr(
    false,
    ['exam', 'periodic_table_enabled'],
    examDetails
  )

  const isPeriodicTableInOriginalExamEnabled = R.pathOr(
    isPeriodicTableInExamCopyEnabled,
    ['exam', 'originalExam', 'periodic_table_enabled'],
    examDetails
  )

  switch (true) {
    case hasExamFetched && hasPageConfigFound:
      return (
        <ExamContainer>
          <RedirectAfterAccessPeriodTimeout date={accessPeriod} />
          <div id={`exam-page-${currentPage}`} />
          <ExamTopComponents
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleTimeEnd={onTimeEnd.handleTimeEnd}
            currentPageConfig={currentPageConfig}
          />
          <ComponentContainer>
            {/* @ts-ignore */}
            <Component
              currentPage={currentPage}
              currentPageConfig={currentPageConfig}
              setCurrentPage={setCurrentPage}
              setNavigationHandlers={setNavigationHandlers}
              setOnTimeEnd={saveNewEndTimeHandler}
              setQuestionFilterType={setQuestionFilterType}
            />
            <ExamLoadingPage />
          </ComponentContainer>
          <ExamNavigation
            navigationItems={navItems}
            keepPeriodicTable={isTutorialPage}
            endExamButton={currentPageConfig.navigationButtons.endExamButton}
            endSectionButton={
              currentPageConfig.navigationButtons.endSectionButton
            }
            endTestDayCertification={
              currentPageConfig.navigationButtons.endTestDayCertification
            }
            navigationButton={
              currentPageConfig.navigationButtons.navigationButton &&
              !questionFilterType
            }
            nextButton={currentPageConfig.navigationButtons.nextButton}
            // @ts-ignore
            periodicTableButton={
              isPeriodicTableInOriginalExamEnabled &&
              currentPageConfig.navigationButtons.periodicTableButton
            }
            previousButton={currentPageConfig.navigationButtons.previousButton}
            reviewAllButton={
              currentPageConfig.navigationButtons.reviewAllButton
            }
            reviewFlaggedButton={
              currentPageConfig.navigationButtons.reviewFlaggedButton
            }
            reviewIncompleteButton={
              currentPageConfig.navigationButtons.reviewIncompleteButton
            }
            reviewScreenButton={
              currentPageConfig.navigationButtons.reviewScreenButton &&
              !!questionFilterType
            }
            answerSheetButton={
              currentPageConfig.navigationButtons.answerSheetButton
            }
            endExamButtonOnClick={navigationHandlers.endExamButtonOnClick}
            endSectionButtonOnClick={navigationHandlers.endSectionButtonOnClick}
            endTestDayCertificationOnClick={
              navigationHandlers.endTestDayCertificationOnClick
            }
            nextButtonOnClick={navigationHandlers.nextButtonOnClick}
            previousButtonOnClick={navigationHandlers.previousButtonOnClick}
            reviewAllButtonOnClick={navigationHandlers.reviewAllButtonOnClick}
            reviewFlaggedButtonOnClick={
              navigationHandlers.reviewFlaggedButtonOnClick
            }
            reviewIncompleteButtonOnClick={
              navigationHandlers.reviewIncompleteButtonOnClick
            }
            reviewScreenButtonOnClick={
              navigationHandlers.reviewScreenButtonOnClick
            }
            answerSheetButtonOnClick={
              navigationHandlers.answerSheetButtonOnClick
            }
            beginBreakButton={
              isBreakPage &&
              isNotNilOrEmpty(navigationHandlers.beginBreakButtonOnClick)
            }
            endBreakButton={
              isBreakPage &&
              isNotNilOrEmpty(navigationHandlers.endBreakButtonOnClick)
            }
            continueExamButton={
              isBreakPage &&
              isNotNilOrEmpty(navigationHandlers.continueExamButtonOnClick)
            }
            beginBreakButtonOnClick={navigationHandlers.beginBreakButtonOnClick}
            endBreakButtonOnClick={navigationHandlers.endBreakButtonOnClick}
            continueExamButtonOnClick={
              navigationHandlers.continueExamButtonOnClick
            }
          />
        </ExamContainer>
      )
    default:
      return <Loader />
  }
}

export default React.memo(Exam)

const ComponentContainer = styled.div`
  position: relative;
  flex: 1;
  min-height: 0;
  overflow: auto;
`
