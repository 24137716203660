import { Book, Resource, RootReducer } from 'types'
import i18n from 'providers/i18n'
import * as R from 'ramda'
import { createSelector, OutputParametricSelector } from 'reselect'
import { isNilOrEmpty, isNotNilOrEmpty, sortAlphabetically } from 'utils/ramda'
import {
  pathOr,
  propOr,
  pipe,
  isEmpty,
  not,
  and,
  filter,
  equals,
  sortBy,
  prop
} from 'ramda'
import { API_STATES } from 'utils/redux'
import { getCurrentCourse } from '../../courses/ducks/selectors'
import { COURSE_TYPES } from '../../../utils/courses'
import { BOOK_VIEWER_PARAMS, RESOURCE_TYPES } from '../../../utils/books'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const selectBooks = (state: RootReducer) => state.books

export const selectAllBooks = createSelector(
  selectBooks,
  pathOr<Book[]>([], ['booksNavigation'])
)

export const selectAllAITutorBooks = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  R.filter((book: Book) => book.ai_tutor_enabled)
)

export const selectScrollAnchorPositionY = createSelector(
  selectBooks,
  pathOr(0, ['scrollAnchorPositionY'])
)

export const selectBookContentsPositions = createSelector(
  selectBooks,
  pathOr({}, ['bookContentsPositions'])
)

export const selectCurrentBookChaptersWithExams = createSelector(
  selectBooks,
  pipe(
    pathOr([], ['currentBookChaptersWithExams', 'chapters']),
    sortBy(prop('order'))
  )
)

export const selectCurrentBookFreeTrialExam = createSelector(
  pathOr({}, ['books', 'currentBookChaptersWithExams']),
  // @ts-ignore
  pathOr({}, ['freeTrialExam'])
)

export const getIsBooksLoading = createSelector(
  R.pathOr('', ['books', 'state']),
  R.equals(API_STATES.IN_PROGRESS)
)

export const selectBookById: OutputParametricSelector<any, any, any, any> =
  createSelector(
    R.pathOr([], ['books', 'booksNavigation']),
    (state, book_id) => book_id,
    (books, book_id) => R.find(R.propEq('id', book_id))(books)
  )

export const selectBookByOriginalId: OutputParametricSelector<
  any,
  any,
  any,
  any
> = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  (state, book_id) => book_id,
  (books, book_id) => R.find(R.propEq('book_id', book_id))(books)
)

const memoizedChaptersByBookIdTransform = R.memoizeWith(
  (books, book_id) =>
    `${book_id}-memoizedChaptersByBookIdTransform-${books.length}`,
  (books, book_id) =>
    R.pipe(
      R.find(R.propEq('book_id', book_id)),
      R.propOr([], 'chapters')
    )(books)
)
export const selectChaptersByBookId: OutputParametricSelector<
  any,
  any,
  any,
  any
> = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  (state, book_id) => book_id,
  memoizedChaptersByBookIdTransform
)

const memoizedCurrentChapterSectionsWithNotesTransform = R.memoizeWith(
  chapters =>
    `${chapters.length}-memoizedCurrentChapterSectionsWithNotesTransform`,
  pipe(
    // @ts-ignore
    filter(item =>
      and(
        not(isEmpty(propOr([], 'notes', item))),
        not(equals(pathOr('', ['notes', 0, 'raw'], item), '\n'))
      )
    )
  )
)

export const selectCurrentChapterSectionsWithNotes = createSelector(
  R.pathOr([], ['books', 'currentBookChaptersWithExams']),
  memoizedCurrentChapterSectionsWithNotesTransform
)

const memoizedFirstSubchapterIdTransform = R.memoizeWith(
  (subchapters, partOrder, chapterOrder) =>
    `${subchapters.length}-${partOrder}-${chapterOrder}-memoizedFirstSubchapterIdTransform`,
  (subchapters, partOrder, chapterOrder, chapters) => {
    // @ts-ignore
    const chapter = chapters.find(chapter => chapter.order === chapterOrder)
    return R.pipe(
      // @ts-ignore
      R.filter(R.propEq('part', partOrder)),
      // @ts-ignore
      R.filter(R.propEq('chapter_id', propOr('', 'id', chapter))),
      R.sortBy(R.prop('order')),
      R.head,
      R.propOr('', 'id')
    )(subchapters)
  }
)

export const selectFirstSubchapterId = createSelector(
  R.pathOr([], ['books', 'subchapters', 'list']),
  (state, partOrder) => partOrder,
  (state, partOrder, chapterOrder) => chapterOrder,
  (state, partOrder, chapterOrder) =>
    R.pathOr([], ['books', 'chapters', 'list'])(state),
  memoizedFirstSubchapterIdTransform
)

export const getBookByOriginalId: OutputParametricSelector<any, any, any, any> =
  createSelector(
    R.pathOr([], ['books', 'books', 'list']),
    (state, book_id) => book_id,
    (books, book_id) => R.find(R.propEq('book_id', book_id), books)
  )

const memoizedHasFlashcardsAccessibleByOriginalIdTransform = R.memoizeWith(
  (originalBooks, book_id) =>
    `${book_id}-memoizedHasFlashcardsAccessibleByOriginalIdTransform-${originalBooks.length}`,
  (originalBooks, book_id) =>
    R.pipe(
      R.find(R.propEq('id', book_id)),
      R.propOr(true, 'flashcards_accessible')
    )(originalBooks)
)
export const getHasFlashcardsAccessibleByOriginalId: OutputParametricSelector<
  any,
  any,
  any,
  any
> = createSelector(
  R.pathOr([], ['books', 'originalBooks', 'list']),
  (state, book_id) => book_id,
  memoizedHasFlashcardsAccessibleByOriginalIdTransform
)

const memoizedAiTutorEnabledByOriginalIdTransform = R.memoizeWith(
  (originalBooks, book_id) =>
    `${book_id}-memoizedAiTutorEnabledByOriginalIdTransform-${originalBooks.length}`,
  (originalBooks, book_id) =>
    R.pipe(
      R.pathOr([], ['originalBooks', 'list']),
      R.find(R.propEq('id', book_id)),
      R.propOr(true, 'ai_tutor_enabled')
    )(originalBooks)
)

export const getAiTutorEnabledByOriginalId: OutputParametricSelector<
  any,
  any,
  any,
  any
> = createSelector(
  R.pathOr([], ['books', 'originalBooks', 'list']),
  (state, book_id) => book_id,
  memoizedAiTutorEnabledByOriginalIdTransform
)

export const getChapterByBookOriginalIdAndOrder = createSelector(
  R.pathOr([], ['books', 'books', 'list']),
  (state, book_id) => book_id,
  (state, book_id, order) => order,
  (state, book_id, order) => R.pathOr([], ['books', 'chapters', 'list'])(state),
  (books, book_id, order, chapters) => {
    const idByOriginalId = R.pipe(
      R.find(R.propEq('book_id', book_id)),
      R.propOr('', 'id')
    )(books)

    return R.find(
      R.allPass([R.propEq('book_id', idByOriginalId), R.propEq('order', order)])
    )(chapters)
  }
)

export const getBookContentsList = createSelector(
  (state: RootReducer) =>
    R.pathOr([], ['books', 'bookContents', 'list'])(state),
  R.sortBy(R.prop('order'))
)

export const getOrderedBookContentsList = createSelector(
  R.pipe(R.pathOr([], ['books', 'subchapters', 'list'])),
  R.pipe(R.pathOr([], ['books', 'bookContents', 'list'])),
  (subchapters, bookContents) => {
    return R.pipe(
      R.map(subchapter => ({
        // @ts-ignore
        ...subchapter,
        contents: R.pipe(
          // @ts-ignore
          R.filter(R.propEq('subchapter_id', subchapter.id)),
          // @ts-ignore
          R.sortBy(R.prop('order'))
          // @ts-ignore
        )(bookContents)
      })),
      R.sortBy(R.prop('order')),
      R.map(R.prop('contents')),
      R.flatten
    )(subchapters)
  }
)

const memoizedSubchaptersListByChapterIdAndPartTransform = R.memoizeWith(
  (subchapters, chapter_id, part_order) =>
    `${subchapters.length}-${chapter_id}-${part_order}-memoizedSubchaptersListByChapterIdAndPartTransform`,
  (subchapters, chapter_id, part_order) =>
    R.pipe(
      // @ts-ignore
      R.filter(
        R.allPass([
          R.propEq('chapter_id', chapter_id),
          R.propEq('part', part_order)
        ])
      ),
      R.sortBy(R.prop('order'))
    )(subchapters)
)

export const getSubchaptersListByChapterIdAndPart: OutputParametricSelector<
  any,
  any,
  never[],
  (res1: any, res2: any, res3: any) => never[]
> = createSelector(
  R.pathOr([], ['books', 'subchapters', 'list']),
  (state, chapter_id) => chapter_id,
  (state, chapter_id, part_order) => Number(part_order),
  memoizedSubchaptersListByChapterIdAndPartTransform
)

export const getSubchaptersListByChapterId: OutputParametricSelector<
  any,
  any,
  never[],
  (res1: any, res2: any, res3: any) => never[]
> = createSelector(
  selectBooks,
  (state, chapter_id) => chapter_id,
  (books, chapter_id) =>
    R.pipe(
      R.pathOr([], ['subchapters', 'list']),
      R.filter(R.allPass([R.propEq('chapter_id', chapter_id)])),
      R.sortBy(R.prop('order'))
    )(books)
)

export const getCurrentBookChapters = createSelector(
  R.pathOr([], ['books', 'chapters', 'list']),
  sortBy(prop('order'))
)

export const getResourcesByContentId = createSelector(
  R.pathOr([], ['books', 'resources', 'list']),
  (state, content_id) => content_id,
  (resources, content_id) =>
    R.pipe(
      // @ts-ignore
      R.filter(R.propEq('content_id', content_id)),
      // @ts-ignore
      R.sortBy(R.prop('order'))
      // @ts-ignore
    )(resources)
)

export const getVideoResourcesByContentId = createSelector<RootReducer, string, Resource[], Resource[]>(
  // @ts-ignore
  R.pathOr([], ['books', 'resources', 'list']),
  (state, content_id) => content_id,
  (resources, content_id) =>
    R.pipe(
      // @ts-ignore
      R.filter(R.propEq('content_id', content_id)),
      // @ts-ignore
      R.filter(R.propEq('type', RESOURCE_TYPES.video)),
      // @ts-ignore
      R.sortBy(R.prop('order'))
      // @ts-ignore
    )(resources)
)

export const getSubchapterById = createSelector(
  R.pathOr([], ['books', 'subchapters', 'list']),
  (state, subchapter_id) => subchapter_id,
  (subchapters, subchapter_id) =>
    R.find(R.propEq('id', subchapter_id))(subchapters)
)

export const getAttachmentsByContentId = createSelector(
  R.pathOr([], ['books', 'attachments', 'list']),
  (attachments, content_id) => content_id,
  (attachments, content_id) => {
    const filteredAttachments = R.filter(R.propEq('content_id', content_id))(
      attachments
    )
    return R.sortBy(R.prop('order'))(filteredAttachments)
  }
)

const memoizedBookContentImagesByContentIdTransform = R.memoizeWith(
  // @ts-ignore
  (images, content_id) => `${content_id}-${images.length}`,
  (images, content_id) =>
    R.pipe(
      // @ts-ignore
      R.filter(R.propEq('content_id', content_id)),
      R.sortBy(R.prop('order'))
      // @ts-ignore
    )(images)
)
export const getBookContentImagesByContentId = createSelector(
  R.pathOr([], ['books', 'bookContentImages', 'list']),
  (state, content_id) => content_id,
  memoizedBookContentImagesByContentIdTransform
)

export const getNextPart = createSelector(
  selectBooks,
  (state, chapter_order) => chapter_order,
  (state, chapter_order, part_order) => part_order,
  (state, chapter_order, part_order, bookId) => bookId,
  (books, chapter_order, part_order, bookId) => {
    const currentChapterId = R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.find(
        R.allPass([
          R.propEq('order', chapter_order),
          R.propEq('book_id', bookId)
        ])
      ),
      R.propOr('', 'id')
    )(books)

    const chapterSubchapters = R.pipe(
      R.pathOr([], ['subchapters', 'list']),
      R.filter(R.propEq('chapter_id', currentChapterId))
    )(books)

    const allParts = R.pipe(
      R.pluck('part'),
      // @ts-ignore
      R.uniq,
      sortAlphabetically
      // @ts-ignore
    )(chapterSubchapters)

    // check if next part exists, if not, return 1
    return R.ifElse(
      R.pipe(R.find(R.equals(Number(part_order) + 1)), isNotNilOrEmpty),
      R.always(Number(part_order) + 1),
      R.always(1)
    )(allParts)
  }
)

export const getPreviousPart = createSelector(
  selectBooks,
  (state, chapter_order) => chapter_order,
  (state, chapter_order, part_order) => part_order,
  (books, chapter_order, part_order) => {
    const currentChapterId = R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.find(R.propEq('order', chapter_order)),
      R.propOr('', 'id')
    )(books)

    const prevChapterId = R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.find(R.propEq('order', Number(chapter_order) - 1)),
      R.propOr('', 'id')
    )(books)

    const chapterSubchapters = R.pipe(
      R.pathOr([], ['subchapters', 'list']),
      R.filter(R.propEq('chapter_id', currentChapterId))
    )(books)

    const prevChapterSubchapters = R.pipe(
      R.pathOr([], ['subchapters', 'list']),
      R.filter(R.propEq('chapter_id', prevChapterId))
    )(books)

    const allParts = R.pipe(
      R.pluck('part'),
      // @ts-ignore
      R.uniq,
      sortAlphabetically
      // @ts-ignore
    )(chapterSubchapters)

    const allPrevParts = R.pipe(
      R.pluck('part'),
      // @ts-ignore
      R.uniq,
      sortAlphabetically
      // @ts-ignore
    )(prevChapterSubchapters)

    const lastPartOfPreviousChapter = R.last(allPrevParts)
    const prevChapterPartOrZero = lastPartOfPreviousChapter || 0

    // check if prev part exists, if not, return
    return R.ifElse(
      R.pipe(R.find(R.equals(Number(part_order) - 1)), isNotNilOrEmpty),
      R.always(Number(part_order) - 1),
      R.always(prevChapterPartOrZero)
    )(allParts)
  }
)

export const getNextChapterOrder = createSelector(
  selectBooks,
  getNextPart,
  (state, chapter_order) => chapter_order,
  (books, next_part, chapter_order) => {
    const allChapterOrders = R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.pluck('order'),
      R.uniq,
      sortAlphabetically
    )(books)

    const hasNextPart = next_part > 1

    if (hasNextPart) {
      return chapter_order
    }

    // check if next chapter exists, if not, return 0
    return R.ifElse(
      R.pipe(R.find(R.equals(Number(chapter_order) + 1)), isNotNilOrEmpty),
      R.always(Number(chapter_order) + 1),
      R.always(0)
    )(allChapterOrders)
  }
)

export const getPreviousChapterOrder = createSelector(
  selectBooks,
  (state, chapter_order) => chapter_order,
  (state, chapter_order, part_order) => part_order,
  (books, chapter_order, part_order) => {
    const allChapterOrders = R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.pluck('order'),
      R.uniq,
      sortAlphabetically
    )(books)

    const hasPreviousPart = part_order > 1

    if (hasPreviousPart) {
      return chapter_order
    }

    // check if prev chapter exists, if not, return 0
    return R.ifElse(
      R.pipe(R.find(R.equals(Number(chapter_order) - 1)), isNotNilOrEmpty),
      R.always(Number(chapter_order) - 1),
      R.always(0)
    )(allChapterOrders)
  }
)

export const getBooksNavigation = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  getCurrentCourse,
  (state, pushHandler) => pushHandler,
  (booksNavigation, currentCourse, pushHandler) => {
    const isFreeTrial = R.pipe(
      R.propOr('', 'type'),
      R.equals(COURSE_TYPES.freeTrial)
    )(currentCourse)

    const generateSecondLevel = bookId => chapter => {
      const chapterOrder = R.propOr(1, 'order', chapter)
      const chapterTitle = R.propOr('', 'title', chapter)

      const hasBookmark = R.not(R.propEq('bookmark_id', null, chapter))
      const bookContentId = R.propOr('', 'bookmark_id', chapter)
      const subchapterId = R.propOr('', 'bookmark_subchapter_id', chapter)
      const partOrder = R.propOr('1', 'bookmark_subchapter_part', chapter)

      const handleScrollToBookContent = () => {
        const bookContentAnchor = document.getElementById(
          `book-content-anchor-${bookContentId}`
        )
        bookContentAnchor &&
          bookContentAnchor.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
      }

      const handleRedirectToBookmark = () => {
        if (hasBookmark) {
          AnalyticsService(null).logEvent(ANALYTICS_EVENTS.seeBooksBookmark, {
            'Course type': currentCourse?.type || false,
            'Course name': currentCourse?.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Go To Bookmark': `${chapterTitle}`
          })
          pushHandler(
            `/books/${bookId}/chapter/${chapterOrder}/part/${partOrder}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${subchapterId}&${BOOK_VIEWER_PARAMS.bookContentIdContext}=${bookContentId}`
          )
          setTimeout(handleScrollToBookContent, 3000)
        }
      }

      return {
        label: `${chapterOrder}. ${chapterTitle}`,
        url: `/books/${bookId}/chapter/${chapterOrder}/part/1`,
        isInactive: isFreeTrial ? chapterOrder !== 1 : false,
        bookmarkOnClick: handleRedirectToBookmark,
        bookmark: hasBookmark
          ? `/books/${bookId}/chapter/${chapterOrder}/part/${partOrder}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${subchapterId}&${BOOK_VIEWER_PARAMS.bookContentIdContext}=${bookContentId}`
          : null,
        tooltip: isFreeTrial
          ? R.ifElse(
              R.pipe(R.equals(1), R.not),
              R.always(i18n.t('toast.chapterNotAvailableInFreeTrial')),
              R.always(null)
            )(chapterOrder)
          : undefined
      }
    }

    const generateFirstLevelElement = book => {
      const bookId = R.propOr('', 'book_id', book)
      const bookCoverImage = R.pathOr('', ['book', 'cover_image_url'], book)

      return {
        label: R.propOr('', 'title', book),
        url: '',
        coverImage: bookCoverImage,
        isInactive: isFreeTrial
          ? R.propOr(true, 'is_available', book) === false
          : false,
        tooltip: isFreeTrial
          ? R.ifElse(
              R.pipe(R.propOr(true, 'is_available'), R.equals(false)),
              R.always(i18n.t('toast.bookNotAvailableInFreeTrial')),
              R.always(null)
            )(book)
          : undefined,
        nextLevel: R.pipe(
          R.propOr([], 'chapters'),
          // @ts-ignore
          R.sortBy(R.prop('order')),
          R.map(generateSecondLevel(bookId))
          // @ts-ignore
        )(book)
      }
    }

    return R.map(generateFirstLevelElement)(booksNavigation)
  }
)

// @ts-ignore
export const getBookContentImagesBySubchapterId = createSelector(
  selectBooks,
  (state, subchapter_id) => subchapter_id,
  (books, subchapter_id) => {
    const content_ids = R.pipe(
      R.pathOr([], ['bookContents', 'list']),
      // @ts-ignore
      R.filter(R.propEq('subchapter_id', subchapter_id)),
      R.pluck('id')
    )(books)

    return R.pipe(
      R.pathOr([], ['bookContentImages', 'list']),
      // @ts-ignore
      R.filter(
        R.pipe(R.propOr('', 'content_id'), cid => R.includes(cid, content_ids))
      ),
      R.sortBy(R.prop('order'))
    )(books)
  }
)

export const getBookContentImages = createSelector(
  R.pathOr([], ['books', 'bookContentImages', 'list']),
  R.sortWith([
    R.ascend(R.prop('subchapter_order')),
    R.ascend(R.prop('content_order')),
    R.ascend(R.prop('order'))
  ])
)

// @ts-ignore
export const getBookChapterImages = createSelector(
  R.pathOr([], ['books', 'bookChapterImages', 'list']),
  R.sortWith([R.ascend(R.prop('order'))])
)

const addImagesToEachBookContent = bookContentImages => bookContent => ({
  ...bookContent,
  // @ts-ignore
  images: R.filter(image => image.content_id === bookContent.id)(
    bookContentImages
  )
})

export const getBookContentsWithImages = createSelector(
  getBookContentImages,
  getOrderedBookContentsList,
  (bookContentImages, bookContents) => {
    return R.map(addImagesToEachBookContent(bookContentImages))(bookContents)
  }
)

export const getBookContentById: OutputParametricSelector<
  any,
  unknown,
  Record<'id', any> | undefined,
  (res1: any, res2: unknown) => Record<'id', any> | undefined
> = createSelector(
  R.pathOr([], ['books', 'bookContents', 'list']),
  (state, book_content_id) => book_content_id,
  (bookContents, book_content_id) =>
    R.find(R.propEq('id', book_content_id))(bookContents)
)

export const getCurrentSubchapterNotes = createSelector(
  R.pathOr({}, ['books', 'notes']),
  R.identity
)

// @ts-ignore
export const getBookContentFlashcardsBySubchapterId = createSelector(
  R.pathOr([], ['books', 'bookContents', 'list']),
  (state, subchapter_id) => subchapter_id,
  (state, subchapter_id) =>
    R.pathOr([], ['books', 'contentFlashcards', 'list'])(state),
  (bookContents, subchapter_id, contentFlashcards) => {
    const content_ids = R.pipe(
      // @ts-ignore
      R.filter(R.propEq('subchapter_id', subchapter_id)),
      R.pluck('id')
      // @ts-ignore
    )(bookContents)

    return R.pipe(
      // @ts-ignore
      R.filter(
        // @ts-ignore
        R.pipe(R.propOr('', 'content_id'), cid => R.includes(cid, content_ids))
      ),
      R.sortBy(R.prop('study_order'))
      // @ts-ignore
    )(contentFlashcards)
  }
)

// @ts-ignore
export const getBookContentQuestionsBySubchapterId = createSelector(
  R.pathOr([], ['books', 'bookContents', 'list']),
  (state, subchapter_id) => subchapter_id,
  (state, subchapter_id) =>
    R.pathOr([], ['books', 'contentQuestions', 'list'])(state),
  (bookContents, subchapter_id, contentQuestions) => {
    const content_ids = R.pipe(
      // @ts-ignore
      R.filter(R.propEq('subchapter_id', subchapter_id)),
      R.sortBy(R.prop('order')),
      R.pluck('original_content_id')
      // @ts-ignore
    )(bookContents)

    // @ts-ignore
    const questions = content_ids.map(id => {
      return R.filter(R.propEq('content_id', id))(contentQuestions)
    })

    return R.pipe(R.flatten, R.sortBy(R.prop('subchapter_order')))(questions)
  }
)

export const selectFreeTrialUnavailableBookTags = createSelector(
  selectBooks,
  getCurrentCourse,
  (books, currentCourse) => {
    const allBooks = R.propOr([], 'booksNavigation', books)
    const isFreeTrialCourse = R.pipe(
      R.propOr('', 'type'),
      R.equals(COURSE_TYPES.freeTrial)
    )(currentCourse)

    const unavailableBooks = R.pipe(
      // @ts-ignore
      R.filter(R.propEq('is_available', false)),
      R.map(R.propOr('', 'tag'))
      // @ts-ignore
    )(allBooks)

    const booksList = isFreeTrialCourse ? unavailableBooks : []

    return booksList
  }
)

export const selectFreeTrialAvailableBookTags = createSelector(
  selectBooks,
  getCurrentCourse,
  (books, currentCourse) => {
    const allBooks = R.propOr([], 'booksNavigation', books)
    // @ts-ignore
    const allBookTags = R.map(R.prop('tag'))(allBooks)

    const isFreeTrialCourse = R.pipe(
      R.propOr('', 'type'),
      R.equals(COURSE_TYPES.freeTrial)
    )(currentCourse)

    const availableBookTags = R.pipe(
      // @ts-ignore
      R.filter(R.propEq('is_available', true)),
      R.map(R.propOr('', 'tag'))
      // @ts-ignore
    )(allBooks)

    const booksTagList = isFreeTrialCourse ? availableBookTags : allBookTags

    return booksTagList
  }
)

export const getChapterByOrder: OutputParametricSelector<
  any,
  any,
  any,
  (res1: any, res2: any, res3: any) => any
> = createSelector(
  selectBooks,
  (state, order) => order,
  (books, order) =>
    R.pipe(
      R.pathOr([], ['chapters', 'list']),
      R.find(R.propEq('order', order))
    )(books)
)

export const getIsBookContentsLoading = createSelector(
  R.pathOr('', ['books', 'bookContents', 'state']),
  R.equals(API_STATES.IN_PROGRESS)
)

export const getIsLastBookElementLoaded = createSelector(
  R.pathOr('', ['books', 'resources', 'state']),
  R.equals(API_STATES.DONE)
)

export const getIsContentQuestionsLoading = createSelector(
  R.pathOr('', ['books', 'contentQuestions', 'state']),
  R.equals(API_STATES.IN_PROGRESS)
)

export const getBookTagById = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  (state, book_id) => book_id,
  (books, book_id) =>
    R.pipe(R.find(R.propEq('id', book_id)), R.propOr('', 'tag'))(books)
)

export const areAllFlashcardsOff = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  books =>
    R.pipe(
      // @ts-ignore
      R.filter(
        R.pipe(R.propOr(false, 'flashcards_accessible'), R.equals(false), R.not)
      ),
      isNilOrEmpty
      // @ts-ignore
    )(books)
)

export const getBookContentIdsWithAttachedTopics = createSelector(
  R.pathOr([], ['books', 'topics', 'list']),
  R.pipe(R.map(R.prop('student_book_content_id')), R.uniq)
)

export const getBookOriginalContentIdsWithAttachedComments = createSelector(
  R.pathOr([], ['books', 'bookContentComments', 'list']),
  R.pipe(R.map(R.prop('original_book_content_id')), R.uniq)
)

export const getBookContentTopics = createSelector(
  R.pathOr([], ['books', 'topics', 'list']),
  (state, contentId) => contentId,
  (topics, contentId) =>
    R.filter(R.propEq('student_book_content_id', contentId))(topics)
)

export const hasOnlyTestBundledBooks = createSelector(
  R.pathOr([], ['books', 'booksNavigation']),
  R.all(R.propEq('is_test_bundle', true))
)

export const isTopicsCommentRead = createSelector(
  R.pathOr([], ['books', 'bookContentComments', 'list']),
  (state, originalBookContentId) => originalBookContentId,
  (bookContentComments, originalBookContentId) =>
    R.pipe(
      // @ts-ignore
      R.find(
        R.allPass([
          R.propEq('original_book_content_id', originalBookContentId),
          R.propEq(
            'student_course_id',
            LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
          )
        ])
      ),
      // @ts-ignore
      R.propOr(false, 'is_read')
      // @ts-ignore
    )(bookContentComments)
)
