import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as R from 'ramda'
import { pathOr } from 'ramda'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { Heading1, Tooltip } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { fetchStudentVideoCategories } from 'services/VideosService'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { ORDERED_CATEGORIES } from '../utils/categories'
import { Course } from 'types/courses'
import { sortByCustomOrder } from 'utils/array'
import { COURSE_TYPES } from 'utils/courses'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/videos/utils/events'
import { isNotNilOrEmpty } from 'utils/ramda'

export const VideoCategories = (): JSX.Element => {
  const { t } = useTranslation()
  const [areCategoriesLoaded, setAreCategoriesLoaded] = useState<boolean>(false)
  const [categories, setCategories] = useState<
    { category: string; count: number }[]
  >([])
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedCategory = pathOr(
    'all',
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const getVideoCategories = () => {
    fetchStudentVideoCategories()
      .then(response => {
        setCategories(
          sortByCustomOrder(response.data, ORDERED_CATEGORIES, 'category')
        )
        setAreCategoriesLoaded(true)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && !areCategoriesLoaded) {
      getVideoCategories()
    }
  }, [currentCourse, areCategoriesLoaded])

  useEffect(() => {
    eventEmitter.on(events.favoriteToggle, getVideoCategories)
    return () => {
      eventEmitter.off(events.favoriteToggle, getVideoCategories)
    }
  }, [])

  const onCateoryClick = category => () => {
    const filterQuery = R.pipe(R.propOr({}, 'filter'))(parsedQuery)

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          [VIDEOS_QUERY_PARAMS.category]: R.propOr('all', 'category', category)
        }
      },
      { addQueryPrefix: true }
    )

    replace(`${pathname}${newQuery}`)
  }

  const renderCategories = categories.map((category, index) =>
    isFreeTrial && category.category === 'medreel' ? (
      <CategoryItem
        key={`category-item-${index}-${category.category}`}
        isActive={selectedCategory === R.propOr('', 'category', category)}
      >
        <Tooltip
          tooltipContent={t('videos.freeTrialBonusVideosCategory')}
          id='not-available-category'
        >
          <>
            <DisabledText>
              {t(
                `videos.videoCategories.${R.propOr('', 'category', category)}`
              )}{' '}
              ({R.propOr(0, 'count', category)})
            </DisabledText>
          </>
        </Tooltip>
      </CategoryItem>
    ) : (
      <CategoryItem
        onClick={onCateoryClick(category)}
        key={`category-item-${index}-${category.category}`}
        isActive={selectedCategory === R.propOr('', 'category', category)}
      >
        <span>
          {t(`videos.videoCategories.${R.propOr('', 'category', category)}`)} (
          {R.propOr(0, 'count', category)})
        </span>
      </CategoryItem>
    )
  )

  return (
    <Container>
      <Heading1 bold>{t('videos.title')}</Heading1>
      <CategoriesTitle>{t('videos.categories')}</CategoriesTitle>
      <CategoriesList>{renderCategories}</CategoriesList>
    </Container>
  )
}

export default VideoCategories

const Container = styled.div``

const CategoriesTitle = styled.div`
  margin: 21px 0;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`

const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DisabledText = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
`

const CategoryItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 0 12px;
  gap: 8px;
  line-height: 32px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.main.primary200 : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  transition: background 250ms
    ${({ theme }) => theme.transitions.easing.easeInOut};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.main.text};
  white-space: nowrap;
  word-break: keep-all;
`
