import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ExamContent,
  ExamAnswers,
  ResponseRequiredModal
} from 'examkrackers-components'

import PageIds from 'modules/exam/utils/pageIds'

import { useSelector } from 'react-redux'

import { getFirstSectionId } from 'modules/exam/ducks/selectors'

export const TutorialStart = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const firstSectionId = useSelector(getFirstSectionId)

  const [responseRequiredModalOpen, setResponseRequiredModalVisibility] =
    useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    selectedAnswer === 'B'
      ? moveToExamSection()
      : setCurrentPage(PageIds.tutorialNavigation)
  }

  const openResponseRequiredModal = () =>
    setResponseRequiredModalVisibility(true)
  const closeResponseRequiredModal = () =>
    setResponseRequiredModalVisibility(false)

  const onNextClick = () => {
    if (selectedAnswer === '') {
      openResponseRequiredModal()
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: onNextClick
    }))
  }, [selectedAnswer])

  const continueTutorialAnswers = [
    {
      answerCode: 'A',
      answerContent: 'Yes, I would like to continue this tutorial.'
    },
    {
      answerCode: 'B',
      answerContent:
        'No, I would like to skip the tutorial and proceed straight to my exam. I understand that I will not be able to return to the tutorial if I choose to skip it.'
    }
  ]

  return (
    <ExamContent>
      <TutorialStartContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>Welcome to the Tutorial</h2>
            <p>
              This tutorial shows you how to navigate the exam. You will be able
              to practice using each feature on the exam as well as the keyboard
              shortcuts that trigger specific actions before starting the exam.
              Keyboard shortcuts use the Alt key and a letter. The letter of the
              word is underlined on the page where you can use them (example:
              Alt+N to go to the <span className='underline'>N</span>ext
              screen).
            </p>
            <p>
              The <strong>Time Remaining</strong> feature on the top right
              corner of the screen shows the amount of time remaining in the
              section. Select the <strong>Time Remaining</strong> feature to
              hide or show the time remaining. The time remaining will
              automatically appear for the last 5 minutes. Time elapses only
              when the content fully renders on the screen.
            </p>
            <p>
              The <strong>Number of Questions</strong> feature on the top right
              corner of the screen shows the question number you are on and the
              total number of questions in the section. Select the{' '}
              <strong>Number of Questions</strong> feature to hide or show the
              question number.
            </p>
          </div>
          <p className='tutorial-start__question'>
            Would you like to continue this tutorial?
          </p>
          <div>
            <ExamAnswers
              answers={continueTutorialAnswers}
              onSelectAnswer={setSelectedAnswer}
              selectedAnswerCode={selectedAnswer}
              answerCodeHidden
            />
          </div>
          <br />
          <p>
            Select an option, then select&nbsp;
            <strong>
              <span className='underline'>N</span>
              <span>ext</span>
            </strong>
            &nbsp;to advance to the next page.
          </p>
        </div>
        <ResponseRequiredModal
          initHeight={185}
          open={responseRequiredModalOpen}
          handleClose={closeResponseRequiredModal}
        />
      </TutorialStartContainer>
    </ExamContent>
  )
}

export default TutorialStart

const TutorialStartContainer = styled.div`
  p {
    margin-bottom: 16px;
  }

  .tutorial-start__question {
    margin-bottom: 18px !important;
  }

  .answer {
    margin-top: 16px;
  }
`
