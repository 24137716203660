import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { HeaderDecorative, Button } from 'examkrackers-components'
import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import PATHS from 'utils/paths'

// NotFound route
//
// This route is shown when a 404 error occurs.
export const NotFound = (): JSX.Element => {
  // Get i18n handler
  const { t } = useTranslation()
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  // Get history handler
  const { push } = useHistory()

  const goToCourses = () => push(PATHS.selectCourse)

  React.useEffect(() => {
    if (isPreviewAdmin) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL}/exams`
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.notFound')}</title>
      </Helmet>
      <HeaderDecorative>404</HeaderDecorative>
      <HeaderDecorative>{t('notFound.title')}</HeaderDecorative>
      <ButtonContainer>
        <Button type='button' id='go-back-btn' onClick={goToCourses}>
          {t('notFound.CTA')}
        </Button>
      </ButtonContainer>
    </React.Fragment>
  )
}

export default NotFound

const ButtonContainer = styled.div`
  margin-top: 10px;
`
