import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamContent, ExamAnswers } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'
import { propOr } from 'ramda'
import { isNilOrEmpty } from '../../../../utils/ramda'

export const TutorialPeriodicTable = (props): JSX.Element => {
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialPeriodicTable)
  )
  const currentAnswer: string = propOr('', 'answer', questionState)

  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const [selectedAnswer, setSelectedAnswer] = useState(currentAnswer)
  const dispatch = useDispatch()
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )
  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialFlagForReview)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialStrikethrough)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? QUESTION_STATUSES.incomplete
          : questionState.status,
      id: PageIds.tutorialPeriodicTable
    })
  }, [])

  const handleSelectAnswer = answer => {
    const newAnswer = answer === selectedAnswer ? '' : answer
    const newStatus = isNilOrEmpty(newAnswer)
      ? QUESTION_STATUSES.incomplete
      : QUESTION_STATUSES.complete

    updateQuestionStep({
      ...questionState,
      status: newStatus,
      id: PageIds.tutorialPeriodicTable,
      answer: newAnswer
    })
    setSelectedAnswer(newAnswer)
  }

  const periodicTableAnswers = [
    {
      answerCode: 'A',
      answerContent: '34'
    },
    {
      answerCode: 'B',
      answerContent: '45'
    },
    {
      answerCode: 'C',
      answerContent: '79'
    },
    {
      answerCode: 'D',
      answerContent: '113'
    }
  ]

  return (
    <>
      <ExamContent>
        <TutorialPeriodicTableContainer>
          <div>
            <div
              class='heavy-bordered content-container'
              id='driver-tutorial-periodic-highlight'
            >
              <h2>Refer to the Periodic Table</h2>
              <h3>Description</h3>
              <p>
                During the Chemical and Physical Foundations of Biological
                Systems and Biological and Biochemical Foundations of Living
                Systems sections, you can refer to the periodic table of
                elements. Since you do not need the periodic table to answer
                most questions, it will not automatically appear on the screen.
              </p>
              <h3>Instructions</h3>
              <p>
                Locate{' '}
                <strong>
                  Periodic <span className='underline'>T</span>able
                </strong>{' '}
                in the bottom left corner of your screen.
              </p>
              <ul>
                <li>
                  <strong>
                    Periodic <span className='underline'>T</span>able
                  </strong>{' '}
                  or the Alt+T keyboard shortcut will make the periodic table
                  appear on the screen.
                </li>
                <li>
                  The <strong>ESC</strong> key on your keyboard or the{' '}
                  <strong>X</strong> on the upper right corner of the periodic
                  table will close it.
                </li>
              </ul>
              <h3>Practice</h3>
              <ul className='numeric'>
                <li>
                  Select{' '}
                  <strong>
                    Periodic <span className='underline'>T</span>able
                  </strong>{' '}
                  or Alt+T to review the periodic table.
                </li>
                <li>
                  Select <strong>ESC</strong> or <strong>X</strong> to close the{' '}
                  <strong>
                    Periodic <span className='underline'>T</span>able
                  </strong>{' '}
                  window.
                </li>
                <li>
                  You can also select Tab to move the focus box to the{' '}
                  <strong>X</strong> and press enter or the space bar.
                </li>
              </ul>
              <p>What is the atomic mass of Selenium (Se)?</p>
              <ExamAnswers
                answers={periodicTableAnswers}
                onSelectAnswer={handleSelectAnswer}
                selectedAnswerCode={selectedAnswer}
              />
            </div>
            <p>
              Select{' '}
              <strong>
                <span className='underline'>N</span>ext
              </strong>{' '}
              to advance to the next page.
            </p>
          </div>
        </TutorialPeriodicTableContainer>
      </ExamContent>
    </>
  )
}

export default TutorialPeriodicTable

const TutorialPeriodicTableContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`
