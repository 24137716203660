import * as R from 'ramda'
import { createSelector, OutputSelector } from 'reselect'
import { LayoutReducer, RootReducer } from 'types'

export const selectLayout = (state: any) => state.layout

export const getIsMobileMenuOpen: OutputSelector<
  RootReducer,
  any,
  (res: LayoutReducer) => any
> = createSelector(selectLayout, R.propOr(false, 'isMobileMenuOpen'))
