import React, { useState } from 'react'
import {
  Button,
  Modal,
  ChapterIcon,
  PlayerIcon,
  SpreadsheetIcon,
  TutorIcon
} from 'examkrackers-components'
import styled from 'styled-components'

import CalendarSaltyOneWeekSubscription from 'assets/images/CalendarSalty_OneWeekSubscription.svg'
import RelumeIcon from 'assets/Relume.svg'

import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'

const FreeTrialModal = () => {
  const [open, setOpen] = useState(true)

  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(currentCourse.transaction_id)
  const transactionId = propOr('', 'transaction_id', currentCourse)
  const openPurchasePortal = () => {
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      hasBorder
      data-testid='FreeTrialModal-Modal'
    >
      <>
        <MainWrapper data-testid='FreeTrialModal-MainWrapper'>
          <HideCloseButtonWrapper data-testid='FreeTrialModal-HideCloseButtonWrapper' />
          <TextWrapper data-testid='FreeTrialModal-TextWrapper'>
            <SectionTitle data-testid='FreeTrialModal-SectionTitle'>
              {t('calendar.freeTrialModalInCalendar.title')}
            </SectionTitle>
          </TextWrapper>
          <Container data-testid='FreeTrialModal-Container'>
            <DescriptionWrapper data-testid='FreeTrialModal-DescriptionWrapper'>
              <InfoText data-testid='FreeTrialModal-InfoText'>
                {t('calendar.freeTrialModalInCalendar.info')}
              </InfoText>
              <OptionWrapper>
                <IconWrapper>
                  <ChapterIcon width='25px' height='25px' />
                </IconWrapper>
                <OptionText>
                  {t('calendar.freeTrialModalInCalendar.book')}
                </OptionText>
              </OptionWrapper>
              <OptionWrapper>
                <IconWrapper>
                  <PlayerIcon width='25px' height='25px' />
                </IconWrapper>
                <OptionText>
                  {t('calendar.freeTrialModalInCalendar.video')}
                </OptionText>
              </OptionWrapper>
              <OptionWrapper>
                <IconWrapper>
                  <SpreadsheetIcon width='25px' height='25px' />
                </IconWrapper>
                <OptionText>
                  {t('calendar.freeTrialModalInCalendar.exam')}
                </OptionText>
              </OptionWrapper>
              <OptionWrapper>
                <IconWrapper>
                  <TutorIcon width='25px' height='25px' />
                </IconWrapper>
                <OptionText>
                  {t('calendar.freeTrialModalInCalendar.tutoring')}
                </OptionText>
              </OptionWrapper>
              <OptionWrapper>
                <IconWrapper>
                  <RelumeIconWrapper src={RelumeIcon} />
                </IconWrapper>
                <OptionText>
                  {t('calendar.freeTrialModalInCalendar.games')}
                </OptionText>
              </OptionWrapper>
            </DescriptionWrapper>

            <SaltyContainer data-testid='FreeTrialModal-SaltyContainer'>
              <OneWeekSaltyImage
                data-testid='FreeTrialModal-OneWeekSaltyImage'
                id='reorganize-salty'
                src={CalendarSaltyOneWeekSubscription}
                alt='Salty reorganize'
              />
            </SaltyContainer>
          </Container>
          <BottomInfo data-testid='FreeTrialModal-BottomInfo'>
            {t('calendar.freeTrialModalInCalendar.description')}
          </BottomInfo>

          <ButtonsContainer>
            <PurchasePageLink onClick={openPurchasePortal}>
              <ButtonWrapper
                color='primary'
                onClick={openPurchasePortal}
                data-testid='FreeTrialModal-ButtonWrapper-buttonPlans'
              >
                {t('calendar.freeTrialModalInCalendar.buttonPlans')}
              </ButtonWrapper>
            </PurchasePageLink>
            <ButtonFreeTrial onClick={handleClose}>
              {t('calendar.freeTrialModalInCalendar.buttonOk')}
            </ButtonFreeTrial>
          </ButtonsContainer>
        </MainWrapper>
      </>
    </Modal>
  )
}

export default FreeTrialModal

const Container = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
`

const SectionTitle = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px; 100%
`
const DescriptionWrapper = styled.div`
  font-size: 16px;
  text-align: left;
  width: 610px;

  line-height: 24px;
`
const ButtonWrapper = styled(Button)`
  display: flex;
  width: 126px;
  padding: 12px;
  color: ${({ theme }) => theme.colors.main.black};
`

const SaltyContainer = styled.div`
  right: 0;
  top: 40px;
  margin-top: 20px;
  margin-bottom: 25px;
  position: absolute;
`
const OneWeekSaltyImage = styled.img`
  height: 136px;
  width: 276px;
`
const RelumeIconWrapper = styled.img`
  height: 25px;
  width: 26px;
`
const MainWrapper = styled.div`
  position: relative;
  margin: 0 50px;
`

const HideCloseButtonWrapper = styled.div`
  position: absolute;
  top: -48px;
  right: -60px;
  width: 80px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.main.white};
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
`
const IconWrapper = styled.div`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.main.black};
  display: flex;
  align-items: center;
`
const InfoText = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
  color: #444;
  margin-top: -25px;
`
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const OptionText = styled.div`
  color: ${({ theme }) => theme.colors.main.black};
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BottomInfo = styled.div`
  color:#444
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;

  text-align: left;
  width: 539px;
`
const ButtonFreeTrial = styled.div`
  color: #412312;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  text-decoration-line: underline;
`
const ButtonsContainer = styled.div`
  display: flex;
  gap: 42px;
  align-items: center;
  cursor: pointer;
`
const PurchasePageLink = styled.a`
  .children-container {
    overflow: visible !important;
  }
`
