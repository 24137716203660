import React from 'react'
import styled from 'styled-components'
import HeadingChapter from 'modules/books/components/HeadingChapter.mobile'
import TitleChapter from 'modules/books/components/TitleChapter'
import SectionContext from './components/SectionContext'
import SubchaptersList from 'modules/books/components/SubchaptersList.mobile'
import ModalFixedImagePreview from 'modules/books/components/ModalFixedImagePreview.mobile'
import ButtonNext from 'modules/books/components/ButtonNext'
import ScrollbarNav from 'modules/books/components/ScrollbarNav.mobile'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { propOr, pipe, equals } from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { Course } from 'types'
import { useSelector } from 'react-redux'

const BookTitle = () => {
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = pipe(
    propOr('', 'type'),
    equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  return (
    <TitleWrapper isFreeTrial={isFreeTrial}>
      <TitleChapter />
      <SectionContext />
    </TitleWrapper>
  )
}

export const BookViewer = (): JSX.Element => {
  return (
    <BookMobileLayout>
      <BookTitle />
      <HeadingChapter />
      <SubchaptersList />
      <ButtonNext />
      <ModalFixedImagePreview />
      <ScrollbarNav />
    </BookMobileLayout>
  )
}

export default BookViewer

const BookMobileLayout = styled.div`
  padding-bottom: ${({ theme }) => theme.dimensions.mobileBottomNavHeight};
  -webkit-overflow-scrolling: touch;
`

const TitleWrapper = styled.div<{ isFreeTrial: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: ${({ isFreeTrial }) => (isFreeTrial ? '30px' : '0')};
  left: 0;
  right: 0;
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 0 20px;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
`
