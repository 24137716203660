import * as R from 'ramda'

export const isBookInFreeTrial = (
  bookIsFreeTrialFlags: BookIsFreeTrialFlags[],
  book_id: string
) => {
  return R.pipe(
    R.find(R.propEq('book_id', book_id)),
    R.propOr(false, 'is_free_trial'),
    R.equals(true)
  )(bookIsFreeTrialFlags)
}

export const isChapterInFreeTrial = (
  bookIsFreeTrialFlags: BookIsFreeTrialFlags[],
  chapter_id: string
) => {
  return R.pipe(
    R.find(R.propEq('original_first_chapter_id', chapter_id)),
    R.propOr(false, 'is_free_trial'),
    R.equals(true)
  )(bookIsFreeTrialFlags)
}

export const isExamIncludedInFreeTrial = (exam, booksFlaggedAsFreeTrial) => {
  const isExamAvailableInFreeTrialBookLevel: boolean =
    R.propOr('', 'attached_type', exam) === 'book' &&
    isBookInFreeTrial(
      booksFlaggedAsFreeTrial,
      R.propOr('', 'original_attached_id', exam)
    )

  const isExamAvailableInFreeTrialChapterLevel: boolean =
    R.propOr('', 'attached_type', exam) === 'chapter' &&
    isChapterInFreeTrial(
      booksFlaggedAsFreeTrial,
      R.propOr('', 'original_attached_id', exam)
    )

  const isExamAvailableInFreeTrialCourseLevel: boolean =
    R.propOr('', 'attached_type', exam) === 'course' &&
    R.propOr(false, 'is_free_trial', exam)

  return (
    isExamAvailableInFreeTrialBookLevel ||
    isExamAvailableInFreeTrialChapterLevel ||
    isExamAvailableInFreeTrialCourseLevel
  )
}
