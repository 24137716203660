import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  IconButton,
  NotificationBellIcon,
  ArrowDownIcon,
  CloseIcon
} from 'examkrackers-components'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useOutsideClick from 'hooks/useOutsideClick'
import { NOTIFICATIONS_EVENTS } from 'utils/socketEvents'
import { selectNotifications } from 'modules/notifications/ducks/selector'
import { fetchNotificationsRoutine } from '../ducks/actions'
import { getStudent } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { Course } from 'types'

import NotificationView from 'modules/notifications/components/NotificationView'
import NotificationCalendarTutoring from 'modules/notifications/components/NotificationCalendarTutoring'
import NotificationCalendarClass from 'modules/notifications/components/NotificationCalendarClass'
import {
  markAllNotificationsAsRead,
  getNotificationsCount,
  markAllNotificationsAsSeen,
  markNotificationAsRead
} from 'services/NotificationService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import notificationsKrackers from 'modules/notifications/components/notifications-krackers.png'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/notifications/utils/events'

import PATHS from 'utils/paths'

const NotificationDropdown = (): JSX.Element => {
  const [clickedIndex, setClickedIndex] = useState(0)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [counter, setCounter] = useState(0)
  const [calendarModalOpen, setCalendarModalOpen] = useState()
  const dispatch = useDispatch()
  const handleClose = () => setOpen(false)

  const containerRef = useRef(null)

  const { path } = useRouteMatch()
  const isDashboard = path === PATHS.dashboard

  useOutsideClick(containerRef, handleClose)
  const student = useSelector(getStudent)
  const studentId = propOr('', 'id', student)

  const currentCourse: Course = useSelector(getCurrentCourse)

  const isExpirationType = currentCourse.type === COURSE_TYPES.live

  const handleOpen = async () => {
    setOpen(open => !open)
    eventEmitter.emit(events.fetchList, open)
    handleItemClick(-1)
    try {
      await markAllNotificationsAsSeen()
      handleNotificationCount()
    } catch (error) {
      console.error(error)
    }
  }
  // @ts-ignore
  const socketClient = window.socketClient

  const fetchNotifications = React.useCallback(
    payload => dispatch(fetchNotificationsRoutine(payload)),
    [dispatch]
  )

  const numberOfNewNotifications = () => {
    if (counter <= 9) {
      return counter
    } else {
      return '9+'
    }
  }

  const handleNotificationCount = async () => {
    const response = await getNotificationsCount()
    const data = await response.data.count
    setCounter(data)
  }

  const handleNotificationEvent = () => {
    studentId && fetchNotifications({ studentId })
    handleNotificationCount()
  }
  const notifications = useSelector(selectNotifications)

  const handleMarkAllAsRead = () => {
    const handleSuccess = () => studentId && fetchNotifications({ studentId })
    const handleError = e => console.error(e)
    markAllNotificationsAsRead().then(handleSuccess).catch(handleError)
  }

  if (socketClient) {
    socketClient.on(NOTIFICATIONS_EVENTS.notification, handleNotificationEvent)
  }

  const handleItemClick = index => {
    setClickedIndex(index)
  }

  const handleScrollTop = () => {
    const element = document.getElementById('notifications-list')
    if (element) {
      element.scrollTop = 0
    }
  }

  useEffect(() => {
    if (open) {
      handleScrollTop()
    }
  }, [open])

  useEffect(() => {
    studentId && fetchNotifications({ studentId })
    handleNotificationCount()
  }, [])

  return (
    <>
      <Container ref={calendarModalOpen ? null : containerRef}>
        <IconWrapper onClick={handleOpen}>
          <StyledIconButton
            icon={<NotificationBellIcon />}
            variant='transparent'
            tooltip={t('navigation.notificationsDropdown.tooltip')}
            tooltipId='notifications-dropdown-button'
            size='medium'
          />
          <CounterContainer hasNewNotification={counter > 0}>
            {numberOfNewNotifications()}
          </CounterContainer>
        </IconWrapper>
        <Menu open={open} isDashboard={isDashboard}>
          <TopSectionWrapper>
            <Title>{t('notifications.title')}</Title>
            <IconContainer>
              <KrackersWrapper src={notificationsKrackers} />
            </IconContainer>
            {isNotNilOrEmpty(notifications) && (
              <TextButtonTop onClick={handleMarkAllAsRead}>
                {t('notifications.hideAllAsRead')}
              </TextButtonTop>
            )}
            <CloseIconContainer>
              <CloseIcon onClick={handleClose} />
            </CloseIconContainer>
          </TopSectionWrapper>
          <NotificationsWrapper id='notifications-list'>
            {isNotNilOrEmpty(notifications) ? (
              notifications.map((notification, index) => {
                const handleMarkParticularNotificationAsRead = () => {
                  const handleSuccess = () =>
                    studentId && fetchNotifications({ studentId })
                  const handleError = e => console.error(e)
                  const notificationId: string = propOr('', 'id', notification)
                  markNotificationAsRead(notificationId)
                    .then(handleSuccess)
                    .catch(handleError)
                }
                const isNotificationRead = propOr(
                  false,
                  'is_read',
                  notification
                )
                const notificationId = propOr('', 'id', notification)
                const isExpanded = clickedIndex === index
                return (
                  <NotificationWrapper key={`notification-${notificationId}`}>
                    <OverlayContainer
                      onClick={() => {
                        isExpanded
                          ? handleItemClick(-1)
                          : handleItemClick(index)
                        if (!isNotificationRead) {
                          handleMarkParticularNotificationAsRead()
                        }
                      }}
                    />

                    <NotificationView
                      notification={notification}
                      isExpanded={isExpanded}
                    />

                    {isExpanded && (
                      <CloseIconElement>
                        <CloseIconWrapper onClick={() => handleItemClick(-1)} />
                      </CloseIconElement>
                    )}
                  </NotificationWrapper>
                )
              })
            ) : (
              <EmptyStateText>{t('notifications.emptyState')}</EmptyStateText>
            )}
          </NotificationsWrapper>
          <GroupWrapper>
            <NotificationCalendarTutoring
              groupTitle={t('notifications.groupTutoring')}
              buttonText={t('notifications.buttonTutoring')}
            />
            {isExpirationType ? (
              <NotificationCalendarClass
                groupTitle={t('notifications.liveOnlineClass')}
                buttonText={t('notifications.buttonClass')}
                calendarModalOpen={calendarModalOpen}
                setCalendarModalOpen={setCalendarModalOpen}
              />
            ) : null}
          </GroupWrapper>
        </Menu>
      </Container>
    </>
  )
}

export default NotificationDropdown

const Container = styled.div`
  margin: 0 4px;
  position: relative;
`

const CloseIconElement = styled.div`
  background-color: #f7e2d2;
  display: flex;
  justify-content: flex-end;
`
const CloseIconWrapper = styled(ArrowDownIcon)`
  cursor: pointer;
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const KrackersWrapper = styled.img`
  display: flex;
  width: 100px;
  height: 50px;
`

const Menu = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 42px;
  left: -191px;
  background: ${({ theme }) => theme.colors.main.primary100};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  border: 1px solid ${props => props.theme.colors.main.primary500};
  width: 500px;
  max-height: 690px;
  animation: fadeIn 300ms ease-out;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.main.primary100};

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.main.text};
  :hover {
    color: ${({ theme }) => theme.colors.main.primary500}!important;
    background-color: ${({ theme }) => theme.colors.main.grey300}!important;
  }
  position: relative;
`

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
`

const TextButtonTop = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin: 0 25px;
  cursor: pointer;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 12px;
`
const NotificationsWrapper = styled.div`
  max-height: 360px;
  overflow: auto;
`
const GroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  :empty {
    border: none;
  }
  :has(div:not(:empty)) {
    border-top: 1px solid ${({ theme }) => theme.colors.main.grey300};
    padding-top: 14px;
  }
`
const IconWrapper = styled.div``

const CounterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.main.primary500};
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 12px;
  font-weight: 700;
  display: ${({ hasNewNotification }) =>
    hasNewNotification ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`
const IconContainer = styled.div`
  position: absolute;
  top: -3px;
  left: 120px;
`
const NotificationWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
  position: relative;
`
const OverlayContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 25px;
  width: 420px;
  height: 70px;
  pointer-events: all;
  z-index: 1;
  cursor: pointer;
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const EmptyStateText = styled.div`
  color: ${({ theme }) => theme.colors.main.grey700};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
`
