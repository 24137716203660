import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'

import PageIds from 'modules/exam/utils/pageIds'

export const DifferenceBetweenOfficialAndPracticeMCAT = (
  props
): JSX.Element => {
  const { setCurrentPage, setNavigationHandlers } = props

  const handleNext = () => setCurrentPage(PageIds.confirmId)

  useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext
    }))
  }, [])

  return (
    <ExamContent>
      <DifferencesContainer>
        <h2>
          The difference between Examkrackers Practice Exams and the Actual MCAT
          Exam.
        </h2>
        <h3>
          Loading Icon
          <img
            style={{ display: 'inline', width: '36px' }}
            src='https://benchprep-public.s3.amazonaws.com/aamc/spinner_image.png'
          />
        </h3>
        <p>
          In the actual exam, there is a spinning loading icon that displays
          while content loads onto the screen, which is not present in the
          practice exams. Time is stopped while the loading icon is present and
          only begins to elapse once the content fully renders on the screen.
        </p>
        <h3>Horizontal Scroll</h3>
        <p>
          Horizontal scroll is a feature that does <strong>not</strong> often
          appear in the practice exams depending upon your screen size, but{' '}
          <strong>may</strong> occasionally appear in the actual exam.
        </p>
        <h3>Screen Resolution</h3>
        <p>
          Screen resolution and display size on practice exams will vary
          depending on your operating system, browser, and screen size. Screen
          resolution at testing centers is set at 1280 x 1024. There may be
          differences in monitor size across test centers.
        </p>
        <h3>Pause vs Exam Breaks</h3>
        <p>
          Practice exams allow you to pause at any time. You{' '}
          <strong>cannot</strong> pause during the actual exam. Taking breaks
          longer than the allotted time will result in lost exam time and/or
          loss of the option to void your exam.
        </p>
        <h3>Radio Buttons</h3>
        <p>
          The radio button to select an answer choice is black in practice
          exams, but may be blue or black in the actual exam.
        </p>
        <h3>Void</h3>
        <p>
          To simulate the actual exam, practice exams will ask you to void or
          score your exam. Regardless of your selection, your practice exam will
          be scored. On the actual exam, if you select VOID, your exam will{' '}
          <strong>NOT</strong> be scored.
        </p>
        <h3>Solutions</h3>
        <p>
          In practice exams, you can review the solutions to items after
          completion of the entire practice exam. In the actual exam, you will
          not be able to review any solutions.
        </p>
      </DifferencesContainer>
    </ExamContent>
  )
}

export default DifferenceBetweenOfficialAndPracticeMCAT

const DifferencesContainer = styled.div`
  p {
    margin-top: 0;
  }
`
