import React, { useEffect, useState } from 'react'
import { ExamBreakCountDown, ExamBreakQuestion } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import {
  always,
  identity,
  ifElse,
  isNil,
  last,
  pipe,
  split,
  propOr,
  pathOr
} from 'ramda'
import styled from 'styled-components'

import {
  getExamDetails,
  getSectionDetailsById
} from 'modules/exam/ducks/selectors'
import { getStudent } from 'modules/auth/ducks/selectors'

import { secondsToTime } from 'modules/exam/utils/time'

import AbandonBreakHandler from 'modules/exam/components/ExamElements/AbandonBreakHandler'

import { RootReducer } from 'types'

export const SectionBreak = (props): JSX.Element => {
  const [confirmedBreak, setConfirmedBreak] = useState(false)
  const {
    setCurrentPage,
    currentPage,
    currentPageConfig,
    setNavigationHandlers
  } = props
  const sectionId: string = pipe(
    split('section-break-'),
    last,
    ifElse(isNil, always(''), identity)
  )(currentPage)

  const examDetails = useSelector(getExamDetails)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )
  const user = useSelector(getStudent)

  const sectionOrder: number = propOr(1, 'order', sectionDetails)

  const defaultBreakSeconds = {
    1: 600,
    2: 1800,
    3: 600
  }

  const breakSeconds = pathOr(
    defaultBreakSeconds[sectionOrder],
    ['break_definition', `section_${sectionOrder}`],
    examDetails
  )
  const breakMinutes = breakSeconds / 60

  const [timerState, setTimerState] = useState({
    timeRemaining: secondsToTime(breakSeconds),
    secondsLeft: breakSeconds
  })

  const countDown = () => {
    setTimerState({
      // @ts-ignore
      timeRemaining: secondsToTime(timerState.secondsLeft - 1),
      // @ts-ignore
      secondsLeft: timerState.secondsLeft - 1
    })
  }

  const handleNext = () => setCurrentPage(currentPageConfig.nextPageId)

  const handleConfirmBreak = () => setConfirmedBreak(true)

  useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: undefined,
      beginBreakButtonOnClick: !confirmedBreak ? handleConfirmBreak : undefined,
      endBreakButtonOnClick: confirmedBreak ? handleNext : undefined,
      continueExamButtonOnClick: !confirmedBreak ? handleNext : undefined
    }))

    return () => {
      setNavigationHandlers(prevState => ({
        ...prevState,
        beginBreakButtonOnClick: undefined,
        endBreakButtonOnClick: undefined,
        continueExamButtonOnClick: undefined
      }))
    }
  }, [confirmedBreak])

  useEffect(() => {
    let timer

    if (confirmedBreak) {
      timer = setInterval(countDown, 1000)
    }

    if (confirmedBreak && timerState.secondsLeft === 0) {
      clearInterval(timer)
      handleNext()
    }

    return () => {
      clearInterval(timer)
    }
  }, [timerState, currentPage, confirmedBreak])

  const timeRemaining = `${timerState.timeRemaining.h}:${timerState.timeRemaining.m}:${timerState.timeRemaining.s}`

  const breakOrder = {
    1: 'first',
    2: 'second',
    3: 'third'
  }

  return (
    <StyledContent id='section-break-content'>
      {confirmedBreak ? (
        <ExamBreakCountDown
          handleResume={handleNext}
          userName={propOr('', 'name', user)}
          examTitle={pathOr('', ['exam', 'title'], examDetails)}
          timeRemaining={timeRemaining}
        />
      ) : (
        <ExamBreakQuestion
          handleConfirm={handleConfirmBreak}
          handleCancel={handleNext}
          breakTime={breakMinutes}
          breakOrder={breakOrder[sectionOrder]}
        />
      )}
      <AbandonBreakHandler currentPageConfig={currentPageConfig} />
    </StyledContent>
  )
}

export default SectionBreak

const StyledContent = styled.div`
  & > div {
    height: auto;
  }
`
