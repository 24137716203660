import {
  BooksContainedIcon,
  HamburgerMenuIcon,
  ProfileIcon,
  Tooltip
} from 'examkrackers-components'
import { getHasCourses } from 'modules/auth/ducks/selectors'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import PATHS from 'utils/paths'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { isNotNilOrEmpty } from 'utils/ramda'
import { handleToggleMobileMenuRoutine } from 'ducks/layout/actions'
import { getIsMobileMenuOpen } from 'ducks/layout/selectors'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import LearningTimer from 'modules/learningTime/components/LearningTimer'

const SingleNavLink = ({
  label,
  icon,
  url,
  onClick,
  disabled,
  disabledTooltip
}: {
  label: string
  icon: React.ReactNode
  url: string
  onClick?: () => void
  disabled?: boolean
  disabledTooltip?: string
}): JSX.Element => {
  const { push } = useHistory()
  const { path } = useRouteMatch()
  const isMenuOpen = useSelector(getIsMobileMenuOpen)
  const dispatch = useDispatch()
  const isActiveBooks = url === PATHS.books && path.includes(PATHS.books)
  const isActive = url === path || isActiveBooks
  const isActiveAndMenuIsNotOpen = isActive && !isMenuOpen
  const isMenuOpenAndIsActive = isMenuOpen && !url

  const handleToggleMenu = useCallback(
    () => dispatch(handleToggleMobileMenuRoutine()),
    [dispatch]
  )

  const handleClick = () => {
    if (isMenuOpen && url) {
      handleToggleMenu()
    }
    if (onClick) {
      onClick()
    } else {
      push(url)
    }
  }

  return disabled ? (
    <NavLinkDisabled>
      <Tooltip tooltipContent={disabledTooltip || ''} id='disabled-tooltip'>
        <NavLink disabled isActive={false}>
          <div>{icon}</div>
          <div>{label}</div>
        </NavLink>
      </Tooltip>
    </NavLinkDisabled>
  ) : (
    <NavLink
      isActive={isActiveAndMenuIsNotOpen || isMenuOpenAndIsActive}
      onClick={handleClick}
    >
      <div>{icon}</div>
      <div>{label}</div>
    </NavLink>
  )
}
const AuthNavMobile = (): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const hasCourses = useSelector(getHasCourses)
  const dispatch = useDispatch()

  const courseIdContext = LocalStorageService.get(
    LOCAL_STORAGE_KEYS.studentCourseId
  )
  const hasCourseContext = isNotNilOrEmpty(courseIdContext)

  const handleMenuClick = useCallback(
    () => dispatch(handleToggleMobileMenuRoutine()),
    [dispatch]
  )

  const handleBooksClick = useCallback(() => {
    eventEmitter.emit(events.mobileBooksTabClick)
    push(PATHS.books)
  }, [dispatch])

  const SelectCourseLink = {
    label: t('navigation.courses'),
    icon: <ProfileIcon width='18px' height='18px' />,
    url: PATHS.selectCourse
  }

  const MenuLink = {
    label: t('navigation.menu'),
    icon: <HamburgerMenuIcon width='18px' height='18px' />,
    onClick: handleMenuClick
  }

  const BooksLink = {
    label: t('navigation.books'),
    icon: <BooksContainedIcon width='18px' height='18px' />,
    onClick: handleBooksClick,
    url: PATHS.books
  }

  const renderedLinks = [
    hasCourses ? (
      <SingleNavLink key='select-course' {...SelectCourseLink} />
    ) : null,
    hasCourses && BooksLink ? (
      <SingleNavLink
        disabledTooltip={t('navigation.selectCourseTooltip')}
        disabled={!hasCourseContext}
        key='books'
        {...BooksLink}
      />
    ) : null,
    <SingleNavLink key='menu' {...MenuLink} />
  ]

  return (
    <>
      <NavContainer>
        {hasCourseContext && (
          <TimerContainer>
            <LearningTimer />
          </TimerContainer>
        )}
        <LinksWrapper>{renderedLinks}</LinksWrapper>
      </NavContainer>
    </>
  )
}

const TimerContainer = styled.div`
  position: fixed;
  bottom: 0;
`

const LinksWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgrounds.app};
  height: ${({ theme }) => theme.dimensions.mobileBottomNavHeight};
  min-height: ${({ theme }) => theme.dimensions.mobileBottomNavHeight};
  border-top: ${({ theme }) => `1px solid ${theme.colors.main.grey350}`};
  z-index: ${({ theme }) => theme.zIndex.menu};

  & > * {
    flex: 1;
  }
`

const NavContainer = styled.nav`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgrounds.app};
  height: ${({ theme }) => theme.dimensions.mobileBottomNavHeight};
  min-height: ${({ theme }) => theme.dimensions.mobileBottomNavHeight};
`

const NavLinkDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const NavLink = styled.div<{ isActive: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
  color: ${({ isActive, theme, disabled }) => {
    switch (true) {
      case disabled:
        return theme.colors.main.grey350
      case isActive:
        return theme.colors.main.primary550
      default:
        return theme.colors.main.text
    }
  }};
  transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

export default AuthNavMobile
