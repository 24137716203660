import React, { useCallback, useEffect, useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'

import { WysiwygEditorField } from 'examkrackers-components'
import { useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'
import { upsertNoteForSubchapterRoutine } from 'modules/books/ducks/actions'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'
import NotesLoadingState from '../../books/components/NotesLoadingState'
import { propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { MixedVideo, Notes } from 'types/videos'
import { Nullable } from 'types'
import { fetchNotesForSubchapter } from 'services/BooksService'

interface VideoSubchapterNoteProps {
  video: MixedVideo
}

const notesFormats = {
  size: true,
  header: true,
  bold: true,
  italic: true,
  underline: true,
  orderedList: true,
  bulletList: true,
  align: true,
  scriptSuper: true,
  scriptSub: true,
  blockquote: true,
  formula: true,
  clean: true
}

export const VideoSubchapterNote: React.FC<VideoSubchapterNoteProps> = ({
  video
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const student_subchapter_id = R.pathOr('', ['student_subchapter_id'], video)
  const [notes, setNotes] = useState<Nullable<Notes>>(null)

  const sectionId: string = R.propOr('', 'student_subchapter_id', video)

  const handleSaveNote = useCallback(
    payload => dispatch(upsertNoteForSubchapterRoutine(payload)),
    [dispatch]
  )

  const handleFetchNotes = () => {
    const handleSuccess = response => setNotes(response.data[0] || null)
    const handleError = e => console.error(e)

    fetchNotesForSubchapter({ sectionId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleSuccess = () => {
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, false), 2000)
  }

  const handleError = () => {
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, false), 2000)
  }

  const handleOnChange = (_name: string, value: any) => {
    eventEmitter.emit(events.notesLoading, true)

    const delta_object = value.getContents()
    const raw = value.getText()

    const payload = {
      values: {
        delta_object,
        raw,
        sectionId: student_subchapter_id
      },
      callback: handleSuccess,
      onFailure: handleError
    }

    handleSaveNote(payload)
  }

  const debounceHandler = debounce(handleOnChange, 900)

  const deltaObject = JSON.parse(propOr(null, 'delta_object', notes)) as string

  useEffect(() => {
    handleFetchNotes()
  }, [])

  return student_subchapter_id ? (
    <EditorContainer>
      <NotesLoadingState />
      <WysiwygEditorField
        key='note-editor'
        id='create-subchapter-note'
        t={t}
        name='delta_object'
        onChange={debounceHandler}
        validate={() => true}
        initialValue={deltaObject}
        formats={notesFormats}
      />
    </EditorContainer>
  ) : null
}

const EditorContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.main.grey300};
  border-radius: 6px;
  min-height: 100vh;

  .text-editor-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #create-subchapter-note {
    flex-grow: 1;
  }
`
