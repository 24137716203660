import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, select, takeEvery } from 'utils/saga'
import { ActionPayload, PaginationProps } from 'types'
import * as R from 'ramda'
import qs from 'qs'

import {
  selectListOfTopics,
  selectListOfTopicsPagination,
  selectListOfTopicsState
} from 'modules/courseTopics/ducks/selectors'
import { SORT_DIRECTION } from 'utils/table'
import { API_STATES } from '../../../utils/redux'
import * as courseTopicsService from 'services/CourseTopicsService'
import { IBookContentCourseTopic } from '../../../types/courseTopics'

export const loadPrevPageRoutine = createRoutine('LOAD_PREV_PAGE')

export const resetSearchCourseTopicsListRoutine = createRoutine(
  'RESET_SEARCH_COURSE_TOPICS_LIST'
)
export const searchCourseTopicsListRoutine = createRoutine(
  'SEARCH_COURSE_TOPICS_LIST'
)
export const resetCourseTopicsListRoutine = createRoutine(
  'RESET_COURSE_TOPICS_LIST'
)
export const fetchCourseTopicsRoutine = createRoutine('FETCH_COURSE_TOPICS')
export const fetchContentTopicsRoutine = createRoutine('FETCH_CONTENT_TOPICS')
export const confirmCourseTopicRoutine = createRoutine(
  'CONFIRM_COURSE_TOPIC_ROUTINE'
)
export const uncheckCourseTopicRoutine = createRoutine(
  'UNCHECK_COURSE_TOPIC_ROUTINE'
)
export const markTopicCheckboxRoutine = createRoutine(
  'MARK_TOPIC_CHECKBOX_ROUTINE'
)
export const unmarkTopicCheckboxRoutine = createRoutine(
  'UNMARK_TOPIC_CHECKBOX_ROUTINE'
)

function* fetchCourseTopics() {
  const pagination: PaginationProps = yield select(selectListOfTopicsPagination)
  const reducerState = yield select(selectListOfTopicsState)

  const initialQuery = {
    order: { by: 'course_topics.order', dir: R.toLower(SORT_DIRECTION.asc) },
    limit: { take: 10, page: 1 }
  }

  const query = R.propEq('recordsTotal', 0)(pagination)
    ? qs.stringify(initialQuery)
    : qs.stringify({
        ...initialQuery,
        // @ts-ignore
        limit: { take: 10, page: Number(R.propOr(0, 'page', pagination)) + 1 }
      })

  const hasMorePages = Number(pagination.page) < Number(pagination.pagesTotal)
  const isInitialFetch =
    Number(pagination.page) === 1 && reducerState === API_STATES.PRISTINE

  if (hasMorePages || isInitialFetch) {
    yield put(fetchCourseTopicsRoutine.request())
    try {
      const { data } = yield call(courseTopicsService.getCourseTopics, {
        query
      })
      yield put(fetchCourseTopicsRoutine.success(data))
    } catch (e) {
      yield put(fetchCourseTopicsRoutine.failure(e))
    }
  }
}

function* resetCourseTopicsList() {
  yield put(resetCourseTopicsListRoutine.request())
  try {
    yield put(resetCourseTopicsListRoutine.success())
  } catch (e) {
    yield put(resetCourseTopicsListRoutine.failure(e))
  }
}

function* fetchContentTopics({ payload }) {
  const { contentId } = payload
  yield put(fetchContentTopicsRoutine.request())
  try {
    const { data } = yield call(courseTopicsService.getContentTopics, {
      contentId
    })
    yield put(fetchContentTopicsRoutine.success(data))
  } catch (e) {
    yield put(fetchContentTopicsRoutine.failure(e))
  }
}

function* loadPrevPage() {
  const listOfTopics = yield select(selectListOfTopics)
  const firstTopic = R.head(listOfTopics)

  const topicOrder = Number(R.propOr(1, 'order', firstTopic))
  const pageByOrder = Math.ceil(topicOrder / 10)

  const query = qs.stringify({
    order: { by: 'order', dir: R.toLower(SORT_DIRECTION.asc) },
    limit: { take: 10, page: pageByOrder - 1 }
  })

  yield put(loadPrevPageRoutine.request())
  try {
    const { data } = yield call(courseTopicsService.getCourseTopics, {
      query
    })
    yield put(loadPrevPageRoutine.success({ data }))
  } catch (e) {
    yield put(loadPrevPageRoutine.failure(e))
  }
}

function* searchCourseTopicsList({ payload }) {
  // @ts-ignore
  const { searchedTopic } = payload

  const topicOrder = Number(R.propOr(1, 'order', searchedTopic))
  const pageByOrder = Math.ceil(topicOrder / 10)

  const query = qs.stringify({
    order: { by: 'order', dir: R.toLower(SORT_DIRECTION.asc) },
    limit: { take: 10, page: pageByOrder }
  })

  yield put(searchCourseTopicsListRoutine.request())
  try {
    const { data } = yield call(courseTopicsService.getCourseTopics, {
      query
    })
    yield put(searchCourseTopicsListRoutine.success({ searchedTopic, data }))
  } catch (e) {
    yield put(searchCourseTopicsListRoutine.failure(e))
  }
}

function* resetSearchCourseTopicsList() {
  yield put(resetSearchCourseTopicsListRoutine.request())
  try {
    yield put(resetSearchCourseTopicsListRoutine.success())
  } catch (e) {
    yield put(resetSearchCourseTopicsListRoutine.failure(e))
  }
}

function* confirmCourseTopic({ payload }: ActionPayload) {
  const updatedTopic = {
    ...payload,
    bookContentCourseTopics: R.pipe(
      R.propOr([], 'bookContentCourseTopics'),
      R.map((t: IBookContentCourseTopic) => ({ ...t, is_read: true }))
    )(payload),
    is_mastered: true
  }

  yield put(confirmCourseTopicRoutine.request())
  try {
    yield call(courseTopicsService.checkCourseTopic, {
      topic_id: R.propOr('', 'id', payload)
    })
    yield put(confirmCourseTopicRoutine.success({ data: updatedTopic }))
    yield call(courseTopicsService.checkCourseTopic, {
      topic_id: R.propOr('', 'id', payload)
    })
  } catch (e) {
    yield put(confirmCourseTopicRoutine.failure(e))
  }
}

function* uncheckCourseTopic({ payload }: ActionPayload) {
  const updatedTopic = {
    ...payload,
    bookContentCourseTopics: R.pipe(
      R.propOr([], 'bookContentCourseTopics'),
      R.map((t: IBookContentCourseTopic) => ({ ...t, is_read: false }))
    )(payload),
    is_mastered: false
  }

  yield put(uncheckCourseTopicRoutine.request())
  try {
    yield put(uncheckCourseTopicRoutine.success({ data: updatedTopic }))
    yield call(courseTopicsService.uncheckCourseTopic, {
      topic_id: R.propOr('', 'id', payload)
    })
    yield put(uncheckCourseTopicRoutine.success({ data: updatedTopic }))
  } catch (e) {
    yield put(uncheckCourseTopicRoutine.failure(e))
  }
}

function* markTopicCheckbox({ payload }: { payload: IBookContentCourseTopic }) {
  const updatedTopic: IBookContentCourseTopic = {
    ...payload,
    is_read: true
  }

  yield put(markTopicCheckboxRoutine.request())
  try {
    yield put(markTopicCheckboxRoutine.success({ data: updatedTopic }))
    yield call(courseTopicsService.markTopicCheckbox, {
      student_content_topic_id: R.propOr('', 'id', payload)
    })
    yield put(markTopicCheckboxRoutine.success({ data: updatedTopic }))
  } catch (e) {
    yield put(markTopicCheckboxRoutine.failure(e))
  }
}

function* unmarkTopicCheckbox({
  payload
}: {
  payload: IBookContentCourseTopic
}) {
  const updatedTopic: IBookContentCourseTopic = {
    ...payload,
    is_read: false
  }

  yield put(unmarkTopicCheckboxRoutine.request())
  try {
    yield put(unmarkTopicCheckboxRoutine.success({ data: updatedTopic }))
    yield call(courseTopicsService.unmarkTopicCheckbox, {
      student_content_topic_id: R.propOr('', 'id', payload)
    })
  } catch (e) {
    yield put(unmarkTopicCheckboxRoutine.failure(e))
  }
}

export function* fetchCourseTopicsWatcher() {
  yield takeEvery(fetchCourseTopicsRoutine.TRIGGER, fetchCourseTopics)
}

export function* fetchContentTopicsWatcher() {
  yield takeEvery(fetchContentTopicsRoutine.TRIGGER, fetchContentTopics)
}

export function* resetCourseTopicsListWatcher() {
  yield takeLatest(resetCourseTopicsListRoutine.TRIGGER, resetCourseTopicsList)
}

export function* searchCourseTopicsListWatcher() {
  yield takeLatest(
    searchCourseTopicsListRoutine.TRIGGER,
    searchCourseTopicsList
  )
}

export function* resetSearchCourseTopicsListWatcher() {
  yield takeLatest(
    resetSearchCourseTopicsListRoutine.TRIGGER,
    resetSearchCourseTopicsList
  )
}

export function* loadPrevPageWatcher() {
  yield takeLatest(loadPrevPageRoutine.TRIGGER, loadPrevPage)
}

export function* confirmCourseTopicWatcher() {
  yield takeEvery(confirmCourseTopicRoutine.TRIGGER, confirmCourseTopic)
}

export function* uncheckCourseTopicWatcher() {
  yield takeEvery(uncheckCourseTopicRoutine.TRIGGER, uncheckCourseTopic)
}

export function* markTopicCheckboxWatcher() {
  yield takeEvery(markTopicCheckboxRoutine.TRIGGER, markTopicCheckbox)
}

export function* unmarkTopicCheckboxWatcher() {
  yield takeEvery(unmarkTopicCheckboxRoutine.TRIGGER, unmarkTopicCheckbox)
}

export const courseTopicsSagas = [
  fork(fetchCourseTopicsWatcher),
  fork(fetchContentTopicsWatcher),
  fork(searchCourseTopicsListWatcher),
  fork(resetSearchCourseTopicsListWatcher),
  fork(loadPrevPageWatcher),
  fork(resetCourseTopicsListWatcher),
  fork(confirmCourseTopicWatcher),
  fork(uncheckCourseTopicWatcher),
  fork(markTopicCheckboxWatcher),
  fork(unmarkTopicCheckboxWatcher)
]
