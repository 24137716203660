import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import * as R from 'ramda'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { Input, SelectSwitcher } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { getVideosPagination } from '../ducks/selectors'

export const VideoMainFilters = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const pagination = useSelector(getVideosPagination)
  const searchValue = R.pathOr('', ['filter', 'search'], parsedQuery)
  const isWatchedValue = R.pathOr(
    'null',
    ['filter', '__is_watched'],
    parsedQuery
  )

  const recordsTotal = R.propOr(0, 'recordsTotal', pagination)

  const handleSearch = value => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          search: value
        }
      },
      { addQueryPrefix: true }
    )

    replace(`${pathname}${newQuery}`)
  }

  const debounceHandler = debounce(
    (e: any) => handleSearch(e.target.value),
    300
  )

  const handleStatusChange = value => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    const filterWithoutWatchedStatus = {
      // @ts-ignore
      ...R.dissoc('__is_watched', filterQuery)
    }

    const filterWithWatchedStatus = {
      // @ts-ignore
      ...filterQuery,
      __is_watched: value === 'true'
    }

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter:
          value === 'null'
            ? filterWithoutWatchedStatus
            : filterWithWatchedStatus
      },
      { addQueryPrefix: true }
    )

    replace(`${pathname}${newQuery}`)
  }

  return (
    <Container>
      <LeftContainer>
        <RecordsTotalWrapper>
          {t('videos.filters.results')}: {recordsTotal}
        </RecordsTotalWrapper>
        <SearchInputContainer>
          <Input
            size='small'
            type='search'
            value={searchValue}
            onChange={debounceHandler}
            placeholder={t('videos.filters.search')}
          />
        </SearchInputContainer>
      </LeftContainer>
      <ToggleWrapper>
        <SelectSwitcher
          id='select-videos-status'
          value={isWatchedValue}
          width='170px'
          onChange={handleStatusChange}
          size='large'
          options={[
            {
              label: t('videos.filters.toWatch'),
              value: 'false'
            },
            {
              label: t('videos.filters.watched'),
              value: 'true'
            },
            {
              label: t('videos.filters.all'),
              value: 'null'
            }
          ]}
        />
      </ToggleWrapper>
    </Container>
  )
}

export default VideoMainFilters

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SearchInputContainer = styled.div`
  & > div {
    width: 330px;
  }
`

const ToggleWrapper = styled.div`
  #select-videos-status {
    width: 200px;
  }
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const RecordsTotalWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`
