import React, { useState } from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'

import { getFileContent } from 'services/FilesService'
import { getFileExtensionFromUrl } from 'utils/file'
import { Animation } from 'examkrackers-components'

export const Image = ({ url }) => {
  const [jsonAnimationData, setJsonAnimationData] = useState(null)

  const extension = getFileExtensionFromUrl(url)

  const retreiveJSON = async () => {
    const response = await getFileContent(url)
    setJsonAnimationData(response.data)
  }

  React.useEffect(() => {
    setJsonAnimationData(null)

    if (extension === 'json') {
      setTimeout(retreiveJSON, 500)
    }
  }, [url])

  const renderFile = () => {
    if (isNil(extension)) {
      return null
    } else if (extension === 'json') {
      return (
        <Animation
          // @ts-ignore
          className='image-animation-container'
          data={jsonAnimationData}
        />
      )
      // previously I was using svg display objects to be able to
      // handle svg file animations. However, object tags behave
      // in an uncontrolled way on browsers (they do not display
      // the correct dimensions of images),
      // which is why I turned off this option.
      //
      // } else if (extension === 'svg') {
      //   return <object className='image-svg-container' data={url} />
    } else {
      return (
        <img
          className='image-img-container'
          src={url}
          alt='image'
          loading='lazy'
          decoding='async'
        />
      )
    }
  }

  return (
    <ImageContainer className='image-container'>{renderFile()}</ImageContainer>
  )
}

export default Image

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  object {
    max-width: 100%;
  }
`
