import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { isNilOrEmpty } from 'utils/ramda'
import { getOptionByValue } from 'utils/form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { fetchAllBooksRoutine } from 'modules/books/ducks/actions'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { Chapter } from 'types'
import * as R from 'ramda'
import { SingleSelect } from 'examkrackers-components'

export const VideoDetailsBookFilters = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const dispatch = useDispatch()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const books = useSelector(selectAllBooks)

  const selectedBookId = R.pathOr('', ['filter', 'b.id'], parsedQuery)
  const selectedBookChapterId = R.pathOr('', ['filter', 'bcc.id'], parsedQuery)

  // @ts-ignore
  const chapters: Chapter[] = R.pipe(
    R.find(R.propEq('id', selectedBookId)),
    R.propOr([], 'chapters')
  )(books)

  const sortByOrder = R.sortBy(R.prop('order'))
  const sortedBookChapters = sortByOrder(chapters)

  const chapterOptions = sortedBookChapters.map((chapter, index) => ({
    label: `${index + 1}. ${R.propOr('', 'title', chapter)}`,
    value: R.propOr('', 'id', chapter)
  }))

  const fetchBooksWithChapters = useCallback(
    () => dispatch(fetchAllBooksRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchBooksWithChapters()
  }, [])

  const handleChapterChange = option => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    if (option) {
      const newQuery = {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          'bcc.id': option.value
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        filter: {
          ...R.omit(['bcc.id'], filterQuery)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  return (
    <SelectWrapper>
      <StyledSelect
        id='select-video-chapter'
        label={t('videos.filters.chapter')}
        placeholder={t('videos.filters.chapter')}
        size='small'
        // @ts-ignore
        options={chapterOptions}
        disabled={isNilOrEmpty(selectedBookId)}
        onChange={handleChapterChange}
        // @ts-ignore
        value={getOptionByValue(selectedBookChapterId)(chapterOptions)}
        removeMargin
      />
    </SelectWrapper>
  )
}

export default VideoDetailsBookFilters

const StyledSelect = styled(SingleSelect)`
  min-width: 196px;
  max-width: 196px;
  div {
    overflow-x: hidden;
  }
`

const SelectWrapper = styled.div``
