import { createRoutine } from 'redux-saga-routines'
import { call, put, takeLatest, fork } from 'utils/saga'
import * as authService from 'services/AuthService'
import * as SettingsService from 'services/SettingsService'
import { has } from 'ramda'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

// ROUTINES

export const studentLogoutRoutine = createRoutine('AUTH_LOGOUT')
export const studentFetchDetailsRoutine = createRoutine('AUTH_DETAILS')
export const updateBackgroundMusicSettingRoutine = createRoutine(
  'UPDATE_BACKGROUND_MUSIC_SETTING'
)
export const updateCQAnimationsSettingRoutine = createRoutine(
  'UPDATE_CQ_ANIMATIONS_SETTING'
)

export const updateDashboardThemeRoutine = createRoutine(
  'UPDATE_DASHBOARD_THEME'
)

export const updateGettingStartedHelperRoutine = createRoutine(
  'UPDATE_GETTING_STARTED'
)

// ACTIONS

function* studentLogout() {
  yield put(studentLogoutRoutine.request())
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)

  // @ts-ignor
  if (has('socketClient', window)) {
    // @ts-ignore
    window.socketClient.disconnect()
    // @ts-ignore
    window.socketClient.removeAllListeners()
    // @ts-ignore
    window.socketClient = null
  }

  yield put(studentLogoutRoutine.success())
}

function* studentFetchDetails() {
  studentFetchDetailsRoutine.request()
  try {
    const result = yield call(authService.fetchStudentDetails)
    yield put(studentFetchDetailsRoutine.success(result.data))
  } catch (e) {
    yield put(studentFetchDetailsRoutine.failure(e))
  }
}

function* updateBackgroundMusicSetting(action) {
  yield put(updateBackgroundMusicSettingRoutine.request())
  try {
    const result = yield call(SettingsService.setVideosBGMusic, action.payload)
    yield put(updateBackgroundMusicSettingRoutine.success(result.data))
  } catch (e) {
    yield put(updateBackgroundMusicSettingRoutine.failure(e))
  }
}

function* updateCQAnimationsSetting(action) {
  yield put(updateCQAnimationsSettingRoutine.request())
  try {
    const result = yield call(SettingsService.setCQAnimations, action.payload)
    yield put(updateCQAnimationsSettingRoutine.success(result.data))
  } catch (e) {
    yield put(updateCQAnimationsSettingRoutine.failure(e))
  }
}

function* updateDashboardTheme(action) {
  yield put(updateDashboardThemeRoutine.request())
  try {
    const result = yield call(SettingsService.setTheme, action.payload)
    yield put(updateDashboardThemeRoutine.success(result.data))
  } catch (e) {
    yield put(updateDashboardThemeRoutine.failure(e))
  }
}

function* updateGettingStartedHelper(action) {
  yield put(updateGettingStartedHelperRoutine.request())
  try {
    const result = yield call(
      SettingsService.toggleGettingStartedHelper,
      action.payload
    )
    yield put(updateGettingStartedHelperRoutine.success(result.data))
  } catch (e) {
    yield put(updateGettingStartedHelperRoutine.failure(e))
  }
}

// WATCHERS

export function* studentLogoutWatcher() {
  yield takeLatest(studentLogoutRoutine.TRIGGER, studentLogout)
}

export function* studentFetchDetailsWatcher() {
  yield takeLatest(studentFetchDetailsRoutine.TRIGGER, studentFetchDetails)
}

export function* updateBackgroundMusicSettingWatcher() {
  yield takeLatest(
    updateBackgroundMusicSettingRoutine.TRIGGER,
    updateBackgroundMusicSetting
  )
}

export function* updateDashboardThemeWatcher() {
  yield takeLatest(updateDashboardThemeRoutine.TRIGGER, updateDashboardTheme)
}

export function* updateCQAnimationsSettingWatcher() {
  yield takeLatest(
    updateCQAnimationsSettingRoutine.TRIGGER,
    updateCQAnimationsSetting
  )
}

export function* updateGettingStartedHelperWatcher() {
  yield takeLatest(
    updateGettingStartedHelperRoutine.TRIGGER,
    updateGettingStartedHelper
  )
}

// SAGAS

export const authSagas = [
  fork(studentFetchDetailsWatcher),
  fork(studentLogoutWatcher),
  fork(updateBackgroundMusicSettingWatcher),
  fork(updateCQAnimationsSettingWatcher),
  fork(updateDashboardThemeWatcher),
  fork(updateGettingStartedHelperWatcher)
]
