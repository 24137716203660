import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getExamDetails } from 'modules/exam/ducks/selectors'

import ExamScoreTabs from 'modules/diagnostics/components/ExamScoreTabs'
import { BackButton } from 'examkrackers-components'
import PATHS from 'utils/paths'
import {} from 'ramda'
import ScoreSheet from 'modules/diagnostics/components/ScoreSheet'
import { ExamDetails } from 'types'
import NonFLScoreSheet from 'modules/diagnostics/components/NonFLScoreScheet'
import RetakeExamButton from 'modules/exams/components/RetakeExamButton'

export const EXAM_DIAGRAM_COLOR_ACCENTS = {
  overall: '#FDC800',
  first_section: '#D1525E',
  second_section: '#F7755C',
  third_section: '#419354',
  fourth_section: '#7860BA'
}

export const ExamSectionDiagnostic = (): JSX.Element => {
  const params = useParams()
  const history = useHistory()
  const { push } = useHistory()
  const { t } = useTranslation()

  const sectionId: string = R.propOr('', 'sectionId')(params)

  const examDetails = useSelector(getExamDetails) as ExamDetails
  const questions = R.propOr([], 'questions')(examDetails)
  // @ts-ignore
  const sectionDetails = questions.find(question => question.id === sectionId)

  const examSections = examDetails?.exam?.scores?.sections || []
  const sectionTitle = sectionDetails?.title || ''
  const examName = examDetails?.exam?.title || ''
  const redirectToExamsList = () => history.push(PATHS.exams)
  const redirectToSectionAnswerSheet = (examId, sectionId) =>
    push(`/exam/${examId}/score-report/answers-graph/${sectionId}/answer-sheet`)

  const pageTitle =
    examSections.length > 2
      ? `${examName} ${sectionTitle ? `: ${sectionTitle}` : ''}`
      : `${examName}`

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.exam.scoreSheet')}</title>
      </Helmet>
      <Container>
        <ExamScoreTabs
          saltyImageUrl='/assets/illustrations/SaltyWooHoo.svg'
          tiles={[
            <div style={{ display: 'flex' }}>
              <ExamName>{pageTitle}</ExamName>
              <RetakeExamButton
                key='retake-exam-button'
                maxCompletionsNumber={examDetails.exam.max_completions}
                numberOfCompletions={examDetails.exam.completions_done}
                id={examDetails.exam.id}
              />
            </div>,
            <BackButton key='back-button' onClick={redirectToExamsList}>
              {t('diagnostics.answerSheet.backButton')}
            </BackButton>
          ]}
          data-testId='ExamScoreSheet'
        >
          {examSections.length === 1 ? (
            <NonFLScoreSheet>
              <TilesContainer>
                <h4>{t('diagnostics.scoreSheet.scaledScore')}</h4>
                <ExamScoreTile>
                  <ExamNameTile>{`${sectionTitle}`}</ExamNameTile>
                  <ExamNameTile>{examName}</ExamNameTile>
                  <ExamInfoTile
                    clasName='exam-score-tile'
                    key='exam-score-tile'
                  >
                    {examDetails?.exam?.scores?.exam?.scaled_score || 0}
                  </ExamInfoTile>
                  <a
                    onClick={() =>
                      redirectToSectionAnswerSheet(
                        examDetails.exam.id,
                        examSections[0].id
                      )
                    }
                  >
                    {t('diagnostics.scoreSheet.reviewAnswers')}
                  </a>
                </ExamScoreTile>
              </TilesContainer>
            </NonFLScoreSheet>
          ) : (
            <ScoreSheet>
              <TilesContainer>
                <h4>{t('diagnostics.scoreSheet.scaledScore')}</h4>
                <ExamScoreTile>
                  <ExamNameTile>{`${sectionTitle}`}</ExamNameTile>
                  <ExamNameTile>{examName}</ExamNameTile>
                  <ExamInfoTile
                    clasName='exam-score-tile'
                    key='exam-score-tile'
                  >
                    {examDetails?.exam?.scores?.exam?.scaled_score || 0}
                  </ExamInfoTile>
                </ExamScoreTile>
                {examDetails?.exam?.scores?.sections?.map((section, index) => {
                  return (
                    <SectionScoreTile
                      className='section-score-tile'
                      key={section.id}
                    >
                      <span>{section.title}</span>
                      <StyledInfoTile>
                        <span>{section.scaled_score}</span>
                      </StyledInfoTile>
                      <a
                        onClick={() =>
                          redirectToSectionAnswerSheet(
                            examDetails.exam.id,
                            section.id
                          )
                        }
                      >
                        {t('diagnostics.scoreSheet.reviewAnswers')}
                      </a>
                    </SectionScoreTile>
                  )
                })}
              </TilesContainer>
            </ScoreSheet>
          )}
        </ExamScoreTabs>
      </Container>
    </React.Fragment>
  )
}

export default ExamSectionDiagnostic

const Container = styled.div`
  position: relative;
`

const ExamName = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 16px;
`

const ExamNameTile = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18.4px;
  padding: 0 2px;
`

const ExamInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 6px 0 4px;
  font-size: 24px;
  font-weight: bold;
  height: 37px;
  border: 1px solid #fdc800 !important;
  background-color: #fff4d7 !important;
`

const ExamScoreTile = styled.div`
  a {
    text-decoration: underline;
    cursor: pointer !important;
    font-size: 12px;
  }
`

const SectionScoreTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 18.4px;
    text-transform: uppercase;
  }

  a {
    text-decoration: underline;
    cursor: pointer !important;
    font-size: 12px;
  }
`

const StyledInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffeedf;
  /* margin: 0 8px; */
  border-radius: 4px;
  padding: 6px 0 4px;
  font-size: 24px;
  font-weight: bold;
  /* line-height: 27.6px; */
  height: 37px;

  span {
    color: ${({ theme }) => theme.colors.main.primary600};
    font-weight: 700;
    font-size: 24px;
  }
`

const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 16px;
  /* gap: 16px; */
`
