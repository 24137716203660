// module/exams/ducks/selectors.ts - Exam Saga Selectors

import * as R from 'ramda'
import * as Reselect from 'reselect'
import { API_STATES } from 'utils/redux'

//
// Selectors
//
export const selectExams = (state: any) => state.exams

// Get if the students state is currently loading
export const getIsExamsLoading = Reselect.createSelector(
  selectExams,
  R.pipe(R.propOr([], 'state'), R.equals(API_STATES.IN_PROGRESS))
)

export const getIsExamsLoaded = Reselect.createSelector(
  selectExams,
  R.pipe(R.propOr([], 'state'), R.equals(API_STATES.DONE))
)

export const getExamsPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectExams,
  R.propOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    'pagination'
  )
)

export const getExamsList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectExams, R.propOr([], 'exams'))

export const getExamLogsPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectExams,
  R.propOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    'logsPagination'
  )
)

export const getExamLogsList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectExams, R.propOr([], 'logs'))

export const getExamsAvailableInFreeTrial: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectExams, R.propOr([], 'examsAvailableInFreeTrial'))
