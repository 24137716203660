import React, { useCallback, useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useDispatch, useSelector } from 'react-redux'
import { getVideosPagination } from 'modules/videos/ducks/selectors'
import {
  fetchVideosListWithInfiniteScrollRoutine,
  resetVideosListRoutine
} from 'modules/videos/ducks/actions'
import { useHistory } from 'react-router-dom'
import usePrevious from '../../../hooks/usePrevious'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import eventEmitter from 'providers/eventEmitter'
import events from '../utils/events'
import qs from 'qs'
import { pathOr } from 'ramda'
import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { VIDEO_CATEGORIES } from '../utils/categories'

export const VideoListInfiniteScroller = (): JSX.Element => {
  const containerRef = useRef(null)
  const { inViewport } = useInViewport(containerRef, { rootMargin: '100px' })
  const pagination = useSelector(getVideosPagination)
  const currentCourse = useSelector(getCurrentCourse)

  const hasCourseFetched = isNotNilOrEmpty(currentCourse.id)

  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()
  const prevSearch = usePrevious(search)
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedCategory = pathOr(
    'all',
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )

  const fetchRecords = useCallback(
    query => dispatch(fetchVideosListWithInfiniteScrollRoutine({ query })),
    [dispatch, search]
  )

  const resetRecords = useCallback(
    () => dispatch(resetVideosListRoutine()),
    [dispatch]
  )

  useEffect(() => {
    resetRecords()
  }, [selectedCategory])

  const fetchIfFavoriteChanged = () => {
    if (selectedCategory === VIDEO_CATEGORIES.favourites) {
      resetRecords()
      fetchRecords(search)
    }
  }

  useEffect(() => {
    if (prevSearch !== search) {
      resetRecords()
    }

    eventEmitter.on(events.favoriteToggle, fetchIfFavoriteChanged)

    return () => {
      eventEmitter.off(events.favoriteToggle, fetchIfFavoriteChanged)
    }
  }, [search])

  useEffect(() => {
    if (inViewport && hasCourseFetched) {
      fetchRecords(search)
    }
  }, [inViewport, pagination, search, currentCourse])

  return (
    <div
      id='video-list-infinite-scroller'
      style={{ width: '1px', height: '1px', display: 'block' }}
      ref={containerRef}
    />
  )
}

export default VideoListInfiniteScroller
