import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialFlagForReview = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialFlagForReview)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )
  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialSectionReview)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialPeriodicTable)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialFlagForReview
    })
  }, [])

  return (
    <ExamContent>
      <TutorialFlagForReviewContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>Use the Flag for Review Feature</h2>
            <h3>Description</h3>
            <p>
              If you are unsure of the answer option you selected and want to
              revisit it later, assuming there is time remaining on the clock,
              you can mark the question with a flag icon using the{' '}
              <strong>
                <span className='underline'>F</span>lag for Review
              </strong>{' '}
              feature.
            </p>
            <p>
              Your score is determined by the number of questions you answer
              correctly. There is no penalty for incorrect answers. Therefore,
              it is to your advantage to answer every question even if you are
              guessing.
            </p>
            <h3>Instructions</h3>
            <p>
              <strong>
                <span className='underline'>F</span>lag for Review
              </strong>{' '}
              at the top right of the screen, or the Alt+F keyboard shortcut
              will mark a question for review.
            </p>
            <h3>Practice</h3>
            <ul className='numeric'>
              <li>
                Select{' '}
                <strong>
                  <span className='underline'>F</span>lag for Review
                </strong>{' '}
                at the top right of the screen so that the flag icon turns
                yellow.
              </li>
              <li>
                If you wish to unmark a flagged question, select{' '}
                <strong>
                  <span className='underline'>F</span>lag for Review
                </strong>{' '}
                or Alt+F again. The yellow flag will disappear.
              </li>
            </ul>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </div>
      </TutorialFlagForReviewContainer>
    </ExamContent>
  )
}

export default TutorialFlagForReview

const TutorialFlagForReviewContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`
