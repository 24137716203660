import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchCoursesListRoutine,
  removeCurrentCourseRoutine
} from 'modules/courses/ducks/actions'
import { getCoursesList } from 'modules/courses/ducks/selectors'
import { Course } from 'types'

import { removeCourseStorageKeys } from '../../../utils/courses'
import CourseRow from './CourseRow.mobile'
import styled from 'styled-components'

export const TableCourses = (): JSX.Element => {
  const dispatch = useDispatch()
  const courses: Course[] = useSelector(getCoursesList)

  const fetchCourses = React.useCallback(
    () => dispatch(fetchCoursesListRoutine()),
    [dispatch]
  )
  const removeCurrentCourse = React.useCallback(
    () => dispatch(removeCurrentCourseRoutine()),
    [dispatch]
  )

  React.useEffect(() => {
    fetchCourses()
    removeCourseStorageKeys()
    removeCurrentCourse()
  }, [])

  const renderedCourses = courses.map(course => (
    <CourseRow key={course.id} course={course} />
  ))

  return <Container>{renderedCourses}</Container>
}

export default TableCourses

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
