import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import * as booksService from 'services/BooksService'
import { ActionPayload } from 'types'

// ROUTINES
export const fetchTotalPartDataRoutine = createRoutine('FETCH_TOTAL_PART_DATA')
export const fetchTotalPartDataStageRoutine = createRoutine(
  'FETCH_TOTAL_PART_DATA_STAGE'
)
export const refreshBookFragmentDataRoutine = createRoutine(
  'REFRESH_BOOK_FRAGMENT'
)
export const fetchAllBooksRoutine = createRoutine('FETCH_ALL_BOOKS')
export const resetBookDataRoutine = createRoutine('RESET_BOOK_DATA')
export const fetchNotesForSubchapterRoutine = createRoutine(
  'FETCH_NOTES_FOR_SUBCHAPTER'
)
export const upsertNoteForSubchapterRoutine = createRoutine(
  'UPSERT_NOTE_FOR_SUBCHAPTER'
)
export const fetchChapterSectionsWithNotesRoutine = createRoutine(
  'FETCH_CHAPTER_SECTIONS_WITH_NOTES'
)
export const fetchChaptersWithExamsRoutine = createRoutine(
  'FETCH_CHAPTERS_WITH_EXAMS'
)

export const saveScrollAnchorPositionRoutine = createRoutine(
  'SAVE_SCROLL_ANCHOR_POSITION'
)

export const saveBookContentPositionsRoutine = createRoutine(
  'SAVE_BOOK_CONTENT_POSITION'
)
export const updateFlashcardManuallyRoutine = createRoutine(
  'UPDATE_FLASHCARD_MANUALLY'
)

export const updateContentQuestionManuallyRoutine = createRoutine(
  'UPDATE_CONTENT_QUESTION_MANUALLY'
)

export const fetchBookContentsPartialRoutine = createRoutine(
  'FETCH_BOOK_CONTENTS_PARTIAL'
)

export const upsertBookContentHighlightsRoutine = createRoutine(
  'UPSERT_BOOK_CONTENT_HIGHLIGHTS'
)

export const upsertBookContentAttachmentHighlightsRoutine = createRoutine(
  'UPSERT_BOOK_CONTENT_ATTACHMENT_HIGHLIGHTS'
)

// ACTIONS
function* saveScrollAnchorPosition({ payload }: ActionPayload) {
  yield Effects.put(saveScrollAnchorPositionRoutine.success(payload))
}

function* saveBookContentPosition({ payload }: ActionPayload) {
  yield Effects.put(saveBookContentPositionsRoutine.success(payload))
}

function* resetBookData() {
  yield Effects.put(resetBookDataRoutine.success())
}

function* fetchTotalPartData({ payload }: ActionPayload) {
  yield Effects.put(fetchTotalPartDataRoutine.request())
  const { callback = () => {} } = payload

  try {
    const result = yield Effects.call(
      booksService.fetchBookPartDetails,
      R.dissoc('callback', payload)
    )

    const data = R.pathOr({}, ['data'], result)

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'book',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'original_book',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'chapters',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'subchapters',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'book_contents',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'content_images',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'chapter_images',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'original_content_questions',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'attachments',
        data
      })
    )

    yield Effects.delay(1000)

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'flashcards',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'resources',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'topics',
        data
      })
    )

    yield Effects.put(
      fetchTotalPartDataStageRoutine({
        fragmentName: 'book_content_comments',
        data
      })
    )

    callback()
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchTotalPartDataRoutine.failure(e))
    callback()
  }
}

function* updateFlashcardManually({ payload }: ActionPayload) {
  yield Effects.put(updateFlashcardManuallyRoutine.success(payload))
}

function* updateContentQuestionManually({ payload }: ActionPayload) {
  yield Effects.put(updateContentQuestionManuallyRoutine.success(payload))
}

function* refreshBookFragmentData({
  payload
}: {
  payload: {
    fragmentName:
      | 'original_book'
      | 'book'
      | 'chapters'
      | 'subchapters'
      | 'book_contents'
      | 'resources'
      | 'attachments'
      | 'content_questions'
      | 'original_content_questions'
      | 'content_images'
      | 'flashcards'
      | 'topics'
      | 'book_content_comments'
    callback?: () => any
    params: {
      bookId: string
      chapterOrder: string | number
      partOrder: string | number
    }
  }
}) {
  yield Effects.put(refreshBookFragmentDataRoutine.request())
  const { callback = () => {}, fragmentName, params } = payload

  try {
    const result = yield Effects.call(booksService.fetchBookPartDetails, params)
    yield Effects.put(
      refreshBookFragmentDataRoutine.success({
        data: R.pathOr([], ['data'], result),
        fragmentName
      })
    )
    callback()
  } catch (e) {
    console.error(e)
    yield Effects.put(refreshBookFragmentDataRoutine.failure(e))
    callback()
  }
}

function* fetchTotalPartDataStage({
  payload
}: {
  payload: {
    fragmentName:
      | 'original_book'
      | 'book'
      | 'chapters'
      | 'subchapters'
      | 'book_contents'
      | 'resources'
      | 'attachments'
      | 'content_questions'
      | 'original_content_questions'
      | 'content_images'
      | 'flashcards'
      | 'topics'
      | 'book_content_comments'
    callback?: () => any
    data
  }
}) {
  yield Effects.put(fetchTotalPartDataStageRoutine.request())
  const { callback = () => {}, fragmentName, data } = payload

  try {
    yield Effects.put(
      fetchTotalPartDataStageRoutine.success({ data, fragmentName })
    )
    callback()
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchTotalPartDataStageRoutine.failure(e))
    callback()
  }
}

function* fetchAllBooks() {
  yield Effects.put(fetchAllBooksRoutine.request())

  try {
    const result = yield Effects.call(booksService.fetchBooks)
    yield Effects.put(
      fetchAllBooksRoutine.success(R.pathOr({}, ['data'], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchAllBooksRoutine.failure(e))
  }
}

function* fetchNotesForSubchapter({ payload }: ActionPayload) {
  yield Effects.put(fetchNotesForSubchapterRoutine.request())
  try {
    const result = yield Effects.call(
      booksService.fetchNotesForSubchapter,
      payload
    )
    yield Effects.put(
      fetchNotesForSubchapterRoutine.success(R.pathOr({}, ['data', 0], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchNotesForSubchapterRoutine.failure(e))
  }
}

function* upsertNoteForSubchapter({
  payload: { values = {}, callback = () => {}, onFailure = () => {} }
}) {
  yield Effects.put(upsertNoteForSubchapterRoutine.request())
  try {
    const result = yield Effects.call(
      booksService.upsertNoteForSubchapter,
      values
    )
    yield Effects.put(
      upsertNoteForSubchapterRoutine.success(R.pathOr({}, ['data'], result))
    )
    callback()
  } catch (e) {
    console.error(e)
    yield Effects.put(upsertNoteForSubchapterRoutine.failure(e))
    onFailure()
  }
}

function* fetchChapterSectionsWithNotes({ payload }: ActionPayload) {
  yield Effects.put(fetchChapterSectionsWithNotesRoutine.request())
  try {
    const result = yield Effects.call(
      booksService.fetchChapterSectionsWithNotes,
      payload
    )
    yield Effects.put(
      fetchChapterSectionsWithNotesRoutine.success(
        R.pathOr({}, ['data'], result)
      )
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchChapterSectionsWithNotesRoutine.failure(e))
  }
}

function* fetchChaptersWithExams({ payload }: ActionPayload) {
  yield Effects.put(fetchChaptersWithExamsRoutine.request())
  try {
    const result = yield Effects.call(
      booksService.fetchBookChaptersWithExams,
      payload
    )
    yield Effects.put(
      fetchChaptersWithExamsRoutine.success(R.propOr([], 'data', result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchChaptersWithExamsRoutine.failure(e))
  }
}

function* fetchBookContentsPartial({ payload }: ActionPayload) {
  yield Effects.put(fetchBookContentsPartialRoutine.request())

  try {
    const result = yield Effects.call(
      booksService.fetchBookContentsPartial,
      payload
    )

    const data = R.pathOr([], ['data'], result)
    yield Effects.put(fetchBookContentsPartialRoutine.success(data))
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchBookContentsPartialRoutine.failure(e))
  }
}

function* upsertBookContentHighlights({ payload }: ActionPayload) {
  yield Effects.put(upsertBookContentHighlightsRoutine.request(payload))

  try {
    const result = yield Effects.call(
      booksService.upsertBookContentHighlightsAPICall,
      payload
    )

    yield Effects.put(upsertBookContentHighlightsRoutine.success(result))
  } catch (e) {
    console.error(e)
    yield Effects.put(upsertBookContentHighlightsRoutine.failure(e))
  }
}

function* upsertBookContentAttachmentHighlights({ payload }: ActionPayload) {
  yield Effects.put(
    upsertBookContentAttachmentHighlightsRoutine.request(payload)
  )

  try {
    const result = yield Effects.call(
      booksService.upsertBookContentAttachmentHighlights,
      payload
    )

    yield Effects.put(
      upsertBookContentAttachmentHighlightsRoutine.success(result)
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(
      upsertBookContentAttachmentHighlightsRoutine.failure(e)
    )
  }
}

// WATCHERS

// watcher FETCH_TOTAL_PART_DATA
export function* fetchTotalPartDataWatcher() {
  yield Effects.takeLatest(
    fetchTotalPartDataRoutine.TRIGGER,
    fetchTotalPartData
  )
}

export function* refreshBookFragmentDataWatcher() {
  yield Effects.takeLatest(
    refreshBookFragmentDataRoutine.TRIGGER,
    refreshBookFragmentData
  )
}

// watcher RESET_BOOK_DATA
export function* resetBookDataWatcher() {
  yield Effects.takeLatest(resetBookDataRoutine.TRIGGER, resetBookData)
}

// watcher FETCH_ALL_BOOKS
export function* fetchAllBooksWatcher() {
  yield Effects.takeLatest(fetchAllBooksRoutine.TRIGGER, fetchAllBooks)
}

export function* fetchNotesForSubchapterWatcher() {
  yield Effects.takeLatest(
    fetchNotesForSubchapterRoutine.TRIGGER,
    fetchNotesForSubchapter
  )
}

export function* upsertNoteForSubchapterWatcher() {
  yield Effects.takeLatest(
    upsertNoteForSubchapterRoutine.TRIGGER,
    upsertNoteForSubchapter
  )
}

export function* fetchChapterSectionsWithNotesWatcher() {
  yield Effects.takeLatest(
    fetchChapterSectionsWithNotesRoutine.TRIGGER,
    fetchChapterSectionsWithNotes
  )
}

export function* fetchChaptersWithExamsWatcher() {
  yield Effects.takeLatest(
    fetchChaptersWithExamsRoutine.TRIGGER,
    fetchChaptersWithExams
  )
}

export function* saveScrollAnchorPositionWatcher() {
  yield Effects.takeLatest(
    saveScrollAnchorPositionRoutine.TRIGGER,
    saveScrollAnchorPosition
  )
}

export function* saveBookContentPositionWatcher() {
  yield Effects.takeLatest(
    saveBookContentPositionsRoutine.TRIGGER,
    saveBookContentPosition
  )
}

export function* updateFlashcardManuallyWatcher() {
  yield Effects.takeEvery(
    updateFlashcardManuallyRoutine,
    updateFlashcardManually
  )
}

export function* updateContentQuestionManuallyWatcher() {
  yield Effects.takeLatest(
    updateContentQuestionManuallyRoutine,
    updateContentQuestionManually
  )
}

export function* fetchTotalPartDataStageWatcher() {
  yield Effects.takeLatest(
    fetchTotalPartDataStageRoutine,
    fetchTotalPartDataStage
  )
}

export function* fetchBookContentsPartialWatcher() {
  yield Effects.takeLatest(
    fetchBookContentsPartialRoutine.TRIGGER,
    fetchBookContentsPartial
  )
}

export function* upsertBookContentHighlightsWatcher() {
  yield Effects.takeEvery(
    upsertBookContentHighlightsRoutine.TRIGGER,
    upsertBookContentHighlights
  )
}

export function* upsertBookContentAttachmentHighlightsWatcher() {
  yield Effects.takeEvery(
    upsertBookContentAttachmentHighlightsRoutine.TRIGGER,
    upsertBookContentAttachmentHighlights
  )
}

// SAGAS
export const booksSagas = [
  Effects.fork(fetchAllBooksWatcher),
  Effects.fork(resetBookDataWatcher),
  Effects.fork(fetchTotalPartDataWatcher),
  Effects.fork(refreshBookFragmentDataWatcher),
  Effects.fork(fetchNotesForSubchapterWatcher),
  Effects.fork(upsertNoteForSubchapterWatcher),
  Effects.fork(fetchChapterSectionsWithNotesWatcher),
  Effects.fork(fetchChaptersWithExamsWatcher),
  Effects.fork(saveScrollAnchorPositionWatcher),
  Effects.fork(saveBookContentPositionWatcher),
  Effects.fork(updateFlashcardManuallyWatcher),
  Effects.fork(updateContentQuestionManuallyWatcher),
  Effects.fork(fetchTotalPartDataStageWatcher),
  Effects.fork(fetchBookContentsPartialWatcher),
  Effects.fork(upsertBookContentHighlightsWatcher),
  Effects.fork(upsertBookContentAttachmentHighlightsWatcher)
]
