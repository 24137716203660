import * as R from 'ramda'
import { stitchArraysByProp } from 'utils/array'

export const BOOK_API_RESPONSE_TO_REDUCER_MAP = {
  book: 'books',
  original_book: 'originalBooks',
  attachments: 'attachments',
  book_contents: 'bookContents',
  chapters: 'chapters',
  chapter_images: 'bookChapterImages',
  content_images: 'bookContentImages',
  original_content_questions: 'contentQuestions',
  flashcards: 'contentFlashcards',
  resources: 'resources',
  subchapters: 'subchapters',
  topics: 'topics',
  book_content_comments: 'bookContentComments'
}

/**
 * This function was created as a result of restructuring the student_content_questions table.
 * Now, the main source of information about question content and order comes from the book_content_questions table,
 * while information about answer correctness needs to be taken from student_content_questions.
 * Therefore, we need to merge these two responses together to maintain the existing application functionality.
 */
export const mergeTwoContentQuestionsResponse = (action: any): any[] => {
  const studentBookContentQuestions = R.pathOr(
    [],
    ['payload', 'data', 'content_questions'],
    action
  )
  const bookContentQuestions = R.pathOr(
    [],
    ['payload', 'data', 'original_content_questions'],
    action
  )

  const studentBookContentQuestionsWithRenamedIdKey =
    studentBookContentQuestions.map((question: Record<string, any>) => ({
      ...question,
      question_id: question.original_content_question_id
    }))

  return stitchArraysByProp(
    'question_id',
    bookContentQuestions,
    studentBookContentQuestionsWithRenamedIdKey
  )
}

export const getBookFragnentListByFragmentName = (action: any) => {
  const fragmentName: string = R.pathOr('', ['payload', 'fragmentName'], action)
  const payloadList = R.pathOr([], ['payload', 'data', fragmentName], action)

  if (fragmentName === 'original_content_questions') {
    return mergeTwoContentQuestionsResponse(action)
  }

  if (fragmentName === 'book') {
    return [payloadList]
  }

  return payloadList
}
