import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ListFlashcards from 'modules/flashcards/components/ListFlashcards'
import ButtonRedirectToStudyView from 'modules/flashcards/components/ButtonRedirectToStudyView'

export const Flashcards = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.flashcards.main')}</title>
      </Helmet>
      <ButtonRedirectToStudyView />
      <ListFlashcards />
    </React.Fragment>
  )
}

export default Flashcards

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
