import React, { useState, useRef, useEffect, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Draggable } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'
import DisplayEventModal from 'modules/calendar//DisplayEventModal'
import { useSelector } from 'react-redux'
import {
  CheckmarkIcon,
  NoteIcon,
  PadlockLockedIcon
} from 'examkrackers-components'
import { formatMinutesToHoursAndMinutes, getUtcDate } from 'utils/date'
import {
  calendarEventTypeLabel,
  calendarEventType,
  calendarEventTypeLabelLong
} from 'utils/calendar'

import DisplayCustomEventModal from 'modules/calendar//DisplayCustomEventModal'
import { removeFLFromTitle, shortenTitle } from 'utils/string'
import PadlockBackground from 'assets/padlock.svg'
import DisplayFreeTrialLockedEventModal from 'modules/calendar/DisplayFreeTrialLockedEventModal'
import { getCalendarAamcEventsColors } from 'modules/calendar/ducks/selectors'
import { getEventColours } from 'modules/calendar/utils/eventColors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { CalendarContext } from 'hooks/CalendarContext'
import LabelIcon from 'assets/images/copy.svg'
import { addMinutes } from 'date-fns'
import eventEmitter from 'providers/eventEmitter'
import events from '../dashboard/utils/events'
import calendarEvents from 'modules/calendar/utils/events'

interface CalendarSingleEventProps {
  item: any
  index: number
  disabled?: boolean
  disabledInFreeTrial?: boolean
  shouldDisableFreeTrialDay?: boolean
  isDashboard?: boolean
}

interface EventColors {
  font_colour: string
  fill_colour_start: string
  fill_colour_stop: string
}

const CalendarSingleEvent: React.FC<CalendarSingleEventProps> = ({
  item,
  index,
  disabled,
  disabledInFreeTrial,
  shouldDisableFreeTrialDay,
  isDashboard = false
}) => {
  const { updateEventStatus, inactiveLiveClasses } = useContext(CalendarContext)

  const { event_colour, status, type } = item
  const [updateStatusEvent, setUpdateStatusEvent] = useState(status)
  const aamcEventsColors = useSelector(getCalendarAamcEventsColors)
  const containerRef = useRef<HTMLElement | null>(null)
  const [, setMenuVisible] = useState(false)
  const [open, setOpen] = useState(false)

  const isSkipped = updateStatusEvent === 'skipped'
  const isCompleted = updateStatusEvent === 'complete'
  const isArchived = updateStatusEvent === 'archived'
  const isCustomEndDateEvent = type === calendarEventType.customEndDateEvent

  useEffect(() => {
    setUpdateStatusEvent(status)
  }, [status])

  const endDateEventColors: EventColors | null = isCustomEndDateEvent
    ? (JSON.parse(event_colour) as EventColors)
    : null

  const customEventColor = getEventColours(aamcEventsColors, item)
  const colourFont = isNotNilOrEmpty(customEventColor)
    ? customEventColor.colour_font
    : 'inherit'
  const colourGradientStart = isNotNilOrEmpty(customEventColor)
    ? customEventColor.colour_gradient_start
    : 'inherit'
  const colourGradientEnd = isNotNilOrEmpty(customEventColor)
    ? customEventColor.colour_gradient_end
    : 'inherit'

  const isCustomEvent = type === calendarEventType.custom
  const isAamcEvent = type === calendarEventType.customEventType

  const isSkippedOrCompleted = isSkipped || isCompleted

  const isFullExamEvent =
    type === calendarEventType.fullLengthExam ||
    type === calendarEventType.customFullLengthExam
  const isFullExamReviewEvent =
    type === calendarEventType.fullLengthExamReview ||
    type === calendarEventType.customFullLengthExamReview
  const isCustomFullLengthExamReviewEvent =
    type === calendarEventType.customFullLengthExamReview

  const isChapterExamReviewEvent = type === calendarEventType.chapterExamReview
  const isOtherExamReview = type === calendarEventType.otherExamReview

  const isFullLengthExamEvent = isFullExamEvent || isFullExamReviewEvent
  const isSectionExamEvent =
    type === calendarEventType.sectionExam ||
    type === calendarEventType.customSectionExam
  const isSectionExamReviewEvent = type === calendarEventType.sectionExamReview
  const isCustomSectionExamReviewEvent =
    type === calendarEventType.customSectionExamReview
  const isSectionExamTypeEvent =
    isSectionExamEvent ||
    isSectionExamReviewEvent ||
    isCustomSectionExamReviewEvent
  const isReviewEvent =
    isFullExamReviewEvent ||
    isSectionExamReviewEvent ||
    isCustomSectionExamReviewEvent ||
    isChapterExamReviewEvent ||
    isOtherExamReview

  const isLiveClass =
    type === calendarEventType.liveClass ||
    type === calendarEventType.customLiveClass
  const isCustomLiveClass = type === calendarEventType.customLiveClass

  let isPastClassEvent = false

  if (isLiveClass) {
    const eventDate = getUtcDate(
      item.event_date.replace('00:00', item.class_time_end)
    )
    const eventDatePlus30Minutes = addMinutes(eventDate, 30)
    const currentDate = new Date()
    isPastClassEvent = eventDatePlus30Minutes < currentDate
  }

  const isOtherExam = type === calendarEventType.otherExam

  const hideMenu = () => {
    setMenuVisible(false)
  }

  const refPosition = (
    containerRef.current as HTMLElement | null
  )?.getBoundingClientRect()

  useEffect(() => {
    if (open) {
      eventEmitter.emit(calendarEvents.calendarEventModalOpened, item.id)
    }
  }, [open])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    updateEventStatus(item, updateStatusEvent)
  }, [updateStatusEvent])

  useEffect(() => {
    const handleCalendarEventStatusChange = (
      event: { eventId: string; status: string } | undefined
    ) => {
      if (event?.eventId === item.id) {
        setUpdateStatusEvent(event?.status)
      }
    }

    eventEmitter.on(
      events.calendarEventStatusChanged,
      handleCalendarEventStatusChange
    )

    return () => {
      eventEmitter.off(
        events.calendarEventStatusChanged,
        handleCalendarEventStatusChange
      )
    }
  }, [item.id])

  const customEventTypes = ['custom_event_type', 'custom', 'live_class']
  const draggableId = customEventTypes.includes(item.type)
    ? `${item.id}-custom`
    : item.id

  const examOrCustomeEventTitle = () => {
    switch (true) {
      case isCustomEvent ||
        isAamcEvent ||
        isCustomFullLengthExamReviewEvent ||
        isCustomSectionExamReviewEvent ||
        isOtherExam ||
        isOtherExamReview ||
        isCustomEndDateEvent:
        return item.title
      default:
        return shortenTitle(item.title)
    }
  }

  return (
    <Draggable
      data-testid='CalendarSingleEvent-Draggable'
      key={item.id}
      draggableId={draggableId}
      index={index}
      isDragDisabled={
        disabledInFreeTrial ||
        shouldDisableFreeTrialDay ||
        item.type === 'live_class' ||
        item.type === 'custom_live_class' ||
        isCustomEndDateEvent
      }
    >
      {(provided, snapshot) => {
        return (
          <Container
            data-testid='CalendarSingleEvent-Container'
            onClick={handleOpen}
            ref={containerRef}
            id={`event-container-${item.id}`}
            isArchived={isArchived}
          >
            {(isCustomEvent || isAamcEvent) && open
              ? createPortal(
                  <DisplayCustomEventModal
                    data-testid='CalendarSingleEvent-DisplayCustomEventModal'
                    open={open}
                    setOpen={setOpen}
                    setUpdateStatusEvent={setUpdateStatusEvent}
                    updateStatuxsEvent={updateStatusEvent}
                    hideMenu={hideMenu}
                    containerPosition={refPosition}
                    event={item}
                  />,
                  document.body
                )
              : (disabledInFreeTrial || shouldDisableFreeTrialDay) && open
              ? createPortal(
                  <DisplayFreeTrialLockedEventModal
                    data-testid='CalendarSingleEvent-DisplayFreeTrialLockedEventModal'
                    open={open}
                    setOpen={setOpen}
                    containerPosition={refPosition}
                  />,
                  document.body
                )
              : (!disabled || (!disabled && isCustomEndDateEvent)) &&
                open &&
                createPortal(
                  <DisplayEventModal
                    data-testid='CalendarSingleEvent-DisplayEventModal'
                    open={open}
                    isFullLengthExamEvent={isFullLengthExamEvent}
                    isSectionExamTypeEvent={isSectionExamTypeEvent}
                    setUpdateStatusEvent={setUpdateStatusEvent}
                    isReviewEvent={isReviewEvent}
                    handleClose={handleClose}
                    containerPosition={refPosition}
                    event={item}
                    hideMenu={hideMenu}
                    hasPadlock={disabledInFreeTrial || shouldDisableFreeTrialDay}
                  />,
                  document.body
                )}
            <ListItem
              data-testid='CalendarSingleEvent-ListItem'
              isLiveClass={isLiveClass}
              isSkippedOrCompleted={isSkippedOrCompleted}
              isPastClassEvent={isPastClassEvent}
              isOverwrittenClassEvent={inactiveLiveClasses.includes(item.id)}
              isFullLengthExamEvent={isFullLengthExamEvent}
              isSectionExamTypeEvent={isSectionExamTypeEvent}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              provided={provided.draggableProps.style}
              color={event_colour}
              isSkipped={isSkipped}
              isCustomEvent={isCustomEvent}
              isAamcEvent={isAamcEvent}
              isCustomEndDateEvent={isCustomEndDateEvent}
              endDateEventColors={endDateEventColors}
              colourFont={colourFont}
              colourGradientStart={colourGradientStart}
              colourGradientEnd={colourGradientEnd}
              isCustomFullLengthExamReviewEvent={
                isCustomFullLengthExamReviewEvent
              }
              isCustomSectionExamReviewEvent={isCustomSectionExamReviewEvent}
              isOtherExam={isOtherExam}
              isOtherExamReview={isOtherExamReview}
              disabled={disabled}
              isFreeTrial={disabledInFreeTrial || shouldDisableFreeTrialDay}
              isDashboard={isDashboard}
            >
              {(disabledInFreeTrial || shouldDisableFreeTrialDay) && (
                <PadlockWrapper
                  bg={PadlockBackground}
                  data-testid='CalendarSingleEvent-PadlockWrapper'
                >
                  <PadlockLockedIcon data-testid='CalendarSingleEvent-PadlockLockedIcon' />
                </PadlockWrapper>
              )}
              {isFullLengthExamEvent ||
              isSectionExamTypeEvent ||
              isOtherExam ||
              isOtherExamReview ||
              isCustomFullLengthExamReviewEvent ||
              isCustomSectionExamReviewEvent ? (
                <CardWrapper
                  data-testid='CalendarSingleEvent-CardWrapper'
                  isFullLengthExamEvent={isFullLengthExamEvent}
                  isOtherExamEvent={isOtherExamReview || isOtherExam}
                >
                  <EventTitleFull data-testid='CalendarSingleEvent-EventTitleFull'>
                    {!isFullExamEvent || isReviewEvent
                      ? removeFLFromTitle(item.title)
                      : item.title}
                  </EventTitleFull>
                  <EventTypeFullExam
                    disabled={disabled}
                    data-testid='CalendarSingleEvent-EventTypeFullExam'
                  >
                    {calendarEventTypeLabelLong(item.type, item.title)}
                  </EventTypeFullExam>
                </CardWrapper>
              ) : (
                <>
                  <EventName
                    data-testid='CalendarSingleEvent-EventName'
                    isLiveClass={isLiveClass}
                    isCustomEvent={isCustomEvent}
                    isCustomFullLengthExamReviewEvent={
                      isCustomFullLengthExamReviewEvent
                    }
                    isCustomSectionExamReviewEvent={
                      isCustomSectionExamReviewEvent
                    }
                    disabled={disabled}
                  >
                    {examOrCustomeEventTitle()}
                  </EventName>
                  <EventType
                    data-testid='CalendarSingleEvent-EventType'
                    color={event_colour}
                    disabled={disabled}
                    isLiveClass={isLiveClass}
                  >
                    <LabelWrapper>
                      {calendarEventTypeLabel(item.type)}
                      {isCustomLiveClass && (
                        <CustomLabelIcon
                          data-testid='ReorderCalendarForExpiration-ReorganizeSaltyImage'
                          id='reorganize-salty'
                          src={LabelIcon}
                          alt='Salty reorganize'
                        />
                      )}
                    </LabelWrapper>
                  </EventType>
                </>
              )}
              {!isSkippedOrCompleted ? (
                <EventDuration
                  data-testid='CalendarSingleEvent-EventDuration'
                  isFullLengthExamEvent={isFullLengthExamEvent}
                  isCustomSectionExamReviewEvent={
                    isCustomSectionExamReviewEvent
                  }
                  isOtherExamEvent={isOtherExam || isOtherExamReview}
                >
                  {(!isCustomEvent || !!isAamcEvent) &&
                    formatMinutesToHoursAndMinutes(item.duration)}
                </EventDuration>
              ) : null}
              {(isCustomEvent || isAamcEvent) && (
                <NoteIconWrapper data-testid='CalendarSingleEvent-NoteIconWrapper'>
                  <NoteIcon
                    data-testid='CalendarSingleEvent-NoteIcon'
                    width='12px'
                    height='12px'
                  />
                </NoteIconWrapper>
              )}
              {isSkipped && (
                <EventSkipped
                  data-testid='CalendarSingleEvent-EventSkipped'
                  isFLExamReview={isFullExamReviewEvent}
                  isOtherExamEvent={isOtherExam || isOtherExamReview}
                >
                  Skip
                </EventSkipped>
              )}
              {isCompleted && (
                <EventCompleted
                  data-testid='CalendarSingleEvent-EventCompleted'
                  isFLExamReview={isFullExamReviewEvent}
                  isOtherExamEvent={isOtherExam || isOtherExamReview}
                  color={event_colour}
                >
                  <CheckmarkIcon data-testid='CalendarSingleEvent-CheckmarkIcon' />
                </EventCompleted>
              )}
            </ListItem>
          </Container>
        )
      }}
    </Draggable>
  )
}

export default CalendarSingleEvent

const Container = styled.div<{
  isArchived: boolean
}>`
  display: block;
  width: ${({ isArchived }) => (isArchived ? '140px' : '100%')};
  position: relative;
`

const ListItem = styled.div<{
  provided: object
  isDragging: boolean
  size: string
  disabled: boolean
  isFreeTrial: boolean
  isLiveClass: boolean
  isDashboard?: boolean
  isPastClassEvent?: boolean
  isCustomEndDateEvent?: boolean
  endDateEventColors: EventColors
}>`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: move;
  height: ${({ size }) => size};
  max-width: ${({ isDashboard }) => (isDashboard ? '100%' : '140px')};
  text-align: left;
  align-items: baseline;
  z-index: 10000;
  opacity: ${({ isSkippedOrCompleted, isPastClassEvent }) =>
    isSkippedOrCompleted || isPastClassEvent ? 0.5 : 1};
  ${({
    provided,
    color,
    theme,
    isFullLengthExamEvent,
    isCustomFullLengthExamReviewEvent,
    isSectionExamTypeEvent,
    isCustomSectionExamReviewEvent,
    isCustomEvent,
    isAamcEvent,
    disabled,
    colourFont,
    colourGradientStart,
    colourGradientEnd,
    isOtherExam,
    isOtherExamReview,
    isLiveClass,
    isCustomEndDateEvent,
    endDateEventColors
  }) => {
    if (isFullLengthExamEvent || isOtherExam) {
      return {
        fontWeight: 'bold',
        padding: '8px 4px',
        height: '38px',
        flexDirection: 'row',
        border: `1px solid #D47B00`,
        background: disabled
          ? theme.colors.main.grey400
          : theme?.colors?.calendar.exam.orange.background,
        ...provided,
        margin: 0,
        color: '#D47B00'
      }
    } else if (isCustomFullLengthExamReviewEvent || isOtherExamReview) {
      return {
        fontWeight: 'bold',
        padding: '8px 4px',
        height: '38px',

        flexDirection: 'row',
        border: `1px solid #D47B00`,
        background: disabled
          ? theme.colors.main.grey400
          : theme?.colors?.calendar.exam.orange.background,
        ...provided,
        margin: 0,
        color: '#D47B00'
      }
    } else if (isCustomSectionExamReviewEvent) {
      return {
        fontWeight: 'bold',
        padding: '4px',
        height: '18px',

        flexDirection: 'row',
        border: `1px solid ${theme?.colors?.calendar.exam.orange.tag}`,
        background: disabled
          ? theme.colors.main.grey400
          : theme?.colors?.calendar.exam.orange.background,
        ...provided,
        margin: 0,
        color: '#D47B00'
      }
    } else if (isCustomEvent) {
      return {
        fontWeight: 'bold',
        padding: '3px 8px',

        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${theme?.colors?.calendar.custom.brown.border}`,

        ...provided,
        margin: 0,
        color: theme?.colors?.calendar.custom.brown.border
      }
    } else if (isAamcEvent) {
      return {
        fontWeight: 'bold',
        padding: '3px 8px',

        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${colourFont}`,

        ...provided,
        margin: 0,
        color: colourFont,
        background: `linear-gradient(180deg, ${colourGradientStart} 0%, ${colourGradientEnd} 100%)`
      }
    } else if (
      isSectionExamTypeEvent ||
      isCustomSectionExamReviewEvent ||
      isOtherExam
    ) {
      return {
        flexDirection: 'row',
        fontWeight: 'bold',
        padding: '4px',
        border: `1px solid #D47B00`,
        background: disabled
          ? theme.colors.main.grey400
          : theme?.colors?.calendar.exam.orange.background,
        color: `#D47B00`,
        height: '18px',
        ...provided,

        margin: 0
      }
    } else if (isCustomEndDateEvent) {
      return {
        fontWeight: 'bold',
        padding: '3px 8px',

        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${colourFont}`,

        ...provided,
        margin: 0,
        color: endDateEventColors.font_colour,
        background: `linear-gradient(180deg, ${endDateEventColors.fill_colour_start} 0%, ${endDateEventColors.fill_colour_stop} 100%)`
      }
    } else if (disabled) {
      return {
        flexDirection: 'row',
        fontWeight: 'bold',
        padding: '4px 8px',
        border: `1px solid ${theme?.colors?.main.grey700}`,
        background: theme?.colors?.main.grey300,
        color: isLiveClass ? theme.colors.main.white : theme.colors.main.black,
        ...provided,

        margin: 0
      }
    } else {
      return {
        fontWeight: 'normal',
        padding: '4px 8px',
        background: isLiveClass
          ? theme?.colors?.calendar[color]?.class
          : theme?.colors?.calendar[color]?.background,
        ...provided,

        margin: 0,
        color: isLiveClass
          ? theme.colors.main.white
          : theme?.colors?.calendar[color]?.tag || theme.colors.main.black
      }
    }
  }};
  ${({ isFreeTrial }) =>
    isFreeTrial &&
    css`
      cursor: pointer !important;
    `}
`

const EventName = styled.div<{
  isCustomEvent: boolean
  disabled: boolean
  isLiveClass: boolean
}>`
  /* max-width: ${({ isCustomEvent }) => (isCustomEvent ? '20px' : '100px')}; */
  color: ${({ theme, disabled, isLiveClass }) =>
    disabled
      ? theme.colors.main.grey600
      : isLiveClass
      ? theme.colors.main.white
      : 'inherit'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 10px;
`

const EventDuration = styled.div`
  ${({
    isFullLengthExamEvent,
    isOtherExamEvent,
    isCustomSectionExamReviewEvent
  }) => {
    if (
      isFullLengthExamEvent ||
      isOtherExamEvent ||
      isCustomSectionExamReviewEvent
    ) {
      return css`
        position: absolute;
        right: 6px;
        top: ${isCustomSectionExamReviewEvent ? '4px' : '7px'};
      `
    }
  }}

  font-size: 10px;
  font-weight: normal;
`
const EventSkipped = styled.div`
  ${({ isFLExamReview, isOtherExamEvent }) => {
    if (isFLExamReview || isOtherExamEvent) {
      return css`
        position: absolute;
        right: 8px;
        top: 6px;
      `
    }
  }}
  display: 'inline-block';
  padding: 1px 6px;
  background-color: ${({ theme }) =>
    theme?.colors?.calendar?.custom.brown.border};
  color: white;
  height: 12px;
  font-size: 10px;
  border-radius: 10px;
`
const EventType = styled.div<{ disabled: boolean; isLiveClass: boolean }>`
  font-size: 10px;
  white-space: nowrap;
  color: ${({ theme, color, disabled, isLiveClass }) =>
    disabled
      ? theme.colors.main.grey600
      : isLiveClass
      ? theme.colors.main.white
      : theme?.colors?.calendar[color]?.tag || theme.colors.main.grey600};
`

const EventTypeFullExam = styled.div`
  /* width: 100px; */
  font-size: 10px;
  color: #d47b00;
  white-space: nowrap;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isFullLengthExamEvent, isOtherExamEvent }) =>
    isFullLengthExamEvent || isOtherExamEvent ? 'column' : 'row'};
  gap: ${({ isFullLengthExamEvent }) => (isFullLengthExamEvent ? '0' : '4px')};
`

const EventTitleFull = styled.div`
  font-size: 10px;
  font-weight: bold;
`

const EventCompleted = styled.div`
  ${({ isFLExamReview, isOtherExamEvent }) => {
    if (isFLExamReview || isOtherExamEvent) {
      return css`
        position: absolute;
        right: 8px;
        top: 6px;
      `
    }
  }}
  color: ${({ theme, color }) =>
    theme?.colors?.calendar[color]?.tag || theme.colors.main.grey600};
  max-height: 12px;
  min-height: 12px;
  overflow: hidden;
`
const NoteIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const PadlockWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  background: ${({ bg }) => `transparent url("${bg}") no-repeat center/cover`};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45px;
  padding-right: 7px;
  z-index: 1;

  svg {
    fill: #6c6c6c;
  }
`
const CustomLabelIcon = styled.img`
  height: 8px;
  width: 8px;
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`
