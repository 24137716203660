export const EK_PURCHASE_PORTAL_PAGE = 'https://examkrackers.com/'

export type TNavLink = {
  label: string
  onClick?: () => void
  icon: React.JSX.Element
  url: string
}

export const findLinkByUrl = (
  links: TNavLink[],
  searchUrl: string
): NavLink | undefined => {
  return links.find(link => link.url === searchUrl)
}

export const findLinkByLabel = (
  links: TNavLink[],
  searchLabel: string
): TNavLink | undefined => {
  return links.find(link => link.label === searchLabel)
}

export const filterLinksByUrls = (
  links: TNavLink[],
  urlsToInclude: string[]
): NavLink[] => {
  return links.filter(link => urlsToInclude.includes(link.url))
}
