import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useNavLinks from 'hooks/useNavLinks'
import styled from 'styled-components'
import { ArrowDownIcon, BookMarkIcon } from 'examkrackers-components'
import * as R from 'ramda'

const StaticSideNav = () => {
  const { push } = useHistory()
  const location = useLocation()
  const { getCourseLinks, getExamLinks, hasCourse, onlyTestBundleBooks } =
    useNavLinks(push)

  const getLinks = () => {
    if (!hasCourse || onlyTestBundleBooks) {
      return getExamLinks()
    }
    return getCourseLinks()
  }

  const links = getLinks()

  const isExpanded = true
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set())
  const [cardHeight, setCardHeight] = useState(360)
  const cardWidth = 192
  const contentRef = useRef<HTMLDivElement>(null)
  const labelRefs = useRef<Map<string, HTMLSpanElement>>(new Map())
  const [activeParent, setActiveParent] = useState<string | null>(null)
  const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 0 })
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false)

  const sideNavRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      setCardHeight(Math.max(360, contentHeight + 20))
    }
  }, [expandedItems, isExpanded])

  const isLinkActive = (url: string) => location.pathname === url

  const toggleExpanded = (label: string) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev)
      if (newSet.has(label)) {
        newSet.delete(label)
      } else {
        newSet.add(label)
      }
      return newSet
    })
  }

  const handleItemClick = (fullLabel: string, event: React.MouseEvent) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect()
    const containerRect = (
      sideNavRef.current as HTMLElement
    ).getBoundingClientRect()

    setSubMenuPosition({
      top: rect.top - containerRect.top,
      left: rect.left - containerRect.left + rect.width
    })

    if (fullLabel === activeParent) {
      setIsSubMenuVisible(prev => !prev)
    } else {
      setActiveParent(fullLabel)
      setIsSubMenuVisible(true)
    }
  }

  const renderNavItems = (items: any[], level = 0, parentLabel = '') => {
    return items.map(link => {
      const fullLabel = parentLabel
        ? `${parentLabel}.${link.label}`
        : link.label
      const isItemExpanded = expandedItems.has(fullLabel)
      return (
        <React.Fragment key={fullLabel}>
          <NavItem
            isActive={isLinkActive(link.url)}
            isExpanded={isItemExpanded}
            level={level}
            onClick={event => {
              if (link.nextLevel) {
                if (level > 0) {
                  handleItemClick(fullLabel, event)
                } else {
                  toggleExpanded(fullLabel)
                }
              } else {
                push(link.url || '')
              }
            }}
            style={{ paddingLeft: `${6 + level * 5}px` }}
          >
            {level === 0 && <IconWrapper>{link.icon}</IconWrapper>}
            <Label
              ref={el => {
                if (el) labelRefs.current.set(fullLabel, el)
              }}
              style={{ opacity: isExpanded ? 1 : 0 }}
            >
              <span>{link.label}</span>
              {R.propOr(false, 'bookmark', link) && (
                <BookmarkLink
                  onClick={
                    R.propOr(false, 'isInactive', link)
                      ? () => {}
                      : link.bookmarkOnClick
                  }
                >
                  Go to
                  <BookMarkIcon />
                </BookmarkLink>
              )}
            </Label>
            {link.nextLevel && (
              <Caret
                isExpanded={isItemExpanded}
                style={{ opacity: isExpanded ? 1 : 0 }}
              >
                <ArrowDownIcon />
              </Caret>
            )}
          </NavItem>
          {isExpanded && isItemExpanded && link.nextLevel && level === 0 && (
            <div
              style={{
                backgroundColor: '#fff0e0',
                borderRadius: '8px',
                marginTop: '-10px',
                marginBottom: '10px'
              }}
            >
              {renderNavItems(link.nextLevel, level + 1, fullLabel)}
            </div>
          )}
        </React.Fragment>
      )
    })
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sideNavRef.current &&
        !sideNavRef.current.contains(event.target as Node)
      ) {
        // setIsExpanded(false)
        setExpandedItems(new Set())
        setActiveParent(null)
        setIsSubMenuVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <SideNavWrapper ref={sideNavRef}>
      <Card isExpanded={isExpanded} height={cardHeight} width={cardWidth}>
        <div
          ref={contentRef}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {renderNavItems(links)}
        </div>
      </Card>
      {isSubMenuVisible && activeParent && (
        <SubItemContainer
          style={{
            transform: `translate(${subMenuPosition.left}px, ${subMenuPosition.top}px)`
          }}
        >
          {renderNavItems(
            links
              .flatMap((link: any) => link.nextLevel || [])
              .find(subLink => subLink.label === activeParent.split('.').pop())
              ?.nextLevel || [],
            2,
            activeParent
          )}
        </SubItemContainer>
      )}
    </SideNavWrapper>
  )
}

export default StaticSideNav

const Card = styled.div<{ isExpanded: boolean; height: number; width: number }>`
  position: absolute;
  border-radius: 8px;
  padding: 16px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme }) => theme.colors.backgrounds.main} !important;
  width: ${({ width }) => `${width}px`};
  transition: height 0.3s ease;
  overflow: hidden;
  z-index: 1;
  width: 205px;
`

const SubItemContainer = styled.div`
  position: sticky;
  background: #fff0e0 !important;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  padding: 16px;
  z-index: 9999;
  max-height: 80vh;
  overflow-y: auto;
`

const NavItem = styled.div<{
  isActive: boolean
  isExpanded: boolean
  level: number
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  background-color: ${({ isActive, isExpanded }) =>
    isActive ? '#fff0e0' : isExpanded ? '#F8961A' : 'transparent'};
  color: ${({ isActive, isExpanded }) =>
    isActive || isExpanded ? (isExpanded ? '#ffffff' : '#c0752c') : 'inherit'};

  &:hover {
    background-color: ${({ isExpanded }) =>
      isExpanded ? '#F8961A' : '#fff0e0'};
    color: ${({ isExpanded }) => (isExpanded ? '#ffffff' : '#c0752c')};
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const IconWrapper = styled.div`
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s ease;

  ${NavItem}:hover & {
    color: ${({ isExpanded }) => (isExpanded ? '#ffffff' : '#c0752c')};
  }
`

const Label = styled.span`
  // margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s ease, color 0.2s ease;
  white-space: wrap;
  flex-grow: 1;
  display: flex;
  align-items: center;
  word-break: keep-all;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 10px;

  ${NavItem}:hover & {
    color: ${({ isExpanded }) => (isExpanded ? '#ffffff' : '#c0752c')};
  }
`

const Caret = styled.span<{ isExpanded: boolean }>`
  margin-left: 8px;
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0)'};
`

const SideNavWrapper = styled.div`
  position: absolute;
  flex: none;
`

const BookmarkLink = styled.div`
  text-decoration: underline;
  display: flex;
  align-items: center;
  margin-left: 6px;

  svg {
    margin-left: 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.main.error500} !important;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500} !important;

    svg {
      color: ${({ theme }) => theme.colors.main.error500} !important;
    }
  }
`
