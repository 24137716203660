export default {
  logoutUser: 'logoutUser',
  notFound: 'notFound',
  courseDeleted: 'courseDeleted',
  coursePaused: 'coursePaused',
  saltyBucksHistoryOpened: 'saltyBucksHistoryOpened',
  modalEditUsernameOpened: 'modalEditUsernameOpened',
  requestIntercepted: 'requestIntercepted',
  requestCompleted: 'requestCompleted',
  examPageLoading: 'examPageLoading',
  openMenu: 'openMenu',
  mobileBooksTabClick: 'mobileBooksTabClick'
}
