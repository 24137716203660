import React, { useEffect } from 'react'
import styled from 'styled-components'
import useIsMobile from 'hooks/useIsMobile'
import TopBarMobile from './TopBar.mobile'
import ModalNarrowScreen from 'modules/student/components/ModalNarrowScreen'

export const LayoutPublic = (props): JSX.Element => {
  const { children } = props

  const isMobile = useIsMobile()

  const LayoutMobile = (
    <MobileContainer>
      <MobileContent>
        <TopBarMobile />
        {children}
      </MobileContent>
    </MobileContainer>
  )

  const LayoutDesktop = (
    <>
      <ModalNarrowScreen />
      <LayoutContainer>
        <LogoContainer>
          <img
            src='/assets/logo/ExamsLogoLightBg.svg'
            alt='logo'
            className='layout-logo'
          />
        </LogoContainer>
        <LayoutBody>
          <div className='layout-illustration'>
            <img src='/assets/illustrations/SaltyToCollege.png' />
          </div>
          <div className='layout-content'>
            <div className='layout-content__inner'>{children}</div>
          </div>
        </LayoutBody>
      </LayoutContainer>
    </>
  )

  return isMobile ? LayoutMobile : LayoutDesktop
}

export default LayoutPublic

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.dimensions.studentTopNavHeight};
  line-height: ${({ theme }) => theme.dimensions.studentTopNavHeight};
  img {
    width: 200px;
  }
`

const LayoutBody = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-grow: 1;

  .layout-illustration {
    filter: blur(4px);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut}
      0ms;

    img {
      height: 100%;
      transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut}
        0ms;
    }
  }

  .layout-content {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 200ms ${({ theme }) => theme.transitions.easing.easeInOut}
      0ms;
  }

  .layout-content__inner {
    position: relative;
    min-width: 40%;
    max-width: 40%;
    max-height: 80%;
    min-height: 80%;
    padding: 6rem 4.78rem;
    background: ${props => props.theme.colors.backgrounds.main};
    border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
    box-shadow: ${props => props.theme.shadows.darkShadow};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const LayoutContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  padding: 0 20px 0;
  display: flex;
  flex-direction: column;

  .layout-heading {
    margin-top: 22px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .layout-logo {
    cursor: pointer;
  }
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
`

const MobileContent = styled.div`
  position: relative;
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 17.5px;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  height: calc(
    100dvh - ${({ theme }) => theme.dimensions.mobileBottomNavHeight}
  );

  h1 {
    font-family: Arial;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  svg {
    font-size: 22px;
  }
`
