import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { addDays, format, isSameDay } from 'date-fns'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { Tooltip } from 'examkrackers-components'
import CalendarDayEventsList from 'modules/calendar/CalendarDayEventsList'
import {
  isDateWithinInterval,
  getOnlyDate,
  removeUTCFromDate,
  noTimezoneDate
} from 'utils/date'
import { CalendarContext } from 'hooks/CalendarContext'
import styled, { css } from 'styled-components'

import { calendarDay } from 'modules/calendar/utils/calendarCell'
import { CourseMeta } from './CalendarRow'
import CalendarCellHeader from './CalendarCellHeader'
import * as R from 'ramda'
const CalendarCell = ({ day, index }) => {
  const { t } = useTranslation()
  const { calendar, lists, course, firstClassDate } =
    useContext(CalendarContext)

  const startAt = R.pipe(R.propOr('', 'start_at'), removeUTCFromDate)(calendar)
  const examAt = R.pipe(R.propOr('', 'exam_at'), removeUTCFromDate)(calendar)
  const mcatAt = R.pipe(R.propOr('', 'mcat_at'), removeUTCFromDate)(calendar)

  const accessibleFrom = R.pipe(R.propOr('', 'accessible_from'))(course)

  // @ts-ignore
  const startDate = noTimezoneDate(startAt || accessibleFrom)
  const finalDate = examAt
  const mcatDate = mcatAt

  const isFreeTrial = course.type === 'free_trial'

  const today = format(new Date(), 'yyyy-MM-dd')

  const cellFullDay = calendarDay(day)

  const isStartDate = cellFullDay === startAt

  const isFinalDate = isSameDay(
    noTimezoneDate(finalDate),
    noTimezoneDate(cellFullDay)
  )
  const isMcatDate = isSameDay(
    noTimezoneDate(mcatDate),
    noTimezoneDate(cellFullDay)
  )
  const isFinalDayAndHaveEvents =
    isFinalDate && isNotNilOrEmpty(lists[cellFullDay])

  const courseMeta: CourseMeta = JSON.parse(
    course?.metadata || '{}'
  ) as CourseMeta

  const firstClass = firstClassDate
    ? firstClassDate.match(getOnlyDate)?.[0]
    : null

  const isFirstClassBeforeStart: boolean = firstClass
    ? noTimezoneDate(firstClass as string) < startDate
    : false

  const courseEndDate = noTimezoneDate(
    removeUTCFromDate(course.accessible_to as string)
  )

  const isWeekCourse = Number(courseMeta?.days_amount) === 7
  const endDateByDaysAmount = addDays(
    startDate,
    Number(courseMeta?.days_amount || '7') - 1
  )

  const endDateByCourseType =
    isWeekCourse || isFreeTrial ? endDateByDaysAmount : courseEndDate

  const isWithin: boolean = isDateWithinInterval(noTimezoneDate(cellFullDay), {
    start: startDate,
    end: endDateByCourseType
  })

  const isToday = today === cellFullDay

  const generateTooltipContent = () => {
    if (isStartDate) {
      return 'Start Date'
    }
    if (isToday) {
      return "Today's Date"
    }
    if (isFinalDate) {
      return 'End date'
    }
    if (isMcatDate) {
      return 'MCAT Date'
    }
    return ''
  }

  const isEmptyMcatDay = isNilOrEmpty(lists[cellFullDay]) && isMcatDate

  const isNotIncludedInCourse = !isWithin && !isMcatDate

  const isDateDisabled: boolean = isNotIncludedInCourse

  const dayWrapperContent = (
    <DayWrapper
      data-testid={`CalendarRow-DayWrapper-${
        isToday ? 'todays-date' : 'day'
      }-${cellFullDay}`}
      id={`table-cell-${cellFullDay}`}
      isToday={isToday}
      isStartDate={isStartDate}
      isFinalDate={isFinalDate}
      isMcatDate={isMcatDate}
      isEmptyMcatDay={isEmptyMcatDay}
      className='day-wrapper'
    >
      <CalendarCellHeader
        day={day}
        isToday={isToday}
        isStartDate={isStartDate}
        isMcatDate={isMcatDate}
        isEmptyMcatDay={isEmptyMcatDay}
        isFreeTrial={isFreeTrial}
        startDate={startDate}
        cellFullDay={cellFullDay}
        courseMeta={courseMeta}
        isWithin={isWithin}
      />
      {isEmptyMcatDay && (
        <McatEvent data-testid='CalendarRow-McatEvent'>
          <h4>{t('calendar.mcatDay')}</h4>
          <p>{t('calendar.mcatDayContents')}</p>
        </McatEvent>
      )}
      {isWithin || isFreeTrial ? (
        <CalendarDayEventsList
          isFirstClassBeforeStart={isFirstClassBeforeStart}
          data-testid='CalendarRow-CalendarDayEventsList'
          currentlyChecked={cellFullDay}
          shouldDisplayEndDate={isFinalDate && !isFinalDayAndHaveEvents}
        />
      ) : (
        <div />
      )}
    </DayWrapper>
  )

  return (
    <TableCell
      data-testid='CalendarRow-TableCell'
      key={`main-table-cell-${cellFullDay.replace('00', `00${index}`)}`}
      isDisabled={isDateDisabled}
      isFinalDate={isFinalDate}
      isMcatDate={isMcatDate}
      isToday={isToday}
      isDayOff={false}
      isFinalDayAndHaveEvents={isFinalDayAndHaveEvents}
    >
      {isMcatDate || isFinalDate || isStartDate || isToday ? (
        <Tooltip
          data-testid='CalendarRow-Tooltip'
          id={`main-table-cell-${cellFullDay.replace('00', `00${index}`)}`}
          tooltipContent={generateTooltipContent()}
        >
          {dayWrapperContent}
        </Tooltip>
      ) : (
        dayWrapperContent
      )}
    </TableCell>
  )
}

export default CalendarCell

const TableCell = styled.td<{
  isDisabled: boolean
  isFinalDate: boolean
  isMcatDate: boolean
  isToday: boolean
  isDayOff: boolean
  isFinalDayAndHaveEvents: boolean
}>`
  text-align: center;
  height: 106px;
  width: 155px;
  max-width: 155px;
  position: relative;
  transition: all 0.3s;
  vertical-align: top;
  display: flex;

  background-color: ${({ isDisabled }) => (isDisabled ? '#f2f2f2' : '#fff')};

  &:has(.day-wrapper) {
    display: grid !important;
    & > div > div {
      display: flex;
    }
  }
`

const DayWrapper = styled.div<{
  isToday: boolean
  isStartDate: boolean
  isFinalDate: boolean
  isMcatDate: boolean
  isEmptyMcatDay: boolean
}>`
  border-radius: 6px;
  display: flex;
  height: 106px;
  position: relative;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;

  ${({ isToday }) =>
    isToday
      ? css`
          border: 1.5px solid #444;
        `
      : null}
  ${({ isStartDate }) =>
    isStartDate
      ? css`
          border: 1.5px solid #119b4c;
        `
      : null}

  ${({ isFinalDate }) =>
    isFinalDate
      ? css`
          border: 1.5px solid #e83e2e;
        `
      : null}
  ${({ isMcatDate }) =>
    isMcatDate
      ? css`
          border: ${({ theme }) =>
            `1.5px solid ${theme?.colors?.calendar.exam.orange.tag}`};
        `
      : null}
  ${({ isEmptyMcatDay }) =>
    isEmptyMcatDay
      ? css`
          background: ${({ theme }) => theme?.colors?.calendar.exam.orange.tag};
        `
      : null}
`

const McatEvent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  height: 106px;
  max-height: 106px;
  font-weight: bold;
  padding: 10px;
  border: ${({ theme }) =>
    `1px solid ${theme?.colors?.calendar.exam.orange.tag}`};
  background: ${({ theme }) => theme?.colors?.calendar.exam.orange.tag};
  margin: 0;
  color: ${({ theme }) => theme.colors.main.white};

  h4 {
    font-size: 14px;
    margin: 0;
  }

  p {
    font-size: 10px;
    font-weight: normal;
    padding-right: 10px;
  }
`
