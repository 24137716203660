import { useState, useEffect } from 'react'

const useIsMobile = (breakpoint: number = 900): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    Math.min(window.screen.width, window.innerWidth) < breakpoint
  )

  const handleResize = () => {
    // We use Math.min() to prevent differences between browser inspection mobile testing and real mobile devices
    // Mobile browsers often report different values for screen.width vs innerWidth
    const width = Math.min(window.screen.width, window.innerWidth)
    setIsMobile(width < breakpoint)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoint])

  return isMobile
}

export default useIsMobile
