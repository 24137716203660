import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Heading1, InfoCircleIcon, Tooltip } from 'examkrackers-components'
import AIEvaluationChapters from 'modules/AIChat/components/AIEvaluationChapters'
import AIEvaluationChatCollapse from 'modules/AIChat/components/AIEvaluationChatCollapse'

export const AIEvaluation = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.aiEvaluation')}</title>
      </Helmet>
      <br />
      <HeaderContainer>
        <div>
          <TitleContainer>
            <Heading1 bold>{t('aiEvaluation.title')}</Heading1>
            <TooltipContainer>
              <Tooltip
                place='bottom'
                tooltipContent={
                  <TooltipContentContainer>
                    <TooltipHeader>
                      {t('aiEvaluation.titleTooltip.header')}
                    </TooltipHeader>
                    <TooltipText>
                      {t('aiEvaluation.titleTooltip.description')}
                    </TooltipText>
                    <TooltipTextOl>
                      {t('aiEvaluation.titleTooltip.howItWorks.description')}
                    </TooltipTextOl>
                    <TooltipList>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point1')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point2')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point3')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point4')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point5')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point6')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point7')}
                      </TooltipListItem>
                      <TooltipListItem>
                        {t('aiEvaluation.titleTooltip.howItWorks.point8')}
                      </TooltipListItem>
                    </TooltipList>
                  </TooltipContentContainer>
                }
                id='title-tooltip'
              >
                <InfoCircleIcon />
              </Tooltip>
            </TooltipContainer>
          </TitleContainer>
          <PageDescription>{t('aiEvaluation.description')}</PageDescription>
        </div>
        <AIEvaluationChatCollapse />
      </HeaderContainer>
      <AIEvaluationChapters />
    </React.Fragment>
  )
}

export default AIEvaluation

const TooltipContainer = styled.div`
  #title-tooltip {
    min-width: 500px;
    max-width: 500px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  h1 {
    white-space: nowrap;
  }
`

const PageDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 8px;
`

const TooltipContentContainer = styled.div`
  padding: 5px 0;

  svg {
    font-size: 16px;
  }
`

const TooltipHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

const TooltipText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 16px 0;
`

const TooltipTextOl = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 8px;
`

const TooltipList = styled.ol`
  padding: 0 0 0 18px;
  margin: 0;
`

const TooltipListItem = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0 0 8px 0;
  margin-left: 0;
`
