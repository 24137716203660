import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import TableCourses from 'modules/courses/components/TableCourses'

import { Heading1 } from 'examkrackers-components'
import useIsMobile from 'hooks/useIsMobile'
import TableCoursesMobile from 'modules/courses/components/TableCourses.mobile'

export const SelectCourse = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const DesktopComponent = <TableCourses />
  const MobileComponent = <TableCoursesMobile />

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.selectCourse')}</title>
      </Helmet>
      <br />
      <Heading1 bold>{t('courses.title')}</Heading1>
      <br />
      {isMobile ? MobileComponent : DesktopComponent}
    </React.Fragment>
  )
}

export default SelectCourse
