import React, { useContext } from 'react'
import { format, isSameDay } from 'date-fns'
import { ClockIcon, Tooltip } from 'examkrackers-components'
import { formatMinutesToHoursAndMinutes, noTimezoneDate } from 'utils/date'
import { getMonthName } from 'utils/calendar'
import McatDaySalty from 'assets/images/Calendar_Salty_MCATDay.svg'
import { CalendarContext } from 'hooks/CalendarContext'
import styled, { css } from 'styled-components'

import {
  isHolidayInDate,
  getHolidayName
} from 'modules/calendar/utils/calendarCell'

const CalendarCellHeader = ({
  day,
  isToday,
  isStartDate,
  isMcatDate,
  isEmptyMcatDay,
  isFreeTrial,
  startDate,
  cellFullDay,
  courseMeta,
  isWithin
}) => {
  const { lists, firstVisibleSunday } = useContext(CalendarContext)

  const dayNumber = noTimezoneDate(day).getDate()
  const month = format(noTimezoneDate(day), 'MM')

  const isFirstVisibleSunday = isSameDay(
    noTimezoneDate(firstVisibleSunday),
    noTimezoneDate(cellFullDay)
  )

  const isHolidayToday = isHolidayInDate(cellFullDay)
  const holidayName = getHolidayName(cellFullDay)

  const dayEvents = lists[cellFullDay]

  const totalTime =
    dayEvents?.reduce((acc, { duration }) => {
      return acc + duration
    }, 0) || 0

  const isBeforeStartDate = noTimezoneDate(cellFullDay) < startDate

  const isNotIncludedInCourse = !isWithin || !isMcatDate

  const isDateDisabled: boolean = isNotIncludedInCourse || isBeforeStartDate

  return (
    <CellHeader data-testid='CalendarRow-CellHeader'>
      {isHolidayToday ? (
        <Tooltip
          data-testid={`CalendarRow-Tooltip-${holidayName}`}
          tooltipContent={holidayName}
          id={cellFullDay}
        >
          <DayNumber
            data-testid='CalendarRow-DayNumber'
            isHoliday={isHolidayToday}
            isStartDate={isStartDate}
            isMcatDate={isMcatDate}
            isToday={isToday}
            isDisabled={isDateDisabled}
          >
            {dayNumber === 1
              ? `${getMonthName(month)} ${dayNumber}`
              : dayNumber}
          </DayNumber>
        </Tooltip>
      ) : (
        <DayNumber
          data-testid={`CalendarRow-DayNumber-${dayNumber}`}
          isToday={isToday}
          isStartDate={isStartDate}
          isMcatDate={isMcatDate}
          isHoliday={false}
          isDisabled={isDateDisabled}
        >
          {isStartDate || isFirstVisibleSunday || dayNumber === 1
            ? `${getMonthName(month)} ${dayNumber}`
            : dayNumber}
          {!isEmptyMcatDay && isMcatDate && (
            <MacatLabel data-testid='CalendarRow-McatLabel'>MCAT</MacatLabel>
          )}
        </DayNumber>
      )}
      {totalTime > 0 && (isWithin || isFreeTrial) ? (
        <TotalTime data-testid='CalendarRow-TotalTime' totalTime={totalTime}>
          <StyledClockIcon />
          {formatMinutesToHoursAndMinutes(totalTime)}
        </TotalTime>
      ) : isMcatDate ? (
        <PartySaltyImage
          data-testid='CalendarRow-PartSaltyImage'
          id='party-salty'
          src={McatDaySalty}
          alt='Salty celebration'
        />
      ) : (
        <div />
      )}
    </CellHeader>
  )
}

export default CalendarCellHeader

const CellHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DayNumber = styled.div<{
  isToday: boolean
  isHoliday: boolean
  isStartDate: boolean
  isMcatDate: boolean
  isDisabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: relative;
  z-index: 13;
  font-weight: bold;
  margin: 4px 0 0 4px;

  ${({ isToday, isHoliday, isStartDate, isMcatDate, isDisabled }) => {
    if (isToday && isHoliday && isDisabled) {
      return css`
        color: ${({ theme }) => theme.colors.main.text};
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (isToday && isHoliday) {
      return css`
        color: #fff;
        min-width: 22px;
        max-width: 22px;
        min-height: 22px;
        max-height: 22px;
        border-radius: 100%;
      `
    } else if (isStartDate) {
      return css`
        background-color: #119b4c;
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (isToday) {
      return css`
        background-color: ${({ theme }) => theme.colors.main.black};
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (isToday) {
      return css`
        background-color: ${({ theme }) => theme.colors.main.black};
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (isHoliday) {
      return css`
        cursor: help;
        color: red;
      `
    } else if (isMcatDate) {
      return css`
        background-color: ${({ theme }) =>
          theme?.colors?.calendar.exam.orange.tag};
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    }
  }}
`

const TotalTime = styled.div<{
  isMcatDate?: boolean
  totalTime: number
}>`
  display: ${({ isMcatDate }) => (isMcatDate ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  font-size: ${({ totalTime }) => (totalTime > 540 ? '10px' : '11px')};
  color: ${({ theme }) => theme.colors.main.grey900};
  padding-right: 8px;
  gap: 2px;
`

const StyledClockIcon = styled(ClockIcon)`
  font-size: 16px !important;
`

const PartySaltyImage = styled.img`
  position: absolute;
  top: -10px;
  right: 0;
  width: 120px;
  z-index: 11;
`

const MacatLabel = styled.div`
  margin-left: 5px;
`
