export const TasksType = {
  BIO: 'bio',
  CAR: 'car',
  CHE: 'che',
  PHY: 'phy',
  ORG: 'org',
  PSY: 'psy',
  CUSTOM: 'custom',
  EXAMS: 'exams',
  INTRO_TASK_CALENDAR: 'intro_task_calendar',
  INTRO_TASK_ONBOARDING: 'intro_task_onboarding',
  LAB: 'lab',
  NO_TASK_REMAIN: 'no_task_remain',
  EK: 'ek-'
} as const

export const calendarEventType = {
  chapter: 'book_link',
  fullLengthExam: 'full_length_exam',
  fullLengthExamReview: 'full_length_exam_review',
  chapterExam: 'chapter_exam',
  chapterExamReview: 'chapter_exam_review',
  sectionExam: 'section_exam',
  sectionExamReview: 'section_exam_review',
  halfLengthExam: 'half_length_exam',
  customEventType: 'custom_event_type',
  customFullLengthExamReview: 'custom_full_length_exam_review',
  customSectionExamReview: 'custom_section_exam_review',
  customSectionExam: 'custom_section_exam',
  customFullLengthExam: 'custom_full_length_exam',
  custom: 'custom',
  otherExam: 'other_exam',
  otherExamReview: 'other_exam_review',
  bookLinkRreReading: 'book_link_pre_reading',
  liveClass: 'live_class',
  customLiveClass: 'custom_live_class', // this enum value is for alternative live classes chosen by student
  customEndDateEvent: 'custom_end_date_event',
  onboarding: '1',
  calendar: '2'
  // this enum value is for custom classes created by Admin
}

export const tasksImage = {
  [TasksType.BIO]: {
    [calendarEventType.chapter]: '/assets/tasks/bio_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/bio_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/bio_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.CAR]: {
    [calendarEventType.chapter]: '/assets/tasks/car_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/car_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/car_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.CHE]: {
    [calendarEventType.chapter]: '/assets/tasks/che_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/che_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/che_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.PHY]: {
    [calendarEventType.chapter]: '/assets/tasks/phy_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/phy_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/phy_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.ORG]: {
    [calendarEventType.chapter]: '/assets/tasks/org_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/org_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/org_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.PSY]: {
    [calendarEventType.chapter]: '/assets/tasks/psy_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/psy_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/psy_class.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.CUSTOM]: {
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customEventType]: '/assets/tasks/admin_custom_task.svg',
    [calendarEventType.customFullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.customFullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.custom]: '/assets/tasks/student_custom_task.svg',
    [calendarEventType.customLiveClass]: '/assets/tasks/admin_custom_class.svg',
    [calendarEventType.customEndDateEvent]:
      '/assets/tasks/admin_custom_class.svg',
    [calendarEventType.otherExam]: '/assets/tasks/exams.svg',
    [calendarEventType.otherExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.halfLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/tasks/admin_custom_task.svg',
    [calendarEventType.halfLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.onboarding]: '/assets/tasks/intro_task_onboarding.png',
    [calendarEventType.calendar]: '/assets/tasks/intro_task_calendar.png'
  },
  [TasksType.EK]: {
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.otherExam]: '/assets/tasks/exams.svg',
    [calendarEventType.otherExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customFullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.customFullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg'
  },
  [TasksType.INTRO_TASK_CALENDAR]: {},
  [TasksType.INTRO_TASK_ONBOARDING]: {},
  [TasksType.LAB]: {
    [calendarEventType.chapter]: '/assets/tasks/lab_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/tasks/lab_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/tasks/exams.svg',
    [calendarEventType.fullLengthExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/tasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/tasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/tasks/lab_class.svg',
    [calendarEventType.customSectionExamReview]: '/assets/tasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/tasks/exams.svg'
  },
  [TasksType.NO_TASK_REMAIN]: {
    [TasksType.NO_TASK_REMAIN]: '/assets/tasks/no_task_remain.svg'
  }
}

export const upcomingTasksImage = {
  [TasksType.BIO]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/bio_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/bio_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/bio_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.CAR]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/car_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/car_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/car_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.CHE]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/che_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/che_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/che_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.PHY]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/phy_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/phy_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/phy_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.ORG]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/org_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/org_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/org_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.PSY]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/psy_reading.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/psy_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/psy_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.CUSTOM]: {
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customEventType]:
      '/assets/upcomingTasks/admin_custom_task.svg',
    [calendarEventType.customFullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.customFullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.custom]: '/assets/upcomingTasks/student_custom_task.svg',
    [calendarEventType.customLiveClass]:
      '/assets/upcomingTasks/admin_custom_class.svg',
    [calendarEventType.customEndDateEvent]:
      '/assets/upcomingTasks/admin_custom_class.svg',
    [calendarEventType.otherExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.otherExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.bookLinkRreReading]:
      '/assets/upcomingTasks/admin_custom_task.svg',
    [calendarEventType.halfLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.onboarding]:
      '/assets/upcomingTasks/intro_task_onboarding.svg',
    [calendarEventType.calendar]:
      '/assets/upcomingTasks/intro_task_calendar.svg'
  },
  [TasksType.EK]: {
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.otherExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.otherExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customFullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.customFullLengthExamReview]: '/assets/upcomingTasks/reviews.svg'
  },
  [TasksType.INTRO_TASK_CALENDAR]: {},
  [TasksType.INTRO_TASK_ONBOARDING]: {},
  [TasksType.LAB]: {
    [calendarEventType.chapter]: '/assets/upcomingTasks/lab_reading.svg',
    [calendarEventType.bookLinkRreReading]: '/assets/upcomingTasks/lab_reading.svg',
    [calendarEventType.fullLengthExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.fullLengthExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.chapterExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.chapterExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.sectionExam]: '/assets/upcomingTasks/exams.svg',
    [calendarEventType.sectionExamReview]: '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.liveClass]: '/assets/upcomingTasks/lab_class.svg',
    [calendarEventType.customSectionExamReview]:
      '/assets/upcomingTasks/reviews.svg',
    [calendarEventType.customSectionExam]: '/assets/upcomingTasks/exams.svg'
  },
  [TasksType.NO_TASK_REMAIN]: {
    [TasksType.NO_TASK_REMAIN]: '/assets/upcomingTasks/no_task_remain.svg'
  }
}
