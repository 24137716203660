import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as R from 'ramda'

import { BOOK_VIEWER_PARAMS, HIGHLIGHT_MODES } from 'utils/books'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import {
  HighlightColorPicker,
  SelectSwitcher,
  HIGHLIGHT_BLOTS
} from 'examkrackers-components'
import { upsertBookResourcesHighlights } from 'services/BooksService'

import { isNotNilOrEmpty } from '../../../utils/ramda'
import { useDispatch } from 'react-redux'
import {
  upsertBookContentHighlightsRoutine,
  upsertBookContentAttachmentHighlightsRoutine
} from '../ducks/actions'
// import { useDispatch } from 'react-redux'
// import { fetchBookContentsPartialRoutine } from '../ducks/actions'

export const TopHandlers = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { push, location } = useHistory()
  const { search, pathname } = location
  const [selectedColor, setSelectedColor] = useState('')
  // const params = useParams()
  // const bookId: string = R.propOr('', 'bookId')(params)
  // const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  // const chapterOrder: number = R.pipe(
  //   R.propOr('1', 'chapterOrder'),
  //   Number
  // )(params)

  const removeHighlights = quill =>
    R.values(HIGHLIGHT_BLOTS).forEach(blot => quill.format(blot, false, 'api'))

  const upsertHighlights = useCallback(
    payload => dispatch(upsertBookContentHighlightsRoutine(payload)),
    [dispatch]
  )

  const upsertAttachmentHighlights = useCallback(
    payload => dispatch(upsertBookContentAttachmentHighlightsRoutine(payload)),
    [dispatch]
  )

  const handleHighlightBookContent = ({ quill, bookContent }) => {
    if (isNotNilOrEmpty(selectedColor)) {
      const bookContentId: string = R.propOr('', 'id')(bookContent)

      removeHighlights(quill)

      if (selectedColor !== 'color-remove') {
        quill.format(HIGHLIGHT_BLOTS[selectedColor], true, 'api')
      }

      const delta_object = quill.getContents()
      dispatch(upsertHighlights({ id: bookContentId, delta_object }))
    }
  }

  const handleHighlightAttachment = ({ quill, attachment }) => {
    if (isNotNilOrEmpty(selectedColor)) {
      const attachmentId: string = R.propOr('', 'id')(attachment)

      removeHighlights(quill)

      if (selectedColor !== 'color-remove') {
        quill.format(HIGHLIGHT_BLOTS[selectedColor], true, 'api')
      }

      const delta_object = quill.getContents()
      upsertAttachmentHighlights({
        id: attachmentId,
        delta_object
      })
    }
  }

  const handleHighlightResource = ({ quill, resource }) => {
    if (isNotNilOrEmpty(selectedColor)) {
      const resourceId: string = R.propOr('', 'id')(resource)

      removeHighlights(quill)

      if (selectedColor !== 'color-remove') {
        quill.format(HIGHLIGHT_BLOTS[selectedColor], true, 'api')
      }

      const delta_object = quill.getContents()
      upsertBookResourcesHighlights({ id: resourceId, delta_object })
    }
  }

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const highlightMode: string = R.propOr(
    HIGHLIGHT_MODES.none,
    BOOK_VIEWER_PARAMS.highlightMode,
    parsedQuery
  )

  const highlightOptions = R.pipe(
    R.keys,
    R.map(
      R.applySpec({
        label: key => t(`bookViewer.highlights.${key}`),
        value: key => HIGHLIGHT_MODES[key]
      })
    )
  )(HIGHLIGHT_MODES)

  // const fetchBookContentsForPart = React.useMemo(
  //   () =>
  //     dispatch(
  //       fetchBookContentsPartialRoutine({
  //         id: bookId,
  //         chapterOrder: chapterOrder,
  //         partOrder: partOrder
  //       })
  //     ),
  //   [dispatch, highlightMode]
  // )

  const handleChange = value => {
    // fetchBookContentsForPart
    if (highlightMode !== value) {
      const newQuery = qs.stringify({
        ...parsedQuery,
        [BOOK_VIEWER_PARAMS.highlightMode]: value
      })

      push(`${pathname}?${newQuery}`)
    }
  }
  // useEffect(() => {
  //   if (bookId && chapterOrder && partOrder) {
  //     fetchTotalPartData()
  //   }
  // }, [highlightMode, fetchTotalPartData, bookId])

  const isYoursSelected = R.equals(highlightMode, HIGHLIGHT_MODES.yours)

  const handleSelectColor = color => setSelectedColor(color)

  useEffect(() => {
    if (isYoursSelected) {
      // @ts-ignore
      eventEmitter.on(events.bookContentSelection, handleHighlightBookContent)
      // @ts-ignore
      eventEmitter.on(events.attachmentSelection, handleHighlightAttachment)
      // @ts-ignore
      eventEmitter.on(events.textResourceSelection, handleHighlightResource)
    }

    if (!isYoursSelected) {
      // @ts-ignore
      eventEmitter.off(events.bookContentSelection, handleHighlightBookContent)
      // @ts-ignore
      eventEmitter.off(events.attachmentSelection, handleHighlightAttachment)
      // @ts-ignore
      eventEmitter.off(events.textResourceSelection, handleHighlightResource)
    }

    return () => {
      // @ts-ignore
      eventEmitter.off(events.bookContentSelection, handleHighlightBookContent)
      // @ts-ignore
      eventEmitter.off(events.attachmentSelection, handleHighlightAttachment)
      // @ts-ignore
      eventEmitter.off(events.textResourceSelection, handleHighlightResource)
    }
  }, [selectedColor, highlightMode])

  return (
    <Container>
      {isYoursSelected && (
        <HighlightContainer>
          <HighlightColorPicker onChange={handleSelectColor} />
        </HighlightContainer>
      )}
      <SelectSwitcher
        label={t('bookViewer.highlights.title')}
        value={highlightMode}
        onChange={handleChange}
        id='select-highlight-mode'
        options={highlightOptions}
      />
    </Container>
  )
}

export default TopHandlers

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 6px;

  box-shadow: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.boxShadow};
  border-color: transparent;
  border-radius: 25px;
  min-height: 40px;
  background: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.background};
`

const HighlightContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: calc(100% + 5px);
  height: 100%;
`
