import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import qs from 'qs'
import FlashcardsFilterBox from 'modules/flashcards/components/FlashcardsFilterBox'
import { useHistory } from 'react-router-dom'
import {
  propOr,
  pipe,
  findIndex,
  equals,
  map,
  sortBy,
  prop,
  not,
  filter
} from 'ramda'
import { BOX_LEVELS, QUERY_PARAMS } from 'utils/flashcards'
import FlashcardFiltersBook from 'modules/flashcards/components/FlashcardFiltersBook'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { BouncingLoader, Button, DnDList } from 'examkrackers-components'
import { Course } from 'types'
import {
  getCurrentCourse,
  getCurseBooksOrder
} from 'modules/courses/ducks/selectors'
import { fetchCustomBoxesRoutine } from 'modules/flashcards/ducks/actions'
import FlashcardCustomBoxesRow, {
  CUSTOM_BOX_CATEGORY_ID
} from 'modules/flashcards/components/FlashcardCustomBoxesRow'
import { getCustomBoxes } from '../modules/flashcards/ducks/selectors'
import { isNilOrEmpty, isNotNilOrEmpty } from '../utils/ramda'
import PATHS from 'utils/paths'
import { setBooksOrder } from 'services/CourseService'
import LocalStorageService from '../services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from '../utils/storage'
import { fetchCurrentCourseRoutine } from '../modules/courses/ducks/actions'

import { getStudent } from 'modules/auth/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'

export const FlashcardBoxes = (): JSX.Element => {
  const { t } = useTranslation()
  const books = useSelector(selectAllBooks)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const flashcardsSnapshot = pipe(
    propOr('{}', 'flashcard_snapshot'),
    JSON.parse
  )(currentCourse)
  const dispatch = useDispatch()
  const allCustomBoxes: Course = useSelector(getCustomBoxes)
  const courseBooksOrder = useSelector(getCurseBooksOrder)
  const user = useSelector(getStudent)
  const [seeFlashcardsPage, setSeeFlashcardsPage] = useState(true)
  const [redirectLoading, setRedirectLoading] = useState(false)

  const totalFlashcards: number = propOr('0', 'amount', flashcardsSnapshot)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeFlashcardsPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeFlashcardsPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeFlashcardsPage(false)
    }
  }, [currentCourse, seeFlashcardsPage])

  const {
    replace,
    push,
    location: { search, pathname }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const currentMainExpandState = propOr(
    BOX_LEVELS.master,
    QUERY_PARAMS.mainExpandState,
    parsedQuery
  )

  const handleRedirectLoading = (value: { isLoading: boolean }) => {
    setRedirectLoading(value.isLoading)
  }

  useEffect(() => {
    fetchCustomBoxes()
    eventEmitter.on(events.flashcardRedirectLoading, handleRedirectLoading)

    return () => {
      eventEmitter.off(events.flashcardRedirectLoading, handleRedirectLoading)
    }
  }, [])

  const filteredBooksByFlashcardsAvailability = filter(
    pipe(propOr(false, 'flashcards_accessible'), equals(false), not)
  )(books)

  const boxes = isNotNilOrEmpty(allCustomBoxes)
    ? [...filteredBooksByFlashcardsAvailability, { id: CUSTOM_BOX_CATEGORY_ID }]
    : filteredBooksByFlashcardsAvailability

  const findBoxOrder = id => findIndex(equals(id))(courseBooksOrder)

  const orderedBoxes = pipe(
    map((box: { id: string }) => ({ ...box, order: findBoxOrder(box.id) })),
    sortBy(prop('order'))
  )(boxes)
  const redirectToArchive = () => push(PATHS.flashcardsArchive)
  const boxesWithFlashcards = orderedBoxes.filter(box => {
    // @ts-ignore
    const correspondingFlashcard = flashcardsSnapshot.books?.find(
      flashcard => flashcard.id === box.id
    )
    return correspondingFlashcard && correspondingFlashcard.amount > 0
  })

  useEffect(() => {
    const boxesIds = boxes.map(propOr('', 'id'))

    if (isNilOrEmpty(search)) {
      const newQuery = qs.stringify({
        [QUERY_PARAMS.mainExpandState]: BOX_LEVELS.book,
        [QUERY_PARAMS.expandedBookLevels]: [...boxesIds, CUSTOM_BOX_CATEGORY_ID]
      })

      replace(`${pathname}?${newQuery}`)
    }
  }, [books, search])

  const fetchCurrentCourse = React.useCallback(
    () => dispatch(fetchCurrentCourseRoutine()),
    [dispatch]
  )

  const handleDragAndDrop = value => {
    const newOrder: string[] = map(propOr('', 'id'))(value)
    const courseId: string = LocalStorageService.get(
      LOCAL_STORAGE_KEYS.studentCourseId
    )

    const payload = {
      list: newOrder,
      courseId
    }

    const oldOrder = boxesWithFlashcards.map(box => box.id)

    const handleSuccess = () => fetchCurrentCourse()

    const handleError = e => {
      console.error(e)
    }

    if (
      not(equals(oldOrder, newOrder)) &&
      isNotNilOrEmpty(currentCourse) &&
      isNotNilOrEmpty(books)
    ) {
      setBooksOrder(payload).then(handleSuccess).catch(handleError)
    }
  }

  if (redirectLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.flashcards.main')}</title>
      </Helmet>
      <HeadingContainer>
        <PageTitle>{t('flashcards.title')}</PageTitle>
        <Button
          color='secondary'
          size='small'
          id='flashcards-archive'
          onClick={redirectToArchive}
        >
          {t('flashcards.archive.redirectCTA')}
        </Button>
      </HeadingContainer>
      <Container>
        <Column>
          <FlashcardsFilterBox
            query=''
            count={totalFlashcards}
            name='All'
            filter=''
            filterId=''
          />
        </Column>
        {currentMainExpandState === BOX_LEVELS.book && (
          <BooksColumn>
            <Line />
            <DnDList
              itemClassName='book-filter'
              containerClassName='book-filters-container'
              items={boxesWithFlashcards}
              onChange={handleDragAndDrop}
              ChildComponent={RowBook}
            />
          </BooksColumn>
        )}
      </Container>
    </React.Fragment>
  )
}

export default FlashcardBoxes

const RowBook = ({ item }) => {
  return propOr('', 'id', item) === CUSTOM_BOX_CATEGORY_ID ? (
    <FlashcardCustomBoxesRow
      key={`flashcards-custom-box-${propOr('', 'id', item)}`}
    />
  ) : (
    <FlashcardFiltersBook
      key={`flashcards-box-${propOr('', 'id', item)}`}
      book={item}
    />
  )
}

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeadingContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 16px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  gap: 8px;
`

export const BooksColumn = styled.div`
  display: block;
  position: relative;

  .book-filters-container {
    margin-left: 16px;
    display: inline-flex;
    flex-direction: column;
    flex: none;
    gap: 8px;
    width: calc(100% - 16px);
  }

  .book-filter {
    background: ${props => props.theme.colors.backgrounds.main};
    border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
    padding: 8px;
    box-shadow: ${({ theme }) => theme.shadows.darkShadow} !important;
    flex: none;
    width: auto;
    align-self: start;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -16px;
      top: ${119 / 2}px;
      height: 1px;
      display: inline-block;
      width: 8px;
      background: ${({ theme }) => theme.colors.main.grey600};
    }
  }
`

export const Line = styled.div`
  position: absolute;
  left: 0;
  top: ${119 / 2}px;
  height: calc(100% - 119px);
  display: inline-block;
  width: 1px;
  background: ${({ theme }) => theme.colors.main.grey600};

  &::before {
    content: '';
    position: absolute;
    left: -7px;
    top: 0px;
    height: 1px;
    display: inline-block;
    width: 7px;
    background: ${({ theme }) => theme.colors.main.grey600};
  }
`

export const Row = styled.div`
  display: flex;
  flex: none;
  gap: 8px;
`

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
`
