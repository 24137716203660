import React, { useCallback } from 'react'
import { propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { initialiseCourse } from 'services/CourseService'

import { showToastRoutine } from 'modules/toast/ducks/actions'

import { isNotNilOrEmpty } from 'utils/ramda'
import { isDateBeforeToday, DATE_FORMATS, formatDate } from 'utils/date'
import PATHS from 'utils/paths'
import { SEVERITY } from 'utils/toast'

import { getStudent } from 'modules/auth/ducks/selectors'

import { Button } from 'examkrackers-components'

import AnalyticsService from 'services/AnalyticsService'
import {
  ANALYTICS_EVENTS,
  handleActionAnalytics,
  getCourseTypeForAnalyticsEvents
} from 'utils/analytics'
import { saveCourseStorageKeys } from 'utils/courses'
import useIsMobile from 'hooks/useIsMobile'

export const ButtonCourseAccess = ({
  course,
  lineBreak = false
}): JSX.Element => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(course.transaction_id)
  const transactionId = propOr('', 'transaction_id', course)

  const extendProduct = () => {
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
  }

  const isCalendarAvailable = course.original?.is_calendar_enabled

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const accessibleTo = propOr('', 'accessible_to', course)
  const isReady = propOr(false, 'is_ready', course)
  const isPaused = propOr(false, 'is_paused', course)
  const isStarted = isNotNilOrEmpty(accessibleTo)
  const isExpired = isDateBeforeToday(accessibleTo)

  const courseAnalysis = () => {
    AnalyticsService(user).logEvent(
      ANALYTICS_EVENTS[
        getCourseTypeForAnalyticsEvents(
          handleActionAnalytics(course),
          course?.type
        )
      ],
      {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': course?.accessible_to || false
      }
    )
  }

  const typeOfProduct = {
    course: 'course',
    exam: 'exam'
  }

  const handleInitialise = async productType => {
    const handleSuccess = () => {
      AnalyticsService(user).logEvent(
        ANALYTICS_EVENTS[
          getCourseTypeForAnalyticsEvents('start', course?.type)
        ],
        {
          Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
          'Course type': course?.type || false,
          'Course name': course?.title || false,
          'Course expiration date': course?.accessible_to || false,
          'Course class end date': `${course?.calendar_start_at}` || false
        }
      )
    }

    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    initialiseCourse({ id: course.id }).then(handleSuccess).catch(handleError)

    saveCourseStorageKeys(course)

    if (productType === typeOfProduct.course) {
      push({ pathname: PATHS.dashboardLoading, state: { course: course } })
    } else {
      push(PATHS.examLoading)
    }
  }

  const redirectToCourseDashboard = () => {
    courseAnalysis()
    saveCourseStorageKeys(course)
    push(isMobile ? PATHS.books : PATHS.dashboard)
  }

  const initialiseCourseOrRedirect = () =>
    isReady
      ? redirectToCourseDashboard()
      : handleInitialise(typeOfProduct.course)

  const getStartButton = () => (
    <ButtonsContainer lineBreak={lineBreak}>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>
      <Button
        data-testid={`ButtonCourseAccess-${course.type}-start-course`}
        variant='contained'
        size='small'
        color='secondary'
        onClick={initialiseCourseOrRedirect}
      >
        {t('courses.startCTA')}
      </Button>
    </ButtonsContainer>
  )

  const getContinueButton = () => (
    <ButtonsContainer lineBreak={lineBreak}>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>
      <Button
        data-testid={`ButtonCourseAccess-${
          course.type
        }-continue-course-calendar-${
          isCalendarAvailable ? 'available' : 'unavailable'
        }`}
        variant='contained'
        size='small'
        color='secondary'
        onClick={initialiseCourseOrRedirect}
      >
        {t('courses.continueCTA')}
      </Button>
    </ButtonsContainer>
  )

  const getInitialiseButton = () => (
    <ButtonsContainer lineBreak={lineBreak}>
      <Button
        data-testid={`ButtonCourseAccess-${course.type}-initialise`}
        variant='contained'
        size='small'
        color='secondary'
        onClick={initialiseCourseOrRedirect}
      >
        {t('courses.continueCTA')}
      </Button>
    </ButtonsContainer>
  )

  const getExpiredButtons = () => (
    <ButtonsContainer lineBreak={lineBreak}>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>
      <Button
        data-testid='ButtonCourseAccess-expired'
        variant='contained'
        size='small'
        color='secondary'
        onClick={() => {}}
        disabled
      >
        {t('courses.continueCTA')}
      </Button>
    </ButtonsContainer>
  )

  switch (true) {
    case isPaused:
    case isExpired:
      return getExpiredButtons()
    case isStarted && isReady:
      return getContinueButton()
    case isStarted && !isReady:
      return getInitialiseButton()
    default:
      return getStartButton()
  }
}

export default ButtonCourseAccess

const ButtonsContainer = styled.div<{ lineBreak?: boolean }>`
  display: flex;
  width: 100%;
  align-items: ${({ lineBreak }) => (lineBreak ? 'flex-start' : 'center')};
  justify-content: ${({ lineBreak }) =>
    lineBreak ? 'flex-start' : 'flex-end'};
  flex-direction: ${({ lineBreak }) => (lineBreak ? 'column' : 'row')};
  gap: ${({ lineBreak }) => (lineBreak ? '8px' : '16px')};
`
