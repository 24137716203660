export const VIDEO_CATEGORIES = {
    all: 'all',
    books: 'books',
    recordings: 'recordings',
    onboarding: 'onboarding',
    medreel: 'medreel',
    review: 'review',
    favourites: 'favourites'
}

export const ORDERED_CATEGORIES  = ['all', 'books', 'recordings', 'onboarding', 'medreel', 'review', 'favourites']