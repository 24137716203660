import * as React from 'react'
import styled from 'styled-components'
import { DATE_FORMATS, formatDate, convertTo12HourFormat } from 'utils/date'
import { TTutorClass } from 'types'
import ModalTutorSession from 'modules/tutoring/components/ModalTutorSession'

type Props = {
  tutorClass: TTutorClass
}

export const TutoringWeeklyScheduleTutor = (props: Props) => {
  return (
    <Container>
      <TutorImage
        src={
          props.tutorClass.tutor?.image_url ||
          '/assets/user/userPlaceholder.svg'
        }
      />
      <TutorDetails>
        <TutorName>{props.tutorClass?.tutor?.name || ''}</TutorName>
        <TutorSchedule>
          {formatDate(
            props.tutorClass.class_date,
            DATE_FORMATS.describedMonthDay
          )}{' '}
          {convertTo12HourFormat(props.tutorClass.class_time)} -{' '}
          {convertTo12HourFormat(props.tutorClass.class_time_end)} ET
        </TutorSchedule>
      </TutorDetails>
      <TutorCTAContainer>
        <ModalTutorSession tutorClass={props.tutorClass} />
      </TutorCTAContainer>
    </Container>
  )
}

export default TutoringWeeklyScheduleTutor

const TutorImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.dashboard.shadow};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  padding: 16px 8px;
  gap: 8px;
  max-height: 75px;
`

const TutorDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const TutorName = styled.div`
  font-size: 16px;
  font-weight: 900;
  line-height: 22.56px;
  font-family: 'Arial Black', Arial, sans-serif;
  color: ${({ theme }) => theme.colors.main.black};
`

const TutorSchedule = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  color: ${({ theme }) => theme.colors.main.grey700};
`

const TutorCTAContainer = styled.div`
  flex-grow: 1;
  text-align: right;
  height: 100%;
`
