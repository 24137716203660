import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialNavigation = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialNavigation)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialScroll)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialStart)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialNavigation
    })
  }, [])

  return (
    <>
      <ExamContent>
        <TutorialNavigationContainer>
          <div className='heavy-bordered'>
            <h2>Navigate the Questions</h2>
            <h3>Description</h3>
            <p>
              Within each section, you can navigate backward or forward, one
              question at a time. You can also skip questions and navigate them
              in the order you desire.
            </p>
            <h3>Instructions</h3>
            <p>
              Locate{' '}
              <strong>
                <span className='underline'>N</span>ext,{' '}
                <span className='underline'>P</span>revious,{' '}
              </strong>
              and{' '}
              <strong>
                Na<span className='underline'>v</span>igation
              </strong>{' '}
              on the bottom of the page.
            </p>
            <ul>
              <li>
                <strong>
                  <span className='underline'>N</span>ext
                </strong>{' '}
                or the Alt+N keyboard shortcut will advance the screen to the
                next question.
              </li>
              <li>
                <strong>
                  <span className='underline'>P</span>revious
                </strong>{' '}
                or the Alt+P keyboard shortcut will return to the previous
                question.
              </li>
              <li>
                <strong>
                  Na
                  <span className='underline'>v</span>
                  igation
                </strong>{' '}
                or the Alt+V keyboard shortcut allows you to skip questions and
                navigate them in the desired order through the review of a table
                of all questions in the section and their status.
              </li>
            </ul>
            <h3>Practice</h3>
            <p>
              Because you are in the tutorial, we recommend that you select{' '}
              <strong>
                <span className='underline'>N</span>
                ext
              </strong>{' '}
              or{' '}
              <strong>
                <span className='underline'>P</span>
                revious
              </strong>{' '}
              to navigate one page at a time.
            </p>
            <p>
              Select{' '}
              <strong>
                Na
                <span className='underline'>v</span>
                igation
              </strong>{' '}
              to review the table.
            </p>
            <ul>
              <li>
                The left column lists all the questions in the section, and the
                center column indicates the status of each question.
              </li>
            </ul>

            <p>
              Question statuses are shown as Complete, Incomplete, or Unseen.
              Select{' '}
              <strong>
                <span className='underline'>C</span>
                lose
              </strong>
              , <strong>ESC</strong> or Alt+C to close the{' '}
              <strong>
                Na
                <span className='underline'>v</span>
                igation
              </strong>{' '}
              table.
            </p>
            <p>
              Note that in the exam, the left column will show the question
              numbers instead of the tutorial page name.
            </p>
            <p>
              You can select the blue Question # column heading, the Status
              column heading, or the Flagged for Review column heading to sort
              by question number, status, or flagged status.
            </p>
            <p>
              In the exam, you can navigate to a question of your choice by
              selecting the question number with your mouse pointer.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </TutorialNavigationContainer>
      </ExamContent>
    </>
  )
}

export default TutorialNavigation

const TutorialNavigationContainer = styled.div`
  p {
    margin: 16px 0;
  }

  ul {
    margin-bottom: 25.888px;
  }
`
