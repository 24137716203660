import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getStudent } from 'modules/auth/ducks/selectors'

import { fetchCurrentCourseRoutine } from 'modules/courses/ducks/actions'
import { fetchAllBooksRoutine } from 'modules/books/ducks/actions'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { Course, RootReducer } from 'types'
import { getBooksNavigation } from 'modules/books/ducks/selectors'
import { previewStudentsEmail } from 'utils/user'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import * as R from 'ramda'
import PATHS from 'utils/paths'

const useAuthNav = () => {
  const dispatch = useDispatch()
  const { push, location } = useHistory()
  const { pathname } = location
  const { path } = useRouteMatch()
  const user = useSelector(getStudent)
  const booksNavigation = useSelector((state: RootReducer) =>
    getBooksNavigation(state, push)
  )
  const currentCourse: Course = useSelector(getCurrentCourse)
  const hasCourse = R.pipe(R.propOr('', 'id'), isNotNilOrEmpty)(currentCourse)
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
  const isPreviewStudentsEmail = user.email === previewStudentsEmail

  const fetchAllBooks = React.useCallback(
    () => dispatch(fetchAllBooksRoutine()),
    [dispatch]
  )
  const fetchCurrentCourse = React.useCallback(
    () => dispatch(fetchCurrentCourseRoutine()),
    [dispatch]
  )
  useEffect(() => {
    if (isPreviewStudentsEmail && isNilOrEmpty(booksNavigation)) {
      fetchAllBooks()
    }
  }, [user])
  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && isNilOrEmpty(booksNavigation)) {
      fetchAllBooks()
    }
  }, [user, path, currentCourse])
  useEffect(() => {
    if (
      path !== PATHS.selectCourse &&
      isNilOrEmpty(courseId) &&
      hasCourse &&
      !isPreviewStudentsEmail
    ) {
      push(PATHS.selectCourse)
    }
    if (path !== PATHS.selectCourse && isNotNilOrEmpty(courseId)) {
      fetchCurrentCourse()
    }
  }, [pathname, user])
}

export default useAuthNav
