import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Course } from 'types'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'
import { BouncingLoader } from 'examkrackers-components'
import EventCalendar from './EventCalendar'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import { getStudent } from 'modules/auth/ducks/selectors'
import { fetchArrayOfExamsAvailableInFreeTrialRoutine } from 'modules/exams/ducks/actions'
import { getExamsAvailableInFreeTrial } from 'modules/exams/ducks/selectors'
import { fetchBookIsFreeTrialFlagsRoutine } from 'modules/freeTrial/ducks/actions'

export const Calendar = (): JSX.Element => {
  const [seeCalendarPage, setSeeCalendarPage] = useState(true)
  const [examsInfoSaved, setExamsInfoSaved] = useState(false)
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const examsAvailableInFreeTrial = useSelector(getExamsAvailableInFreeTrial)
  const user = useSelector(getStudent)

  const fetchBookIsFreeTrialFlags = React.useCallback(
    () => dispatch(fetchBookIsFreeTrialFlagsRoutine()),
    [dispatch, currentCourse]
  )

  const fetchExamsAvailableInFreeTrial = React.useCallback(
    () => dispatch(fetchArrayOfExamsAvailableInFreeTrialRoutine()),
    [dispatch]
  )

  const hasCourseFetched = R.pipe(
    R.propOr('', 'id'),
    isNotNilOrEmpty
  )(currentCourse)

  useEffect(() => {
    if (
      isNotNilOrEmpty(currentCourse.id) &&
      isNilOrEmpty(examsAvailableInFreeTrial) &&
      !examsInfoSaved
    ) {
      fetchExamsAvailableInFreeTrial()
      setExamsInfoSaved(true)
    }

    if (isNotNilOrEmpty(currentCourse.id)) {
      fetchBookIsFreeTrialFlags()
    }
  }, [currentCourse, fetchExamsAvailableInFreeTrial, examsInfoSaved])

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeCalendarPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeCalendarPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeCalendarPage(false)
    }
  }, [currentCourse, seeCalendarPage])

  if (!hasCourseFetched) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.calendar')}</title>
      </Helmet>
      <EventCalendar />
    </React.Fragment>
  )
}

export default Calendar

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
