// module/exams/ducks/reducer.ts - Exam Saga Reducer

import {
  fetchExamLogsListRoutine,
  fetchExamsListRoutine,
  fetchArrayOfExamsAvailableInFreeTrialRoutine
} from 'modules/exams/ducks/actions'
import * as R from 'ramda'
import { Action, ExamsReducer } from 'types'
import { API_STATES } from 'utils/redux'

export const initialState: ExamsReducer = {
  state: API_STATES.PRISTINE,
  exams: [],
  examsAvailableInFreeTrial: [],
  logs: [],
  logsPagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  },
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

// Exams Reducer
export default (state = initialState, action: Action): ExamsReducer => {
  switch (action.type) {
    case fetchExamsListRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchExamsListRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchExamsListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        exams: action.payload.data,
        pagination: R.mapObjIndexed(value => Number(value), action.payload.meta)
      }
    case fetchExamLogsListRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchExamLogsListRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }

    case fetchExamLogsListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        logs: action.payload.data,
        logsPagination: R.mapObjIndexed(
          value => Number(value),
          action.payload.meta
        )
      }
    case fetchArrayOfExamsAvailableInFreeTrialRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchArrayOfExamsAvailableInFreeTrialRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchArrayOfExamsAvailableInFreeTrialRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        examsAvailableInFreeTrial: action.payload.data
      }
    default:
      return state
  }
}
